export enum CreationMode {
  New = 1,
  Edit,
}

export enum DataCreationMode {
  New = 1,
  Edit,
  Copy
}
