<span
  class="text"
  [ngStyle]="{'max-width.px': width ? width : ''}"
  container="body"
  [ngbTooltip]="tooltip"
  [placement]="tooltipPlacement"
>
  {{text}}
</span>

<span
  #ruler
  class="ruler"
>
  {{text}}
</span>
