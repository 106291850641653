import { Injectable } from '@angular/core';
import {LocationScreen} from '@models/location-screen';
import {ApiService} from '@services/api.service';
import {SortingOptions} from '@models/sorting-options';
import {ViewFilterOptions} from '@models/view-filter-options';
import {PaginationOptions} from '@shared/pagination/pagination-options';
import {PaginationData} from '@models/pagination-data';
import {Characteristic} from '@models/characteristic';
import {UserService} from '@user/user.service';
import {LocationNode} from '@models/location-node';
import {ProcessChart} from '@models/process-chart';


@Injectable()
export class TreeDataApiService {
  constructor(private apiService: ApiService) { }

  public getAllCharacteristic(sortingOptions: SortingOptions, filterOptions: ViewFilterOptions)
    : Promise<PaginationData> {

    const relativeUrl = `characteristic/pagList/all`;

    const body = {
      plantId: filterOptions.selectedPlant,
      departmentId: filterOptions.selectedDepartment,
      textFilter: filterOptions.filterQuery,
      favorite: filterOptions.favorites,
      sortingType: sortingOptions.sortingType,
      sortingMode: sortingOptions.sortingMode
    };

    return this.apiService.post(relativeUrl, body);
  }

  public getPaginatedCharacteristic(sortingOptions: SortingOptions, filterOptions: ViewFilterOptions, paginationOptions: PaginationOptions)
    : Promise<PaginationData> {
    const {itemsPerPage, pageNumber} = paginationOptions;
    const relativeUrl = `characteristic/pagList/${itemsPerPage}/${pageNumber}`;

    const body = {
      plantId: filterOptions.selectedPlant,
      departmentId: filterOptions.selectedDepartment,
      textFilter: filterOptions.filterQuery,
      favorite: filterOptions.favorites,
      sortingType: sortingOptions.sortingType,
      sortingMode: sortingOptions.sortingMode
    };

    return this.apiService.post(relativeUrl, body);
  }

  public getCharacteristicList(): Promise<Characteristic[]> {
    const loggedUser = UserService.getLoggedUser();

    if (!loggedUser) {
      this.apiService.logout();
      return;
    }

    const relativeUrl = `characteristic/list/${loggedUser.id}`;

    return this.apiService.get(relativeUrl);
  }

  public getCharacteristicTree(): Promise<LocationNode[]> {
    const relativeUrl = `characteristic/LocationTreeShort`;

    return this.apiService.get(relativeUrl);
  }

  public getProcessChartList(): Promise<ProcessChart[]> {
    const loggedUser = UserService.getLoggedUser();

    if (!loggedUser) {
      this.apiService.logout();
      return;
    }

    const relativeUrl = `ProcessChart/list/${loggedUser.id}`;

    return this.apiService.get(relativeUrl);
  }

  public getProcessChartTree(): Promise<LocationNode[]> {
    const relativeUrl = `ProcessChart/LocationTreeShort`;

    return this.apiService.get(relativeUrl);
  }

  public getLocationScreenList(): Promise<LocationScreen[]> {
    const loggedUser = UserService.getLoggedUser();

    if (!loggedUser) {
      this.apiService.logout();
      return;
    }

    const relativeUrl = `LocationScreen/list/${loggedUser.id}`;

    return this.apiService.get(relativeUrl);
  }

  public getLocationScreenTree(): Promise<LocationNode[]> {
    const relativeUrl = `LocationScreen/LocationTreeShort`;

    return this.apiService.get(relativeUrl);
  }
}
