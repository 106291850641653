<app-modal-basic
  #modal
  [dialogClass]="'modal-lg'"
  xmlns="http://www.w3.org/1999/html">
  <div class="app-modal-header my-0 mx-auto">
    <h4 class="modal-title">
      {{componentsTranslation.reactionPlan_modalTitle}}
    </h4>

    <button
      type="button"
      class="close basic-close"
      (click)="closeModal()">
      <span aria-hidden="true">
        &times;
      </span>
    </button>
  </div>

  <div class="app-modal-body">
    <div class="card shadow-none">
      <div *ngIf="characteristic?.severity"
           class="col-sm-4 severity">
        <h5 class="modal-title">
          {{componentsTranslation.reactionPlan_severity}}
        </h5>
      </div>

      <div class="card-block borderBottom">
        <div class="row">
          <div class="col-sm-4 text-right">
            {{componentsTranslation.reactionPlan_characteristicName}}
          </div>

          <div class="col-sm-8">
            {{characteristic.name}}
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4 text-right">
            {{componentsTranslation.reactionPlan_characteristicDescription}}
          </div>

          <div class="col-sm-8">
            {{characteristic.description}}
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4 text-right">
            {{componentsTranslation.reactionPlan_dateTimeViolation}}
          </div>

          <div class="col-sm-8">
            <ng-container *ngIf="reactionPlanModel.dateCreated">
              {{reactionPlanModel.dateCreated | date:'M/d/yy HH:mm a'}}
            </ng-container>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4 text-right">
            {{componentsTranslation.reactionPlan_rulesViolated}}:
          </div>

          <div class="col-sm-8">
            <div class="row">
              <ng-container *ngIf="reactionPlanModel">
                <ng-container *ngFor="let rule of rules">
                  <div *ngIf="isRuleBroken(rule)"
                       class="col-md-12">
                    <b>{{ rule.ruleId }}</b> - {{ rule.name }}
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4 text-right">
            {{componentsTranslation.reactionPlan_valueLabel}}
          </div>

          <div class="col-sm-8">
            {{reactionPlanModel.value}}
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4 text-right">
            {{componentsTranslation.reactionPlan_uslLabel}}
          </div>

          <div class="col-sm-8">
            {{reactionPlanModel.usl}}
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4 text-right">
            {{componentsTranslation.reactionPlan_lslLabel}}
          </div>

          <div class="col-sm-8">
            {{reactionPlanModel.lsl}}
          </div>
        </div>
      </div>

      <div class="card-block">
        <div class="subTitle">
          <h4 class="modal-title">
            {{componentsTranslation.reactionPlan_operationInput}}
          </h4>
        </div>

        <form
          #reactionPlan="ngForm"
          class="form-material"
          id="reactionPlanOperations"
          (ngSubmit)="onSubmitOperationsReaction()">
          <div class="card-block borderBottom">
            <div class="row form-group action-row">
              <div class="col-md-4">
                <div class="process-checkbox">
                  <div class="label-process">
                    {{componentsTranslation.reactionPlan_processStopped}}
                  </div>

                  <div class="checkbox-color"
                       [ngClass]="(reactionPlanModel.completedPlan || userAccessBlocked) && 'checkbox-disabled'">
                    <input
                      id="processStopped"
                      name="processStopped"
                      type="checkbox"
                      [(ngModel)]="reactionPlanModel.processStopped"
                      [disabled]="reactionPlanModel.completedOperations || reactionPlanModel.completedPlan || userAccessBlocked"/>
                    <label for="processStopped"></label>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="position-relative my-0 mx-3">
                  <ng-select
                    required
                    class="ng-select"
                    labelForId="actionTakenOptions"
                    name="actionTakenOptions"
                    [searchable]="false"
                    [clearable]="false"
                    [placeholder]="componentsTranslation.reactionPlan_actionTakenLabel"
                    [items]="actionTakenOptions"
                    [bindLabel]="'label'"
                    [bindValue]="'value'"
                    [disabled]="reactionPlanModel.completedOperations || reactionPlanModel.completedPlan || userAccessBlocked"
                    [ngClass]="{'cursor-disabled': reactionPlanModel.completedPlan || userAccessBlocked}"
                    [(ngModel)]="reactionPlanModel.actionTaken">
                  </ng-select>

                  <div *ngIf="reactionPlan.submitted && !reactionPlanModel.actionTaken"
                       class="messages text-danger text-center">
                    {{globalTranslation.isRequired}}
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="!(supervisorAuthorized || qualityEngineerAuthorized)"
                 class="row form-group">
              <label class="col-sm-3 col-form-label">
                {{componentsTranslation.reactionPlan_radioButtonDeviation}}
              </label>

              <div class="col-sm-3">
                <app-vk-input
                  type="number"
                  name="deviationValue"
                  min="0"
                  step="1"
                  [required]="true"
                  [(ngModel)]="reactionPlanModel.deviationValue"
                  [disabled]="reactionPlanModel.completedOperations || reactionPlanModel.completedPlan || userAccessBlocked">
                </app-vk-input>

                <div *ngIf="reactionPlan.submitted && reactionPlanModel.deviationValue === null"
                     class="messages text-danger text-center mb-3"
                     style="margin-top: -20px;">
                  {{globalTranslation.isRequired}}
                </div>
              </div>
            </div>

            <div class="row form-group">
              <label class="col-sm-3 col-form-label">
                {{componentsTranslation.reactionPlan_operatorInitialsLabel}}
              </label>

              <div class="col-sm-3">
                <app-vk-input
                  type="text"
                  maxlength="50"
                  name="operatorInitials"
                  [required]="true"
                  [(ngModel)]="reactionPlanModel.operatorInitials"
                  [disabled]="true">
                </app-vk-input>
                <!-- "reactionPlanModel.completedOperations || reactionPlanModel.completedPlan || userAccessBlocked"> -->

                <div *ngIf="reactionPlan.submitted && !reactionPlanModel.operatorInitials"
                     class="messages text-danger">
                  {{componentsTranslation.reactionPlan_operatorInitialsValidationMessage}}
                </div>
              </div>
            </div>

            <div class="row form-group">
              <label class="col-sm-3 col-form-label">
                {{componentsTranslation.reactionPlan_operatorCommentLabel}}
              </label>

              <div class="col-sm-9">
                <app-vk-input
                  type="textarea"
                  maxlength="1000"
                  name="operatorComment"
                  [required]="true"
                  [(ngModel)]="reactionPlanModel.operatorComment"
                  [disabled]="reactionPlanModel.completedOperations || reactionPlanModel.completedPlan || userAccessBlocked">
                </app-vk-input>

                <div *ngIf="reactionPlan.submitted && !reactionPlanModel.operatorComment"
                     class="messages text-danger">
                  {{componentsTranslation.reactionPlan_operatorCommentValidationMessage}}
                </div>
              </div>
            </div>

            <div class="row form-group">
              <div class="col-sm-12">
                <button
                  form="reactionPlanOperations"
                  class="btn btn-primary ripple light float-right"
                  type="submit"
                  [disabled]="reactionPlanModel.completedOperations || reactionPlanModel.completedPlan || userAccessBlocked
                              || reactionPlanModel.actionTaken == null || reactionPlanModel.operatorInitials == null || reactionPlanModel.operatorComment == null"
                  [ngClass]="(reactionPlanModel.completedPlan || userAccessBlocked) && 'disabled cursor-disabled'"
                >
                  {{componentsTranslation.reactionPlan_completeBtn}}
                </button>
              </div>
            </div>
          </div>
        </form>

        <form
          #reactionPlan="ngForm"
          class="form-material"
          id="reactionPlanCompleted"
          (ngSubmit)="onSubmitCompletedReaction()">
          <div class="card-block borderBottom">
            <div class="subTitle">
              <h4 class="modal-title">
                {{componentsTranslation.reactionPlan_qualityInput}}
              </h4>
            </div>
            <div class="row mx-0 my-5">
              <div class="col-sm-12 sign-off-button-wrapper">
                <button
                  class="btn btn-primary ripple light"
                  style="width: 140px"
                  type="button"
                  [disabled]="!reactionPlanModel.completedOperations || !characteristic.supervisorSignOff || supervisorAuthorized || reactionPlanModel.completedPlan || userAccessBlocked"
                  [ngClass]="(!characteristic.supervisorSignOff || supervisorAuthorized || reactionPlanModel.completedPlan || userAccessBlocked) && 'disabled cursor-disabled'"
                  (click)="supervisorSingOff()"
                >
                  {{componentsTranslation.reactionPlan_supervisorSignOff}}
                </button>

                <button
                  class="btn btn-primary ripple light"
                  style="width: 140px"
                  type="button"
                  [disabled]="!reactionPlanModel.completedOperations || !characteristic.qualitySignOff || qualityEngineerAuthorized || reactionPlanModel.completedPlan || userAccessBlocked"
                  [ngClass]="(!characteristic.qualitySignOff || qualityEngineerAuthorized || reactionPlanModel.completedPlan || userAccessBlocked) && 'disabled cursor-disabled'"
                  (click)="qualitySingOff()"
                >
                  {{componentsTranslation.reactionPlan_qualitySignOff}}
                </button>
              </div>
            </div>
          </div>
        </form>

        <ng-container *ngIf="characteristic.supervisorSignOff && (reactionPlanModel.supervisorSignOffComplete || supervisorAuthorized)">
          <app-signature
            [signatureTitle]="componentsTranslation.reactionPlan_svFormTitle"
            [signaturePersonLabel]="componentsTranslation.reactionPlan_svFormInitials"
            [reactionPlanModel]="reactionPlanModel"
            [signatureType]="signatureTypes.Supervisor"
            [initialValidationMessage]="componentsTranslation.reactionPlan_svFormInitialsValidationMsg"
          >
          </app-signature>
        </ng-container>

        <ng-container *ngIf="characteristic.qualitySignOff && (reactionPlanModel.qualityEngSignOffComplete || qualityEngineerAuthorized)">
          <app-signature
            [signatureTitle]="componentsTranslation.reactionPlan_qeFormTitle"
            [signaturePersonLabel]="componentsTranslation.reactionPlan_qeFormLabel"
            [reactionPlanModel]="reactionPlanModel"
            [signatureType]="signatureTypes.QualityEngineer"
            [initialValidationMessage]="componentsTranslation.reactionPlan_qeFormInitialsValidationMsg"
            [materialDispositionOptions]="this.materialDispositionOptions"
          >
          </app-signature>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="app-modal-footer">
    <button
      type="button"
      class="btn btn-default ripple"
      (click)="closeModal()">
      {{globalTranslation.cancelBtn}}
    </button>

    <button
      form="reactionPlanCompleted"
      class="btn btn-primary ripple light"
      type="submit"
      [disabled]="!isFormValid || reactionPlanModel.completedPlan || userAccessBlocked"
      [ngClass]="(!isFormValid || reactionPlanModel.completedPlan || userAccessBlocked) && 'disabled cursor-disabled'"
    >
      {{componentsTranslation.reactionPlan_completeBtn}}
    </button>
  </div>
</app-modal-basic>

<app-user-authorization
  [(openPermissionModal)]="openPermissionModal"
  [modalTitle]="checkPermissionModalTitle"
  [requiredPermission]="requiredPermission"
  (authorize)="onUserAuthorized($event)"
>
</app-user-authorization>
