export class ViewFilterOptions {
  public filterQuery: string = null;
  public selectedPlant: string = null;
  public selectedDepartment: string = null;
  public favorites = false;

  constructor(filterOptions: Partial<ViewFilterOptions> = {}) {
    Object.assign(this, filterOptions);
  }

  public equals(filterOptions: ViewFilterOptions): boolean {
    return !!filterOptions &&
      this.filterQuery === filterOptions.filterQuery &&
      this.selectedPlant === filterOptions.selectedPlant &&
      this.selectedDepartment === filterOptions.selectedDepartment &&
      this.favorites === filterOptions.favorites;
  }

  public isAnyFilterApplied(): boolean {
    return !!(this.filterQuery ||
      this.selectedPlant ||
      this.selectedDepartment ||
      this.favorites);
  }
}

export class CharacteristicViewFilterOptions extends ViewFilterOptions {
  public operation: string = null;
  public model: string = null;
  public part: string = null;
  public modifiedBy: string = null;
  public createdBy: string = null;
  public created: string = null;
  public showCMM: boolean = null;

  constructor(filterOptions?: Partial<CharacteristicViewFilterOptions>) {
    super(filterOptions);

    Object.assign(this, filterOptions);
  }

  public equals(filterOptions: CharacteristicViewFilterOptions): boolean {
    return super.equals(filterOptions) &&
      this.operation === filterOptions.operation &&
      this.model === filterOptions.model &&
      this.part === filterOptions.part &&
      this.modifiedBy === filterOptions.modifiedBy &&
      this.createdBy === filterOptions.createdBy &&
      this.created === filterOptions.created &&
      this.showCMM === filterOptions.showCMM;
  }

  public isAnyFilterApplied(): boolean {
    return super.isAnyFilterApplied() ||
      !!(this.operation ||
        this.model ||
        this.part ||
        this.modifiedBy ||
        this.createdBy ||
        this.showCMM);
  }
}
