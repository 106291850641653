import {Injectable} from '@angular/core';
import {EnvironmentType} from '@common/enums/environment-type';
import {GlobalVariable} from '@common/global';
import {environment} from '@environment/environment';
import {MenuTranslation} from '@models/translation';
import {TranslationService} from '@services/translation.service';
import {BehaviorSubject} from 'rxjs';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItem {
  disabled?: boolean;
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  permissions?: string[];
  children?: ChildrenItem[];
  badge?: BadgeItem[];
}

export interface MenuItem extends ChildrenItem {
  short_label?: string;
  main_state?: string;
  icon: string;
}

export interface Menu {
  label: string;
  main: MenuItem[];
}

@Injectable()
export class MenuItems {
  private readonly routePaths = GlobalVariable.ROUTE_PATHS;

  private menuTranslation: MenuTranslation;

  public readonly menuItems$ = new BehaviorSubject<Menu[]>([]);

  constructor(
    private translationService: TranslationService
  ) {
    this.setTranslation();
  }

  private setTranslation(): void {
    this.translationService.translations$.subscribe(translation => {
      this.menuTranslation = translation.menu;
      this.setMenu();
    });
  }

  public setMenu(): void {
    const mainMenuItems = this.createMenuItems();
    const menuItems = [{
      label: 'SPC',
      main: mainMenuItems
    }];

    this.menuItems$.next(menuItems);
  }

  private createMenuItems(): MenuItem[] {
    const dashboardMenuItem: MenuItem = {
      state: this.routePaths.dashboard,
      short_label: 'D',
      name: this.menuTranslation.dashboard,
      type: 'link',
      icon: 'feather icon-home',
      permissions: [],
    };

    const characteristicMenuItem: MenuItem = {
      state: this.routePaths.characteristic.root,
      short_label: 'C',
      name: this.menuTranslation.characteristic,
      type: 'sub',
      icon: 'feather icon-layers',
      children: [
        {
          state: this.routePaths.characteristic.view,
          name: this.menuTranslation.characteristicView,
          permissions: ['characteristic.view'],
        },
        {
          state: this.routePaths.characteristic.create,
          name: this.menuTranslation.characteristicCreate,
          permissions: ['characteristic.create'],
        }
      ]
    };

    const processChartMenuItem: MenuItem = {
      state: this.routePaths.processChart.root,
      short_label: 'C',
      name: this.menuTranslation.processChart,
      type: 'sub',
      icon: 'feather icon-bar-chart',
      children: [
        {
          state: this.routePaths.processChart.view,
          name: this.menuTranslation.processChartView,
          permissions: ['processChart.view']
        },
        {
          state: this.routePaths.processChart.create,
          name: this.menuTranslation.processChartCreate,
          permissions: ['processChart.create']
        },
      ]
    };

    const locationScreenMenuItem: MenuItem = {
      state: this.routePaths.locationScreen.root,
      short_label: 'C',
      name: this.menuTranslation.locationScreen,
      type: 'sub',
      icon: 'feather icon-cast',
      children: [
        {
          state: this.routePaths.locationScreen.view,
          name: this.menuTranslation.locationScreenView,
          permissions: ['locationScreen.view']
        },
        {
          state: this.routePaths.locationScreen.create,
          name: this.menuTranslation.locationScreenCreate,
          permissions: ['locationScreen.create']
        },
      ]
    };

    const cmmDataMenuItem: MenuItem = {
      state: this.routePaths.cmmData.root,
      short_label: 'C',
      name: this.menuTranslation.cmmData,
      type: 'sub',
      icon: 'ruler',
      permissions: ['CMMData.view'],
      children: [
        {
          state: this.routePaths.cmmData.view,
          name: this.menuTranslation.cmmDataView,
          permissions: []
        },
        {
          state: this.routePaths.cmmData.runChart,
          name: this.menuTranslation.cmmDataRunChart,
          permissions: []
        },
      ]
    };

    const administrationMenuItem: MenuItem = {
      state: this.routePaths.administration.root,
      short_label: 'C',
      name: this.menuTranslation.administration,
      type: 'sub',
      icon: 'feather icon-settings',
      permissions: [],
      children: [
        {
          state: this.routePaths.administration.users,
          name: this.menuTranslation.administrationUsers,
          permissions: ['users.general'],
        },
        {
          state: this.routePaths.administration.userRoles,
          name: this.menuTranslation.administrationRoles,
          permissions: ['userRoles.general'],
        },
        {
          state: this.routePaths.administration.permissions,
          name: this.menuTranslation.administrationPermissions,
          permissions: ['userRoles.general'],
        },
        {
          state: this.routePaths.administration.settings,
          name: this.menuTranslation.administrationSettings,
          permissions: ['settings.general'],
        },
        {
          state: this.routePaths.administration.units,
          name: this.menuTranslation.administrationUnits,
          permissions: ['dictionaries.general'],
        },
        ... GlobalVariable.isNotMexico
          ? [{
            state: this.routePaths.administration.fixture,
            name: this.menuTranslation.administrationFixtures,
            permissions: ['dictionaries.general']
          }]
          : [],
        {
          state: this.routePaths.administration.recycleBin,
          name: this.menuTranslation.administrationRecycleBin,
          permissions: ['dictionaries.general']
        },
        {
          state: this.routePaths.administration.parts,
          name: this.menuTranslation.administrationParts,
          permissions: ['dictionaries.general']
        },
        {
          state: this.routePaths.administration.partsComparison,
          name: this.menuTranslation.administrationPartMapping,
          permissions: ['dictionaries.general']
        },
        {
          state: this.routePaths.administration.sampleReasons,
          name: this.menuTranslation.administrationSampleReasons,
          permissions: ['dictionaries.general']
        },
        {
          state: this.routePaths.administration.reactionPlanReasons,
          name: this.menuTranslation.administrationReactionPlanReasons,
          permissions: ['dictionaries.general']
        },
        ... GlobalVariable.partModelVisibility
          ? [{
            state: this.routePaths.administration.partModels,
            name: this.menuTranslation.administrationPartModels,
            permissions: ['dictionaries.general']
          }]
          : [],
        {
          state: this.routePaths.administration.ruleSettings,
          name: this.menuTranslation.administrationRuleSettings,
          permissions: ['dictionaries.general']
        }
      ]
    };

    const reportsMenuItem: MenuItem = {
      state: this.routePaths.reports,
      short_label: 'R',
      name: this.menuTranslation.reports,
      type: 'link',
      icon: 'feather icon-pie-chart',
      permissions: []
    };

    return [
      dashboardMenuItem,
      characteristicMenuItem,
      ... GlobalVariable.isNotMexico ? [processChartMenuItem] : [],
      ... GlobalVariable.isNotMexico ? [locationScreenMenuItem] : [],
      ... GlobalVariable.isNotMexico ? [cmmDataMenuItem] : [],
      administrationMenuItem,
      reportsMenuItem
    ];
    // ... environment.type === EnvironmentType.Local ? [reportsMenuItem] : []
  }
}
