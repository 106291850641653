import { Component, OnInit, Input, ViewEncapsulation, HostListener } from '@angular/core';
import {HelpersService} from '@services/helpers.service';

interface MyEvent extends Event {
  target: any;
}

@Component({
  selector: 'app-modal-basic',
  templateUrl: './modal-basic.component.html',
  styleUrls: ['./modal-basic.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalBasicComponent implements OnInit {
  public visible = false;
  public visibleAnimate = false;
  public contentWidth = '100%';
  public contentHeight = '100%';

  @Input() dialogClass: string;
  @Input() hideHeader = false;
  @Input() hideFooter = false;
  @Input() slimModal = false;
  @Input() width = 1000;
  @Input() height = null;

  constructor() {}

  public ngOnInit(): void {
    this.setContentWidth();
    this.setContentHeight();
  }

  private setContentWidth(): void {
    this.contentWidth = HelpersService.valueToPixels(this.width);
  }

  private setContentHeight(): void {
    this.contentHeight = HelpersService.valueToPixels(this.height);
  }

  public show(): void {
    this.visible = true;
    setTimeout(() => this.visibleAnimate = true, 100);
  }

  public hide(): void {
    this.visibleAnimate = false;
    setTimeout(() => this.visible = false, 300);
  }

  @HostListener('window:resize', ['$event'])
  public setPosition(): void {
    // @ts-ignore
    const e: MyEvent = event;
    const width = e.target.innerWidth - 50;

    if (width < this.width) {
      this.contentWidth = HelpersService.valueToPixels(width);
    }
    if (width > this.width || width > 1000) {
      this.setContentWidth();
    }

    const height = e.target.innerHeight - 50;

    if (height < this.height) {
      this.contentHeight = HelpersService.valueToPixels(height);
    }
    if (height > this.height || height > 1000) {
      this.setContentHeight();
    }
  }
}
