export enum DataTableSorting {
  ByName,
  ByDateModified,
  ByUserCreatedBy,
  ByDateCreated,
  ByPlant,
  ByPart,
  ByCorrectiveActionCount,
  ByModel,
  ByUserModifiedBy
}
