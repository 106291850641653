<div class="w-100 text-center">
  <ng-container *ngIf="!isMulti">
    {{ content }}
  </ng-container>

  <ng-container *ngIf="isMulti">
    {{componentsTranslation.tableResult_multi}}

    <fa-icon
      class="p-0 cursor-pointer ml-1"
      [icon]="infoIcon"
      placement="top"
      container="body"
      [ngbTooltip]="content">
    </fa-icon>
  </ng-container>
</div>
