import {NgModule} from '@angular/core';
import {AngularResizeEventModule} from 'angular-resize-event';
import {ClickOutsideModule} from 'ng-click-outside';
import {CommonModule, DecimalPipe} from '@angular/common';
// import {DataTableModule} from 'angular2-datatable';
import {DirectivesModule} from '@common/directives/directives.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgxPaginationModule} from 'ngx-pagination';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from '@danielmoncada/angular-datetime-picker';
import {PipesModule} from '@common/pipes/pipes.module';
import {RouterModule} from '@angular/router';
import {TreeModule} from 'ng2-tree';
import {UiSwitchModule} from 'ngx-ui-switch';

import {AdministrationApiService} from '@administration/administration-api.service';
import {CharacteristicApiService} from '@characteristic/characteristic-api.service';
import {ChartService} from '@shared/charts/chart.service';
import {ProcessChartApiService} from '@shared/process-chart-api.service';
import {UserApiService} from '@user/user-api.service';
import {UserPhotoService} from '@user/user-photo.service';

import {AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective} from '@shared/accordion';
import {ToggleFullScreenDirective} from '@shared/fullscreen/toggle-fullscreen.directive';

import {AddSampleCollectionComponent} from '@shared/add-sample-collection/add-sample-collection.component';
import {AddSampleModalComponent} from '@shared/add-sample-modal/add-sample-modal.component';
import {BaseChartComponent} from '@shared/charts/base-chart/base-chart.component';
import {CardComponent} from '@shared/card/card.component';
import {UserAuthorizationComponent} from '@shared/user-authorization/user-authorization.component';
import {FiltersComponent} from '@shared/filters/filters.component';
import {LoaderComponent} from '@shared/loader/loader.component';
import {LocationPickComponent} from '@shared/location-pick/location-pick.component';
import {LocationTreeModalComponent} from '@shared/location-tree-modal/location-tree-modal.component';
import {MeanChartComponent} from '@shared/charts/mean-chart/mean-chart.component';
import {ModalBasicComponent} from '@shared/modal-basic/modal-basic.component';
import {PaginationComponent} from '@shared/pagination/pagination.component';
import {PreControlChartComponent} from '@shared/pre-control-chart/pre-control-chart.component';
import {ProcessChartComponent} from '@shared/process-chart/process-chart.component';
import {RangeChartComponent} from '@shared/charts/range-chart/range-chart.component';
import {ReactionPlanComponent} from '@shared/reaction-plan/reaction-plan.component';
import {ResponsiveTextLineComponent} from '@shared/responsive-text-line/responsive-text-line/responsive-text-line.component';
import {SampleInputComponent} from '@shared/add-sample-collection/sample-input/sample-input.component';
import {SpinnerComponent} from '@shared/spinner/spinner.component';
import {SwitchLanguageComponent} from '@shared/switch-language/switch-language.component';
import {TabFilterResultComponent} from '@shared/tab-filter-result/tab-filter-result.component';
import {TreeComponent} from '@shared/tree/tree.component';
import {VirtualKeyboardComponent} from '@shared/virtual-keyboard/virtual-keyboard.component';
import {VkInputComponent} from '@shared/vk-input/vk-input.component';
import {AddSamplesApiService} from '@shared/add-samples-api.service';
import {TableFilterApiService} from '@shared/table-filter-api.service';
import { FilterSelectorComponent } from '@shared/filters/filter-selector/filter-selector.component';
import { ModelsViewComponent } from '@shared/models-view/models-view.component';
import { SwitcherComponent } from '@shared/switcher/switcher.component';
import { SignatureComponent } from '@shared/reaction-plan/signature/signature.component';
import { HistogramComponent } from '@shared/charts/histogram/histogram.component';
import { BellCurveChartComponent } from '@shared/charts/bell-curve-chart/bell-curve-chart.component';
import { BoxPlotChartComponent } from './charts/box-plot-chart/box-plot-chart.component';
import { SingleMultiTemplateComponent } from './tab-filter-result/table-templates/single-multi-template/single-multi-template.component';
import { ExpandedSelectComponent} from '@shared/expanded-select/expanded-select.component';
import { AddPartMappingModalComponent } from '@shared/add-part-mapping-modal/add-part-mapping-modal.component';
import {NgScrollbarModule} from 'ngx-scrollbar';


@NgModule({
  imports: [
    AngularResizeEventModule,
    ClickOutsideModule,
    CommonModule,
    // DataTableModule,
    DirectivesModule,
    FontAwesomeModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
    NgxDatatableModule,
    NgxPaginationModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgScrollbarModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
    TreeModule,
    UiSwitchModule,
  ],
  exports: [
    AngularResizeEventModule,
    ClickOutsideModule,
    // DataTableModule,
    DirectivesModule,
    FontAwesomeModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
    NgxDatatableModule,
    NgxPaginationModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PipesModule,
    ReactiveFormsModule,
    TreeModule,
    UiSwitchModule,

    AccordionAnchorDirective,
    AccordionDirective,
    AccordionLinkDirective,
    ToggleFullScreenDirective,

    AddSampleCollectionComponent,
    AddSampleModalComponent,
    AddPartMappingModalComponent,
    CardComponent,
    FiltersComponent,
    LoaderComponent,
    LocationPickComponent,
    LocationTreeModalComponent,
    MeanChartComponent,
    ModalBasicComponent,
    PaginationComponent,
    PreControlChartComponent,
    ProcessChartComponent,
    RangeChartComponent,
    ReactionPlanComponent,
    ResponsiveTextLineComponent,
    SpinnerComponent,
    SwitchLanguageComponent,
    TabFilterResultComponent,
    TreeComponent,
    VirtualKeyboardComponent,
    VkInputComponent,
    ModelsViewComponent,
    FilterSelectorComponent,
    SwitcherComponent,
    HistogramComponent,
    BellCurveChartComponent,
    BoxPlotChartComponent,
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionDirective,
    AccordionLinkDirective,
    ToggleFullScreenDirective,

    AddSampleCollectionComponent,
    AddSampleModalComponent,
    AddPartMappingModalComponent,
    BaseChartComponent,
    CardComponent,
    UserAuthorizationComponent,
    FiltersComponent,
    LoaderComponent,
    LocationPickComponent,
    LocationTreeModalComponent,
    MeanChartComponent,
    ModalBasicComponent,
    PaginationComponent,
    PreControlChartComponent,
    ProcessChartComponent,
    RangeChartComponent,
    ReactionPlanComponent,
    ResponsiveTextLineComponent,
    SampleInputComponent,
    SpinnerComponent,
    SwitchLanguageComponent,
    TabFilterResultComponent,
    TreeComponent,
    VirtualKeyboardComponent,
    VkInputComponent,
    FilterSelectorComponent,
    ModelsViewComponent,
    SwitcherComponent,
    SignatureComponent,
    HistogramComponent,
    BellCurveChartComponent,
    BoxPlotChartComponent,
    SingleMultiTemplateComponent,
    ExpandedSelectComponent,
  ],
  bootstrap: [SwitchLanguageComponent],
  providers: [
    AdministrationApiService,
    AddSamplesApiService,
    CharacteristicApiService,
    ChartService,
    ProcessChartApiService,
    TableFilterApiService,
    UserApiService,
    UserPhotoService,
    DecimalPipe,
  ]
})
export class SharedModule {
}
