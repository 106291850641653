import {UserRole} from '@models/user-role';
import {DataViewMode} from '@services/user-settings.service';
import {Language} from '@common/enums/language';
import {DashboardViewMode} from '@dashboard/view/dashboard-view-mode';

export enum AvatarStatus {
  Initial = 0,
  Modified,
  Removed = -1
}

export class Credentials {
  login: string;
  password: string;
}

export class User {
  public active?: boolean;
  public adUser?: boolean;
  public apiPermissions?: string[];
  public apiPlantPermissions?: string[];
  public confirmPassword?: string;
  public currentPassword?: string;
  public email?: string;
  public firstName?: string;
  public forcePasswordChange?: boolean;
  public id?: number;
  public initials?: string;
  public language?: Language;
  public lastName?: string;
  public lastLoginDate?: Date;
  public listType?: DataViewMode;
  public locationId?: number | string;
  public login?: string;
  public masterDataId?: string;
  public password?: string;
  public photo?: string;
  public roles?: UserRole[];
  public rowQuantity?: number;
  public userName?: string;
  public avatarStatus?: AvatarStatus;
  public isUsed?: boolean;
  public dashboardCount?: number;
  public dashboardDefaultView?: DashboardViewMode;

  constructor(user: Partial<User> = {}) {
    Object.assign(this, user);
    this.avatarStatus = AvatarStatus.Initial;
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get credentials(): Credentials {
    return {
      login: this.userName,
      password: this.currentPassword
    };
  }
}
