import { Injectable } from '@angular/core';
import {PaginationData} from '@models/pagination-data';
import {TreeDataService} from '@services/tree-data.service';
import {TreeDataApiService} from '@services/tree-data-api.service';
import {SortingOptions} from '@models/sorting-options';
import {ViewFilterOptions} from '@models/view-filter-options';
import {PaginationOptions} from '@shared/pagination/pagination-options';
import {GlobalVariable} from '@common/global';

@Injectable()
export class CharacteristicTreeService extends TreeDataService {
  constructor(private treeDataApiService: TreeDataApiService) {
    super();

    this.currentTreeType = GlobalVariable.OPERATION_FILTER_TYPE.characteristic;
    this.dataListApiCall = () => treeDataApiService.getCharacteristicList();
    this.dataTreeApiCall = () => treeDataApiService.getCharacteristicTree();
  }

  public refreshCharacteristicData(
    sortingOptions?: SortingOptions, filterOptions?: ViewFilterOptions, paginationOptions?: PaginationOptions)
    : Promise<void> {
    const treeApiCall = this.isDataLoaded
      ? Promise.resolve()
      : this.dataTreeApiCall().then((dataTree) => {
        this._locationTreeData = dataTree;
        this.filteredTree$.next(dataTree);
        this.dataLoaded.next(true);
      });

    this.isDataLoaded = true;
    return treeApiCall.then(() => {
      this.getCharacteristicsList(sortingOptions, filterOptions, paginationOptions).then();
    });
  }

  public refreshCharacteristicsList(sortingOptions: SortingOptions, filterOptions: ViewFilterOptions, paginationOptions?: PaginationOptions)
    : Promise<number> {
    return this.getCharacteristicsList(sortingOptions, filterOptions, paginationOptions)
      .then(characteristicsCount => {
        this.filteredList$.next(this._dataList);

        return characteristicsCount;
      });
  }

  private getCharacteristicsList(sortingOptions: SortingOptions, filterOptions: ViewFilterOptions, paginationOptions?: PaginationOptions)
    : Promise<number> {
    const getCharacteristicsCall = !!paginationOptions
      ? this.treeDataApiService
        .getPaginatedCharacteristic(sortingOptions, filterOptions, paginationOptions)
        .then(paginatedData => paginatedData)
      : this.treeDataApiService
        .getAllCharacteristic(sortingOptions, filterOptions)
        .then(paginatedData => paginatedData);
      /*
      : this.dataListApiCall()
        .then(characteristics => <PaginationData>{count: characteristics.length, paginatedData: characteristics});*/

    return getCharacteristicsCall.then(characteristicsData => {
      this._dataList = characteristicsData.paginatedData;

      return characteristicsData.count;
    });
  }
}
