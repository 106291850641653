import { Injectable } from '@angular/core';
import {PlantRole} from '@models/plant-role';
import {ApiService} from '@services/api.service';
import {Fixture} from '@models/fixture';
import {LocationNode} from '@models/location-node';
import {Part} from '@models/part';
import {UserRole} from '@models/user-role';
import {Permission} from '@models/permission';
import {ReactionPlanReason} from '@models/reaction-plan-reason';
import {RecycleBinType} from '@administration/recycle-bin/recycle-bin/recycle-bin.component';
import {RecycledModel} from '@models/recycled-model';
import {SampleReason} from '@models/sample-reason';
import {Setting} from '@models/setting';
import {Unit} from '@models/unit';
import {User} from '@models/user';
import {PartModel} from '@models/part-model';
import {RuleSetting} from '@models/rule-setting';
import {LocationParts} from '@models/location-parts';
import {MappingFilters} from '@models/mapping-filters';
import {PartMappingFilter} from '@models/part-mapping-filter';
import {PartMapping} from '@models/part-mapping';

@Injectable()
export class AdministrationApiService {
  constructor(
    private apiService: ApiService
  ) {}

  // region Fixtures API
  public getFixtures(): Promise<Fixture[]> {
    const relativeUrl = `fixture`;

    return this.apiService.get(relativeUrl);
  }

  public addFixture(fixture: Fixture): Promise<any> {
    const relativeUrl = `fixture`;

    return this.apiService.post(relativeUrl, fixture);
  }

  public updateFixture(fixture: Fixture): Promise<any> {
    const relativeUrl = `fixture/${fixture.id}`;

    return this.apiService.put(relativeUrl, fixture);
  }

  public removeFixture(fixtureId: number): Promise<any> {
    const relativeUrl = `fixture/${fixtureId}`;

    return this.apiService.delete(relativeUrl);
  }
  // endregion

  // region Parts API
  public getPartsLocationTree(departmentId: string): Promise<LocationNode[]> {
    const relativeUrl = `PartNumber/PartNumberTree/${departmentId}`;

    return this.apiService.get(relativeUrl);
  }

  public getPartsByLocation(locationId: string): Promise<Part[]> {
    const relativeUrl = `PartNumber/PartList/${locationId}`;

    return this.apiService.get(relativeUrl);
  }

  public addPart(part: Part): Promise<any> {
    const relativeUrl = `PartNumber`;

    return this.apiService.post(relativeUrl, part);
  }

  public updatePart(part: Part): Promise<any> {
    const relativeUrl = `PartNumber/${part.usn}`;
    delete part.path;
    delete part.usn;
    delete part.parentId;
    delete part.partImages;

    return this.apiService.put(relativeUrl, part);
  }
  // endregion

  // region Permissions Matrix API
  public getUserRoles(): Promise<UserRole[]> {
    const relativeUrl = `userRoles/permissions`;

    return this.apiService.get(relativeUrl);
  }

  public getPermissions(): Promise<Permission[]> {
    const relativeUrl = `permissions`;

    return this.apiService.get(relativeUrl);
  }

  public updateUserRolePermission(userRoleId: number, permissionId: number, assigned: boolean): Promise<any> {
    const relativeUrl = `userRoles/permissions/${userRoleId}/${permissionId}/${assigned}`;

    return this.apiService.put(relativeUrl, null);
  }
  // endregion

  // region User Roles API
  public getUserRolesList(): Promise<UserRole[]> {
    const relativeUrl = `userRoles`;

    return this.apiService.get(relativeUrl);
  }

  public updateUserRole(userRole: UserRole): Promise<any> {
    const relativeUrl = `userRoles/${userRole.id}`;

    return this.apiService.put(relativeUrl, userRole);
  }

  public addUserRole(userRole: UserRole): any {
    const relativeUrl = `userRoles`;

    return this.apiService.post(relativeUrl, userRole);
  }

  public removeUserRole(userRoleId: number): any {
    const relativeUrl = `userRoles/${userRoleId}`;

    return this.apiService.delete(relativeUrl);
  }
  // endregion

  // region Rule Settings API
  public getRuleSettings(): Promise<RuleSetting[]> {
    const relativeUrl = `ruleSettings`;

    return this.apiService.get(relativeUrl);
  }

  public updateRuleSetting(ruleSetting: RuleSetting): Promise<any> {
    const relativeUrl = `ruleSettings/${ruleSetting.id}`;

    return this.apiService.put(relativeUrl, ruleSetting);
  }

  public addRuleSetting(ruleSetting: RuleSetting): any {
    const relativeUrl = `ruleSettings`;
    const ruleSettingNoId = {
      'ruleId' : ruleSetting.ruleId,
      'settingValue' : ruleSetting.settingValue
    };

    return this.apiService.post(relativeUrl, ruleSettingNoId);
  }

  public removeRuleSetting(ruleSettingId: number): any {
    const relativeUrl = `ruleSettings/${ruleSettingId}`;

    return this.apiService.delete(relativeUrl);
  }
  // endregion

  // region Units API
  public getUnits(): Promise<Unit[]> {
    const relativeUrl = `unit`;

    return this.apiService.get(relativeUrl);
  }

  public addUnit(unit: Unit): Promise<any> {
    const relativeUrl = `unit`;

    return this.apiService.post(relativeUrl, unit);
  }

  public updateUnit(unit: Unit): Promise<any> {
    const relativeUrl = `unit/${unit.id}`;

    return this.apiService.put(relativeUrl, unit);
  }

  public removeUnit(unitId: number): any {
    const relativeUrl = `unit/${unitId}`;

    return this.apiService.delete(relativeUrl);
  }
  // endregion

  // region Settings API
  public getSettings(): Promise<Setting> {
    const relativeUrl = `setting`;

    return this.apiService.get(relativeUrl);
  }

  public updateSettings(settings: Setting): Promise<any> {
    const relativeUrl = `setting`;

    return this.apiService.put(relativeUrl, settings);
  }
  // endregion

  // region Recycle Bin API
  public getRecycleBinData(recycleBinType: RecycleBinType): Promise<RecycledModel[]> {
    const relativeUrl = `${recycleBinType}/RecycleBin`;

    return this.apiService.get(relativeUrl);
  }

  public restoreModel(recycleBinType: RecycleBinType, modelId: number) {
    const relativeUrl = `${recycleBinType}/Reveal/${modelId}`;

    return this.apiService.put(relativeUrl, null);
  }

  public removeModel(recycleBinType: RecycleBinType, modelId: number) {
    const relativeUrl = `${recycleBinType}/${modelId}`;

    return this.apiService.delete(relativeUrl);
  }
  // endregion

  // region Reaction Plan API
  public getReactionPlanReasons(): Promise<ReactionPlanReason[]> {
    const relativeUrl = `ReactionPlanReasons`;

    return this.apiService.get(relativeUrl);
  }

  public removeReactionPlanReason(reactionPlanReasonId: number): Promise<any> {
    const relativeUrl = `ReactionPlanReasons/${reactionPlanReasonId}`;

    return this.apiService.delete(relativeUrl);
  }

  public addReactionPlanReason(reactionPlanReason: ReactionPlanReason): Promise<any> {
    const relativeUrl = `ReactionPlanReasons`;

    return this.apiService.post(relativeUrl, reactionPlanReason);
  }

  public updateReactionPlanReason(reactionPlanReason: ReactionPlanReason): Promise<any> {
    const relativeUrl = `ReactionPlanReasons/${reactionPlanReason.id}`;

    return this.apiService.put(relativeUrl, reactionPlanReason);
  }
  // endregion

  // region Sample Reason API
  public getDefaultSampleReasons(): Promise<SampleReason[]> {
    const relativeUrl = `SampleReason/default`;

    return this.apiService.get(relativeUrl);
  }

  public getSampleReasons(): Promise<SampleReason[]> {
    const relativeUrl = `SampleReason`;
    console.log('Sample Reason ulr:', relativeUrl);
    return this.apiService.get(relativeUrl);
  }

  public removeSampleReason(sampleReasonId: number): Promise<any> {
    const relativeUrl = `SampleReason/${sampleReasonId}`;

    return this.apiService.delete(relativeUrl);
  }

  public addSampleReason(sampleReason: SampleReason): Promise<any> {
    const relativeUrl = `SampleReason`;

    return this.apiService.post(relativeUrl, sampleReason);
  }

  public updateSampleReason(sampleReason: SampleReason): Promise<any> {
    const relativeUrl = `SampleReason/${sampleReason.id}`;

    return this.apiService.put(relativeUrl, sampleReason);
  }
  // endregion

  // region Users API
  public getUsersList(): Promise<User[]> {
    const relativeUrl = `user`;

    return this.apiService.get(relativeUrl);
  }

  public addUser(user: User): Promise<any> {
    const relativeUrl = `user`;

    return this.apiService.post(relativeUrl, user);
  }

  public updateUser(user: User): Promise<any> {
    const relativeUrl = `user/${user.id}`;

    return this.apiService.put(relativeUrl, user);
  }

  public getUserById(userId: number): Promise<User> {
    const relativeUrl = `user/${userId}`;

    return this.apiService.get(relativeUrl);
  }

  public getUserPermissionsByPlant(userId: number): Promise<PlantRole[]> {
    const relativeUrl = `user/${userId}/PlantRoles`;

    return this.apiService.get(relativeUrl);
  }

  public removeUser(userId: number): any {
    const relativeUrl = `user/${userId}`;

    return this.apiService.delete(relativeUrl);
  }

  public hideUser(userId: number): any {
    const relativeUrl = `user/hide/${userId}`;

    return this.apiService.put(relativeUrl, null);
  }
  // endregion

  // region Model Part API
  public getPartModels(): Promise<PartModel[]> {
    const relativeUrl = `model/list`;

    return this.apiService.get(relativeUrl);
  }

  public getPartModelsByPart(partNumber: string): Promise<PartModel[]> {
    const relativeUrl = `model/list/${partNumber}`;

    return this.apiService.get(relativeUrl);
  }

  public getPartModelsByLocation(location: string): Promise<PartModel[]> {
    const relativeUrl = `model/location/${location}`;

    return this.apiService.get(relativeUrl);
  }

  public addPartModel(partModel: PartModel): Promise<any> {
    const relativeUrl = `model`;

    return this.apiService.post(relativeUrl, partModel);
  }

  public updatePartModel(partModel: PartModel): Promise<any> {
    const relativeUrl = `model/${partModel.id}`;

    return this.apiService.put(relativeUrl, partModel);
  }

  public removePartModel(id: number): Promise<any> {
    const relativeUrl = `model/${id}`;

    return this.apiService.delete(relativeUrl);
  }

  public getPartsByCharacteristicLocation(locationId: string): Promise<Part[]> {
    return this.apiService.getPartsByCharacteristicLocation(locationId);
  }

  public getComparisonList(filter: PartMappingFilter): Promise<LocationParts[]> {
    const relativeUrl = 'PartNumber/ComparisonList';
    return this.apiService.put(relativeUrl, filter);
  }

  public getPartMappingFilters(): Promise<MappingFilters> {
    const relativeUrl = 'PartNumber/PartMappingFilters';
    return this.apiService.get(relativeUrl);
  }

  public getCmmMachineList(): Promise<string[]> {
    const relativeUrl = 'PartNumber/CmmMachineList';
    return this.apiService.get(relativeUrl);
  }

  public getPartMappingList(filter: PartMappingFilter): Promise<PartMapping[]> {
    const relativeUrl = 'PartNumber/PartMappingList';
    return this.apiService.put(relativeUrl, filter);
  }

  public postPartMapping(partMapping: PartMapping): Promise<any> {
    const relativeUrl = `PartNumber/PartMapping`;
    return this.apiService.post(relativeUrl, partMapping);
  }

  public deletePartMappingFromLocation(uid: string): Promise<any> {
    const relativeUrl = `PartNumber/PartMappingByLocation/${uid}`;
    return this.apiService.delete(relativeUrl);
  }
  // endregion
}
