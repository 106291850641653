import {Component, Input, OnDestroy} from '@angular/core';
import {TableRowModel} from '@models/table-row-model';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from 'rxjs';
import {TranslationService} from '@services/translation.service';
import {ComponentsTranslation} from '@models/translation';

@Component({
  selector: 'app-single-multi-template',
  templateUrl: './single-multi-template.component.html',
  styleUrls: ['./single-multi-template.component.scss']
})
export class SingleMultiTemplateComponent implements OnDestroy {
  private readonly subscriptions = new Subscription();

  public readonly infoIcon = faInfoCircle;

  public componentsTranslation: ComponentsTranslation = new ComponentsTranslation();

  @Input() isMulti = false;
  @Input() content = '';

  constructor(private translationService: TranslationService) {
    this.setTranslation();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.componentsTranslation = translation.components;
    });

    this.subscriptions.add(translationSubscription);
  }

}
