import {Translation} from '@models/translation';

// tslint:disable:max-line-length
export class ChineseTranslation {
  public static readonly translation: Translation = {
    type: `chinese`,
    administration: {
      partsList: `Part list`,
      partAddBtn: `Add part`,
      partNo: `Part No`,
      partEnabled: `Enabled`,
      partName: `Part Name`,
      partNumber: `Part Number`,
      partDescription: `Description`,
      partUsed: `Part Used`,
      partModel: `Part Model`,
      partGrossCount: `Gross Count`,
      partPPC: `PPC`,
      partEditTooltip: `Edit part`,
      partDeleteTooltip: `Delete part`,
      partTitle: `Part`,
      reactionPlanReasonList: `Reaction plan reason list`,
      reactionPlanReasonAddBtn: `Add reaction plan reason`,
      reactionPlanReasonName: `Reaction Plan Reason Name`,
      reactionPlanReasonType: `Type`,
      reactionPlanReasonIsActive: `Active`,
      reactionPlanReasonEnabled: `Enabled`,
      reactionPlanEditTooltip: `Edit reaction plan`,
      reactionPlanDeleteTooltip: `Delete reaction plan`,
      reactionPlanIsUsedTooltip: `Reaction plan is used`,
      reactionPlanTitle: `Reaction plan`,
      reactionPlanNotificationDeleted: `Reaction plan has been deleted`,
      sampleReasonList: `Sample reason list`,
      sampleReasonAddBtn: `Add sample reason`,
      sampleReasonName: `Sample Reason Name`,
      sampleReasonType: `Type`,
      sampleReasonIsDefault: `Default`,
      sampleReasonEditTooltip: `Edit sample reason`,
      sampleReasonDeleteTooltip: `Delete sample reason`,
      sampleReasonIsUsedTooltip: `Sample reason is used`,
      sampleReasonTitle: `Sample reason`,
      sampleReasonNotificationDeleted: `Sample reason has been deleted`,
      addFixtureBtn: `Add Fixture`,
      addUnitBtn: `添加单元`,
      addUserBtn: `添加用户`,
      addMappingBtn: `Add Mapping`,
      adminPermissionsAlert: `无法更改管理权限`,
      descriptionBlankWarning: `描述不能为空`,
      fixtureDeleteTooltip: `Delete fixture`,
      fixtureEditTooltip: `Edit fixture`,
      fixtureIsUsedTooltip: `Fixture is used`,
      fixtureList: `Fixture list`,
      fixtureName: `Fixture name`,
      fixtureNotificationDeleted: `Fixture has been deleted`,
      fixtureTitle: `Fixture`,
      fixtureValidationNameIsTaken: `An error occurred - probably name is already taken`,
      idleLogoutMessage: `用户空闲注销时间（分钟）`,
      idleLogoutMessagePlantUser: `工厂用户空闲注销时间（分钟）`,
      partModelAddModel: `Add Model`,
      partModelDeleteModelTooltip: `Delete model`,
      partModelEditModelTooltip: `Edit model`,
      partModelName: `Model name`,
      partModelUsed: `Model is used`,
      partModels: `Part models`,
      partModelParts: `Parts`,
      partMappingIsUsed: `Part Mapping is in use`,
      partMappingDelete: `Delete Part Mapping`,
      partMappingEdit: `Edit Part Mapping`,
      nameBlankWarning: `名称不能为空`,
      permissionsMatrix: `权限`,
      recycleBinCharacteristicDeleteTooltip: `Remove characteristic permanently`,
      recycleBinCharacteristicName: `Characteristic Name`,
      recycleBinCharacteristicUsed: `Characteristic is used`,
      recycleBinDataRecycled: `Date Recycled`,
      recycleBinLocationScreenDeleteTooltip: `Remove location screen permanently`,
      recycleBinLocationScreenName: `Location Screen Name`,
      recycleBinLocationScreenUsed: `Location Screen is used`,
      recycleBinNotificationCharacteristicDeleted: `Characteristic has been deleted`,
      recycleBinNotificationLocationScreenDeleted: `Location screen has been deleted`,
      recycleBinNotificationProcessChartDeleted: `Process chart has been deleted`,
      recycleBinProcessChartDeleteTooltip: `Remove process chart permanently`,
      recycleBinProcessChartName: `Process Chart Name`,
      recycleBinProcessChartUsed: `Process Chart is used`,
      recycleBinRecycledBy: `Recycled By`,
      recycleBinRestoreCharacteristicTooltip: `Restore characteristic`,
      recycleBinRestoreLocationScreenTooltip: `Restore location screen`,
      recycleBinRestoreProcessChartTooltip: `Restore process chart`,
      recycleBinTypeLabel: `Recycle bin type`,
      recycleBinTitle: `Recycle Bin`,
      recycleBinUserName: `Username`,
      recycleBinUserDeleteTooltip: `Remove user permanently`,
      recycleBinNotificationUserDeleted: `User has been deleted`,
      recycleBinRestoreUserTooltip: `Restore user`,
      recycleBinUserUsed: `User is used`,
      roleListDeleteMessage: `用户的角色账户已删除`,
      roleListDeleteTooltip: `Delete role`,
      roleListEditTooltip: `Edit role`,
      roleListIsUsedTooltip: `Role is used`,
      roleListNameIsTakenMsg: `发生错误-可能该名称已存在`,
      roleListTableActions: `Actions`,
      roleListTableDescription: `描述`,
      roleListTableName: `名称`,
      ruleSettingTableName: `Name`,
      ruleSettingTableValue: `Value`,
      ruleSettingModalHeader: `Rule Setting`,
      ruleSettingModalRuleLabel: `Select Rule`,
      ruleSettingAddSettingBtn: `Add Rule Setting`,
      ruleSettingListHeader: `Rule Settings List`,
      settings: `设置`,
      ruleManagement: `Rule Management`,
      settingsCleanDataBtnLabel: `自动删除超过一年的数据`,
      showLabel: `显示`,
      unitDeleteTooltip: `Delete unit`,
      unitEditTooltip: `Edit unit`,
      unitIsUsedTooltip: `Unit is used`,
      unitLists: `单元列表`,
      name: `名称`,
      unitNotificationUnitDeleted: `单元已删除`,
      unitShortName: `短名称`,
      unitTitle: `单元`,
      unitValidationNameBlank: `名称不能为空`,
      unitValidationNameIsTaken: `出现错误-短名称可能已被使用`,
      unitValidationShortNameBlank: `短名称不能为空`,
      userFileFormatWarning: `File format is incorrect, please make sure that You are using jpg/jpeg/img/png format`,
      userFileSizeWarning: `Image size is too big, max size is 3 MB, use smaller photo`,
      userListAddMasterDataBtn: `添加Masterdata用户`,
      userListDeleteTooltip: `Delete user`,
      userListEditTooltip: `Edit user`,
      userListIsUsedTooltip: `User is used`,
      userListNotificationAccDeleted: `用户账户已删除`,
      userListNotificationCantDelAcc: `您能/不能移除您的账户`,
      userListNotificationUserNameIsUsed: `发生一个错误-可能用户名已经被使用`,
      userListNotificationUserSaved: `用户已保存`,
      userListTitle: `用户列表`,
      userModalActiveLabel: `活跃的`,
      userModalAddPhotoBtn: `添加`,
      userModalAddLocationBtn: `添加角色`,
      userModalClearRoleBtn: `Clear`,
      userModalAddUserByPlantBtn: `添加工厂用户`,
      userModalAddedRolesLabel: `添加工厂角色`,
      userModalChangePhotoBtn: `改变`,
      userModalChosePlantTitle: `选择用户指定到工厂`,
      userModalConfirmPasswordLabel: `确认密码`,
      userModalConfirmPasswordValidationBlank: `确认密码不能为空`,
      userModalConfirmPasswordValidationDifferent: `密码不能不一致`,
      userModalEditFirstNameValidation: `名不能为空`,
      userModalEditNameValidation: `用户名不能为空`,
      userModalEditTitle: `用户`,
      userModalEmailLabel: `邮件`,
      userModalEmailValidation: `填写正确的邮件地址`,
      userModalFirstNameLabel: `名`,
      userModalLastNameLabel: `姓`,
      userModalLastNameValidation: `姓不能为空`,
      userModalLocationPathBtnChange: `改变位置路径`,
      userModalLocationPathBtnChoose: `选择位置路径`,
      userModalLocationPathValidation: `位置路径不能为空`,
      userModalMasterdataUsn: 'Masterdata Usn',
      userModalNextBtn: `下一个`,
      userModalPasswordLabel: `密码`,
      userModalPasswordValidationBlank: `密码不能为空`,
      userModalPasswordValidationDifferent: `密码不能不一致`,
      userModalPlantLabel: `工厂：`,
      userModalRemoveBtn: `移除`,
      userModalRolesByPlantLabel: `工厂角色`,
      userModalRolesByPlantLocationLabel: `Plant location`,
      userModalRolesLabel: `角色`,
      userModalRolesValidation: `角色不能为空`,
      userModalTreeTitle: `为用户选择位置`,
      userModalUserNameLabel: `用户名`,
      userSpecialMarkDefaultWarning: `The query is invalid. Please check the correct use of the following special characters: ! \` # $ % & ' ( ) * + , - . / \\ : ; < = > ? @ [ ] ^ _ { | } ~`,
      userSpecialMarkEmailWarning: `The query is invalid. Please check the correct use of the following special characters: ! \` # $ % & ' ( ) * + , / \\ : ; < = > ? [ ] ^ { | } ~`,
      userSpecialMarkPasswordWarning: `The query is invalid. Please check the correct use of the following special characters: = ? < > { }`,
      usersAddUserMasterdataModalTitle: `选择用户`,
      usersListColumnActive: `活跃的`,
      usersListColumnEmail: `电子邮件`,
      usersListColumnFirstName: `名`,
      usersListColumnLastName: `姓`,
      usersListColumnLastLogin: `Last Login`,
      usersListColumnUsername: `用户名`,
      usersRolesAddUserBtn: `添加用户角色`,
      usersRolesListHeader: `用户角色列表`,
      usersRolesModalHeader: `用户的角色`,
      validationIdleLogoutMessageBlank: `用户空闲注销时间不能为空`,
      validationIdleLogoutMessageLogoutTime: `用户空闲注销时间不能低于1`,
      valueBlankWarning: `Value is required`
    },
    auth: {
      signInTitle: `输入`,
      errorInvalidGrant: `请您核对用户名与密码正确`,
      errorInvalidRequest: `未找到该账户`,
      error: `错误`,
      passwordLabel: `密码`,
      signInBtn: `输入`,
      usernameLabel: `用户名`
    },
    characteristics: {
      addStringToCharaNameAfterCopy: ` - copy`,
      attribute: `属性`,
      characteristicIsInPartExist: `该零件的特性已经存在`,
      characteristicNameIsExist: `此名称的特性已经存在`,
      characteristicType: `Characteristic type`,
      characteristicView: `特征视图`,
      chartDefinition: `图表定义`,
      chartType: `图表类型`,
      chartTypeIMR: `I-MR（位置，范围）`,
      chartTypeWithout: `不含图`,
      chartTypeXR: `XR（位置，范围）`,
      cp: `过程能力指数CP`,
      cpk: `过程能力K指数CPK`,
      dataInput: `录入数据`,
      dateStampLabel: `日期戳`,
      description: `描述`,
      disabledCharacteristicWarning: `如果禁用此特性，所有由它算出的特征也将被禁用，您确定要继续吗?`,
      enabledCharacteristic: `启用的特性`,
      enterUniqueName: `录入特殊特性名称`,
      excludeReasons: `Exclude reasons`,
      fixtureAddTooltip: `Add fixture`,
      fixtureApplyToAll: `Apply fixture to all`,
      fixtureDeleteTooltip: `Delete fixture`,
      fixtureHeaderLabel: `Fixture`,
      fixtureHeaderNameLabel: `Name: `,
      fixtureHeaderNumberLabel: `Number: `,
      fixtureNoOptionsString: `No options to pick`,
      fixtureRemoveFromAll: `Remove fixture from all`,
      fixtureSelectTitle: `Fixtures to select`,
      formLabelMax: `最大`,
      formLabelMin: `最小`,
      frequency: `频率`,
      frequencySelectPlaceholder: `Select frequency`,
      characteristicSampleReasonValidation: `“开始班次”、“中班次”、“每班一次”、“每小时”、“每两小时”、“每四小时”、“每六小时”至少应选填一个`,
      gaugeType: `测量类型`,
      general: `常规`,
      graphFiltering: `Graph filtering`,
      lcl: `LCL`,
      ldc: `偏差常数下限`,
      locationChartTitle: `位置`,
      lsl: `SPEC下线`,
      manualControlLabel: `手动空值`,
      maxLimitSslOption: `最大限制`,
      maxLimitValidationFirstPart: `最大限度不能被超过`,
      maxLimitValidationSecondPart: `或向下`,
      minLimitSslOption: `最小限制`,
      minLimitValidationFirstPart: `最小限度不能被超过`,
      minLimitValidationSecondPart: `或向下`,
      minMaxValidation: `最小值不能大于或等于最大值`,
      modelPartNumbers: `Model Part Numbers`,
      multiPart: `Multi Part #`,
      name: `名称`,
      nominalMinus: `标定-`,
      nominalOnOff: `标定（开/关）`,
      nominalPlus: `标定+`,
      nominalValue: `标定值`,
      oneSidedChartBorderTypeLabel: `One-sided chart border type`,
      oneSidedChartBorderTypeFirstOption: `Limit config`,
      oneSidedChartBorderTypeFirstOptionTooltip: `Chart will be limited to specification limit and control limit by default.`,
      oneSidedChartBorderTypeSecondOption: `MinMax`,
      oneSidedChartBorderTypeSecondOptionTooltip: `Chart will be limited to specification limit and an outlier sample value by default.`,
      part: `零件号`,
      pickLocationBtn: `为新特性选择位置`,
      pp: `过程绩效指数PP`,
      ppk: `过程绩效K指数PPK`,
      precision: `精度`,
      promptLabel: `提示`,
      qclCode: `QCL code`,
      qclCodeFormFive: `CL5`,
      qclCodeFormFour: `CL4`,
      qclCodeFormNone: `无`,
      qclCodeFormOne: `CL1`,
      qclCodeFormThree: `CL3`,
      qclCodeFormTwo: `CL2`,
      rangeChartTitle: `范围`,
      requiredGaugeDescription: `要求的规格号/描述`,
      rule: `规则`,
      rules: `规则`,
      samples: `样品`,
      samplesQuantityLabel: `样本容量`,
      search: `搜索`,
      selectRules: `Select rules`,
      serialNumberTableLabel: `序列号`,
      settingsAlerting: `报警`,
      settingsAlertingGroups: `报警组别`,
      settingsPart: `零件号`,
      partNumbers: `零件号组别`,
      partNumbersSelectPlaceholder: `Select part number(s)`,
      settingsQualitySignOff: `质量签字`,
      settingsSerial: `序列号`,
      settingsSupervisorSignOff: `主管签字`,
      settingsTitle: `设置面板`,
      severityLabel: `严重度9/10`,
      showOnChart: `显示在图中`,
      specLimitFirstOption: `单侧`,
      specLimitLabel: `SPEC限制类型`,
      specLimitSecondOption: `双侧`,
      ssl: `SSL`,
      sslLimitType: `限制类型`,
      tableCp: `过程能力指数CP`,
      tableCpk: `过程能力K指数CPK`,
      tableDateCreated: `日期/时间`,
      tableDeviationTooltip: `偏差值`,
      tableLsl: `控制线下限`,
      tableMean: `均值`,
      tableMeanCenterLine: `总平均`,
      tableNo: `标号`,
      tableNumberOfSamples: `样品`,
      tableOperatorInitials: `操作员缩写`,
      tablePartNumber: `Part number`,
      tableRange: `范围`,
      tableRangeCenterLineMr: `mR-bar`,
      tableRangeCentralLine: `R-bar`,
      tableSampleValue: `值`,
      tableShorthandDeviation: `偏差D`,
      tableUsl: `控制线上限`,
      tableValue: `值`,
      targetCpk: `目标CPK`,
      trendLine: `Trend Line`,
      ucl: `UCL`,
      uclLclValidation: `控制线上限应该大于下限`,
      udc: `偏差常数上限`,
      unit: `单位`,
      usl: `SPEC上限`,
      uslLslValidation: `SPEC上限应大于SPEC下限`,
      variable: `变量`
    },
    CMMTranslation: {
      runChartChartLslPlotLine: `SPEC下限：`,
      runChartChartSerieName: `运行图`,
      runChartChartTitle: `运行图`,
      runChartChartTooltipValue: `值：`,
      runChartChartUslPlotLine: `SPEC上限：`,
      runChartChartXAxisName: `时间戳`,
      runChartChartYAxisName: `值`,
      runChartFiltersCharacteristicPlaceholder: `特性`,
      runChartFiltersDateRangePlaceholder: `Date Range`,
      runChartFiltersDepartmentPlaceholder: `部门`,
      runChartFiltersDevicePlaceholder: `设备`,
      runChartFiltersMachinePlaceholder: `机器`,
      runChartFiltersPartNumberPlaceholder: `零件号`,
      runChartFiltersPlantPlaceholder: `工厂`,
      runChartFiltersMeasurementPlanPlaceholder: `Measurement Plan`,
      runChartFiltersPlotGraphBtn: `Plot Graph`,
      runChartFiltersResetBtn: `Reset filters`,
      runChartModalBackButton: `转到细节`,
      runChartModalDetailsMachine: `机器：`,
      runChartModalDetailsMeasurement: `测量类型`,
      runChartModalDetailsPN: `零件号：`,
      runChartModalDetailsTimestamp: `时间戳`,
      runChartModalTableCharacteristic: `特性`,
      runChartModalTableTitle: `测量`,
      runChartModalTableValue: `值`,
      runChartModalTitle: `细节报告`,
      runChartModalViewPdfBtn: `查看PDF报告`,
      runChartReportListTitle: `报告`,
      runChartTableColumnTimeStamp: `时间戳`,
      runChartTableColumnValue: `值`,
      runChartTableValueListTitle: `Values`,
      viewCharacteristicsCardTitle: `特性`,
      viewCharacteristicsTableComments: `评论`,
      viewCharacteristicsTableName: `名称`,
      viewCharacteristicsTableValue: `值`,
      viewCmmCardCMM: `三坐标`,
      viewCmmCardLab: `实验室`,
      viewCmmCardPlanta: `编号`,
      viewCmmCardReports: `Shift reports`,
      viewCmmGetDevicesTooltip: `Pick plant and time interval`,
      viewCmmPlantsChoiceLabel: `Plants to choice`,
      viewCmmTimeIntervalLabel: `Pick time interval`,
      viewGetDevicesBtn: `获取设备`,
      viewPlantCardTitle: `工厂`,
      viewReportCardTitle: `当前报告`,
      viewReportCardViewLink: `视图`
    },
    components: {
      addPartMappingModal_modalHeader: `Part Mapping`,
      addPartMappingSelectLocationBtn: `Select Location`,
      addPartMappingSelectLocationNameExists: `Location name already exists`,
      addPartMappingSelectLocationNameUnique: `Location name must be unique`,
      addPartMappingCmmPartsPlaceholder: `Select CMM Parts for the mapping`,
      addPartMappingSubmitBtn: `Add Mapping`,
      addSampleCollection_excludeLabel: `排除图标和计算`,
      addSampleCollection_limitBrokenMsgMaxVal: `最大值`,
      addSampleCollection_limitBrokenMsgMinVal: `最小值`,
      addSampleCollection_limitBrokenMsgTitle: `输入值超出范围`,
      addSampleCollection_limitBrokenMsgWantToContinue: `您确定添加限制以外的值吗？`,
      addSampleCollection_notesLabel: `备注`,
      addSampleCollection_sampleEditedMsg: `样本已被编辑`,
      addSampleCollection_saveBtn: `保存更改`,
      addSampleCollection_subTitleSamples: `样本`,
      addSampleCollection_title: `说明`,
      addSampleCollection_titleAddSamples: `增加样本`,
      addSampleCollection_titleEditSamples: `编辑样本`,
      addSampleCollection_validationValueOver: `样本不能多于`,
      addSampleCollection_validationValueUnder: `或少于`,
      addSampleModal_addedSamplesMsg: `样本已被添加`,
      addSampleModal_addSampleBtn: `增加`,
      addSampleModal_addSamplesError: `You need to add samples or set 'Not running'`,
      addSampleModal_cardTitleSamples: `样本`,
      addSampleModal_completeBtn: `完成`,
      addSampleModal_modalHeader: `增加样本`,
      addSampleModal_notRunningLabel: `未运行`,
      addSampleModal_operatorInitialLabel: `操作者缩写`,
      addSampleModal_partNumberLabel: `Part number`,
      addSampleModal_reasonLabel: `原因`,
      addSampleModal_remarksLabel: `备注（可选）`,
      addSampleModal_serialNumberLabel: `序列号`,
      chartOptions_trendLine: `Trend Line`,
      chartOptions_yAxisMin: `Y axis min`,
      chartOptions_yAxisMax: `Y axis max`,
      confirmUserPermission_emptyFieldsWarning: `表格需要被填写`,
      confirmUserPermission_engineerPermissionWarn: `用户没有QE许可`,
      confirmUserPermission_incorrectPasswordWarn: `密码错误或用户不存在`,
      confirmUserPermission_passwordLabel: `密码`,
      confirmUserPermission_permissionConfirmedMsg: `用户权限确认`,
      confirmUserPermission_qualitySupervisorPermissionWarn: `用户没有质量主管许可`,
      confirmUserPermission_usernameLabel: `用户名`,
      filters_cardTitle: `过滤`,
      filters_clearBtn: `清空`,
      filters_defaultOptionDept: `任何部门`,
      filters_defaultOptionPlant: `任何工厂`,
      filters_departmentLabel: `部门：`,
      filters_listViewBtn: `列表视图`,
      filters_plantLabel: `工厂：`,
      filters_cmmPartLabel: `CMM Part:`,
      filters_mdPartLabel: `MD Part:`,
      searchLabel: `搜索`,
      filters_showFavoriteLabel: `显示收藏夹`,
      filters_treeViewBtn: `树状视图`,
      meanChart_cardTitle: `选项面板`,
      meanChart_chartTextDateXaxis: `日期`,
      meanChart_chartTextLocationYaxis: `位置`,
      meanChart_LCLLabel: `控制线下限`,
      meanChart_LSLLabel: `SPEC下限`,
      meanChart_maxLimitOptions: `极限类型：最大极限`,
      meanChart_meanLabel: `均值`,
      meanChart_minLimitOptions: `极限类型：最小极限`,
      meanChart_nominalRange: `标定范围`,
      meanChart_partNumberTooltip: `零件号：`,
      meanChart_plotLineTextCentralLineLabelGrandMean: `总平均`,
      meanChart_plotLineTextCentralLineLabelMean: `均值`,
      meanChart_plotLineTextLCLLabel: `控制线下限=`,
      meanChart_plotLineTextLSLLabel: `SPEC下限=`,
      meanChart_plotLineTextRangeMinusLabel: `范围减=`,
      meanChart_plotLineTextRangePlusLabel: `范围加=`,
      meanChart_plotLineTextSSLLabel: `SSL=`,
      meanChart_plotLineTextUCLLabel: `控制线上限=`,
      meanChart_plotLineTextUSLLabel: `SPEC上限=`,
      meanChart_ProcessAverageTooltip: `过程平均：`,
      meanChart_recalcGroupPlotLine: `重新计算`,
      meanChart_refreshChartTooltip: `Refresh location chart`,
      meanChart_RulesTooltip: `规则：`,
      meanChart_SSLLabel: `SSL`,
      meanChart_SubgroupAverage: `Subgroup average:`,
      meanChart_ticksAmount: `标记数量`,
      meanChart_maximizeChart: `maximize chart`,
      meanChart_UCLLabel: `控制线上限`,
      meanChart_USLLabel: `SPEC上限`,
      preControlChart_CentralLineLabel: `中心`,
      preControlChart_chartTextDateXaxis: `日期`,
      preControlChart_chartTextDateYaxis: `位置`,
      preControlChart_LCLLabel: `控制线下限`,
      preControlChart_LGZLabel: `LGZ`,
      preControlChart_Ticks: `标记`,
      preControlChart_Title: `预控制`,
      preControlChart_UClLabel: `控制线上限`,
      preControlChart_UGZLabel: `UGZ`,
      processChart_cantEditReactionPlanWarning: `反应计划只能在界面上编辑`,
      processChart_dateColumnName: `日期`,
      processChart_frequencyShortVersionFourHr: `每4小时`,
      processChart_frequencyShortVersionOneHr: `每1小时`,
      processChart_frequencyShortVersionSixHr: `每6小时`,
      processChart_frequencyShortVersionTwoHr: `每2小时`,
      processChart_gageInstruction: `请参阅检具说明`,
      processChart_headerInformation: `项目待核对，向团队报告任何超出SPEC的读数`,
      processChart_inspColumnName: `检查人`,
      processChart_machine: `机器`,
      processChart_partLabel: `零件号`,
      processChart_processChartNameLabel: `过程流程图名称`,
      processChart_reasonColumnName: `检查原因`,
      processChart_remarksColumnName: `标注`,
      processChart_rowsQuantity: `行数量`,
      processChart_selectPartNumberWarning: `请选择零件号`,
      processChart_severityCell: `严重度9/10`,
      processChart_supervisorInitialsColumnName: `主管缩写`,
      processChart_timeColumnName: `时间`,
      rangeChart_cardTitle: `操作面板`,
      rangeChart_chartTextDateXaxis: `日期`,
      rangeChart_chartTitle: `范围`,
      rangeChart_LCLLabel: `控制线下限`,
      rangeChart_plotLineTextCentralLineLabelMrbar: `mR-bar`,
      rangeChart_plotLineTextCentralLineLabelRbar: `R-bar`,
      rangeChart_plotLineTextLCLLabel: `控制线下限=`,
      rangeChart_plotLineTextUCLLabel: `控制线上限=`,
      rangeChart_rangeTooltip: `范围：`,
      rangeChart_refreshChartTooltip: `Refresh range chart`,
      rangeChart_rulesTooltip: `规则：`,
      rangeChart_ticksAmount: `标记数量`,
      rangeChart_UCLLabel: `控制线上限`,
      reactionPlan_actionTakenLabel: `采取行动`,
      reactionPlan_calculationReset: `Calculation Reset`,
      reactionPlan_changeMachineParameterOption: `更改机器参数`,
      reactionPlan_characteristicDescription: `特性描述`,
      reactionPlan_characteristicName: `特性名称`,
      reactionPlan_cleanMachineOption: `清洗机器设备`,
      reactionPlan_completeBtn: `完成`,
      reactionPlan_correctionOfDeviceOption: `测量装置校正`,
      reactionPlan_correctiveActionLabel: `Corrective Action`,
      reactionPlan_dateLabel: `日期`,
      reactionPlan_dateTimeViolation: `违规日期/时间`,
      reactionPlan_deviationOption: `偏差`,
      reactionPlan_interventionOption: `维护干预`,
      reactionPlan_lslLabel: `SPEC下限：`,
      reactionPlan_machineAdjustmentOption: `机器调整`,
      reactionPlan_materialChangeOption: `物料变化`,
      reactionPlan_materialDisposition: `材料处理`,
      reactionPlan_modalTitle: `反应计划`,
      reactionPlan_operationInput: `操作者输入`,
      reactionPlan_operatorCommentLabel: `操作员评论`,
      reactionPlan_operatorCommentValidationMessage: `操作员评论不能为空`,
      reactionPlan_operatorInitialsLabel: `操作员缩写`,
      reactionPlan_operatorInitialsValidationMessage: `操作员缩写不能为空`,
      reactionPlan_processStopped: `停止过程？`,
      reactionPlan_qeFormInitialsValidationMsg: `QE缩写不能为空`,
      reactionPlan_qeFormLabel: `QE`,
      reactionPlan_qeFormTitle: `质量签字`,
      reactionPlan_qeLoginComponentTitle: `质量用户认证`,
      reactionPlan_qualityInput: `Quality Input`,
      reactionPlan_qualitySignOff: `质量签字`,
      reactionPlan_radioButtonCorrectedLabel: `纠正`,
      reactionPlan_radioButtonDeviation: `偏差`,
      reactionPlan_radioButtonHoldLabel: `停放`,
      reactionPlan_radioButtonPassedLabel: `传递到库存`,
      reactionPlan_radioButtonReworkLabel: `返工`,
      reactionPlan_radioButtonScrapLabel: `报废`,
      reactionPlan_radioButtonSortLabel: `排序`,
      reactionPlan_reactionPlanSavedMsg: `反应计划已保存`,
      reactionPlan_remarkLabel: `备注：`,
      reactionPlan_repairDeviceOption: `修理或更换测量装置`,
      reactionPlan_rule: `违规规则`,
      reactionPlan_ruleBreakOverride: `Change color to black`,
      reactionPlan_rulesViolated: `违规规则`,
      reactionPlan_severity: `严重度9/10`,
      reactionPlan_supervisorSignOff: `主管签字`,
      reactionPlan_svFormInitials: `主管缩写`,
      reactionPlan_svFormInitialsValidationMsg: `主管签字不能为空`,
      reactionPlan_svFormTitle: `主管签字`,
      reactionPlan_svLoginComponentTitle: `主管用户认证`,
      reactionPlan_toolAdjustmentOption: `工装调整`,
      reactionPlan_toolChangeOption: `工装改变`,
      reactionPlan_uslLabel: `SPEC上限：`,
      reactionPlan_valueLabel: `值：`,
      reactionPlan_verificationOfDeviceOption: `测量装置校验`,
      sampleReason_eventOption: `Event`,
      sampleReason_frequencyOption: `Frequency`,
      sampleReason_hourlyOption: `Hourly`,
      tableResult_cantDisabledCharacteristicMsg: `您能/不能在禁用特性中添加样本`,
      tableResult_characteristic: `特性`,
      tableResult_itemNameControlCharts: `控制图`,
      tableResult_itemNameLocationScreen: `界面`,
      tableResult_itemNameProcessChart: `过程图`,
      tableResult_locationScreen: `界面`,
      tableResult_maxFavoriteValidationPartOne: `您已经超过了最大数量`,
      tableResult_maxFavoriteValidationPartTwo: `您喜爱的`,
      tableResult_moveToRecycleBinMsg: `This will go the Recycle Bin`,
      tableResult_multi: `多`,
      tableResult_noPermissionToAddSamplesMsg: `您有/没有权限添加样品`,
      tableResult_processChart: `过程图`,
      tableResult_tableActions: `行动`,
      tableResult_tableCreator: `创建者`,
      tableResult_tableLastModified: `最后修改`,
      tableResult_tableName: `名称`,
      tableResult_tableNamePath: `名称/路径`,
      tableResult_tablePart: `零件`,
      tableResult_tablePath: `路径`,
      tableResult_tooltipAddData: `添加数据`,
      tableResult_tooltipAddToFavorite: `添加喜爱`,
      tableResult_tooltipCopy: `复制`,
      tableResult_tooltipEdit: `编辑`,
      tableResult_tooltipRemoveFavorite: `从喜爱中删除`,
      tableResult_tooltipView: `视图`,
      tableResult_tooltipCMMCharacteristic: `Can't add data to a CMM Characteristic`,
      tableResult_typeToValidationMaxFavoriteCharacteristics: `特性`,
      tableResult_typeToValidationMaxFavoriteLocationScreen: `界面`,
      tableResult_typeToValidationMaxFavoriteProcessChart: `过程图`,
      variableChart_trendLine: `Trend Line`
    },
    dashboard: {
      controlChardHideState: `control charts has been hidden`,
      itemsPerPageSelect: `每页项目`,
      switchLabelCompactView: `紧凑的视图`
    },
    global: {
      addSampleBtn: `增加样本`,
      afterChangeoverOptions: `换型后`,
      afterCorrectionOptions: `修正后`,
      afterMaintenanceInterventionOptions: `维修维护后`,
      afterMealOptions: `饭后`,
      afterModelChangeOptions: `After Model Change`,
      afterProcessChangeOptions: `过程变化后`,
      cancelBtn: `取消`,
      clearBtn: `清除`,
      closeBtn: `关闭`,
      confirmButtonText: `是的`,
      confirmDelete: `是的，确认删除！`,
      dataDeletedMsgTitle: `删除！`,
      dataEditedMsg: `数据已修改`,
      dataLostMsg: `您确定吗？所有数据将会丢失`,
      dataLostTextUndo: `您将无法撤销此操作`,
      dataMismatch: `Some Part Numbers no longer exist in the Masterdata.`,
      dataRestored: `Data have been restored`,
      dataSaved: `数据已保存`,
      editCharacteristicBtn: `编辑特性`,
      empty: `空值`,
      emptyAttribute: `空值`,
      enterBtn: `进入`,
      errorFetchMsg: `数据尚未提取`,
      errorOccurred: `出现了一个错误`,
      every2HoursOptions: `每两小时`,
      every4HoursOptions: `每四小时`,
      every6HoursOptions: `每六小时`,
      everyHourOptions: `每小时`,
      excelExportBtn: `导出Excel`,
      failAttribute: `失败`,
      isRequired: `值必须填写`,
      lclFullName: `Lower Control Limit`,
      ldcFullName: `偏差常数下线`,
      lostChangesStatement: `您确定吗？所有的更改将会丢失`,
      lslFullName: `SPEC下限`,
      midOfShiftOptions: `中班次`,
      modalTitleSelectLocation: `选择位置`,
      nameLabel: `Name`,
      notRunningAttribute: `未运行`,
      oncePerDayOptions: `Once Per Day`,
      oncePerShiftOptions: `每班一次`,
      passedAttribute: `通过`,
      refreshBtn: `刷新`,
      removeMsg: `您将无法恢复数据`,
      removeMsgTitle: `您确定吗？`,
      reset: `Reset`,
      ruleEightDescription: `四分之五的点大于中心线的一个标准差（同侧）`,
      ruleElevenDescription: `在标定范围之外的组别`,
      ruleFiveDescription: `连续升高或降低的6分点`,
      ruleFourDescription: `在中心线同一侧（上或下）的7个点`,
      ruleNineDescription: `一条线上的十五个点在中心线的一个标准差内（两侧）`,
      ruleOneDescription: `超出SPEC界限的组别`,
      ruleSevenDescription: `三分之二的点大于中心线的两个标准差（同侧）`,
      ruleSixDescription: `一排上下交替的14个点`,
      ruleTenDescription: `一条线上的八个点大于中心线的一个标准差（两侧）`,
      ruleThreeDescription: `一个点比中心线大三个标准差`,
      ruleTwoDescription: `超出控制界限的但在SPEC界限的组别`,
      ruleTwelveDescription: `Seven points in a row increasing or decreasing`,
      ruleThirteenDescription: `Seven points in a row between the mean and one of the control limits (on one side)`,
      saveBtn: `保存`,
      searchBtn: `搜索`,
      showEmptyLocationLabel: `展示空值位置`,
      sslFullName: `单SPEC限`,
      startOfShiftOptions: `开始班次`,
      submitBtn: `提交`,
      toolChangeOptions: `工装变化`,
      uclFullName: `Upper Control Limit`,
      udcFullName: `偏差常数上限`,
      entries: `条目`,
      show: `显示`,
      uslFullName: `SPEC上限`,
      noPartNumber: `no part number available`
    },
    layout: {
      passwordChangeModalChangePasswordBtn: `修改密码`,
      passwordChangeModalConfirmPasswordLabel: `确认密码`,
      passwordChangeModalConfirmPasswordValidation: `确认密码不能为空`,
      passwordChangeModalDifferentPassValidation: `两次密码不能不同`,
      passwordChangeModalPasswordChanged: `密码已修改`,
      passwordChangeModalPasswordLabel: `密码`,
      passwordChangeModalPasswordValidation: `密码不能为空`,
      passwordChangeModalTitle: `修改密码`,
      userMenuOptionLogout: `注销`,
      userMenuOptionSettings: `设置`
    },
    locationScreen: {
      createEnableLabel: `启用界面`,
      createEnterNameLabel: `输入唯一的界面名称`,
      createLackOfProcessCharts: `缺少过程流程图`,
      createLocationScreenNameValidation: `有该名称的界面已经存在`,
      createNameLabel: `名称`,
      createNameValidationMessage: `有该名称的界面已经存在`,
      createNewLocationScreenBtn: `为新的界面选择位置`,
      createPickProcessChartLabel: `选择过程流程图包括于该界面`,
      createProcessChartCheckboxLabel: `过程流程图`,
      createProcessChartListTitle: `过程流程图包括于该界面`,
      createSelectLocation: `选择位置`,
      locationScreenView: `界面视图`
    },
    menu: {
      administration: `管理员`,
      administrationFixtures: `Fixtures`,
      administrationPartModels: `Part Models`,
      administrationParts: `Parts`,
      administrationPartMapping: `Part Mapping`,
      administrationPermissions: `权限`,
      administrationReactionPlanReasons: `Reaction Plan Reasons`,
      administrationRecycleBin: `Recycle Bin`,
      administrationRoles: `用户角色`,
      administrationRuleSettings: `Rule Settings`,
      administrationSampleReasons: `Sample Reasons`,
      administrationSettings: `设置`,
      administrationUnits: `单位`,
      administrationUsers: `用户`,
      characteristic: `特性`,
      characteristicCreate: `创建`,
      characteristicView: `视图`,
      cmmData: `三坐标数据`,
      cmmDataRunChart: `运行图`,
      cmmDataView: `视图`,
      dashboard: `指示板`,
      locationScreen: `屏幕位置`,
      locationScreenCreate: `创建`,
      locationScreenView: `视图`,
      processChart: `过程图`,
      processChartCreate: `创建`,
      processChartView: `视图`,
      reports: `报告`,
      user: `User`
    },
    processChart: {
      calculatedAttributeCharacteristicDisabled: `您不能将属性特性添加到计算特性中`,
      calculatedCreateCalculationString: `计算字符串`,
      calculatedCreateConstantValue: `常量的值：`,
      calculatedCreateCorrectCompletedEdit: `编辑`,
      calculatedCreateCorrectCompletedMsgPart1: `您确定想要`,
      calculatedCreateCorrectCompletedMsgPart2: `计算特性`,
      calculatedCreateCorrectCompletedMsgTitle: `计算字符串已完成`,
      calculatedCreateCorrectCompletedSave: `保存`,
      calculatedCreateEnabledBtn: `能够计算的特性`,
      calculatedCreateEnterName: `输入唯一的计算特性名称`,
      calculatedCreateIncludedCardHeader: `特性包括于此过程图`,
      calculatedCreateLocationPath: `位置路径：`,
      calculatedCreateMoreCharacteristicErrorMsg: `至少要两个特性（或特性常数）`,
      calculatedCreateNameLabel: `名称`,
      calculatedCreateNameValidation: `名称已被占用`,
      calculatedCreateResetBtn: `重置`,
      calculatedCreateSelectOperation: `选择操作`,
      calculatedCreateWithoutNameErrorMsg: `您必须填写计算特性的名称`,
      calculatedCreateWithoutOperatorErrorMsg: `必须添加操作者`,
      createCalculatedCharacteristicsIsUsedWarning: `计算特性已被用在其他的过程图`,
      createCharacteristicIsUsedWarning: `特性已被用在其他的过程图`,
      createCheckboxCharacteristicToSelect: `特性`,
      createCheckboxCharacteristicToSelectPN: ` - 零件号 `,
      createDataHaveBeen: `数据已保存`,
      createEditedData: `编辑`,
      createEnableProcessChartCheckboxLabel: `启用过程图`,
      createEnterUniqueName: `输入唯一的过程流程名称`,
      createIncludedCharacteristicsCartTitle: `特性包含于此过程图`,
      createLackOfCharacteristics: `缺少特性`,
      createMaxCharacteristicsWarning: `您已经添加了最大特性数量（20）`,
      createNameLabel: `名称`,
      createNameValidation: `此过程流程图的名称已经存在`,
      createNewCalculatedCharacteristicsBtn: `新的计算特性`,
      createPickCalculatedCharacteristicsLabel: `选择计算特性包含于此过程图`,
      createPickCharacteristicCardHeader: `选择特性包含于此过程图`,
      createPickLocationBtn: `为新的过程图选择位置`,
      createPickLocationModalTitle: `界面`,
      createSavedData: `保存`,
      createTeamCenterLabel: `团队中心文档号/版本`,
      processChartView: `过程图视图`,
      processChartButtonWarningTooltip: `You need to select at least one characteristic and complete the form`
    },
    userSetting: {
      addPhotoBtn: `添加`,
      cardHeader: `关于我`,
      changePhotoBtn: `更改`,
      confirmMsgBtn: `是的，修改密码！`,
      confirmPasswordLabel: `确认新密码`,
      confirmPasswordValidation: `确认新密码不能为空`,
      currentPasswordLabel: `当前密码`,
      currentPasswordValidationBlank: `当前密码不能为空`,
      currentPasswordValidationIsIncorrect: `密码不正确`,
      firstNameLabel: `名`,
      firstNameValidation: `名不能为空`,
      lastNameLabel: `姓`,
      lastNameValidation: `姓不能为空`,
      newPasswordLabel: `新的密码`,
      newPasswordValidation: `密码不能为空`,
      passwordIncorrectMsg: `当前密码错误`,
      passwordsValidationDifferent: `密码不能不相同`,
      removePhotoBtn: `移动`,
      userNameLabel: `用户名`
    },
  };
}
