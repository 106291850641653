import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {TranslationService} from '@services/translation.service';
import {GlobalTranslation, LayoutTranslation} from '@models/translation';
import {AvatarStatus, User} from '@models/user';
import {HelpersService, NotifierType} from '@services/helpers.service';
import {ModalBasicComponent} from '@shared/modal-basic/modal-basic.component';
import {NgForm} from '@angular/forms';
import {UserApiService} from '@user/user-api.service';

@Component({
  selector: 'app-first-user-sign-in',
  templateUrl: './first-user-sign-in.component.html',
  styleUrls: ['./first-user-sign-in.component.scss']
})
export class FirstUserSignInComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();

  public loggedUser = new User();
  public layoutTranslation = new LayoutTranslation();
  public globalTranslation = new GlobalTranslation();

  @ViewChild('repeatPasswordModal') repeatPasswordModal: ModalBasicComponent;

  constructor(
    private userApiService: UserApiService,
    private translationService: TranslationService,
    private helpersService: HelpersService
  ) {
    this.setTranslation();
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.layoutTranslation = translation.layout;
      this.globalTranslation = translation.global;
    });

    this.subscriptions.add(translationSubscription);
  }

  public showModal(loggedUser: User): void {
    this.loggedUser = loggedUser;
    this.repeatPasswordModal.show();
  }

  public onSubmit(formValues: User, isValid: boolean): void {
    if (!isValid) {
      return;
    }

    const updatedLoggedUser = <User>{
      ...this.loggedUser,
      password: formValues.password,
      confirmPassword: formValues.confirmPassword,
      forcePasswordChange: false,
      avatarStatus: AvatarStatus.Initial
    };

    this.userApiService.updateUserMe(updatedLoggedUser)
      .then(() => {
        this.repeatPasswordModal.hide();
        this.helpersService.notificationTrigger(NotifierType.Success, this.layoutTranslation.passwordChangeModalPasswordChanged);

        return false;
      })
      .catch(() => {
        this.helpersService.notificationTrigger(NotifierType.Error, this.globalTranslation.errorOccurred);
        this.repeatPasswordModal.hide();
      });
  }
}
