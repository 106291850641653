<app-modal-basic
  #repeatPasswordModal
  [dialogClass]="'modal-lg'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      {{layoutTranslation.passwordChangeModalTitle}}
    </h4>
  </div>

  <div class="app-modal-body"
       style="max-height: calc(100vh - 210px)">
    <form #userForm="ngForm"
          class="form-material"
          id="userForm"
          (ngSubmit)="onSubmit(userForm.value, userForm.valid)"
          autocomplete="off">
      <ng-container>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">
            {{layoutTranslation.passwordChangeModalPasswordLabel}}
          </label>

          <div *ngIf="loggedUser"
               class="col-sm-10">
            <app-vk-input
              #password="ngModel"
              type="password"
              name="password"
              appValidateEqual="confirmPassword"
              [required]="true"
              [(ngModel)]="loggedUser.password">
            </app-vk-input>

            <ng-container *ngIf="userForm.submitted">
              <div *ngIf="password.errors?.required"
                   class="messages text-danger">
                {{layoutTranslation.passwordChangeModalPasswordValidation}}
              </div>

              <div *ngIf="!password.valid && loggedUser.password && !password.errors?.required"
                   class="messages text-danger" >
                {{layoutTranslation.passwordChangeModalDifferentPassValidation}}
              </div>
            </ng-container>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-2 col-form-label">
            {{layoutTranslation.passwordChangeModalConfirmPasswordLabel}}
          </label>

          <div class="col-sm-10">
            <app-vk-input
              #confirmPassword="ngModel"
              type="password"
              name="confirmPassword"
              appValidateEqual="password"
              [required]="true"
              [(ngModel)]="loggedUser.confirmPassword">
            </app-vk-input>

            <ng-container *ngIf="userForm.submitted">
              <div *ngIf="confirmPassword.errors?.required"
                   class="messages text-danger">
                {{layoutTranslation.passwordChangeModalConfirmPasswordValidation}}
              </div>

              <div *ngIf="!confirmPassword.valid && loggedUser.confirmPassword && !confirmPassword.errors?.required"
                   class="messages text-danger">
                {{layoutTranslation.passwordChangeModalDifferentPassValidation}}
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </form>
  </div>

  <div class="app-modal-footer">
    <button
      type="submit"
      class="btn btn-primary ripple light"
      form="userForm">
      {{layoutTranslation.passwordChangeModalChangePasswordBtn}}
    </button>
  </div>
</app-modal-basic>
