<div class="d-flex flex-column width-100">
  <label
    class="text-center"
    [for]="label"
  >
    {{label}}
  </label>

  <ng-select
    [placeholder]="'any'"
    [id]="label"
    [labelForId]="label"
    [name]="label"
    [multiple]="false"
    [items]="filterOptions"
    [bindLabel]="'label'"
    [bindValue]="'value'"
    [(ngModel)]="selectedFilters"
    [ngbTooltip]="selectedFilterLabel"
    (clear)="onFilterChange(null)"
    (ngModelChange)="onFilterChange($event)"
  >
    <ng-template ng-option-tmp let-item="item">
      <span class="w-100 h-100 text-truncate"
            [title]="item.label">
        {{item.label}}
      </span>
    </ng-template>
  </ng-select>
</div>
