import { Sample } from '@models/sample';
import {CharacteristicSampleCollectionRulesBroken} from '@models/characteristic-sample-collection-rules-broken';

export class CharacteristicSampleCollection {
  public bgColor?: string;
  public calculationReset: boolean;
  public characteristicId: number;
  public collectionDesc: string;
  public cp?: number;
  public cpk?: number;
  public dateCreated?: Date;
  public dateEdited?: Date;
  public disabled?: boolean;
  public excludeCollection?: boolean;
  public id?: number;
  public isSampleCorrect?: boolean;
  public lsl?: number;
  public manualLCL?: number;
  public manualUCL?: number;
  public mean?: number;
  public meanCenterLine?: number;
  public meanLimitStatus?: number;
  public meanLCL?: number;
  public meanUCL?: number;
  public meanStartingLCL?: number;
  public meanStartingUCL?: number;
  public no?: number; // not from api, used as order in data table
  public notRequired?: boolean;
  public numberOfSamples?: number;
  public operatorInitials?: string;
  public partNumber?: string;
  public pp?: number;
  public ppk?: number;
  public processChartInputId?: number;
  public range?: number;
  public rangeCenterLine?: number;
  public rangeLimitStatus?: number;
  public rangeLCL?: number;
  public rangeUCL?: number;
  public reasonId?: number;
  public rulesBroken?: CharacteristicSampleCollectionRulesBroken[] = [];
  public ruleBreakOverride?: boolean;
  public sampleReason?: number;
  public serialNumber?: string;
  public characteristicCollectionSamples?: Sample[] = [];
  public standardDeviation?: number;
  public stdDevCenterLine?: number;
  public stdDevLCL?: number;
  public stdDevUCL?: number;
  public target?: number;
  public toRecalc?: boolean;
  public unscheduledCollection?: boolean;
  public udc?: number;
  public usl?: number;

  constructor(numberOfSamples = 10) {
    this.bgColor = '#000000';
    this.numberOfSamples = numberOfSamples;

    while (this.characteristicCollectionSamples.length < numberOfSamples) {
      this.characteristicCollectionSamples.push(new Sample());
    }
  }
}
