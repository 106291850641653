<div [hidden]="!isBackgroundLoading"
     class="loader-background">
</div>

<div [hidden]="!isLoading"
     [ngClass]="{'loader-body-wrapper': isBackgroundLoading}"
     class="loader-block">
  <svg id="loader2"
       viewBox="0 0 100 100">
    <circle
      id="circle-loader2"
      cx="50"
      cy="50"
      r="45">
    </circle>
  </svg>
</div>
