import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {ChartService} from '@shared/charts/chart.service';
import {TranslationService} from '@services/translation.service';
import {BaseChartComponent, ChartData, SeriesType} from '@shared/charts/base-chart/base-chart.component';
import {DataPoint} from '@shared/charts/sample-point';
import {SeriesOptionsType} from 'highcharts';
import {HistogramData} from '@models/histogram-data';


@Component({
  selector: 'app-histogram',
  templateUrl: '../base-chart/./base-chart.component.html',
  styleUrls: ['../base-chart/./base-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ChartService]
})
export class HistogramComponent extends BaseChartComponent implements OnInit, OnDestroy, OnChanges {
  chartOptionsLocalStorageKey = 'histogramOptionsLocalStorageKey';

  @Input() histogramData: HistogramData;

  constructor(
    private translationService: TranslationService,
    private chartService: ChartService
  ) {
    super(translationService);

    this.chartContainerId = 'histogramChartId';
    this.seriesType = SeriesType.Column;
    this.seriesColor = '#81b5eb';

    this.sampleTooltipFormatter = (point: DataPoint) => this.chartService.createHistogramTooltip(point);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    console.log('ON INIT - Histogram Data: ', this.histogramData);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public ngOnChanges(changes): void {
    console.log('ON CHANGES - Histogram Data: ', changes.histogramData);
    if (changes.isOptionsOpened) {
      this.resizeChart();
    }
    if (changes.histogramData) {
      this.init();
    }
  }

  protected setLabels() {
    this.sampleName = 'Data';
    this.chartCardTitle = this.componentsTranslation.meanChart_cardTitle;
    this.xAxisTitle = this.componentsTranslation.meanChart_chartTextDateXaxis;
    this.yAxisTitle = 'Result';
  }

  protected init(): void {
    if (this.histogramData && this.chartContainer) {
      this.setLabels();
      this.generateChart();
    }
  }

  protected generateSamples(): ChartData {
    if (!this.histogramData) {
      return  { points: [], labels: [] };
    }

    const points: DataPoint[] = [];

    let x = 0;

    for (const binCount of this.histogramData.binCounts) {
      points.push({
        x: x++,
        y: binCount,
        color: this.seriesColor
      });
    }

    this.chartOptions.yAxisMinValue = 0;
    this.chartOptions.yAxisMaxValue = Math.max(...this.histogramData.binCounts);

    return { points: points, labels: this.histogramData.binRanges };
  }

  protected generateSeries(): Array<SeriesOptionsType> {
    const chartData = this.generateSamples();
    this.xAxisLabelFormatter = point => chartData.labels[point];

    return [
      {
        type: 'column',
        name: this.sampleName,
        color: this.seriesColor,
        data: chartData.points,
        showInLegend: !!chartData.points.length,
      }
    ];
  }
}
