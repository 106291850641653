
import {filter} from 'rxjs/operators';
import {
  AfterContentInit,
  Component, EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {TabFilterService} from '@shared/filters/tab-filter.service';
import {TranslationService} from '@services/translation.service';

import {ComponentsTranslation} from '@models/translation';
import {LabeledValue} from '@common/global';
import {Subscription} from 'rxjs';
import {CharacteristicViewFilterOptions, ViewFilterOptions} from '@models/view-filter-options';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FiltersComponent implements OnDestroy, AfterContentInit {
  private readonly subscriptions = new Subscription();

  private isInitialized = false;
  private lastFilterOptions: ViewFilterOptions;
  public plantsLocation: LabeledValue<string>[] = [];
  public departmentsLocation: LabeledValue<string>[] = [];
  public componentsTranslation: ComponentsTranslation;

  @Input() availableFilterOptions = new ViewFilterOptions();
  @Input() indexedDbKey: string;

  @Output() availableFilterOptionsChange = new EventEmitter<ViewFilterOptions>();

  constructor(
    private tabFilterService: TabFilterService,
    private translationService: TranslationService
  ) {
    this.setTranslation();
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.componentsTranslation = translation.components;
    });

    this.subscriptions.add(translationSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.saveLocalStorage();
  }

  public ngAfterContentInit(): void {
    this.loadLocalStorage();
    this.getData();

    const customFiltersSubscription = this.tabFilterService.customFiltersInitialized
      .subscribe(customFilters => customFilters
        .forEach(customFilter => customFilter.selectedFiltersChange.subscribe(() => {
          this.applyFilter();
        })));
    const customSwitcherSubscription = this.tabFilterService.customSwitchersInitialized
      .subscribe(customSwitchers => customSwitchers
        .forEach(customSwitcher => customSwitcher.valueChange.subscribe(() => {
          this.applyFilter();
        })));

    this.subscriptions.add(customFiltersSubscription);
    this.subscriptions.add(customSwitcherSubscription);
  }

  private saveLocalStorage(): void {
    const sessionData = this.availableFilterOptions.isAnyFilterApplied()
      ? this.availableFilterOptions
      : null;

    localStorage.setItem(this.indexedDbKey, JSON.stringify(sessionData));
  }

  private getData(): void {
    const filterOptionChangedSubscription = this.tabFilterService.filterOptionsChanged.pipe(
      filter(filterOptions => !!filterOptions))
      .subscribe(filterOptions => {
        this.plantsLocation = filterOptions.plants
          .map(plant => new LabeledValue<string>(plant.uid, plant.name));

        if (this.availableFilterOptions.selectedPlant != null) {
          this.departmentsLocation = filterOptions.departments
            .filter(department => department.plantUid === this.availableFilterOptions.selectedPlant)
            .map(department => new LabeledValue<string>(department.uid, department.name));
        } else {
          this.departmentsLocation = filterOptions.departments
            .map(department => new LabeledValue<string>(department.uid, department.name));
        }
      });

    this.subscriptions.add(filterOptionChangedSubscription);
  }

  private loadLocalStorage(): void {
    const options = JSON.parse(localStorage.getItem(this.indexedDbKey)) as ViewFilterOptions;

    if (options) {
      this.availableFilterOptions = new CharacteristicViewFilterOptions(options);
      this.availableFilterOptionsChange.emit(this.availableFilterOptions);
    }

    this.isInitialized = true;
    this.applyFilter();
  }

  public applyFilter(plantChange?: boolean): void {
    if (!this.isInitialized || this.availableFilterOptions.equals(this.lastFilterOptions)) {
      return;
    }
    if (plantChange) {
      delete this.availableFilterOptions.selectedDepartment;
      this.setAvailableDepartments();
    }

    this.lastFilterOptions = new CharacteristicViewFilterOptions(this.availableFilterOptions);
    this.tabFilterService.filtersChanged.next(this.availableFilterOptions);
  }

  private setAvailableDepartments(): void {
    this.getData();
  }

  public clearFilter(): void {
    this.availableFilterOptions = new CharacteristicViewFilterOptions();
    this.availableFilterOptionsChange.emit(this.availableFilterOptions);
    this.applyFilter();
  }
}
