<div class="basic modal fade"
     tabindex="-1"
     [ngClass]="{'in': visibleAnimate}"
     [ngStyle]="{
       'display': visible
         ? 'block'
         : 'none',
       'opacity': visibleAnimate
         ? '1'
         : '0'
     }">
  <div class="modal-dialog"
       [ngClass]="dialogClass"
       [ngStyle]="{
         'width': slimModal
           ? '500px'
           : contentWidth,
          'maxWidth': 'unset',
         'height': !height
           ? '500px'
           : contentHeight,
          'maxHeight': 'unset'
       }">
    <div class="modal-content"
         [ngStyle]="{
           'margin': slimModal
             ? '0 auto'
             : 'unset'
         }">
      <div *ngIf="!hideHeader"
           class="modal-header">
        <ng-content select=".app-modal-header">
        </ng-content>
      </div>

      <div class="modal-body">
        <ng-content select=".app-modal-body">
        </ng-content>
      </div>

      <div *ngIf="!hideFooter"
           class="modal-footer">
        <ng-content select=".app-modal-footer">
        </ng-content>
      </div>
    </div>
  </div>
</div>
