import { Component, OnInit } from '@angular/core';
import { User } from '@models/user';
import { TranslationService } from '@services/translation.service';
import {HelpersService} from '@services/helpers.service';
import {UserService} from '@user/user.service';
import {Language} from '@common/enums/language';
import {UserApiService} from '@user/user-api.service';

interface LanguageOption {
  value: Language;
  label: string;
  fullName: string;
  locale: string;
}

@Component({
  selector: 'app-switch-language',
  templateUrl: './switch-language.component.html',
  styleUrls: ['./switch-language.component.scss'],
})
export class SwitchLanguageComponent implements OnInit {
  public loggedUser: User;
  public chosenLanguage: Language;
  public languages: LanguageOption[] = [
    {value: Language.English, label: 'en', fullName: 'english', locale: 'en-US'},
    {value: Language.Polish, label: 'pl', fullName: 'polish', locale: 'pl-PL'},
    {value: Language.Spanish, label: 'es', fullName: 'spanish', locale: 'es-ES'},
    {value: Language.Chinese, label: 'zh', fullName: 'chinese', locale: 'zh-ZH'},
    {value: Language.Portuguese, label: 'pt', fullName: 'portuguese', locale: 'pt-PT'}
  ];

  constructor(
    private translationService: TranslationService,
    private userApiService: UserApiService,
  ) { }

  public ngOnInit(): void {
    this.loggedUser = UserService.getLoggedUser();
    if (this.loggedUser && this.loggedUser.language) {
      this.chosenLanguage = this.loggedUser.language;
    } else {
      this.chosenLanguage = Language.English;
    }
  }

  public setLanguage(languageOption: LanguageOption): void {
    this.translationService.setTranslation(languageOption.value);
    const permissions = this.loggedUser.apiPermissions;
    this.loggedUser.language = languageOption.value;

    this.userApiService.updateUserMe(this.loggedUser).then(updatedUser => {
      this.loggedUser = updatedUser;
      this.loggedUser.apiPermissions = permissions;
      HelpersService.saveLoggedUser(this.loggedUser);
      this.chosenLanguage = updatedUser.language;
    });
  }
}
