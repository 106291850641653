import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {GlobalTranslation} from '@models/translation';
import {AttributeSampleValue} from '@services/helpers.service';
import {TranslationService} from '@services/translation.service';
import {Subscription} from 'rxjs';


@Directive({
  selector: '[appSetAttributeCell]'
})
export class SetAttributeCellDirective implements OnInit, OnChanges, OnDestroy {
  private readonly subscriptions = new Subscription();

  public globalTranslation: GlobalTranslation;

  @Input() value: AttributeSampleValue;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private translationService: TranslationService,
  ) {
    this.setTranslation();
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.globalTranslation = translation.global;
    });

    this.subscriptions.add(translationSubscription);
  }

  public ngOnInit(): void {
    this.setAttribute();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.setAttribute();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setAttribute(): void {
    if (this.value === AttributeSampleValue.NR) {
      // closed to remove in future
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', '<div class="icon-o-yellow my-0 mx-auto"></div>');
    } else if (this.value === AttributeSampleValue.X) {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', '<div class="icon-x-red my-0 mx-auto"><div></div><div></div>');
    } else if (this.value === AttributeSampleValue.Plus) {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', '<div class="icon-plus-green my-0 mx-auto"><div></div><div></div>');
    } else if (this.value === AttributeSampleValue.Null) {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', `<div>${this.globalTranslation.notRunningAttribute}</div>`);
    }
  }
}
