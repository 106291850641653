<app-modal-basic
  #modal
  [dialogClass]="'modal-lg'"
  [width]="1000"
  xmlns="http://www.w3.org/1999/html">
  <div class="app-modal-header w-100 my-0 mx-auto">
    <div class="header-title w-100">
      <h4 class="modal-title w-100">
        {{componentsTranslation.addSampleModal_modalHeader}}
      </h4>

      <h5 class="w-100">
        <ng-container *ngIf="characteristicData?.name">
          <app-responsive-text-line
            [text]="characteristicData.name"
            tooltipPlacement="bottom">
          </app-responsive-text-line>
        </ng-container>
      </h5>
    </div>

    <button class="close basic-close"
            (click)="closeModal()"
            type="button">
      <span aria-hidden="true">
        &times;
      </span>
    </button>
  </div>

  <div class="app-modal-body"
       style="max-height: calc(100vh - 210px); overflow-y: auto;">
    <form id="sampleForm"
          class="form-material"
          #sampleForm="ngForm"
          (ngSubmit)="onSubmitSample(sampleForm)">
      <div class="card"
           style="box-shadow: none; margin-bottom: 10px;">
        <div *ngIf="addSampleSource === addSampleSources.ProcessChart"
             class="checkbox-color NR-checkbox">
          <input
            id="notRunning"
            name="notRunning"
            [(ngModel)]="addSample.notRunning"
            (ngModelChange)="checkIsNotRunningOn($event)"
            type="checkbox"/>

          <label for="notRunning">
            {{componentsTranslation.addSampleModal_notRunningLabel}}
          </label>
        </div>

        <div class="card-block">
          <div class="form-group row part-number-row">
            <div class="col-sm-5">
              <select
                id="partId"
                name="partId"
                class="form-control"
                required
                [(ngModel)]="addSample.partId"
                [disabled]="parts.length === 1">
                <option *ngFor="let part of parts"
                        [ngValue]="part.partNumber">
                  {{part.partNumber}}
                </option>
              </select>

              <label
                class="float-label"
                [ngStyle]="parts.length === 1 && {'top': '-14px', 'font-size': '11px'}"
                for="partId"
                style="left: 15px">
                {{componentsTranslation.addSampleModal_partNumberLabel}}
              </label>

              <div *ngIf="sampleForm.submitted && !addSample.partId"
                   class="messages text-danger">
                {{globalTranslation.isRequired}}
              </div>
            </div>
            <div class="col-sm-7 d-flex justify-content-end">
              <label class="col-form-label text-right">
                {{componentsTranslation.reactionPlan_calculationReset}}
              </label>
              <div class="col-sm-auto">
                <div class="checkbox-color">
                  <input
                    id="calculationReset"
                    name="calculationReset"
                    type="checkbox"
                    [(ngModel)]="addSample.calculationReset"
                    [disabled]="false"/>
                  <label for="calculationReset"></label>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="serialNumberEnabled"
               class="form-group row">
            <div class="col-sm-5">
              <app-vk-input
                type="text"
                maxlength="255"
                name="serialNumber"
                [label]="componentsTranslation.addSampleModal_serialNumberLabel"
                [required]="serialNumberEnabled"
                [(ngModel)]="addSample.serialNumber">
              </app-vk-input>

              <div class="messages text-danger"
                   *ngIf="sampleForm.submitted && serialNumberEnabled && !addSample.serialNumber">
                {{globalTranslation.isRequired}}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-5">
              <select
                class="form-control"
                id="reasonId"
                name="reasonId"
                [(ngModel)]="addSample.reasonId"
                required>
                <option *ngFor="let reason of sampleReasonsOptions"
                        [ngValue]="reason.value">
                  {{reason.label}}
                </option>
              </select>

              <label
                class="float-label"
                style="left: 15px"
                for="reasonId">
                {{componentsTranslation.addSampleModal_reasonLabel}}
              </label>

              <div class="messages text-danger"
                   *ngIf="sampleForm.submitted && !addSample.reasonId">
                {{globalTranslation.isRequired}}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-4">
              <app-vk-input
                type="text"
                maxlength="50"
                name="operatorInitials"
                [label]="componentsTranslation.addSampleModal_operatorInitialLabel"
                [required]="true"
                [(ngModel)]="addSample.operatorInitials">
              </app-vk-input>

              <div *ngIf="sampleForm.submitted && !addSample.operatorInitials"
                   class="messages text-danger">
                {{globalTranslation.isRequired}}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-12">
              <app-vk-input
                type="text"
                maxlength="255"
                name="remarks"
                [label]="componentsTranslation.addSampleModal_remarksLabel"
                [(ngModel)]="addSample.remarks">
              </app-vk-input>
            </div>
          </div>
        </div>
      </div>

      <div style="border-top: 1px solid #dee2e6; margin-bottom: 20px;"></div>

      <div class="card"
           style="box-shadow: none">
        <div class="card-title">
          {{componentsTranslation.addSampleModal_cardTitleSamples}}
        </div>

        <div class="characteristic-card-wrapper">
          <div class="characteristic-card"
               *ngFor="let characteristic of selectedCharacteristics; let i = index;">
            <p class="characteristic-title w-200 flex">
              <b>
                <app-responsive-text-line
                  [text]="characteristic.name">
                </app-responsive-text-line>
              </b>
            </p>

            <div class="characteristic-info">
              <div
                [id]="'add-value-btn-' + i"
                [ngClass]="setButtonStyle(characteristic, addSample.notRunning)"
                (click)="openSampleModal(characteristic)"
                (wheel)="setScrollEventsToButton($event, characteristic)"
              >
                {{setValueButton(characteristic)}}
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="sampleForm.submitted && addSampleSource === addSampleSources.Characteristic && !averageOfSamples.size"
             class="messages text-danger">
          {{globalTranslation.isRequired}}
        </div>
      </div>
    </form>
  </div>

  <div class="app-modal-footer">
    <button class="btn btn-default ripple"
            (click)="closeModal()"
            type="button">
      {{globalTranslation.cancelBtn}}
    </button>

    <button class="btn btn-primary ripple light"
            form="sampleForm"
            [disabled]="isSubmitting"
            type="submit">
      {{componentsTranslation.addSampleModal_completeBtn}}
    </button>
  </div>
</app-modal-basic>

<app-add-sample-collection
  *ngIf="openModalAddSampleCollection"
  id="add-sample-modal"
  [addSampleModal]="true"
  [characteristic]="characteristicTooAddSamples"
  [openModal]="openModalAddSampleCollection"
  [sampleCollectionsToChange]="sampleCollectionsToChange"
  [withoutValues]="withoutValuesAddSample"
  (samples)="setSamples($event)"
  (setAttributeSample)="setAttributeSample($event)"
  (setClose)="closeAddSampleModal()"
>
</app-add-sample-collection>
