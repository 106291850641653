import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import {PlacementArray} from '@ng-bootstrap/ng-bootstrap/util/positioning';

@Component({
  selector: 'app-responsive-text-line',
  templateUrl: './responsive-text-line.component.html',
  styleUrls: ['./responsive-text-line.component.scss']
})
export class ResponsiveTextLineComponent {
  public tooltip = '';

  @Input() width: number;
  @Input() text: any;
  @Input() isItFilterValue: boolean;
  @Input() isItConceptSearchQuery: boolean;
  @Input() isTooltipVisible = true;
  @Input() tooltipPlacement: PlacementArray = 'top';

  @ViewChild('ruler') ruler;

  constructor(
    private elementRef: ElementRef,
  ) { }

  @HostListener('mouseover')
  onMouseOver() {
    this.prepareTooltip();
  }

  prepareTooltip(): void {
    if (!this.isTooltipVisible) {
      return;
    }

    const parentWidth = this.elementRef.nativeElement.offsetWidth;
    const textWidth = this.ruler.nativeElement.offsetWidth;

    if (this.isItFilterValue) {
      const maxFilterWidth = 172;
      this.tooltip = textWidth >= maxFilterWidth ? this.text : '';
    } else if (this.isItConceptSearchQuery) {
      const maxConceptSearchWidth = 280;
      this.tooltip = textWidth >= maxConceptSearchWidth ? this.text : '';
    } else {
      this.tooltip = textWidth >= parentWidth ? this.text : '';
    }
  }
}
