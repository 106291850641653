<ng-container *ngIf="imageType === imageTypes.Url">
  <img [src]="photoUrl"
       class="img-radius"
       alt="User-Profile-Image">
</ng-container>

<ng-container *ngIf="imageType === imageTypes.Base64">
  <img [src]="sanitizedBase64Image"
       class="img-radius"
       alt="User-Profile-Image">
</ng-container>
