import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FixtureData } from '@characteristic/create/fixture-select/fixture-select.component';
import { CharacteristicsTranslation} from '@models/translation';
import { TranslationService } from '@services/translation.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-sample-input',
  templateUrl: './sample-input.component.html',
  styleUrls: ['./sample-input.component.scss']
})
export class SampleInputComponent implements OnDestroy {
  private readonly subscriptions = new Subscription();

  public readonly iconPlus = faPlus;

  public isFixtureListOpen = false;
  public characteristicTranslation: CharacteristicsTranslation;

  @Input() selectedFixtureId: number;
  @Input() fixtureData: FixtureData[] = [];
  @Input() disabled: boolean;

  @Output() sampleFixtureDataChange = new EventEmitter<number>();

  constructor(
    private translationService: TranslationService
  ) {
    this.setTranslations();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setTranslations(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.characteristicTranslation = translation.characteristics;
    });

    this.subscriptions.add(translationSubscription);
  }

  public toggleFixtureList(): void {
    this.isFixtureListOpen = !this.isFixtureListOpen;
  }

  public closeFixtureList(): void {
    this.isFixtureListOpen = false;
  }

  public toggleFixture(selectedFixture: FixtureData): void {
    if (this.selectedFixtureId === selectedFixture.fixtureId) {
      this.selectedFixtureId = null;
    } else {
      this.selectedFixtureId = selectedFixture.fixtureId;
    }

    this.sampleFixtureDataChange.emit(this.selectedFixtureId);
  }

  public getListItemText(fixture: FixtureData): string {
    return `Fixture: ${fixture.internalId} - ${fixture.fixtureName}`;
  }
}

