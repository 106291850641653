<app-modal-basic
  #modalPermission
  [dialogClass]="'modal-lg small-confirm-modal'">
  <div class="app-modal-header my-0 mx-auto">
    <h4 class="modal-title">
      {{modalTitle}}
    </h4>

    <button
      type="button"
      class="close basic-close"
      (click)="closeModal()">
      <span aria-hidden="true">
        &times;
      </span>
    </button>
  </div>

  <div class="app-modal-body"
       style="max-height: calc(100vh - 210px); overflow-y: auto;">
    <form
      #checkPermissionsForm="ngForm"
      class="form-material"
      id="checkPermissionsForm"
      autocomplete="off"
      (ngSubmit)="onSubmit(checkPermissionsForm.value, checkPermissionsForm.valid)"
    >
      <div class="form-group form-primary"
           style="margin: 20px 10%">
        <app-vk-input
          type="text"
          maxlength="50"
          name="login"
          [label]="componentsTranslation.confirmUserPermission_usernameLabel"
          [required]="true"
          [(ngModel)]="credentials.login">
        </app-vk-input>
      </div>

      <div class="form-group form-primary"
           style="margin: 20px 10%">
        <app-vk-input
          type="password"
          maxlength="50"
          name="password"
          [label]="componentsTranslation.confirmUserPermission_passwordLabel"
          [required]="true"
          [(ngModel)]="credentials.password">
        </app-vk-input>
      </div>

      <div class="row messages text-danger"
           style="margin: 10px 10%;"
           *ngIf="checkPermissionsForm.submitted && (!credentials.password || !credentials.login)">
        {{componentsTranslation.confirmUserPermission_emptyFieldsWarning}}
      </div>
    </form>
  </div>

  <div class="app-modal-footer">
    <div>
      <button
        type="button"
        class="btn btn-default ripple"
        (click)="closeModal()"
      >
        {{globalTranslation.cancelBtn}}
      </button>
    </div>

    <div>
      <button
        form="checkPermissionsForm"
        class="btn btn-primary ripple light"
        type="submit"
      >
        {{globalTranslation.enterBtn}}
      </button>
    </div>
  </div>
</app-modal-basic>
