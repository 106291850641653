import {AfterViewInit, Component, OnChanges, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {BaseChartComponent, ChartData, SeriesType} from '@shared/charts/base-chart/base-chart.component';
import {TranslationService} from '@services/translation.service';
import {ChartService} from '@shared/charts/chart.service';
import {DataPoint} from '@shared/charts/sample-point';
import {SeriesOptionsType, XAxisOptions, YAxisOptions} from 'highcharts';

import * as Highcharts from 'highcharts';
import BellCurve from 'highcharts/modules/histogram-bellcurve';
BellCurve(Highcharts);


@Component({
  selector: 'app-bell-curve-chart',
  templateUrl: '../base-chart/./base-chart.component.html',
  styleUrls: ['../base-chart/./base-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ChartService]
})
export class BellCurveChartComponent extends BaseChartComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  chartOptionsLocalStorageKey = 'bellCurveOptionsLocalStorageKey';

  constructor(
    private translationService: TranslationService
  ) {
    super(translationService);

    this.chartContainerId = 'bellCurveId';
    this.seriesType = SeriesType.BellCurve;
    this.seriesColor = '#81b5eb';
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public ngOnChanges(changes): void {
    if (changes.isOptionsOpened) {
      this.resizeChart();
    }
  }

  public ngAfterViewInit(): void {
    this.init();
  }

  protected setLabels() {
    this.sampleName = 'Bell Curve - Data';
    this.chartCardTitle = 'Bell Curve';
    this.xAxisTitle = 'Data';
    this.yAxisTitle = 'Data';
  }

  protected init(): void {
    if (this.chartContainer) {
      this.setLabels();
      this.generateChart();
    }
  }

  protected generateSeries(): Array<SeriesOptionsType> {
    const chartData = this.generateSamples();
    this.xAxisLabelFormatter = null;

    return [
      {
        name: 'Bell curve',
        type: 'bellcurve',
        xAxis: 1,
        yAxis: 1,
        baseSeries: 's1',
        zIndex: -1
      },
      {
        name: this.sampleName,
        type: 'scatter',
        data: chartData.points.map(point => point.y),
        id: 's1',
        color: this.seriesColor,
        showInLegend: !!chartData.points.length,
        marker: {
          enabled: true,
          radius: 1.5
        }
      }
    ];
  }

  protected generateSamples(): ChartData {
    const labels: string[] = [];
    const points: DataPoint[] = [];
    let x = 0;

    for (const sample of this.getData()) {
      points.push({
        x: x++,
        y: sample,
        color: this.seriesColor
      });
    }

    const pointValues = points.map(point => point.y);

    this.chartOptions.yAxisMinValue = 0;
    this.chartOptions.yAxisMaxValue = Math.max(...pointValues);

    return { points: points, labels: labels };
  }

  protected getXAxisOptions(): Array<XAxisOptions> {
    const xAxisOptions = super.getXAxisOptions();
    xAxisOptions.push(
      {
        title: {
          text: 'bell'
        },
        opposite: true
      });

    return xAxisOptions;
  }

  protected getYAxisOptions(): Array<YAxisOptions> {
    const yAxisOptions = super.getYAxisOptions();
    yAxisOptions.push(
      {
        title: {
          text: 'bell'
        },
        alignTicks: false,
        opposite: true
      });

    return yAxisOptions;
  }

  private getData(): number[] {
    return [3.5, 3, 3.2, 3.1, 3.6, 3.9, 3.4, 3.4, 2.9, 3.1, 3.7, 3.4, 3, 3, 4,
      4.4, 3.9, 3.5, 3.8, 3.8, 3.4, 3.7, 3.6, 3.3, 3.4, 3, 3.4, 3.5, 3.4, 3.2,
      3.1, 3.4, 4.1, 4.2, 3.1, 3.2, 3.5, 3.6, 3, 3.4, 3.5, 2.3, 3.2, 3.5, 3.8, 3,
      3.8, 3.2, 3.7, 3.3, 3.2, 3.2, 3.1, 2.3, 2.8, 2.8, 3.3, 2.4, 2.9, 2.7, 2, 3,
      2.2, 2.9, 2.9, 3.1, 3, 2.7, 2.2, 2.5, 3.2, 2.8, 2.5, 2.8, 2.9, 3, 2.8, 3,
      2.9, 2.6, 2.4, 2.4, 2.7, 2.7, 3, 3.4, 3.1, 2.3, 3, 2.5, 2.6, 3, 2.6, 2.3,
      2.7, 3, 2.9, 2.9, 2.5, 2.8, 3.3, 2.7, 3, 2.9, 3, 3, 2.5, 2.9, 2.5, 3.6,
      3.2, 2.7, 3, 2.5, 2.8, 3.2, 3, 3.8, 2.6, 2.2, 3.2, 2.8, 2.8, 2.7, 3.3, 3.2,
      2.8, 3, 2.8, 3, 2.8, 3.8, 2.8, 2.8, 2.6, 3, 3.4, 3.1, 3, 3.1, 3.1, 3.1, 2.7,
      3.2, 3.3, 3, 2.5, 3, 3.4, 3];
  }
}
