export class Unit {
  public id: number;
  public name: string;
  public shortName: string;
  public isUsed?: boolean;

  constructor(unit: Partial<Unit> = {}) {
    Object.assign(this, unit);
  }

  public toString(): string {
    return `${this.shortName} (${this.name})`;
  }
}
