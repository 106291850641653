import { Injectable } from '@angular/core';
import {ApiService} from '@services/api.service';
import {Plant} from '@models/plant';
import {Department} from '@models/department';

@Injectable()
export class TableFilterApiService {
  protected modelPrefixUrl;
  constructor(
    private apiService: ApiService,
  ) {}

  public hideModel(id: number): Promise<any> {
    const relativeUrl = `${this.modelPrefixUrl}/Hide/${id}`;

    return this.apiService.put(relativeUrl, null);
  }

  public removeModelFromFavorite(userId: number, locationId: number): Promise<any> {
    const relativeUrl = `user/${userId}/${this.modelPrefixUrl}/${locationId}`;

    return this.apiService.delete(relativeUrl);
  }

  public addModelToFavorite(userId: number, locationId: number): Promise<any> {
    const relativeUrl = `user/${userId}/${this.modelPrefixUrl}/${locationId}`;

    return this.apiService.post(relativeUrl, null);
  }

  public getPlants(): Promise<Plant[]> {
    const relativeUrl = `characteristic/GetPlants`;

    return this.apiService.get(relativeUrl);
  }

  public getDepartments(): Promise<Department[]> {
    const relativeUrl = `characteristic/GetDepartments`;

    return this.apiService.get(relativeUrl);
  }
}
