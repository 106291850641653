import { Injectable } from '@angular/core';
import {ApiService} from '@services/api.service';
import {CharacteristicApiService} from '@characteristic/characteristic-api.service';

import {ReactionPlan} from '@models/reaction-plan';
import {ProcessChart} from '@models/process-chart';
import {Characteristic} from '@models/characteristic';
import {CalculatedCharacteristic} from '@models/calculated-characterisitc';
import {CharacteristicComparison} from '@models/characteristic-comparison';

@Injectable()
export class ProcessChartApiService {

  constructor(
    private apiService: ApiService,
    private characteristicApiService: CharacteristicApiService
  ) { }

  public getCalculatedCharacteristicsByLocation(id: string): Promise<CalculatedCharacteristic[]> {
    const relativeUrl = `ProcessChart/CalculatedCharacteristicsByLocation/${id}`;

    return this.apiService.get(relativeUrl);
  }

  // Process Chart Control API
  public getReactionPlan(id: number): Promise<ReactionPlan> {
    return this.characteristicApiService.getReactionPlan(id);
  }

  public getProcessChart(processChartId: number): Promise<ProcessChart> {
    const relativeUrl = `ProcessChart/${processChartId}`;

    return this.apiService.get(relativeUrl);
  }

  public getProcessChartRows(processChartId: number, partNumber: string) {
    const relativeUrl = `ProcessChart/ProcessChartRows/${processChartId}/${partNumber}`;

    return this.apiService.get(relativeUrl);
  }
  // endregion

  // region Process Chart Create API
  public isProcessChartNameUnique(sdNumber: string, name: string): Promise<boolean> {
    const relativeUrl = `ProcessChart/IsUnique/${sdNumber}`;

    return this.apiService.post(relativeUrl, name);
  }

  public addProcessChart(processChart: ProcessChart): Promise<any> {
    const relativeUrl = `ProcessChart`;

    return this.apiService.post(relativeUrl, processChart);
  }

  public editProcessChart(processChart: ProcessChart): Promise<any> {
    const relativeUrl = `ProcessChart/${processChart.id}`;

    return this.apiService.put(relativeUrl, processChart);
  }

  public getCharacteristicListByMachine(machineID: string): Promise<Characteristic[]> {
    const relativeUrl = `ProcessChart/MachineCharacteristics/${machineID}`;

    return this.apiService.get(relativeUrl);
  }

  public getCharacteristicListByMachineEdit(machineId: string, processChartId: number): Promise<Characteristic[]> {
    const relativeUrl = `ProcessChart/MachineCharacteristics/${machineId}/${processChartId}`;

    return this.apiService.get(relativeUrl);
  }

  public getCalculatedCharacteristicsByLocationEdit(id: string, processChartId: number): Promise<CalculatedCharacteristic[]> {
    const relativeUrl = `ProcessChart/CalculatedCharacteristicsByLocation/${id}/${processChartId}`;

    return this.apiService.get(relativeUrl);
  }
  // endregion

  // region Calculated Characteristic API
  public isCalculatedCharacteristicNameUnique(locationId: string, name: string): Promise<boolean> {
    const relativeUrl = `ProcessChart/CalculatedCharacteristic/IsUnique/${locationId}`;

    return this.apiService.post(relativeUrl, name);
  }

  public getCalculatedCharacteristic(id: number): Promise<CalculatedCharacteristic> {
    const relativeUrl = `ProcessChart/CalculatedCharacteristic/${id}`;

    return this.apiService.get(relativeUrl);
  }

  public addCalculatedCharacteristic(data: CalculatedCharacteristic): Promise<any> {
    const relativeUrl = `ProcessChart/CalculatedCharacteristic`;

    return this.apiService.post(relativeUrl, data);
  }

  public editCalculatedCharacteristic(data: CalculatedCharacteristic): Promise<any> {
    const relativeUrl = `ProcessChart/CalculatedCharacteristic/${data.id}`;

    return this.apiService.put(relativeUrl, data);
  }

  public getCompatibleCharacteristics(characteristicComparison: CharacteristicComparison): Promise<Characteristic[]> {
    const relativeUrl = `Characteristic/GetCompatible`;

    return this.apiService.post(relativeUrl, characteristicComparison);
  }
  // endregion
}
