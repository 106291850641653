<app-loader [isLoading]="isLoading"
            [isBackgroundLoading]="isLoading">
</app-loader>

<div class="page-body">
  <div class="row">
    <div class="col-lg-12 small-padding-col">
      <div class="md-tabs md-card-tabs">
        <div class="card">
          <div class="card-header">
            <h5 class="card-header-text">
              {{userSettingTranslation.cardHeader}}
            </h5>
          </div>

          <div class="card-body">
            <div class="view-info">
              <div class="row">
                <div class="col-lg-12">
                  <div class="general-info">
                    <form #editForm="ngForm"
                          id="editForm"
                          (ngSubmit)="onSubmit(editForm.value, editForm.valid); filePicker.value = null"
                          autocomplete="off">
                      <div class="row">
                        <div class="col-lg-12 col-xl-6">
                          <input type="hidden"
                                 id="id" name="id"
                                 [(ngModel)]="user.id"
                                 #id="ngModel"/>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              {{userSettingTranslation.userNameLabel}}
                            </label>

                            <div class="col-sm-9">
                              <app-vk-input
                                type="text"
                                name="userName"
                                [required]="true"
                                [disabled]="true"
                                [(ngModel)]="user.userName">
                              </app-vk-input>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              {{userSettingTranslation.firstNameLabel}}
                            </label>

                            <div class="col-sm-9">
                              <app-vk-input
                                type="text"
                                name="firstName"
                                maxlength="50"
                                [disabled]="true"
                                [required]="true"
                                [(ngModel)]="user.firstName">
                              </app-vk-input>

                              <div *ngIf="editForm.submitted && !user.firstName"
                                   class="messages text-danger">
                                {{userSettingTranslation.firstNameValidation}}
                              </div>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              {{userSettingTranslation.lastNameLabel}}
                            </label>

                            <div class="col-sm-9">
                              <app-vk-input
                                type="text"
                                name="lastName"
                                maxlength="50"
                                [disabled]="true"
                                [required]="true"
                                [(ngModel)]="user.lastName">
                              </app-vk-input>

                              <div *ngIf="editForm.submitted && !user.lastName"
                                   class="messages text-danger">
                                {{userSettingTranslation.lastNameValidation}}
                              </div>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              {{userSettingTranslation.currentPasswordLabel}}
                            </label>

                            <div class="col-sm-9">
                              <app-vk-input
                                #currentPassword
                                type="password"
                                name="currentPassword"
                                maxlength="50"
                                [required]="!!user.password || !!user.confirmPassword"
                                [(ngModel)]="user.currentPassword">
                              </app-vk-input>

                              <div *ngIf="editForm.submitted && currentPassword.required && !user.currentPassword"
                                   class="messages text-danger">
                                {{userSettingTranslation.currentPasswordValidationBlank}}
                              </div>

                              <div *ngIf="editForm.submitted && incorrectPassword"
                                   class="messages text-danger">
                                {{userSettingTranslation.currentPasswordValidationIsIncorrect}}
                              </div>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              {{userSettingTranslation.newPasswordLabel}}
                            </label>

                            <div class="col-sm-9">
                              <app-vk-input
                                #password
                                type="password"
                                name="password"
                                maxlength="50"
                                appValidateEqual="confirmPassword"
                                [required]="!!user.currentPassword || !!user.confirmPassword"
                                [(ngModel)]="user.password">
                              </app-vk-input>

                              <div *ngIf="editForm.submitted && password.required && !user.password"
                                   class="messages text-danger">
                                {{userSettingTranslation.newPasswordValidation}}
                              </div>

                              <div *ngIf="editForm.submitted && password.required && user.password && user.password !== user.confirmPassword"
                                   class="messages text-danger">
                                {{userSettingTranslation.passwordsValidationDifferent}}
                              </div>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              {{userSettingTranslation.confirmPasswordLabel}}
                            </label>

                            <div class="col-sm-9">
                              <app-vk-input
                                #confirmPassword
                                type="password"
                                name="confirmPassword"
                                maxlength="50"
                                appValidateEqual="password"
                                [required]="!!user.currentPassword || !!user.password"
                                [(ngModel)]="user.confirmPassword">
                              </app-vk-input>

                              <div *ngIf="editForm.submitted && confirmPassword.required && !user.confirmPassword"
                                   class="messages text-danger">
                                {{userSettingTranslation.confirmPasswordValidation}}
                              </div>

                              <div *ngIf="editForm.submitted && confirmPassword.required && user.confirmPassword && user.password !== user.confirmPassword"
                                   class="messages text-danger">
                                {{userSettingTranslation.passwordsValidationDifferent}}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12 col-xl-6">
                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label"></label>

                            <div class="col-sm-4 user-profile-photo">
                              <app-user-photo
                                [photo]="user.photo"
                                [urlPhotoPrefix]="user.avatarStatus !== avatarStatuses.Modified
                                ? userPhotoService.photoUrlPrefix
                                : null">
                              </app-user-photo>
                            </div>

                            <div class="col-sm-2 text-center my-auto mx-0">
                              <button
                                *ngIf="user.photo && canEdit"
                                class="btn btn-sm btn-danger btn-block-photo-item btn-block-photo-item-full mb-2"
                                type="button"
                                (click)="onPhotoRemoved(); filePicker.value = null">
                                <i class="fa fa-trash-o"></i>
                                {{userSettingTranslation.removePhotoBtn}}
                              </button>

                              <button
                                *ngIf="canEdit"
                                class="btn btn-sm btn-success btn-block-photo-item"
                                type="button"
                                (click)="filePicker.click()">
                                <i class="fa fa-upload"></i>

                                <span *ngIf="!user.photo">
                                  {{userSettingTranslation.addPhotoBtn}}
                                </span>

                                <span *ngIf="user.photo">
                                  {{userSettingTranslation.changePhotoBtn}}
                                </span>
                              </button>
                            </div>

                            <input
                              class="d-none"
                              type="file"
                              name="filePicker"
                              #filePicker
                              accept=".img,.png,.jpg,.jpeg"
                              (change)="handlePhotoFile($event.target.files)">

<!--                            <input type="hidden"-->
<!--                                   id="photo"-->
<!--                                   name="photo"-->
<!--                                   [(ngModel)]="user.photo"-->
<!--                                   #photo="ngModel">-->
                          </div>
                        </div>
                      </div>

                      <div class="text-right">
                        <button
                          *ngIf="canEdit"
                          type="submit"
                          class="btn btn-primary ripple light"
                          form="editForm"
                        >
                          {{globalTranslation.submitBtn}}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
