import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appValidateEqual][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EqualValidatorDirective),
      multi: true
    }
  ]
})
export class EqualValidatorDirective implements Validator {
  readonly selectorName = 'appValidateEqual';
  constructor(@Attribute('appValidateEqual') public appValidateEqual: string) {}

  public static getControlName(control: AbstractControl): string {
    for (const key in control.parent.controls) {
      if (control.parent.controls.hasOwnProperty(key) && control.parent.controls[key] === control) {
        return key;
      }
    }
  }

  validate(control: AbstractControl): { [key: string]: any } {
    const controlValue = control.value;
    const comparedControl = control.root.get(this.appValidateEqual);

    if (comparedControl !== null && typeof (comparedControl) !== 'undefined') {
      if (controlValue !== comparedControl.value) {
        const controlName = EqualValidatorDirective.getControlName(control);
        comparedControl.setErrors({ appValidateEqual: `${controlName} not equal` });

        return {
          appValidateEqual: `${this.appValidateEqual} not equal`
        };
      }
      if (controlValue === comparedControl.value) {
        if (comparedControl.errors) {
          delete comparedControl.errors.appValidateEqual;

          if (!Object.keys(comparedControl.errors).length) {
            comparedControl.setErrors(null);
          }
        }
      }
    }

    return null;
  }
}
