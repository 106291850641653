
import {filter} from 'rxjs/operators';
import {
  AfterContentInit, AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {DataViewMode, UserSettingsService} from '@services/user-settings.service';
import {TranslationService} from '@services/translation.service';
import {TreeDataService} from '@services/tree-data.service';
import {TabFilterService} from '@shared/filters/tab-filter.service';

import {Subscription} from 'rxjs';
import {ComponentsTranslation, GlobalTranslation, ProcessChartTranslation} from '@models/translation';
import {LocationNode} from '@models/location-node';
import {ViewFilterOptions} from '@models/view-filter-options';
import {FilterSelectorComponent} from '@shared/filters/filter-selector/filter-selector.component';
import {SwitcherComponent} from '@shared/switcher/switcher.component';

@Component({
  selector: 'app-models-view',
  templateUrl: './models-view.component.html',
  styleUrls: ['./models-view.component.scss']
})
export class ModelsViewComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {
  private readonly subscriptions = new Subscription();

  private firstLoad = true;

  public readonly dataViewModes = DataViewMode;

  public dataTree: LocationNode[];
  public dataViewMode = DataViewMode.TableView;
  public globalTranslation: GlobalTranslation;
  public componentsTranslation: ComponentsTranslation;
  public processChartTranslation: ProcessChartTranslation;

  @Input() readonly indexedDbKey: string;
  @Input() readonly operationType: string;
  @Input() readonly viewTitle: string;
  @Input() filterOptions: ViewFilterOptions = new ViewFilterOptions();
  @Input() treeDataService: TreeDataService;
  @Input() isLoading: boolean;
  @Input() showEmptyLocations = false;

  @Output() showEmptyLocationsChange = new EventEmitter<boolean>();
  @Output() filterOptionsChange = new EventEmitter<ViewFilterOptions>();

  @ContentChildren(FilterSelectorComponent) customFilterSelectorsContent: QueryList<FilterSelectorComponent>;
  @ContentChildren(SwitcherComponent) customSwitcherComponentsContent: QueryList<SwitcherComponent>;
  @ViewChildren(FilterSelectorComponent) customFilterSelectorsView: QueryList<FilterSelectorComponent>;
  @ViewChildren(SwitcherComponent) customSwitcherComponentsView: QueryList<SwitcherComponent>;

  constructor(
    private translationService: TranslationService,
    private userSettingsService: UserSettingsService,
    private tabFilterService: TabFilterService,
  ) {
    this.setTranslation();
  }

  public ngOnInit(): void {
    this.subscribeUserDateViewMode();
    this.subscribeDataLoaded();
    this.subscribeFilteredTree();
    this.subscribeNotFilteredTree();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngAfterContentInit(): void {
    this.tabFilterService.customFiltersInitialized.next(this.customFilterSelectorsContent.toArray());
    this.tabFilterService.customSwitchersInitialized.next(this.customSwitcherComponentsContent.toArray());
  }

  public ngAfterViewInit(): void {
    this.tabFilterService.customFiltersInitialized.next(this.customFilterSelectorsView.toArray());
    this.tabFilterService.customSwitchersInitialized.next(this.customSwitcherComponentsView.toArray());
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.globalTranslation = translation.global;
      this.componentsTranslation = translation.components;
      this.processChartTranslation = translation.processChart;
    });

    this.subscriptions.add(translationSubscription);
  }

  private subscribeUserDateViewMode(): void {
    const userDataViewModeSubscription = this.userSettingsService.dataViewMode$.subscribe((dataViewMode: DataViewMode) => {
      this.dataViewMode = dataViewMode;
      this.changeView(dataViewMode);
    });

    this.subscriptions.add(userDataViewModeSubscription);
  }

  private subscribeDataLoaded(): void {
    const dataLoadedSubscription = this.treeDataService.dataLoaded.pipe(
      filter(isDataLoaded => isDataLoaded))
      .subscribe(() => this.tabFilterService.getFiltersOptions());

    this.subscriptions.add(dataLoadedSubscription);
  }

  private subscribeFilteredTree(): void {
    const filteredTreeSubscription = this.treeDataService.filteredTree$.pipe(
      filter(data => !!data))
      .subscribe((data: LocationNode[]) => {
        if (this.firstLoad || !this.showEmptyLocations) {
          this.dataTree = data;
          this.firstLoad = false;
          this.isLoading = false;
        }
      });

    this.subscriptions.add(filteredTreeSubscription);
  }

  private subscribeNotFilteredTree(): void {
    const notFilteredTreeSubscription = this.treeDataService.notFilteredTree$.pipe(
      filter(data => !!data))
      .subscribe((data: LocationNode[]) => {
        if (this.showEmptyLocations) {
          this.dataTree = data;
          this.isLoading = false;
        }
      });

    this.subscriptions.add(notFilteredTreeSubscription);
  }

  public changeTreeData(showEmptyLocations: boolean): void {
    this.showEmptyLocationsChange.emit(showEmptyLocations);
    this.treeDataService.refreshTree();
  }

  public changeView(dataViewMode: DataViewMode): void {
    if (dataViewMode !== undefined) {
      this.dataViewMode = dataViewMode;
    }
  }

  public switchView(): void {
    this.dataViewMode = this.dataViewMode === DataViewMode.TableView
      ? DataViewMode.TreeView
      : DataViewMode.TableView;

    this.userSettingsService.setListType(this.dataViewMode);
  }
}
