import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appEmailValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailValidatorDirective),
      multi: true
    }
  ]
})
export class EmailValidatorDirective implements Validator {
  emailRegex = /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:[.][a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?[.])+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

  constructor() {}

  validate(c: AbstractControl): { [key: string]: any } {
    const val = c.value;
    if (val !== null) {
      if (!this.emailRegex.test(val)) {
        return {
          EmailAccount: false
        };
      }
    }

    return null;
  }
}
