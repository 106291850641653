import {Attribute, Directive, forwardRef, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {VkInputComponent} from '@shared/vk-input/vk-input.component';
import {nameof} from 'ts-simple-nameof';

@Directive({
  selector: '[appMinMaxValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MinMaxValidatorDirective),
      multi: true
    }
  ]
})
export class MinMaxValidatorDirective implements Validator {

  @Input() validatedControl: VkInputComponent;
  constructor() { }

  public validate(c: AbstractControl): ValidationErrors | null {
    if (!this.validatedControl) {
      return  null;
    }

    const minimumExceeded = this.validatedControl.min !== null && this.validatedControl.min > this.validatedControl.value;
    const maximumExceeded = this.validatedControl.max !== null && this.validatedControl.max < this.validatedControl.value;

    if (minimumExceeded) {
      return {[nameof<VkInputComponent>(obj => obj.min)]: `Minimum value is ${this.validatedControl.min}`};
    } else if (maximumExceeded) {
      return {[nameof<VkInputComponent>(obj => obj.max)]: `Maximum value is ${this.validatedControl.max}`};
    } else {
      return null;
    }
  }
}
