import { Injectable } from '@angular/core';
import {User} from '@models/user';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class UserService {
  private static readonly userKey = 'user';

  public static readonly user$ = new BehaviorSubject<User>(UserService.getLoggedUser());

  constructor() {
  }

  public static getLoggedUser(): User {
    try {
      const user = JSON.parse(localStorage.getItem(this.userKey));
      return new User(user);
    } catch {
      return null;
    }
  }

  public static saveLoggedUser(loggedUser: User): void {
    localStorage.setItem(this.userKey, JSON.stringify(loggedUser));
    this.user$.next(loggedUser);
  }

  public static hasUserPermission(user: User, permission: string): boolean {
    return user.apiPermissions.includes(permission);
  }
}
