import {Translation} from '@models/translation';

// tslint:disable:max-line-length
export class EnglishTranslation {
  public static readonly translation: Translation = {
    type: `english`,
    administration: {
      partsList: `Part list`,
      partAddBtn: `Add part`,
      partNo: `Part No`,
      partEnabled: `Enabled`,
      partName: `Part Name`,
      partNumber: `Part Number`,
      partDescription: `Description`,
      partUsed: `Part Used`,
      partModel: `Part Model`,
      partGrossCount: `Gross Count`,
      partPPC: `PPC`,
      partEditTooltip: `Edit part`,
      partDeleteTooltip: `Delete part`,
      partTitle: `Part`,
      reactionPlanReasonList: `Reaction plan reason list`,
      reactionPlanReasonAddBtn: `Add reaction plan reason`,
      reactionPlanReasonName: `Reaction Plan Reason Name`,
      reactionPlanReasonType: `Type`,
      reactionPlanReasonIsActive: `Active`,
      reactionPlanReasonEnabled: `Enabled`,
      reactionPlanEditTooltip: `Edit reaction plan`,
      reactionPlanDeleteTooltip: `Delete reaction plan`,
      reactionPlanIsUsedTooltip: `Reaction plan is used`,
      reactionPlanTitle: `Reaction plan`,
      reactionPlanNotificationDeleted: `Reaction plan has been deleted`,
      sampleReasonList: `Sample reason list`,
      sampleReasonAddBtn: `Add sample reason`,
      sampleReasonName: `Sample Reason Name`,
      sampleReasonType: `Type`,
      sampleReasonIsDefault: `Default`,
      sampleReasonEditTooltip: `Edit sample reason`,
      sampleReasonDeleteTooltip: `Delete sample reason`,
      sampleReasonIsUsedTooltip: `Sample reason is used`,
      sampleReasonTitle: `Sample reason`,
      sampleReasonNotificationDeleted: `Sample reason has been deleted`,
      addFixtureBtn: `Add Fixture`,
      addUnitBtn: `Add unit`,
      addUserBtn: `Add user`,
      addMappingBtn: `Add Mapping`,
      adminPermissionsAlert: `Admin permissions can not be changed`,
      descriptionBlankWarning: `Description can't be blank`,
      fixtureDeleteTooltip: `Delete fixture`,
      fixtureEditTooltip: `Edit fixture`,
      fixtureIsUsedTooltip: `Fixture is used`,
      fixtureList: `Fixture list`,
      fixtureName: `Fixture name`,
      fixtureNotificationDeleted: `Fixture has been deleted`,
      fixtureTitle: `Fixture`,
      fixtureValidationNameIsTaken: `An error occurred - probably name is already taken`,
      idleLogoutMessage: `User idle logout time (minutes)`,
      idleLogoutMessagePlantUser: `Plant user idle logout time (minutes)`,
      partModelAddModel: `Add Model`,
      partModelDeleteModelTooltip: `Delete model`,
      partModelEditModelTooltip: `Edit model`,
      partModelName: `Model name`,
      partModelUsed: `Model is used`,
      partModels: `Part models`,
      partModelParts: `Parts`,
      partMappingIsUsed: `Part Mapping is in use`,
      partMappingDelete: `Delete Part Mapping`,
      partMappingEdit: `Edit Part Mapping`,
      nameBlankWarning: `Name can't be blank`,
      permissionsMatrix: `Permissions matrix`,
      recycleBinCharacteristicDeleteTooltip: `Remove characteristic permanently`,
      recycleBinCharacteristicName: `Characteristic Name`,
      recycleBinCharacteristicUsed: `Characteristic is used`,
      recycleBinDataRecycled: `Date Recycled`,
      recycleBinLocationScreenDeleteTooltip: `Remove location screen permanently`,
      recycleBinLocationScreenName: `Location Screen Name`,
      recycleBinLocationScreenUsed: `Location Screen is used`,
      recycleBinNotificationCharacteristicDeleted: `Characteristic has been deleted`,
      recycleBinNotificationLocationScreenDeleted: `Location screen has been deleted`,
      recycleBinNotificationProcessChartDeleted: `Process chart has been deleted`,
      recycleBinProcessChartDeleteTooltip: `Remove process chart permanently`,
      recycleBinProcessChartName: `Process Chart Name`,
      recycleBinProcessChartUsed: `Process Chart is used`,
      recycleBinRecycledBy: `Recycled By`,
      recycleBinRestoreCharacteristicTooltip: `Restore characteristic`,
      recycleBinRestoreLocationScreenTooltip: `Restore location screen`,
      recycleBinRestoreProcessChartTooltip: `Restore process chart`,
      recycleBinTitle: `Recycle Bin`,
      recycleBinUserName: `Username`,
      recycleBinUserDeleteTooltip: `Remove user permanently`,
      recycleBinNotificationUserDeleted: `User has been deleted`,
      recycleBinRestoreUserTooltip: `Restore user`,
      recycleBinUserUsed: `User is used`,
      recycleBinTypeLabel: `Recycle bin type`,
      roleListDeleteMessage: `User's role account has been deleted`,
      roleListDeleteTooltip: `Delete role`,
      roleListEditTooltip: `Edit role`,
      roleListIsUsedTooltip: `Role is used`,
      roleListNameIsTakenMsg: `An error occurred - probably name is already used`,
      roleListTableActions: `Actions`,
      roleListTableDescription: `Description`,
      roleListTableName: `Name`,
      ruleSettingTableName: `Name`,
      ruleSettingTableValue: `Value`,
      ruleSettingModalHeader: `Rule Setting`,
      ruleSettingModalRuleLabel: `Select Rule`,
      ruleSettingAddSettingBtn: `Add Rule Setting`,
      ruleSettingListHeader: `Rule Settings List`,
      settings: `Settings`,
      ruleManagement: `Rule Management`,
      settingsCleanDataBtnLabel: `Automatically remove data over one year old`,
      showLabel:	`Show`,
      unitDeleteTooltip: `Delete unit`,
      unitEditTooltip: `Edit unit`,
      unitIsUsedTooltip: `Unit is used`,
      unitLists: `Units list`,
      name: `Name`,
      unitNotificationUnitDeleted: `Unit has been deleted`,
      unitShortName: `Short name`,
      unitTitle: `Unit`,
      unitValidationNameBlank: `Name can't be blank`,
      unitValidationNameIsTaken: `An error occurred - probably short name is already used`,
      unitValidationShortNameBlank: `Short name can't be blank`,
      userFileFormatWarning: `File format is incorrect, please make sure that You are using jpg/jpeg/img/png format`,
      userFileSizeWarning: `Image size is too big, max size is 3 MB, use smaller photo`,
      userListAddMasterDataBtn: `Add Masterdata User`,
      userListDeleteTooltip: `Delete user`,
      userListEditTooltip: `Edit user`,
      userListIsUsedTooltip: `User is used`,
      userListNotificationAccDeleted: `User account has been deleted`,
      userListNotificationCantDelAcc: `You can't remove your account`,
      userListNotificationUserNameIsUsed: `An error occurred - probably username is already used`,
      userListNotificationUserSaved: `User has been saved`,
      userListTitle: `User List`,
      userModalActiveLabel: `Active`,
      userModalAddPhotoBtn: `Add`,
      userModalAddLocationBtn: `Add Location`,
      userModalClearRoleBtn: `Clear`,
      userModalAddUserByPlantBtn: `Add user by plant`,
      userModalAddedRolesLabel: `Added roles to plants:`,
      userModalChangePhotoBtn: `Change`,
      userModalChosePlantTitle: `Select user assigment to Plant`,
      userModalConfirmPasswordLabel: `Confirm password`,
      userModalConfirmPasswordValidationBlank: `Confirm password can't be blank`,
      userModalConfirmPasswordValidationDifferent: `Passwords can't be different`,
      userModalEditFirstNameValidation: `First name can't be blank`,
      userModalEditNameValidation: `User name can't be blank`,
      userModalEditTitle: `User`,
      userModalEmailLabel: `Email`,
      userModalEmailValidation: `Write correct email address`,
      userModalFirstNameLabel: `First name`,
      userModalLastNameLabel: `Last name`,
      userModalLastNameValidation: `Last name can't be blank`,
      userModalLocationPathBtnChange: `Change location path`,
      userModalLocationPathBtnChoose: `Choose location path`,
      userModalLocationPathValidation: `Location path can't be blank`,
      userModalMasterdataUsn: 'Masterdata Usn',
      userModalNextBtn: `Next`,
      userModalPasswordLabel: `Password`,
      userModalPasswordValidationBlank: `Password can't be blank`,
      userModalPasswordValidationDifferent: `Passwords can't be different`,
      userModalPlantLabel: `Plant:`,
      userModalRemoveBtn: `Remove`,
      userModalRolesByPlantLabel: `Roles by plant`,
      userModalRolesByPlantLocationLabel: `Plant location`,
      userModalRolesLabel: `Roles`,
      userModalRolesValidation: `roles can't be blank`,
      userModalTreeTitle: `Select location for user`,
      userModalUserNameLabel: `Username`,
      userSpecialMarkDefaultWarning: `The query is invalid. Please check the correct use of the following special characters: ! # $ % & ' ( ) * + , - . / \\ : ; < = > ? @ [ ] ^ _ \` { | } ~`,
      userSpecialMarkEmailWarning: `The query is invalid. Please check the correct use of the following special characters: ! # $ % & ' ( ) * + , / \\ : ; < = > ? [ ] ^ \` { | } ~`,
      userSpecialMarkPasswordWarning: `The query is invalid. Please check the correct use of the following special characters:  = ? < > { }`,
      usersAddUserMasterdataModalTitle: `Select user`,
      usersListColumnActive: `Active`,
      usersListColumnEmail: `Email`,
      usersListColumnFirstName: `First name`,
      usersListColumnLastName: `Last name`,
      usersListColumnLastLogin: `Last Login`,
      usersListColumnUsername: `Username`,
      usersRolesAddUserBtn: `Add user's role`,
      usersRolesListHeader: `User's roles list`,
      usersRolesModalHeader: `User's role`,
      validationIdleLogoutMessageBlank: `User idle logout time can't be blank`,
      validationIdleLogoutMessageLogoutTime: `User idle logout time can't be lower then 1`,
      valueBlankWarning: `Value is required`
    },
    auth: {
      signInTitle: `Sign in`,
      errorInvalidGrant: `Please check that your username and password is correct`,
      errorInvalidRequest: `User account not found`,
      error: `Error`,
      passwordLabel: `Password`,
      signInBtn: `Sign in`,
      usernameLabel: `Username`
    },
    characteristics: {
      addStringToCharaNameAfterCopy: ` - copy`,
      attribute: `Attribute`,
      characteristicIsInPartExist: `Characteristic for this part already exists`,
      characteristicNameIsExist: `Characteristics with this name already exists`,
      characteristicType: `Characteristic type`,
      characteristicView: `Characteristic view`,
      chartDefinition: `Chart definition`,
      chartType: `Chart type`,
      chartTypeIMR: `I-MR (Location, Range)`,
      chartTypeWithout: `Without chart`,
      chartTypeXR: `XR (Location, Range)`,
      cp: `CP:`,
      cpk: `CPK:`,
      dataInput: `Data input`,
      dateStampLabel: `Date stamp`,
      description: `Description`,
      disabledCharacteristicWarning: `If you disable this characteristic, all the calculated characteristics that use it will also get disabled. Are you sure you want to continue?`,
      enabledCharacteristic: `Enabled characteristic`,
      enterUniqueName: `Enter Unique Characteristic Name:`,
      excludeReasons: `Exclude reasons`,
      fixtureAddTooltip: `Add fixture`,
      fixtureApplyToAll: `Apply fixture to all`,
      fixtureDeleteTooltip: `Delete fixture`,
      fixtureHeaderLabel: `Fixture`,
      fixtureHeaderNameLabel: `Name: `,
      fixtureHeaderNumberLabel: `Number: `,
      fixtureNoOptionsString: `No options to pick`,
      fixtureRemoveFromAll: `Remove fixture from all`,
      fixtureSelectTitle: `Fixtures to select`,
      formLabelMax: `Max`,
      formLabelMin: `Min`,
      frequency: `Frequency`,
      frequencySelectPlaceholder: `Select frequency`,
      characteristicSampleReasonValidation: `At least one of \`Start of Shift\`, \`Mid Shift\`, \`Once Per Day\`, \`Once Per Shift\`, Every hour\`, \`Every two hours\`, \`Every four hours\`, \`Every six hours\` is required`,
      gaugeType: `Gauge type`,
      general: `General`,
      graphFiltering: `Graph filtering`,
      lcl: `LCL`,
      ldc: `LDC`,
      locationChartTitle: `Location`,
      lsl: `LSL`,
      manualControlLabel: `Manual Control`,
      maxLimitSslOption: `Max limit`,
      maxLimitValidationFirstPart: `max limit can't be over`,
      maxLimitValidationSecondPart: `or under -`,
      minLimitSslOption: `Min limit`,
      minLimitValidationFirstPart: `min limit can't be over`,
      minLimitValidationSecondPart: `or under -`,
      minMaxValidation: `the value of min can't be greater than or equal to max`,
      modelPartNumbers: `Model Part Numbers`,
      multiPart: `Multi Part #`,
      name: `Name`,
      nominalMinus: `Nominal -`,
      nominalOnOff: `Nominal (On/Off)`,
      nominalPlus: `Nominal +`,
      nominalValue: `Nominal Value`,
      oneSidedChartBorderTypeLabel: `One-sided chart border type`,
      oneSidedChartBorderTypeFirstOption: `LimitConfig`,
      oneSidedChartBorderTypeFirstOptionTooltip: `Chart will be limited to specification limit and control limit by default.`,
      oneSidedChartBorderTypeSecondOption: `MinMax`,
      oneSidedChartBorderTypeSecondOptionTooltip: `Chart will be limited to specification limit and an outlier sample value by default.`,
      part: `Part #`,
      pickLocationBtn: `Pick location for new characteristic`,
      pp: `PP:`,
      ppk: `PPK:`,
      precision: `Precision`,
      promptLabel: `Prompt`,
      qclCode: `QCL code`,
      qclCodeFormFive: `CL5`,
      qclCodeFormFour: `CL4`,
      qclCodeFormNone: `None`,
      qclCodeFormOne: `CL1`,
      qclCodeFormThree: `CL3`,
      qclCodeFormTwo: `CL2`,
      rangeChartTitle: `Range`,
      requiredGaugeDescription: `Required Gauge # / Description`,
      rule: `Rule`,
      rules: `Rules`,
      samples: `Samples`,
      samplesQuantityLabel: `Samples quantity`,
      search: `Search`,
      selectRules: `Select rules`,
      serialNumberTableLabel: `Serial Number`,
      settingsAlerting: `Alerting`,
      settingsAlertingGroups: `Alerting Group(s)`,
      settingsPart: `Part #`,
      partNumbers: `Manual Part Numbers`,
      partNumbersSelectPlaceholder: `Select part number(s)`,
      settingsQualitySignOff: `Quality Sign-Off`,
      settingsSerial: `Serial #`,
      settingsSupervisorSignOff: `Supervisor Sign-Off`,
      settingsTitle: `Settings panel`,
      severityLabel: `Severity 9/10`,
      showOnChart: `Show on Chart`,
      specLimitFirstOption: `One sided`,
      specLimitLabel: `Spec limit type`,
      specLimitSecondOption: `Two sided`,
      ssl: `SSL`,
      sslLimitType: `Limit type`,
      tableCp: `Cp`,
      tableCpk: `Cpk`,
      tableDateCreated: `Date/Time`,
      tableDeviationTooltip: `Deviation value`,
      tableLsl: `LSL`,
      tableMean: `Mean`,
      tableMeanCenterLine: `Grand Mean`,
      tableNo: `No`,
      tableNumberOfSamples: `Samples`,
      tableOperatorInitials: `Operator initials`,
      tablePartNumber: `Part number`,
      tableRange: `Range`,
      tableRangeCenterLineMr: `mR-bar`,
      tableRangeCentralLine: `R-bar`,
      tableSampleValue: `Value`,
      tableShorthandDeviation: `D`,
      tableUsl: `USL`,
      tableValue: `Value`,
      targetCpk: `Target CPK`,
      trendLine: `Trend Line`,
      ucl: `UCL`,
      uclLclValidation: `UCL should be greater than LCL`,
      udc: `UDC`,
      unit: `Unit`,
      usl: `USL`,
      uslLslValidation: `USL should be greater than LSL`,
      variable: `Variable`
    },
    CMMTranslation: {
      runChartChartLslPlotLine: `LSL:`,
      runChartChartSerieName: `Run chart`,
      runChartChartTitle: `Run Chart`,
      runChartChartTooltipValue: `Value:`,
      runChartChartUslPlotLine: `USL:`,
      runChartChartXAxisName: `Timestamp`,
      runChartChartYAxisName: `Value`,
      runChartFiltersCharacteristicPlaceholder: `Characteristic`,
      runChartFiltersDateRangePlaceholder: `Date Range`,
      runChartFiltersDepartmentPlaceholder: `Department`,
      runChartFiltersDevicePlaceholder: `Device`,
      runChartFiltersMachinePlaceholder: `Machine`,
      runChartFiltersPartNumberPlaceholder: `Part Number`,
      runChartFiltersPlantPlaceholder: `Plant`,
      runChartFiltersMeasurementPlanPlaceholder: `Measurement Plan`,
      runChartFiltersPlotGraphBtn: `Plot Graph`,
      runChartFiltersResetBtn: `Reset filters`,
      runChartModalBackButton: `Back to details`,
      runChartModalDetailsMachine: `Machine:`,
      runChartModalDetailsMeasurement: `Measurement Type:`,
      runChartModalDetailsPN: `PN:`,
      runChartModalDetailsTimestamp: `Timestamp:`,
      runChartModalTableCharacteristic: `Characteristic`,
      runChartModalTableTitle: `Measurements`,
      runChartModalTableValue: `Value`,
      runChartModalTitle: `Reports Details`,
      runChartModalViewPdfBtn: `View Report PDF`,
      runChartReportListTitle: `Reports`,
      runChartTableColumnTimeStamp: `Timestamp`,
      runChartTableColumnValue: `Value`,
      runChartTableValueListTitle: `Values`,
      viewCharacteristicsCardTitle: `Characteristics`,
      viewCharacteristicsTableComments: `Comments`,
      viewCharacteristicsTableName: `Name`,
      viewCharacteristicsTableValue: `Value`,
      viewCmmCardCMM: `CMM-`,
      viewCmmCardLab: `Lab`,
      viewCmmCardPlanta: `plant:`,
      viewCmmCardReports: `Shift reports`,
      viewCmmGetDevicesTooltip: `Pick plant and time interval`,
      viewCmmPlantsChoiceLabel: `Plants to choice`,
      viewCmmTimeIntervalLabel: `Pick time interval`,
      viewGetDevicesBtn: `Get Devices`,
      viewPlantCardTitle: `Plants`,
      viewReportCardTitle: `Current Shift Reports`,
      viewReportCardViewLink: `View`
    },
    components: {
      addPartMappingModal_modalHeader: `Part Mapping`,
      addPartMappingSelectLocationBtn: `Select Location`,
      addPartMappingSelectLocationNameExists: `Location name already exists`,
      addPartMappingSelectLocationNameUnique: `Location name must be unique`,
      addPartMappingCmmPartsPlaceholder: `Select CMM Parts for the mapping`,
      addPartMappingSubmitBtn: `Add Mapping`,
      addSampleCollection_excludeLabel: `Exclude from the chart and calculations`,
      addSampleCollection_limitBrokenMsgMaxVal: `Maximum value:`,
      addSampleCollection_limitBrokenMsgMinVal: `Minimum value:`,
      addSampleCollection_limitBrokenMsgTitle: `Input value out of range`,
      addSampleCollection_limitBrokenMsgWantToContinue: `Are sure you want add value outside of those limits ?`,
      addSampleCollection_notesLabel: `Notes`,
      addSampleCollection_sampleEditedMsg: `Samples have been edited`,
      addSampleCollection_saveBtn: `Save changes`,
      addSampleCollection_subTitleSamples: `Samples`,
      addSampleCollection_title: `Instructions`,
      addSampleCollection_titleAddSamples: `Add samples`,
      addSampleCollection_titleEditSamples: `Edit samples`,
      addSampleCollection_validationValueOver: `Sample value can't be over than`,
      addSampleCollection_validationValueUnder: `or under -`,
      addSampleModal_addedSamplesMsg: `Samples have been added`,
      addSampleModal_addSampleBtn: `Add`,
      addSampleModal_addSamplesError: `You need to add samples or set 'Not running'`,
      addSampleModal_cardTitleSamples: `Samples`,
      addSampleModal_completeBtn: `COMPLETE`,
      addSampleModal_modalHeader: `ADD SAMPLE`,
      addSampleModal_notRunningLabel: `Not running`,
      addSampleModal_operatorInitialLabel: `Operator initials`,
      addSampleModal_partNumberLabel: `Part number`,
      addSampleModal_reasonLabel: `Reason`,
      addSampleModal_remarksLabel: `Remarks (optional)`,
      addSampleModal_serialNumberLabel: `Serial number`,
      chartOptions_trendLine: `Trend Line`,
      chartOptions_yAxisMin: `Y axis min`,
      chartOptions_yAxisMax: `Y axis max`,
      confirmUserPermission_emptyFieldsWarning: `Forms need to be filled`,
      confirmUserPermission_engineerPermissionWarn: `User doesn't have quality engineer permission`,
      confirmUserPermission_incorrectPasswordWarn: `Password is incorrect or user doesn't exist`,
      confirmUserPermission_passwordLabel: `Password`,
      confirmUserPermission_permissionConfirmedMsg: `User permission confirmed`,
      confirmUserPermission_qualitySupervisorPermissionWarn: `User doesn't have quality supervisor permission`,
      confirmUserPermission_usernameLabel: `Username`,
      filters_cardTitle: `Filters`,
      filters_clearBtn: `Clear`,
      filters_defaultOptionDept: `Any department`,
      filters_defaultOptionPlant: `Any plant`,
      filters_departmentLabel: `Department:`,
      filters_listViewBtn: `List view`,
      filters_plantLabel: `Plant:`,
      filters_cmmPartLabel: `CMM Part:`,
      filters_mdPartLabel: `MD Part:`,
      searchLabel: `Search`,
      filters_showFavoriteLabel: `Show favorites`,
      filters_treeViewBtn: `Tree view`,
      meanChart_cardTitle: `Option panel`,
      meanChart_chartTextDateXaxis: `Date`,
      meanChart_chartTextLocationYaxis: `Location`,
      meanChart_LCLLabel: `LCL`,
      meanChart_LSLLabel: `LSL`,
      meanChart_maxLimitOptions: `Limit type: Max limit`,
      meanChart_meanLabel: `Mean`,
      meanChart_minLimitOptions: `Limit type: Min limit`,
      meanChart_nominalRange: `Nominal Range`,
      meanChart_partNumberTooltip: `Part number:`,
      meanChart_plotLineTextCentralLineLabelGrandMean: `Grand Mean`,
      meanChart_plotLineTextCentralLineLabelMean: `Mean`,
      meanChart_plotLineTextLCLLabel: `LCL=`,
      meanChart_plotLineTextLSLLabel: `LSL=`,
      meanChart_plotLineTextRangeMinusLabel: `Range minus=`,
      meanChart_plotLineTextRangePlusLabel: `Range plus=`,
      meanChart_plotLineTextSSLLabel: `SSL=`,
      meanChart_plotLineTextUCLLabel: `UCL=`,
      meanChart_plotLineTextUSLLabel: `USL=`,
      meanChart_ProcessAverageTooltip: `Process average:`,
      meanChart_recalcGroupPlotLine: `Recalc on Group`,
      meanChart_refreshChartTooltip: `Refresh location chart`,
      meanChart_RulesTooltip: `Rules:`,
      meanChart_SSLLabel: `SSL`,
      meanChart_SubgroupAverage: `Subgroup average:`,
      meanChart_ticksAmount: `Ticks amount`,
      meanChart_maximizeChart: `maximize chart`,
      meanChart_UCLLabel: `UCL`,
      meanChart_USLLabel: `USL`,
      preControlChart_CentralLineLabel: `Central`,
      preControlChart_chartTextDateXaxis: `Date`,
      preControlChart_chartTextDateYaxis: `Location`,
      preControlChart_LCLLabel: `LCL`,
      preControlChart_LGZLabel: `LGZ`,
      preControlChart_Ticks: `Ticks`,
      preControlChart_Title: `Pre-Control`,
      preControlChart_UClLabel: `UCL`,
      preControlChart_UGZLabel: `UGZ`,
      processChart_cantEditReactionPlanWarning: `Reaction plan can be only edited at Location Screen`,
      processChart_dateColumnName: `Date`,
      processChart_frequencyShortVersionFourHr: `Every 4hr`,
      processChart_frequencyShortVersionOneHr: `Every 1hr`,
      processChart_frequencyShortVersionSixHr: `Every 6hr`,
      processChart_frequencyShortVersionTwoHr: `Every 2hr`,
      processChart_gageInstruction: `Please refer to gauge instructions`,
      processChart_headerInformation: `Items to be checked. Report any readings out of specification limits to the team.`,
      processChart_inspColumnName: `INSP BY`,
      processChart_machine: `MACHINE`,
      processChart_partLabel: `Part #`,
      processChart_processChartNameLabel: `PROCESS CHART NAME:`,
      processChart_reasonColumnName: `REASON FOR CHECK`,
      processChart_remarksColumnName: `REMARKS`,
      processChart_rowsQuantity: `Rows quantity`,
      processChart_selectPartNumberWarning: `Please select part number`,
      processChart_severityCell: `SEV 9/10`,
      processChart_supervisorInitialsColumnName: `SUPERVISOR'S INITIALS`,
      processChart_timeColumnName: `TIME`,
      rangeChart_cardTitle: `Option panel`,
      rangeChart_chartTextDateXaxis: `Date`,
      rangeChart_chartTitle: `Range`,
      rangeChart_LCLLabel: `LCL`,
      rangeChart_plotLineTextCentralLineLabelMrbar: `mR-bar`,
      rangeChart_plotLineTextCentralLineLabelRbar: `R-bar`,
      rangeChart_plotLineTextLCLLabel: `LCL=`,
      rangeChart_plotLineTextUCLLabel: `UCL=`,
      rangeChart_rangeTooltip: `Range:`,
      rangeChart_refreshChartTooltip: `Refresh range chart`,
      rangeChart_rulesTooltip: `Rules:`,
      rangeChart_ticksAmount: `Ticks amount`,
      rangeChart_UCLLabel: `UCL`,
      reactionPlan_actionTakenLabel: `Action Taken`,
      reactionPlan_calculationReset: `Calculation Reset`,
      reactionPlan_changeMachineParameterOption: `Changing machine parameters`,
      reactionPlan_characteristicDescription: `Characteristic Description:`,
      reactionPlan_characteristicName: `Characteristic Name:`,
      reactionPlan_cleanMachineOption: `Cleaning machine, device`,
      reactionPlan_completeBtn: `COMPLETE`,
      reactionPlan_correctionOfDeviceOption: `Correction  of measurement device`,
      reactionPlan_correctiveActionLabel: `Corrective Action`,
      reactionPlan_dateLabel: `Date:`,
      reactionPlan_dateTimeViolation: `Date / Time of Violation:`,
      reactionPlan_deviationOption: `Deviation`,
      reactionPlan_interventionOption: `Maintenance intervention`,
      reactionPlan_lslLabel: `LSL:`,
      reactionPlan_machineAdjustmentOption: `Machine adjustment`,
      reactionPlan_materialChangeOption: `Material change`,
      reactionPlan_materialDisposition: `Material disposition`,
      reactionPlan_modalTitle: `REACTION PLAN`,
      reactionPlan_operationInput: `OPERATIONS INPUT`,
      reactionPlan_operatorCommentLabel: `Operator comment:`,
      reactionPlan_operatorCommentValidationMessage: `Operator comment can't be blank`,
      reactionPlan_operatorInitialsLabel: `Operator initials:`,
      reactionPlan_operatorInitialsValidationMessage: `Operator initials can't be blank`,
      reactionPlan_processStopped: `Process stopped?`,
      reactionPlan_qeFormInitialsValidationMsg: `Quality Engineer initials can't be blank`,
      reactionPlan_qeFormLabel: `Quality Engineer:`,
      reactionPlan_qeFormTitle: `QUALITY SIGN-OFF`,
      reactionPlan_qeLoginComponentTitle: `QUALITY USER VERIFICATION`,
      reactionPlan_qualityInput: `Quality Input`,
      reactionPlan_qualitySignOff: `QUALITY SIGN-OFF`,
      reactionPlan_radioButtonCorrectedLabel: `Corrected`,
      reactionPlan_radioButtonDeviation: `Deviation`,
      reactionPlan_radioButtonHoldLabel: `Hold`,
      reactionPlan_radioButtonPassedLabel: `Passed to Stock`,
      reactionPlan_radioButtonReworkLabel: `Rework`,
      reactionPlan_radioButtonScrapLabel: `Scrap`,
      reactionPlan_radioButtonSortLabel: `Sort`,
      reactionPlan_reactionPlanSavedMsg: `Reaction Plan saved`,
      reactionPlan_remarkLabel: `Remark:`,
      reactionPlan_repairDeviceOption: `Repair or replacement of measurement device`,
      reactionPlan_rule: `Rules Violated`,
      reactionPlan_ruleBreakOverride: `Change color to black:`,
      reactionPlan_rulesViolated: `Rules Violated`,
      reactionPlan_severity: `SEVERITY 9/10`,
      reactionPlan_supervisorSignOff: `SUPERVISOR SIGN-OFF`,
      reactionPlan_svFormInitials: `Supervisor initials:`,
      reactionPlan_svFormInitialsValidationMsg: `Supervisor initials can't be blank`,
      reactionPlan_svFormTitle: `SUPERVISOR SIGN-OFF`,
      reactionPlan_svLoginComponentTitle: `SUPERVISOR USER VERIFICATION`,
      reactionPlan_toolAdjustmentOption: `Tool adjustment`,
      reactionPlan_toolChangeOption: `Tool change`,
      reactionPlan_uslLabel: `USL:`,
      reactionPlan_valueLabel: `Value:`,
      reactionPlan_verificationOfDeviceOption: `Verification of measurement device`,
      sampleReason_eventOption: `Event`,
      sampleReason_frequencyOption: `Frequency`,
      sampleReason_hourlyOption: `Hourly`,
      tableResult_cantDisabledCharacteristicMsg: `You can't add sample to disabled characteristic`,
      tableResult_characteristic: `Characteristic`,
      tableResult_itemNameControlCharts: `Control Charts`,
      tableResult_itemNameLocationScreen: `Location Screen`,
      tableResult_itemNameProcessChart: `Process Chart`,
      tableResult_locationScreen: `Location Screen`,
      tableResult_maxFavoriteValidationPartOne: `You have exceeded the maximum number`,
      tableResult_maxFavoriteValidationPartTwo: `of your favorite`,
      tableResult_moveToRecycleBinMsg: `This will go the Recycle Bin`,
      tableResult_multi: `Multi`,
      tableResult_noPermissionToAddSamplesMsg: `You don't have permission to add samples`,
      tableResult_processChart: `Process Chart`,
      tableResult_tableActions: `Actions`,
      tableResult_tableCreator: `Creator`,
      tableResult_tableLastModified: `Last Modified`,
      tableResult_tableName: `Name`,
      tableResult_tableNamePath: `Name / Path`,
      tableResult_tablePart: `Part`,
      tableResult_tablePath: `Path`,
      tableResult_tooltipAddData: `Add data`,
      tableResult_tooltipAddToFavorite: `Add to favorite`,
      tableResult_tooltipCopy: `Copy`,
      tableResult_tooltipEdit: `Edit`,
      tableResult_tooltipRemoveFavorite: `Remove from favorite`,
      tableResult_tooltipView: `View`,
      tableResult_tooltipCMMCharacteristic: `Can't add data to a CMM Characteristic`,
      tableResult_typeToValidationMaxFavoriteCharacteristics: `Characteristics`,
      tableResult_typeToValidationMaxFavoriteLocationScreen: `Location Screens`,
      tableResult_typeToValidationMaxFavoriteProcessChart: `Process Charts`,
      variableChart_trendLine: `Trend Line`
    },
    dashboard: {
      controlChardHideState: `control charts has been hidden`,
      itemsPerPageSelect: `Items per page`,
      switchLabelCompactView: `Compact view`
    },
    global: {
      addSampleBtn: `Add Sample`,
      afterChangeoverOptions: `After Changeover`,
      afterCorrectionOptions: `After Corrections`,
      afterMaintenanceInterventionOptions: `After Maintenance Intervention`,
      afterMealOptions: `After Meal`,
      afterModelChangeOptions: `After Model Change`,
      afterProcessChangeOptions: `After Process Change`,
      cancelBtn: `Cancel`,
      clearBtn: `clear`,
      closeBtn: `Close`,
      confirmButtonText: `Yes`,
      confirmDelete: `Yes, delete it!`,
      dataDeletedMsgTitle: `Deleted!`,
      dataEditedMsg: `Data have been edited`,
      dataLostMsg: `Are you sure? All data will be lost`,
      dataLostTextUndo: `You will not be able to undo this`,
      dataMismatch: `Some Part Numbers no longer exist in the Masterdata.`,
      dataRestored: `Data have been restored`,
      dataSaved: `Data have been saved`,
      editCharacteristicBtn: `Edit characteristic`,
      empty: `empty`,
      emptyAttribute: `Empty`,
      enterBtn: `Enter`,
      entries: `entries`,
      errorFetchMsg: `Data have not been fetched`,
      errorOccurred: `An error occurred`,
      every2HoursOptions: `Every two hours`,
      every4HoursOptions: `Every four hours`,
      every6HoursOptions: `Every six hours`,
      everyHourOptions: `Every hour`,
      excelExportBtn: `Excel export`,
      failAttribute: `Fail`,
      isRequired: `Value is required`,
      lclFullName: `Lower Control Limit`,
      ldcFullName: `Lower Deviation Constant`,
      lostChangesStatement: `Are you sure? All changes will be lost`,
      lslFullName: `Lower Specification Limit`,
      midOfShiftOptions: `Mid Shift`,
      modalTitleSelectLocation: `Select location`,
      nameLabel: `Name`,
      notRunningAttribute: `N/R`,
      oncePerDayOptions: `Once Per Day`,
      oncePerShiftOptions: `Once Per Shift`,
      passedAttribute: `Passed`,
      refreshBtn: `Refresh`,
      removeMsg: `You wont be able to revert`,
      removeMsgTitle: `Are you sure?`,
      reset: `Reset`,
      ruleEightDescription: `Four out of five points > one standard deviation from center line (same side)`,
      ruleElevenDescription: `Group out of the Nominal Range`,
      ruleFiveDescription: `Six points in a row increasing or decreasing`,
      ruleFourDescription: `Seven points in a row on the same side of the center line (up or down)`,
      ruleNineDescription: `Fifteen points in a row within one standard deviation of center line (either side)`,
      ruleOneDescription: `Group out of the Specification Limit`,
      ruleSevenDescription: `Two out of three points > two standard deviations from center line (same side)`,
      ruleSixDescription: `Fourteen points in a row, alternating up and down`,
      ruleTenDescription: `Eight points in a row > one standard deviation from center line (either side)`,
      ruleThreeDescription: `One point more than three standard deviations from center line`,
      ruleTwoDescription: `Group out of the Control Limit, but within the Specification Limit`,
      ruleTwelveDescription: `Seven points in a row increasing or decreasing`,
      ruleThirteenDescription: `Seven points in a row between the mean and one of the control limits (on one side)`,
      saveBtn: `Save`,
      searchBtn: `Search`,
      show: `Show`,
      showEmptyLocationLabel: `Show empty locations`,
      sslFullName: `Single Specification Limit`,
      startOfShiftOptions: `Start of shift`,
      submitBtn: `Submit`,
      toolChangeOptions: `Tool Change`,
      uclFullName: `Upper Control Limit`,
      udcFullName: `Upper Deviation Constant`,
      uslFullName: `Upper Specification Limit`,
      noPartNumber: `no part number available`
    },
    layout: {
      passwordChangeModalChangePasswordBtn: `Change password`,
      passwordChangeModalConfirmPasswordLabel: `Confirm password`,
      passwordChangeModalConfirmPasswordValidation: `Confirm password can't be blank`,
      passwordChangeModalDifferentPassValidation: `Passwords can't be different`,
      passwordChangeModalPasswordChanged: `password has been changed`,
      passwordChangeModalPasswordLabel: `Password`,
      passwordChangeModalPasswordValidation: `Password can't be blank`,
      passwordChangeModalTitle: `Password change`,
      userMenuOptionLogout: `Logout`,
      userMenuOptionSettings: `Settings`
    },
    locationScreen: {
      createEnableLabel: `Enabled Location Screen`,
      createEnterNameLabel: `Enter Unique Location Screen Name:`,
      createLackOfProcessCharts: `Lack of Process Charts`,
      createLocationScreenNameValidation: `Location Screen with this name already exists`,
      createNameLabel: `Name`,
      createNameValidationMessage: `Location Screen with this name already exists`,
      createNewLocationScreenBtn: `Pick location for new Location Screen`,
      createPickProcessChartLabel: `Pick Process Charts to Include on This Location Screen`,
      createProcessChartCheckboxLabel: `Process Chart`,
      createProcessChartListTitle: `Process Charts included on This Location Screen`,
      createSelectLocation: `Select location`,
      locationScreenView: `Location Screen view`
    },
    menu: {
      administration: `Administration`,
      administrationFixtures: `Fixtures`,
      administrationPartModels: `Part Models`,
      administrationParts: `Parts`,
      administrationPartMapping: `Part Mapping`,
      administrationPermissions: `Permissions`,
      administrationReactionPlanReasons: `Reaction Plan Reasons`,
      administrationRecycleBin: `Recycle Bin`,
      administrationRoles: `User's roles`,
      administrationRuleSettings: `Rule Settings`,
      administrationSampleReasons: `Sample Reasons`,
      administrationSettings: `Settings`,
      administrationUnits: `Units`,
      administrationUsers: `Users`,
      characteristic: `Characteristic`,
      characteristicCreate: `create`,
      characteristicView: `view`,
      cmmData: `CMM Data`,
      cmmDataRunChart: `run chart`,
      cmmDataView: `view`,
      dashboard: `Dashboard`,
      locationScreen: `Location screen`,
      locationScreenCreate: `create`,
      locationScreenView: `view`,
      processChart: `Process chart`,
      processChartCreate: `create`,
      processChartView: `view`,
      reports: `Reports`,
      user: `User`
    },
    processChart: {
      calculatedAttributeCharacteristicDisabled: `You can't add attribute Characteristic to Calculated Characteristic`,
      calculatedCreateCalculationString: `Calculation string:`,
      calculatedCreateConstantValue: `Constant Value:`,
      calculatedCreateCorrectCompletedEdit: `edit`,
      calculatedCreateCorrectCompletedMsgPart1: `Do you want to`,
      calculatedCreateCorrectCompletedMsgPart2: `Calculated Characteristic ?`,
      calculatedCreateCorrectCompletedMsgTitle: `Calculated string has been completed correctly`,
      calculatedCreateCorrectCompletedSave: `save`,
      calculatedCreateEnabledBtn: `Enabled Calculated Characteristic`,
      calculatedCreateEnterName: `Enter Unique Calculated Characteristic Name:`,
      calculatedCreateIncludedCardHeader: `Characteristics Included on This Process Chart`,
      calculatedCreateLocationPath: `Location Path:`,
      calculatedCreateMoreCharacteristicErrorMsg: `At least two characteristics (or characteristics and constant value) should be added`,
      calculatedCreateNameLabel: `Name`,
      calculatedCreateNameValidation: `This name is taken`,
      calculatedCreateResetBtn: `Reset`,
      calculatedCreateSelectOperation: `Select Operation`,
      calculatedCreateWithoutNameErrorMsg: `You must write the name of the calculated characteristic`,
      calculatedCreateWithoutOperatorErrorMsg: `An operator must be added`,
      createCalculatedCharacteristicsIsUsedWarning: `Calculated Characteristic contains characteristics already used in another Process Chart`,
      createCharacteristicIsUsedWarning: `Characteristic is already used in another Process Chart`,
      createCheckboxCharacteristicToSelect: `Characteristic`,
      createCheckboxCharacteristicToSelectPN: ` - PN `,
      createDataHaveBeen: `Data have been`,
      createEditedData: `edited`,
      createEnableProcessChartCheckboxLabel: `Enabled Process Chart`,
      createEnterUniqueName: `Enter Unique Process Chart Name:`,
      createIncludedCharacteristicsCartTitle: `Characteristics Included on This Process Chart`,
      createLackOfCharacteristics: `Lack of characteristics`,
      createMaxCharacteristicsWarning: `You have added the maximum number (20) of characteristics`,
      createNameLabel: `Name`,
      createNameValidation: `Process Chart with this name already exists`,
      createNewCalculatedCharacteristicsBtn: `New Calculated Characteristic`,
      createPickCalculatedCharacteristicsLabel: `Pick Calculated Characteristics to Include on This Process Chart`,
      createPickCharacteristicCardHeader: `Pick Characteristics to Include on This Process Chart`,
      createPickLocationBtn: `Pick location for new Process Chart`,
      createPickLocationModalTitle: `Select location`,
      createSavedData: `saved`,
      createTeamCenterLabel: `Teamcenter Doc # / Rev`,
      processChartView: `Process Chart view`,
      processChartButtonWarningTooltip: `You need to select at least one characteristic and complete the form`
    },
    userSetting: {
      addPhotoBtn: `Add`,
      cardHeader: `About Me`,
      changePhotoBtn: `Change`,
      confirmMsgBtn: `Yes, change the password!`,
      confirmPasswordLabel: `Confirm new password`,
      confirmPasswordValidation: `Confirm password can't be blank`,
      currentPasswordLabel: `Current password`,
      currentPasswordValidationBlank: `Current password can't be blank`,
      currentPasswordValidationIsIncorrect: `The password is incorrect`,
      firstNameLabel: `First name`,
      firstNameValidation: `First name can't be blank`,
      lastNameLabel: `Last name`,
      lastNameValidation: `Last name can't be blank`,
      newPasswordLabel: `New password`,
      newPasswordValidation: `Password can't be blank`,
      passwordIncorrectMsg: `The current password is incorrect`,
      passwordsValidationDifferent: `Passwords can't be different`,
      removePhotoBtn: `Remove`,
      userNameLabel: `Username`
    },
  };
}
