import {Injectable} from '@angular/core';
import {faKeyboard} from '@fortawesome/free-solid-svg-icons';
import {ElementRect} from '@shared/virtual-keyboard/virtual-keyboard.component';

@Injectable()
export class VirtualKeyboardService {
  private inputValue = '';
  private inputParameterOfObject: string;
  private objectReferenceToInput: any;

  public readonly iconKeyboard = faKeyboard;

  public keyboardPlace = '';
  public keyboardPosition: number[] = [];
  public keyboardRect: ElementRect;
  public numericKeyboard = false;
  public showKeyboard = false;
  public lastInputElement: HTMLElement | string;
  public valueToKeyboard = '';

  constructor() {
    window.addEventListener('scroll', _ => {
      this.setKeyboardPosition();
    });

    window.addEventListener('resize', _ => {
      this.setKeyboardPosition();
    });
  }

  private setInputValue(parameter?: string): void {
    if (parameter) {
      this.inputParameterOfObject = parameter;
    }

    this.inputValue = this.objectReferenceToInput[this.inputParameterOfObject]
      ? this.objectReferenceToInput[this.inputParameterOfObject]
      : '';
  }

  public setKeyboardPosition(): void {
    if (this.lastInputElement && this.showKeyboard) {
      const rect = (this.lastInputElement as HTMLInputElement).getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;
      this.keyboardPosition = [topPosition, leftPosition];
      this.keyboardRect = rect;
    }
  }

  public turnOnKeyboard(
    event, inputElement: HTMLElement | string, referenceToObject: any, parameterOfObject: string, isDisable = false,
    numericKeyboard: boolean = false, index: number = 0, place?: string): void {
    if (isDisable) {
      return;
    }

    if (inputElement === this.lastInputElement && this.showKeyboard) {
      this.showKeyboard = false;
      return;
    }

    this.numericKeyboard = numericKeyboard;
    this.keyboardPlace = place;
    this.objectReferenceToInput = referenceToObject;
    this.setInputValue(parameterOfObject);
    this.valueToKeyboard = this.inputValue;
    this.inputParameterOfObject = parameterOfObject;
    this.showKeyboard = false;

    setTimeout(() => {
      this.lastInputElement = inputElement;
      this.showKeyboard = true;
      this.setKeyboardPosition();
    }, 5);
  }

  public setInputValueFromKeyboard(inputValue: string): void {
    if (this.numericKeyboard) {
      const htmlInput = this.lastInputElement as HTMLInputElement;
      htmlInput.type = 'text';
      htmlInput.value = inputValue;
      this.objectReferenceToInput[this.inputParameterOfObject] = inputValue;
      if (inputValue.substr(inputValue.length - 1) !== '.') {
        htmlInput.type = 'number';
      }
    } else {
      this.objectReferenceToInput[this.inputParameterOfObject] = inputValue;
    }
  }

  public sendValueToKeyboard(value: string, inputElement: HTMLElement | string): void {
    if (this.lastInputElement === inputElement) {
      this.valueToKeyboard = value;
      this.setInputValue();
    }
  }

  public turnOffKeyboard(): void {
    this.showKeyboard = false;
  }
}
