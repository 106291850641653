<div *ngIf="lastBreadCrumbs.status"
     class="page-header"
     style="background: #bbb">
  <div class="page-block">
    <div class="row align-items-center">
      <div class="col-md-8">
        <div class="page-header-title">
          <span>
            <h4 class="m-b-10">
              {{ lastBreadCrumbs.label }}
            </h4>
          </span>
        </div>

        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/dashboard/']">
              <i class="feather icon-home"></i>
            </a>
          </li>

          <li  *ngFor="let breadcrumb of breadcrumbs"
               class="breadcrumb-item">
            <a [ngClass]="{'not-active': !breadcrumb.status}"
               [routerLink]="breadcrumb.url">
              {{breadcrumb.label}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
