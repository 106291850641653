import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();

  constructor(
    private router: Router,
  ) { }

  public ngOnInit(): void {
    const routerEventsSubscription = this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      window.scrollTo(0, 0);
    });

    this.subscriptions.add(routerEventsSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
