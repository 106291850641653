export enum SslLimitType {
  none = -1,
  min = 0,
  max = 1
}

export class LimitConfig {
  constructor(
    public centralLineLocationDraw: boolean = true,
    public centralLineLocationValue?: number,
    public centralLineRangeDraw: boolean = true,
    public centralLineRangeValue?: number,
    public characteristicId?: number,
    public id?: number,
    public lclLocationDraw: boolean = true,
    public lclRangeDraw: boolean = true,
    public lclRangeValue?: number,
    public ldcValue: number = 3, // parameter for recalc
    public lgzValue: number = 0,  // parameter for recalc
    public lslDraw: boolean = true,
    public lslValue: number = 0,
    public manualLCL: number = 0,
    public manualLimitConfig?: boolean,
    public manualUCL: number = 0,
    public maxLimit: number = null,
    public minLimit: number = null,
    public nominalLowerOffset: number = 0,
    public nominalUpperOffset: number = 0,
    public nominalValue: number = 0,  // to check for usage
    public sslLimit: SslLimitType = SslLimitType.none,
    public sslValue: number = null,
    public targetCpkDraw: boolean = true, // no usage for now
    public targetCpkValue: number = 0, // no usage for now
    public trendLineLocationDraw: boolean = true,
    public trendLineRangeDraw: boolean = true,
    public uclLocationDraw: boolean = true,
    public uclRangeDraw: boolean = true,
    public uclRangeValue?: number,
    public udcValue: number = 3, // parameter for recalc
    public ugzValue: number = 0, // parameter for recalc
    public uslDraw: boolean = true,
    public uslValue: number = 0
  ) {
  }
}
