<div
  id="pcoded"
  class="pcoded"
  [ngClass]="animateSidebar"
  [attr.nav-type]="navType"
  [attr.theme-layout]="themeLayout"

  [attr.vertical-nav-type]="verticalNavbarType"
  [attr.vertical-effect]="verticalEffect"
  [attr.vertical-placement]="verticalPlacement"
  [attr.vertical-layout]="verticalLayout"

  (window:resize)="onResize($event)">
  <div class="pcoded-overlay-box">
  </div>

  <div class="pcoded-container navbar-wrapper">
    <div class="top-page-menu">
      <nav class="navbar header-navbar pcoded-header"
           style="background: #fff;"
>
        <div class="navbar-wrapper">
          <div class="navbar-logo">
            <a [routerLink]="['/dashboard/']"
               class="ripple light">
              <img class="img-fluid"
                   src="assets/images/nexteer_logo_small.png"
                   alt="SPC" />
            </a>

            <a href="javascript:"
               class="mobile-options ripple light"
               (click)="toggleHeaderNavRight()">
              <i class="feather icon-more-horizontal"></i>
            </a>
          </div>

          <div class="navbar-container container-fluid">
            <ul class="nav-left">
              <li class="ripple light">
                <div class="sidebar_toggle">
                  <a href="javascript:">
                    <i class="icon-menu"
                       style="color: #bbb">
                    </i>
                  </a>
                </div>
              </li>

              <li>
                <a class="mobile-menu ripple light"
                   id="mobile-collapse"
                   href="javascript:"
                   [exclude]="'#main_navbar'"
                   (click)="toggleTopNavBarOptions()"
                   (clickOutside)="onSideNavBarClickedOutside()">
                  <i class="feather"
                     [ngClass]="navigationNavbarToggleIcon">
                  </i>
                </a>
              </li>

              <li class="ripple light"
                  appToggleFullScreen>
                <a href="javascript:">
                  <i class="feather icon-maximize full-screen"
                     style="color: #bbb">
                  </i>
                </a>
              </li>
            </ul>

            <ul [@expandToggle]="topNavbarExpandState"
                class="nav-right">
              <li>
                <app-switch-language>
                </app-switch-language>
              </li>

              <li class="user-profile header-notification"
                  (clickOutside)="onTopNavbarOutsideClick()">
                <ng-container
                  [ngTemplateOutlet]="userExpandableLabel"
                  [ngTemplateOutletContext]="{
                    clickAction: topNavBarUserOptionExpandToggle,
                    expandIcon: topNavBarUserOptionsExpandIcon
                  }"
                >
                </ng-container>

                <div [@expandToggle]="topBarUserOptionsExpandState"
                     class="profile-notification option-details pl-3"
                     [ngClass]="topBarUserOptionsExpandClass">
                  <ng-container
                    [ngTemplateOutlet]="userOption">
                  </ng-container>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>

    <div class="pcoded-main-container">
      <div class="pcoded-wrapper">
        <nav
          id="main_navbar"
          class="pcoded-navbar"
          [attr.navbar-theme]="navBarTheme"
          [attr.active-item-theme]="activeItemTheme"
          [attr.pcoded-header-position]="pcodedHeaderPosition"
          [attr.pcoded-navbar-position]="pcodedSidebarPosition"
          [style.top]="headerFixedTop"
          (clickOutside)="onSideNavBarClickedOutside()"
          (mouseleave)="hoverOutsideSidebar()">
          <ng-scrollbar
            [style.max-width]="'235px'"
            [style.max-height]="sidebarFixedHeight"
            [ngClass]="{'disabled': !isNavigationNavbarVisible && !!isMobileView}">
            <div class="main-menu pcoded-inner-navbar"
                 appAccordion>
              <div>
                <div class="main-menu-header menu-user">
                  <ng-container
                    [ngTemplateOutlet]="userExpandableLabel"
                    [ngTemplateOutletContext]="{
                      clickAction: sideNavbarUserOptionToggle,
                      expandIcon: sideNavBarUserOptionsExpandIcon
                    }"
                  >
                  </ng-container>
                </div>

                <div [@expandToggle]="sideNavbarUserOptionsExpandState"
                     class="option-details">
                  <ng-container
                    [ngTemplateOutlet]="userOption">
                  </ng-container>
                </div>
              </div>

              <div *ngFor="let asideItems of menuItemsData"
                   class="d-color side-short-menu">
                <div class="pcoded-navigation-label">
                  {{asideItems.label}}
                </div>

                <ul
                  *ngFor="let asideItem of asideItems.main"
                  class="pcoded-item pcoded-left-item"
                  appAccordionLink
                  group="{{asideItem.state}}">
                  <li *ngIf="asideItem.type === 'external'"
                      [routerLinkActive]="['active']"
                      appAccordionLink
                      group="{{asideItem.state}}">
                    <a [routerLinkActive]="['active']"
                       class="ripple {{ asideItem.disabled }}"
                       target="{{asideItem.target ? '_blank' : '_self'}}"
                       appAccordionToggle>
                      <span class="pcoded-micon">
                        <fa-icon *ngIf="asideItem.icon === 'ruler'; else icons"
                                 [icon]="iconRuler">
                        </fa-icon>

                        <ng-template #icons>
                          <i class="{{ asideItem.icon }}"></i>
                        </ng-template>

                        <b>{{ asideItem.short_label }}</b>
                      </span>

                      <span class="pcoded-mtext">
                        {{ asideItem.name }}
                      </span>

                      <span *ngFor="let asideBadge of asideItem.badge"
                            class="pcoded-badge label label-{{ asideBadge.type }}">
                        {{asideBadge.value}}
                      </span>

                      <span class="pcoded-mcaret">
                      </span>
                    </a>
                  </li>

                  <li *ngIf="asideItem.type === 'link'"
                      [routerLinkActive]="['active']"
                      appAccordionLink
                      [group]="asideItem.state">
                    <a *ngIf="asideItem.main_state; else mainContent"
                       appAccordionToggle
                       class="ripple {{ asideItem.disabled }}"
                       [routerLinkActive]="['active']"
                       [routerLink]="['/', asideItem.main_state, asideItem.state]"
                       [target]="asideItem.target ? '_blank' : '_self'"
                    >
                      <span class="pcoded-micon">
                        <fa-icon *ngIf="asideItem.icon === 'ruler'; else icons"
                                 [icon]="iconRuler">
                        </fa-icon>

                        <ng-template #icons>
                          <i class="{{ asideItem.icon }}"></i>
                        </ng-template>

                        <b>{{ asideItem.short_label }}</b>
                      </span>

                      <span class="pcoded-mtext">
                        {{ asideItem.name }}
                      </span>

                      <span *ngFor="let asideBadge of asideItem.badge"
                            class="pcoded-badge label label-{{ asideBadge.type }}">
                        {{asideBadge.value}}
                      </span>

                      <span class="pcoded-mcaret">
                      </span>
                    </a>

                    <ng-template #mainContent>
                      <a *ngIf="menuSubItemHasPermission(asideItem.permissions)"
                         appAccordionToggle
                         class="ripple {{ asideItem.disabled }}"
                         [routerLinkActive]="['active']"
                         [routerLink]="['/', asideItem.state]"
                         [target]="asideItem.target ? '_blank' : '_self'"
                      >
                        <span class="pcoded-micon">
                          <i class="{{ asideItem.icon }}"></i>
                          <b>{{ asideItem.short_label }}</b>
                        </span>

                        <span *ngIf="isNavigationNavbarVisible"
                              class="pcoded-mtext">
                          {{ asideItem.name }}
                        </span>

                        <span *ngFor="let asideBadge of asideItem.badge"
                              class="pcoded-badge label label-{{ asideBadge.type }}">
                          {{asideBadge.value}}
                        </span>

                        <span class="pcoded-mcaret">
                        </span>
                      </a>
                    </ng-template>
                  </li>

                  <li
                    *ngIf="asideItem.type === 'sub' && menuSubItemsHasVisibleByPermissions(asideItem.children)"
                    appAccordionLink
                    class="pcoded-hasmenu"
                    [routerLinkActive]="['active']"
                    [attr.dropdown-icon]="dropDownIcon"
                    [attr.subitem-icon]="subItemIcon"
                    [group]="asideItem.state"
                    (mouseleave)="fireClickLeave($event)">
                    <a appAccordionToggle
                       href="javascript:"
                       class="{{ asideItem.disabled }}"
                       [routerLinkActive]="['active']"
                       (mouseover)="fireClick($event)"
                    >
                      <div class="ripple menu-ripple">
                      </div>

                      <span class="pcoded-micon">
<!--                        main items-->
                        <fa-icon *ngIf="asideItem.icon === 'ruler'; else icons"
                                 [icon]="iconRuler" >
                        </fa-icon>

                        <ng-template #icons>
                          <i class="{{ asideItem.icon }}"></i>
                        </ng-template>

                        <b>{{ asideItem.short_label }}</b>
                      </span>

                      <span *ngIf="isNavigationNavbarVisible"
                            class="pcoded-mtext">
                        {{ asideItem.name }}
                      </span>

                      <span *ngFor="let asideBadge of asideItem.badge"
                            class="pcoded-badge label label-{{ asideBadge.type }}">
                        {{asideBadge.value}}
                      </span>

                      <span class="pcoded-mcaret">
                      </span>
                    </a>

                    <ul class="pcoded-submenu">
                      <ng-template ngFor let-asideChildren
                                   [ngForOf]="asideItem.children">
                        <li *ngIf="asideChildren.type !== 'sub' && menuSubItemHasPermission(asideChildren.permissions)"
                            [routerLinkActive]="['active']" >
                          <a [routerLinkActive]="['active']"
                             class="{{ asideChildren.disabled }}"
                             [routerLink]="['/', asideItem.state, asideChildren.state]"
                             target="{{asideChildren.target ? '_blank' : '_self'}}">
                            <div class="ripple menu-ripple">
                            </div>

                            <span class="pcoded-micon">
                              <i class="icon-angle-right"></i>
                            </span>

                            <span class="pcoded-mtext">
                              {{ asideChildren.name }}
                            </span>

                            <span *ngFor="let asideChildrenBadge of asideChildren.badge"
                                  class="pcoded-badge label label-{{ asideChildrenBadge.type }}">
                              {{asideChildrenBadge.value}}
                            </span>

                            <span class="pcoded-mcaret">
                            </span>
                          </a>
                        </li>

                        <li
                          *ngIf="asideChildren.type === 'sub'"
                          class="pcoded-hasmenu"
                          [routerLinkActive]="['active']"
                          [attr.dropdown-icon]="dropDownIcon"
                          [attr.subitem-icon]="subItemIcon"
                          appAccordionLink
                          (mouseleave)="fireClickLeave($event)"
                          group="sub-toggled">
                          <a (mouseover)="fireClick($event)"
                             class="{{ asideChildren.disabled }}"
                             [routerLinkActive]="['active']"
                             href="javascript:"
                             appAccordionToggle>
                            <div class="ripple menu-ripple">
                            </div>

                            <span class="pcoded-micon">
                              <i class="icon-direction-alt"></i>
                            </span>

                            <span class="pcoded-mtext">
                              {{ asideChildren.name }}
                            </span>

                            <span *ngFor="let asideChildrenBadge of asideChildren.badge"
                                  class="pcoded-badge label label-{{ asideChildrenBadge.type }}">
                              {{asideChildrenBadge.value}}
                            </span>

                            <span class="pcoded-mcaret">
                            </span>
                          </a>

                          <ul class="pcoded-submenu">
                            <ng-template ngFor let-asideChildrenSub
                                         [ngForOf]="asideChildren.children">
                              <li [routerLinkActive]="['active']">
                                <a [routerLinkActive]="['active']"
                                   class="{{ asideItem.disabled }}"
                                   [routerLink]="['/', asideItem.state, asideChildren.state, asideChildrenSub.state]"
                                   target="{{asideChildrenSub.target ? '_blank' : '_self'}}">
                                  <div class="ripple menu-ripple">
                                  </div>

                                  <span class="pcoded-micon">
                                    <i class="icon-angle-right"></i>
                                  </span>

                                  <span class="pcoded-mtext">
                                    {{ asideChildrenSub.name }}
                                  </span>

                                  <span *ngFor="let asideChildrenSubBadge of asideChildrenSub.badge"
                                        class="pcoded-badge label label-{{ asideChildrenSubBadge.type }}">
                                    {{asideChildrenSubBadge.value}}
                                  </span>

                                  <span class="pcoded-mcaret">
                                  </span>
                                </a>
                              </li>
                            </ng-template>
                          </ul>
                        </li>
                      </ng-template>
                    </ul>
                  </li>
                </ul>

                <hr class="side-menu-hr">

                <div class="expandable-section">
                  <a class="cursor-pointer"
                     (click)="toggleAppInfo()">
                    <b>App Info</b>
                  </a>

                  <i class="feather {{ appInfoExpandIcon }}">
                  </i>
                </div>

                <div class="app-info"
                     [@expandToggle]="appInfoExpandState">
                  <span>
                    <b>Version</b>: {{applicationInfo.version}}
                  </span>

                  <span>
                    <b>Localization</b>: {{applicationInfo.localization}}
                  </span>
                </div>
              </div>
            </div>
          </ng-scrollbar>
        </nav>

        <div class="pcoded-content">
          <app-breadcrumbs>
          </app-breadcrumbs>

          <div class="pcoded-inner-content">
            <!-- Main-body start -->
            <div class="main-body">
              <div class="page-wrapper">
                <router-outlet>
                  <title>
                  </title>

                  <app-spinner>
                  </app-spinner>
                </router-outlet>

                <notifier-container></notifier-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-first-user-sign-in>
</app-first-user-sign-in>

<ng-template #userOption>
  <a [routerLink]="['/user/profile']">
    <i class="feather icon-user"></i>

    <span>
      {{layoutTranslation.userMenuOptionSettings}}
    </span>
  </a>

  <a href="javascript:"
     (click)="logout()">
    <i class="feather icon-log-out"></i>

    <span>
      {{layoutTranslation.userMenuOptionLogout}}
    </span>
  </a>
</ng-template>

<ng-template
  #userExpandableLabel
  let-clickAction="clickAction"
  let-expandIcon="expandIcon">
  <app-user-photo
    [photo]="user.photo"
    [urlPhotoPrefix]="userPhotoService.photoUrlPrefix">
  </app-user-photo>

  <span class="logged-user-label"
        (click)="clickAction()">
    {{user.fullName}}

    <i class="feather {{expandIcon}}"></i>
  </span>
</ng-template>
