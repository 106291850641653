import {Component, OnChanges, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {CharacteristicSamplesType} from '@models/characteristic';
import {CharacteristicSampleCollection} from '@models/characteristic-sample-collection';
import {TranslationService} from '@services/translation.service';
import {BaseChartComponent, ChartData, LimitType, SeriesType} from '@shared/charts/base-chart/base-chart.component';
import {ChartService} from '@shared/charts/chart.service';
import {SamplePoint} from '@shared/charts/sample-point';
import * as Highcharts from 'highcharts';
import {SeriesLineOptions} from 'highcharts';
import HC_exporting from 'highcharts-regression';

HC_exporting(Highcharts);

interface SeriesLinearRegressionOptions extends SeriesLineOptions {
  regression: boolean;
  regressionSettings: {
    name?: string;
    tooltip?: {};
    dashStyle?: string;
    decimalPlaces?: number;
    useAllSeries?: boolean;
    regressionSeriesOptions?: {};
    type?: string;
    color?: string;
  };
}

@Component({
  selector: 'app-range-chart',
  templateUrl: '../base-chart/./base-chart.component.html',
  styleUrls: ['../base-chart/./base-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ChartService]
})
export class RangeChartComponent extends BaseChartComponent implements OnInit, OnDestroy, OnChanges {
  chartOptionsLocalStorageKey = 'rangeChartOptionsLocalStorageKey';
  centralLineLabel = '';

  constructor(
    translationService: TranslationService,
    private chartService: ChartService
  ) {
    super(translationService);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.seriesType = SeriesType.Line;
    this.chartService.decimals = `1.${this.characteristic.decimals}-${this.characteristic.decimals}`;
    this.sampleTooltipFormatter = (point: SamplePoint) => this.chartService.createRangeChartTooltip(point);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    localStorage.removeItem(this.chartOptionsLocalStorageKey);
  }

  public ngOnChanges(changes): void {
    if (changes.isOptionsOpened) {
      this.resizeChart();
    }
    if (changes.recalcValues && this.recalcValues) {
      this.sampleCollections = this.recalcValues.sampleCollections || [];
      this.init();
    }
  }

  protected setLabels(): void {
    this.sampleName = this.componentsTranslation.rangeChart_rangeTooltip;
    this.chartCardTitle = this.componentsTranslation.rangeChart_cardTitle;
    this.xAxisTitle = this.componentsTranslation.rangeChart_chartTitle;
    this.yAxisTitle = this.componentsTranslation.rangeChart_chartTitle;
  }

  protected init(): void {
    this.setLabels();
    if (this.recalcValues && this.chartContainer) {
      this.generateOptions();
      this.generateLimitSwitchOptions();
      this.generateChart();
    }
  }

  private generateOptions(): void {
    const twoSidedSpecLimitColor = '#ff0000';
    const centralLine = '#55b847';
    this.centralLineLabel = this.characteristic.sampleType === CharacteristicSamplesType.SingleSample
      ? this.componentsTranslation.rangeChart_plotLineTextCentralLineLabelRbar
      : this.componentsTranslation.rangeChart_plotLineTextCentralLineLabelMrbar;

    this.plotLineMap.clear();

    let plotLine = this.chartService.createPlotLine(
      twoSidedSpecLimitColor,
      LimitType.UCL,
      this.recalcValues.ucLr,
      this.componentsTranslation.rangeChart_UCLLabel);

    this.plotLineMap.set(LimitType.UCL, [plotLine]);

    plotLine = this.chartService.createPlotLine(
      centralLine,
      LimitType.CentralLine,
      this.recalcValues.rBar,
      this.centralLineLabel);

    this.plotLineMap.set(LimitType.CentralLine, [plotLine]);

    plotLine = this.chartService.createPlotLine(
      twoSidedSpecLimitColor,
      LimitType.LCL,
      this.recalcValues.lcLr,
      this.componentsTranslation.rangeChart_LCLLabel);

    this.plotLineMap.set(LimitType.LCL, [plotLine]);
  }

  private generateLimitSwitchOptions(): void {
    let showOptions = [
      {
        limitType: LimitType.UCL,
        available: true,
        showLimit: this.characteristic.limitConfig.uclRangeDraw,
        limitValue: 0,
        label: this.componentsTranslation.meanChart_UCLLabel
      },
      {
        limitType: LimitType.CentralLine,
        available: true,
        showLimit: this.characteristic.limitConfig.centralLineRangeDraw,
        limitValue: 0,
        label: this.centralLineLabel
      },
      {
        limitType: LimitType.LCL,
        available: true,
        showLimit: this.characteristic.limitConfig.lclRangeDraw,
        limitValue: 0,
        label: this.componentsTranslation.meanChart_LCLLabel
      },
      {
        limitType: LimitType.TrendLine,
        available: true,
        showLimit: this.characteristic.limitConfig.trendLineRangeDraw,
        limitValue: 0,
        label: this.componentsTranslation.variableChart_trendLine
      }
    ];

    showOptions
      .filter(option => option.available && option.limitType !== LimitType.TrendLine)
      .forEach(option => option.limitValue = this.plotLineMap.get(option.limitType)[0].value);

    const options = JSON.parse(localStorage.getItem(this.chartOptionsLocalStorageKey));

    if (options) {
      showOptions = options;
    }

    this.chartOptions.showOptions = showOptions;
    this.chartOptions.yAxisMinValue = this.recalcValues.lcLr;
    this.chartOptions.yAxisMaxValue = this.recalcValues.ucLr;
  }

  public prepareCharts(): void {
    this.characteristic.sampleType === CharacteristicSamplesType.MultiSamples
      ? this.createSTDVChart()
      : this.prepareRangeChart();

  }

  private createSTDVChart(): void {
    const values: number[] = [
      this.recalcValues.ucLr,
      this.recalcValues.rBar,
      this.recalcValues.lcLr,
      this.recalcValues.lsl,
      this.recalcValues.usl
    ];
  }

  private prepareRangeChart(): void {
    const  values: number[] = [
      this.recalcValues.ucLr,
      this.recalcValues.rBar,
      this.recalcValues.lcLr
    ];
  }

  protected generateSamples(): ChartData {
    const dates: string[] = [];
    const data: SamplePoint[] = [];

    this.sampleCollections.forEach((element: CharacteristicSampleCollection, index: number) => {
      const itemDate = new Date(element.dateCreated);
      const formattedDate = this.chartService.transformDate(itemDate);
      const sampleData: SamplePoint = {
        x: index,
        y: element.range,
        color: this.getRangeSampleColor(element),
        rules: [],
        dateCreated: formattedDate
      };

      dates.push(formattedDate);
      data.push(sampleData);
    });

    return { points: data, labels: dates };
  }

  protected generateSeries(): Array<SeriesLinearRegressionOptions> {
    if (this.sampleCollections.length < 1) {
      return [];
    }

    const chartData = this.generateSamples();
    this.xAxisLabelFormatter = point => chartData.labels[point];

    return [
      {
        type: 'line',
        name: this.sampleName,
        regression: this.chartOptions.showOptions.find(option => option.limitType === LimitType.TrendLine).showLimit,
        regressionSettings: {
          name : 'Trend Line',
          type: 'linear',
          color: 'rgba(223, 183, 83, .9)',
          dashStyle: 'solid'
        },
        lineWidth: 2,
        color: this.seriesColor,
        data: chartData.points,
        showInLegend: !!chartData.points.length,
        marker: {
          enabled: true
        }
      }];
  }

  private getRangeSampleColor(elements: CharacteristicSampleCollection): string {
    let color = '#000000';
    const uclValue = this.recalcValues.ucLr;
    const lclValue = this.recalcValues.lcLr;

    if (elements.range > uclValue || elements.range < lclValue) {
      color = '#ff5252';
    }

    return color;
  }
}
