import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {TranslationService} from '@services/translation.service';
import {DashboardTranslation, GlobalTranslation} from '@models/translation';
import {Subscription} from 'rxjs';
import {LabeledValue} from '@common/global';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnDestroy, OnChanges {
  private readonly subscriptions = new Subscription();

  public readonly defaultPageOptions: LabeledValue<number>[] = [
    new LabeledValue(undefined, 'All'),
    new LabeledValue(10),
    new LabeledValue(25),
    new LabeledValue(50),
    new LabeledValue(100),
  ];

  public dashboardTranslation: DashboardTranslation;
  public globalTranslation: GlobalTranslation;

  @Input() pageOptions: LabeledValue<number>[] = this.defaultPageOptions;
  @Input() itemsCount: number;
  @Input() itemsPerPage = this.pageOptions[1].value;

  @Output() itemsPerPageChange = new EventEmitter<number>();

  constructor(translationService: TranslationService) {
    const translationSubscription = translationService.translations$.subscribe(translation => {
      this.dashboardTranslation = translation.dashboard;
      this.globalTranslation = translation.global;
    });

    this.subscriptions.add(translationSubscription);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemsCount) {
      this.pageOptions.find(option => option.label === 'All').value = this.itemsCount;
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onItemsPerPageChange(itemsPerPage: number): void {
    this.itemsPerPage = itemsPerPage;
    this.itemsPerPageChange.emit(this.itemsPerPage);
  }
}
