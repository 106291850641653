<div class="row-table row-table-header"
     (resized)="onResized()">

  <ng-container *ngIf="paginationEnabled else noPagination">
    <app-pagination
      #pagination
      [itemsCount]="itemsCount"
      [itemsPerPage]="pagination.itemsPerPage"
      (itemsPerPageChange)="table.offset = 0; onPaginationChange({pageNumber: 0, itemsPerPage: pagination.itemsPerPage})"
    >
    </app-pagination>

    <ngx-datatable
      class='data-table table-responsive'
      [count]="itemsCount"
      [externalPaging]="paginationEnabled"
      [externalSorting]="paginationEnabled"
      [columns]="columns"
      [columnMode]="columnMode"
      [scrollbarH]="true"
      [headerHeight]="headerHeight"
      [footerHeight]="50"
      [rowHeight]="55"
      [limit]="pagination.itemsPerPage"
      [rows]="rowsFilter"
      [sorts]="sorts"
      (page)="onPaginationChange({pageNumber: table.offset, itemsPerPage: pagination.itemsPerPage})"
      (sort)="onSortingChange($event)">
    </ngx-datatable>
  </ng-container>

  <ng-template #noPagination>
    <app-pagination
      #pagination
      [itemsCount]="itemsCount"
      [itemsPerPage]="pagination.itemsPerPage"
    >
    </app-pagination>

    <ngx-datatable
      class='data-table table-responsive'
      [columns]="columns"
      [columnMode]="columnMode"
      [scrollbarH]="true"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="55"
      [limit]="pagination.itemsPerPage"
      [rows]="rowsFilter"
      [sorts]="sorts">
    </ngx-datatable>
  </ng-template>

  <ng-template #headerTemplate let-column="column">
    <ng-container *ngIf="tableColumn(column) as tableColumn">
      <span class="datatable-header-cell-wrapper">
        <span class="datatable-header-cell-label draggable"
              [title]="tableColumn.headerTooltip">
          {{tableColumn.name}}
        </span>
      </span>
    </ng-container>
  </ng-template>

  <ng-template
    #actionsTemplate
    let-row="row"
  >
    <ng-container *ngIf="tableRowModel(row) as tableRow">
      <div class="w-100 text-center cursor-pointers">
        <fa-icon
          *ngIf="itemViewPermission"
          routerLink="/{{tableRow.routerAddress}}/view/{{tableRow.id}}"
          [icon]="faView"
          placement="left"
          container="body"
          ngbTooltip="{{componentsTranslation.tableResult_tooltipView}} {{itemName}}">
        </fa-icon>

        <fa-icon
          *ngIf="itemEditPermission"
          routerLink="/{{tableRow.routerAddress}}/create/{{tableRow.id}}"
          [icon]="faEdit"
          placement="left"
          container="body"
          ngbTooltip="{{componentsTranslation.tableResult_tooltipEdit}} {{itemName}}">
        </fa-icon>

        <fa-icon
          *ngIf="itemCopyPermission"
          (click)="copy(tableRow.id)"
          [icon]="faCopy"
          placement="left"
          container="body"
          ngbTooltip="{{componentsTranslation.tableResult_tooltipCopy}} {{itemName}}">
        </fa-icon>

        <fa-icon
          *ngIf="operationType === operationTypes.characteristic && addSamplePermission"
          [ngClass]="{disabled: tableRow.isCMM}"
          (click)="openAddSample(row.id)"
          [icon]="faFill"
          placement="left"
          container="body"
          [ngbTooltip]="tableRow.isCMM
            ? componentsTranslation.tableResult_tooltipCMMCharacteristic
            : componentsTranslation.tableResult_tooltipAddData">
        </fa-icon>

        <fa-icon
          *ngIf="tableRow.favorite"
          (click)="removeFromFavorites(tableRow.id)"
          [icon]="faHeart"
          placement="left"
          container="body"
          [ngbTooltip]="componentsTranslation.tableResult_tooltipRemoveFavorite">
        </fa-icon>

        <fa-icon
          *ngIf="!tableRow.favorite"
          class="cursor-pointer"
          (click)="addToFavorites(tableRow.id)"
          [icon]="faHeartBroken"
          placement="left"
          container="body"
          [ngbTooltip]="componentsTranslation.tableResult_tooltipAddToFavorite">
        </fa-icon>

        <fa-icon
          *ngIf="itemDeletePermission"
          class="cursor-pointer"
          (click)="deleteItem(tableRow.id)"
          [icon]="faTrash"
          placement="left"
          container="body"
          ngbTooltip="Delete {{itemName}}">
        </fa-icon>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #dateTemplate let-value="value">
    <div class="w-100 text-center"
         container="body"
         placement="left"
         [ngbTooltip]="value">
      {{ value | date:'shortDate' }}
    </div>
  </ng-template>

  <ng-template #textTemplate let-column="column" let-value="value">
    <ng-container *ngIf="tableColumn(column) as tableColumn">
      <div
        class="w-100 text-truncate text-center"
        container="body"
        placement="left"
        [ngbTooltip]="value"
      >
        {{ value }}
      </div>
    </ng-container>
  </ng-template>

  <ng-template #isCMMTemplate let-column="column" let-value="value">
    <ng-container *ngIf="tableColumn(column) as tableColumn">
      <div
        class="w-100 text-truncate text-center"
        container="body"
        placement="left"
        [ngbTooltip]="value ? 'CMM' : 'SPC' "
      >
        {{ value ? 'CMM' : 'SPC' }}
      </div>
    </ng-container>
  </ng-template>

  <ng-template #nameTemplate let-row="row">
    <ng-container *ngIf="tableRowModel(row) as tableRowModel">
      <div
        class="w-100 text-truncate text-left"
        [ngStyle]="!tableRowModel.enabled && {'color': 'darkgray'}"
        container="body"
        placement="left"
        [ngbTooltip]="tableRowModel.name"
      >
        {{ tableRowModel.name }}
      </div>
    </ng-container>
  </ng-template>

  <ng-template #namePathTemplate let-row="row">
    <ng-container *ngIf="tableRowModel(row) as tableRowModel">
      <div class="w-100 d-flex flex-row text-left"
           [ngStyle]="!tableRowModel.enabled && {'color': 'darkgray'}">
        <span>
          <b>{{ tableRowModel.name }}</b>
        </span>

        <span>
          &nbsp;&nbsp;-&nbsp;&nbsp;
        </span>

        <abbr class="abbr-attribute text-truncate"
              title="{{tableRowModel.path}}">
          {{ row.path }}
        </abbr>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #partNumbersTemplate let-row="row">
    <ng-container *ngIf="tableRowModel(row) as tableRow">
      <app-single-multi-template
        [isMulti]="tableRow.multipart"
        [content]="tableRow.partNumbers">
      </app-single-multi-template>
    </ng-container>
  </ng-template>

  <ng-template #partModelsTemplate let-row="row">
    <ng-container *ngIf="tableRowModel(row) as tableRow">
      <app-single-multi-template
        [isMulti]="tableRow.multimodel"
        [content]="tableRow.models">
      </app-single-multi-template>
    </ng-container>
  </ng-template>
</div>
