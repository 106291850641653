import { Injectable } from '@angular/core';
import {GlobalVariable} from '@common/global';
import {DatePipe, DecimalPipe} from '@angular/common';
import {TranslationService} from '@services/translation.service';
import {ComponentsTranslation, GlobalTranslation} from '@models/translation';
import {DataPoint, MeanSamplePoint, SamplePoint} from '@shared/charts/sample-point';
// import {Chart} from 'angular-highcharts';
import {Chart} from 'highcharts';
// import {PlotLines} from 'highcharts';
import * as Highcharts from 'highcharts';

@Injectable()
export class ChartService {
  private readonly locale = 'en-US';

  public readonly decimalPipe = new DecimalPipe(this.locale);
  public readonly datePipe = new DatePipe(this.locale);

  private componentsTranslation: ComponentsTranslation;
  private globalTranslation: GlobalTranslation;

  public decimals: string;

  constructor(translationService: TranslationService) {
    translationService.translations$.subscribe(translation => {
      this.componentsTranslation = translation.components;
      this.globalTranslation = translation.global;
    });
  }

  public setSessionStorage(key: string, chartOptions: any): void {
    sessionStorage.setItem(key, JSON.stringify(chartOptions));
  }

  public loadSessionStorage(key: string): any {
    return JSON.parse(sessionStorage.getItem(key));
  }

  public transformDate(date: Date): string {
    return this.datePipe.transform(date, GlobalVariable.DATE_FORMAT);
  }

  private transformNumber(value: any): string {
    return this.decimalPipe.transform(value, this.decimals);
  }

  public createPlotLine(color: string, id: string, value: number, label: string): Highcharts.YAxisPlotLinesOptions {
    return {
      color: color,
      id: id,
      zIndex: 2,
      dashStyle: 'Solid',
      width: 1,
      value: value,
      label: {
        text: `${label}=${this.transformNumber(value)}`,
        align: 'left',
        x: 0,
        style: {
          color: color,
          fontSize: '10px'
        }
      }
    };
  }

  private baseTooltip(point: SamplePoint, rulesLabel: string, valueLabel: string): string {
    let tooltip = '';
    if (point.rules && point.rules.length > 0) {
      tooltip += `<tr><td colspan="2" style="color: red;">${rulesLabel}</td></tr>`;
      for (const rule of point.rules) {
        tooltip += '<tr><td colspan="2" style="color: red;">' + rule + '</td></tr>';
      }
      tooltip += '<tr><td colspan="2" style="border-bottom: 1px dotted #00AEEF;"></td></tr>';
    }
    if (point.y) {
      tooltip += '<tr><td style="font-weight: bold;">' +
        valueLabel +
        '</td><td style="font-weight: bold;">' +
        this.transformNumber(point.y) +
        '</td></tr>';
    }

    return tooltip;
  }

  public createRangeChartTooltip(point: SamplePoint): string {
    return '<p>' + point.dateCreated + '</p><table>' +
      this.baseTooltip(point, this.componentsTranslation.rangeChart_rulesTooltip, this.componentsTranslation.rangeChart_rangeTooltip) +
      '</table>';
  }

  public createMeanChartTooltip(point: MeanSamplePoint): string {
    let tooltip = '<p>' + point.dateCreated + '</p><table>' +
      this.baseTooltip(point, this.componentsTranslation.meanChart_RulesTooltip, this.componentsTranslation.meanChart_SubgroupAverage);

    if (point.XBAR) {
      tooltip += `<tr><td>${this.componentsTranslation.meanChart_ProcessAverageTooltip} </td><td>` +
        this.transformNumber(point.XBAR) +
        '</td></tr>';
    }
    if (point.partNumber) {
      tooltip += `<tr><td>${this.componentsTranslation.meanChart_partNumberTooltip} </td><td>` +
        point.partNumber +
        `</td></tr>`;
    }
    if (point.startingUCL) {
      tooltip += `<tr><td>${this.componentsTranslation.meanChart_UCLLabel}: </td><td>` +
        this.transformNumber(point.startingUCL) +
        '</td></tr>';
    }
    if (point.startingLCL) {
      tooltip += `<tr><td>${this.componentsTranslation.meanChart_LCLLabel}: </td><td>` +
        this.transformNumber(point.startingLCL) +
        '</td></tr>';
    }

    tooltip += '</table>';

    return tooltip;
  }

  public createHistogramTooltip(point: DataPoint): string {
    return String(point.y);
  }

  public createChart(
    labelsFormatter: (value: number) => string,
    xAxisTitle: string,
    yAxisTitle: string,
    yAxisOptions: {[key: string]: any},
    tooltipFormatter = function (point: SamplePoint) {},
    redrawAction?: () => {},
    selectPointAction?: (param: any) => void): Chart {
    return new Chart(<any>{
      chart: {
        type: 'line',
        zoomType: 'x',
        panning: true,
        panKey: 'shift',
        events: {
          redraw: redrawAction ? redrawAction : () => {},
        }
      },
      title: {
        text: null
      },
      subtitle: {
        text: null
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'line',
        tickInterval: 1,
        labels: {
          formatter: function () {
            return labelsFormatter(this.value);
          }
        },
        title: {
          text: xAxisTitle
        },
        gridLineWidth: 1,
        min: 0,
      },
      yAxis: {
        title: {
          text: yAxisTitle
        },
        startOnTick: false,
        endOfTick: false,
        minorGridLineWidth: 1,
        gridLineWidth: 1,
        plotLines: new Array<Highcharts.YAxisPlotLinesOptions>(),
        min: 0,
        ...yAxisOptions
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          return tooltipFormatter(this.point);
        },
        footerFormat: '</table>',
      },
      plotOptions: {
        series: {
          allowPointSelect: !!selectPointAction,
          point: {
            events: {
              select: function(e) {
                selectPointAction(e);
              }
            }
          }
        }
      }
    });
  }
}
