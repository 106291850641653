import { Injectable } from '@angular/core';
import {ApiService} from '@services/api.service';
import {CharacteristicApiService} from '@characteristic/characteristic-api.service';
import {CharacteristicSampleCollection} from '@models/characteristic-sample-collection';
import {AdministrationApiService} from '@administration/administration-api.service';
import {SampleReason} from '@models/sample-reason';
import {AddSampleData} from '@models/add-sample-data';

@Injectable()
export class AddSamplesApiService {
  constructor(
    private apiService: ApiService,
    private administrationApiService: AdministrationApiService,
    private characteristicApiService: CharacteristicApiService
  ) { }

  public getSampleReasons(): Promise<SampleReason[]> {
    return this.administrationApiService.getSampleReasons();
  }

  // region Add Sample Collection API
  public getCharacteristicSamples(id: number): Promise<CharacteristicSampleCollection[]> {
    return this.characteristicApiService.getCharacteristicSamples(id);
  }

  public getSamplesCollection(id: number): Promise<CharacteristicSampleCollection> {
    const relativeUrl = `Characteristic/sampleCollection/${id}`;

    return this.apiService.get(relativeUrl);
  }

  public addSampleCollectionInsteadNR(characteristicId: number, data: CharacteristicSampleCollection, processChartId: number): Promise<any> {
    const relativeUrl = `Characteristic/${characteristicId}/samples/${processChartId}`;

    return this.apiService.post(relativeUrl, data);
  }

  public addSampleCollection(characteristicId: number, sampleCollection: CharacteristicSampleCollection): Promise<any> {
    const relativeUrl = `Characteristic/${characteristicId}/samples`;

    return this.apiService.post(relativeUrl, sampleCollection);
  }

  public editCharacteristicSample(body, characteristicId: number, collectionId: number): Promise<any> {
    const relativeUrl = `Characteristic/${characteristicId}/samples/${collectionId}`;

    return this.apiService.put(relativeUrl, body);
  }
  // endregion

  // region Add Sample API
  public isCharacteristicInProcessChartAndGetId(characteristicId: number): Promise<number> {
    const relativeUrl = `Characteristic/${characteristicId}/CharacteristicsProcessChart`;

    return this.apiService.get(relativeUrl);
  }

  public addSamplesCollection(data: AddSampleData): Promise<any> {
    const relativeUrl = `ProcessChart/ProcessChartInput`;

    return this.apiService.post(relativeUrl, data);
  }
  // endregion
}
