import { Injectable } from '@angular/core';
import {CmmMeasurement} from '@models/cmm-characteristic-chart';
import {CmmData} from '@models/cmm-data';
import {CmmOption} from '@models/cmm-option';
import {ApiService} from '@services/api.service';
import {AdministrationApiService} from '@administration/administration-api.service';

import {Characteristic} from '@models/characteristic';
import {CharacteristicSampleCollection} from '@models/characteristic-sample-collection';
import {ReactionPlan} from '@models/reaction-plan';
import {RecalcValues} from '@models/recalc-values';
import {SampleReason} from '@models/sample-reason';
import {ReactionPlanReason} from '@models/reaction-plan-reason';
import {User} from '@models/user';
import {VerificationConfirmation} from '@models/verification-confirmation';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Fixture} from '@models/fixture';
import {Part} from '@models/part';
import {PartModel} from '@models/part-model';
import {Unit} from '@models/unit';
import {HistogramData} from '@models/histogram-data';
import {ChartFilters} from '@models/chart-filters';
import {omit} from 'ng2-tree/src/utils/fn.utils';
import {nameof} from 'ts-simple-nameof';
import {CmmOptionList} from '@models/cmm-option-list';
import {CmmFilter} from '@models/cmm-filter';

@Injectable()
export class CharacteristicApiService {
  constructor(
    private apiService: ApiService,
    private administrationApiService: AdministrationApiService
  ) { }

  public getCharacteristicById(characteristicId: number): Promise<Characteristic> {
    const relativeUrl = `characteristic/${characteristicId}`;

    return this.apiService.get<Characteristic>(relativeUrl).then(characteristic => new Characteristic(characteristic));
  }

  public async getCharacteristicById2(characteristicId: number): Promise<Characteristic> {
    const relativeUrl = `characteristic/${characteristicId}`;

    return this.apiService.get<Characteristic>(relativeUrl).then(characteristic => new Characteristic(characteristic));
  }

  // region Characteristic Create API
  public getSamplesCollection(id: number): Promise<CharacteristicSampleCollection> {
    const relativeUrl = `Characteristic/sampleCollection/${id}`;

    return this.apiService.get(relativeUrl);
  }

  public checkIsCharacteristicWorking(characteristicId: number): Promise<boolean> {
    const relativeUrl = `Characteristic/${characteristicId}/IsWorking`;

    return this.apiService.get(relativeUrl);
  }

  public isCharacteristicNameUnique(name: string): Promise<boolean> {
    const relativeUrl = `Characteristic/IsUnique`;

    return this.apiService.post(relativeUrl, name);
  }

  public addCharacteristic(characteristic: Characteristic): Promise<any> {
    const relativeUrl = `characteristic`;

    return this.apiService.post(relativeUrl, characteristic);
  }

  public updateCharacteristic(characteristic: Characteristic): Promise<any> {
    const relativeUrl = `characteristic/${characteristic.id}`;

    return this.apiService.put(relativeUrl, characteristic);
  }

  public isCharacteristicInCalculatedCharacteristic(characteristicId: number): Promise<boolean> {
    const relativeUrl = `Characteristic/${characteristicId}/IsInCalculated`;

    return this.apiService.get(relativeUrl);
  }

  public isCharacteristicInProcessChart(characteristicId: number): Promise<boolean> {
    const relativeUrl = `Characteristic/${characteristicId}/IsInProcessChart/`;

    return this.apiService.get(relativeUrl);
  }

  public getUnits(): Promise<Unit[]> {
    return this.administrationApiService.getUnits().then(units => units.map(unit => new Unit(unit)));
  }

  public getFixtures(): Promise<Fixture[]> {
    const relativeUrl = `fixture`;

    return this.apiService.get(relativeUrl);
  }

  public getPartsByCharacteristicLocation(locationId: string): Promise<Part[]> {
    return this.apiService.getPartsByCharacteristicLocation(locationId);
  }

  public getPartNumbersModelsByLocation(locationId: string): Promise<PartModel[]> {
    return this.administrationApiService.getPartModelsByLocation(locationId);
  }

  public getCmmOptions(filter: CmmFilter): Promise<CmmOptionList> {
    const relativeUrl = `CMM/CCFilterData`;

    return this.apiService.put(relativeUrl, filter);
  }

  public getCmmCharacteristicData(cmmOption: CmmOption): Promise<CmmData> {
    const relativeUrl = `CMM/FillData`;

    return this.apiService.put(relativeUrl, cmmOption);
  }
  // endregion

  // region Characteristic View API
  public getCharacteristicSamples(characteristicId: number, chartFilters?: ChartFilters): Promise<CharacteristicSampleCollection[]> {
    let query = '';

    if (chartFilters) {
      query += '?';

      if (chartFilters.startDate) {
        query += 'start=' + chartFilters.startDate.toISOString();
      }
      if (chartFilters.endDate) {
        query += '&end=' + chartFilters.endDate.toISOString();
      }
      if (chartFilters.sampleCount !== null) {
        query += '&sampleCount=' + chartFilters.sampleCount;
      }
    }

    const relativeUrl = `characteristic/${characteristicId}/samples${query}`;

    return this.apiService.get(relativeUrl);
  }

  public getDefaultSampleReasons(): Promise<SampleReason[]> {
    return this.administrationApiService.getDefaultSampleReasons();
  }

  public getSampleReasons(): Promise<SampleReason[]> {
    return this.administrationApiService.getSampleReasons();
  }

  public async getSampleReasons2(): Promise<SampleReason[]> {
    return this.administrationApiService.getSampleReasons();
  }

  public getReactionPlan(id: number): Promise<ReactionPlan> {
    const relativeUrl = `CharacteristicReactionPlan/${id}`;

    return this.apiService.get<ReactionPlan>(relativeUrl).then(reactionPlan => new ReactionPlan(reactionPlan));
  }

  public getCharacteristicReactionPlan(samplesCollection: CharacteristicSampleCollection[]): Promise<ReactionPlan[]> {
    const collection = [];
    for (const item of samplesCollection) {
      collection.push(item.id);
    }

    const relativeUrl = `CharacteristicReactionPlan/ids?ids=${collection.join(',')}`;

    return this.apiService.get(relativeUrl);
  }

  public recalcStaticCharacteristic(characteristicId: number): Promise<RecalcValues> {
    const relativeUrl = `Characteristic/${characteristicId}/RecalcResult`;

    return this.apiService.get(relativeUrl);
  }

  public recalcCharacteristicFromLastSamplesAmount(characteristicId: number, quantity: number): Promise<RecalcValues> {
    const relativeUrl = `Characteristic/${characteristicId}/Recalc/${quantity}`;

    return this.apiService.put(relativeUrl, {});
  }

  public recalcCharacteristic(characteristicId: number, chartFilters: ChartFilters): Promise<RecalcValues> {
    const relativeUrl = `Characteristic/${characteristicId}/Recalc`;
    const skipProperties = [
      nameof<ChartFilters>(obj => obj.partNumberList),
      nameof<ChartFilters>(obj => obj.sampleReasonIdList),
    ];

    chartFilters = omit(chartFilters, skipProperties);

    return this.apiService.put(relativeUrl, chartFilters);
  }

  public recalcCharacteristicSample(characteristicId: number): Promise<RecalcValues> {
    const relativeUrl = `Characteristic/${characteristicId}/RecalcSample`;

    return this.apiService.put(relativeUrl, null);
  }

  public getCmmSamples(cmmOption: CmmOption): Promise<CmmMeasurement[]> {
    const relativeUrl = `CMM/RefreshSamples`;

    return this.apiService.post(relativeUrl, cmmOption);
  }

  public getCharacteristicHistogramData(characteristicId: number, chartFilters: ChartFilters): Promise<HistogramData> {
    const relativeUrl = `Characteristic/${characteristicId}/HistogramData`;
    const skipProperties = [
      nameof<ChartFilters>(obj => obj.partNumberList),
      nameof<ChartFilters>(obj => obj.sampleReasonIdList),
    ];

    chartFilters = omit(chartFilters, skipProperties);

    return this.apiService.put(relativeUrl, chartFilters);
  }

  public getCharacteristicExcelFile(characteristicId: number, chartFilters: ChartFilters): Promise<ArrayBuffer> {
    let query = '';

    if (chartFilters) {
      query += '?';

      if (chartFilters.startDate) {
        query += '&start=' + chartFilters.startDate.toISOString();
      }
      if (chartFilters.endDate) {
        query += '&end=' + chartFilters.endDate.toISOString();
      }
      if (chartFilters.sampleCount !== null) {
        query += '&sampleCount=' + chartFilters.sampleCount;
      }
    }

    const relativeUrl = `Characteristic/${characteristicId}/toExcel${query}`;
    const httpOptions = {
      headers: ApiService.createDefaultOptions().headers,
      responseType: 'arraybuffer'
    };

    return this.apiService.get(relativeUrl, httpOptions);
  }

  public getCharacteristicHistogramFromDateRange(id: number, dateStart: string, dateStop: string): Promise<HistogramData> {
    const relativeUrl = `Characteristic/${id}/HistogramData?&start=${dateStart}&end=${dateStop}`;

    return this.apiService.put(relativeUrl, null);
  }
  // endregion

  // region Reaction Plan API
  public updateReactionPlan(id: number, reactionPlan: ReactionPlan): Promise<any> {
    const relativeUrl = `CharacteristicReactionPlan/${id}`;

    return this.apiService.put(relativeUrl, reactionPlan);
  }

  public getReactionPlanReasons(): Promise<ReactionPlanReason[]> {
    return this.administrationApiService.getReactionPlanReasons();
  }

  public qualityEngineerVerification(credentials: User): Promise<VerificationConfirmation> {
    return this.formEncoded(credentials, 'reactionPlan.editQualitySignoff');
  }

  public supervisorVerification(credentials: User): Promise<VerificationConfirmation> {
    return this.formEncoded(credentials, 'reactionPlan.editSupervisorSignoff');
  }
  // endregion

  // region Characteristic List API
  public getCharacteristicsCount(): Promise<number> {
    const relativeUrl = `characteristic/ListCount`;

    return this.apiService.get(relativeUrl);
  }
  // endregion

  private formEncoded<T>(credentials: User, permission: string): Promise<T> {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
    };

    const relativeUrl = `Authorization/userInfo`;
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', credentials.login)
      .set('password', credentials.password)
      .set('permission', permission);

    return this.apiService.postForm(relativeUrl, body, httpOptions);
  }
}
