<div class="card m-0 {{ fullscreenCardClass }} {{ cardLoadClass }} {{ cardToggleClass }}"
     [@cardClose]="openCloseToggleState"
     [ngClass]="cardClass">
  <div class="card-header card-header-option"
       *ngIf="title"
       [ngClass]="headerClass">
    <div class="text-truncate">
      <h6>
        <app-responsive-text-line
          [text]="title"
          tooltipPlacement="bottom">
        </app-responsive-text-line>
      </h6>
    </div>

    <span *ngIf="!useClassHeader">
      {{ headerContent }}
    </span>

    <span *ngIf="useClassHeader">
      <ng-content select=".code-header">
      </ng-content>
    </span>

    <div>
      <ul class="list-unstyled card-option w-auto">
        <li *ngIf="isOptionsMenuEnabled">
          <i class="feather {{ optionMenuIcon }} full-card"
             (click)="toggleOptionsMenu()">
          </i>
        </li>

        <li *ngIf="enableFavoriteOption">
          <fa-icon
            [icon]="faHeartBroken"
            class="cursor-pointer"
            placement="right"
            container="body"
            [ngbTooltip]="componentsTranslation.tableResult_tooltipRemoveFavorite"
            (click)="unfavoriteCharacteristic()">
          </fa-icon>
        </li>

        <li *ngIf="enableFullscreenOption">
          <i class="feather {{ fullscreenCardIcon }} full-card"
             (click)="toggleFullscreen()">
          </i>
        </li>

        <li *ngIf="enableMinimizeOption">
          <i class="feather minimize-card {{ minimizeIcon }}"
             (click)="toggleVisibilityCard()">
          </i>
        </li>

        <li *ngIf="enableReloadOption">
          <i class="feather icon-refresh-cw reload-card"
             (click)="cardRefresh()">
          </i>
        </li>

        <li *ngIf="enableCloseOption">
          <i class="feather icon-trash close-card"
             (click)="closeCard()">
          </i>
        </li>
      </ul>
    </div>
  </div>

  <div [@cardToggle]="cardToggleClass"
       class="card-block-hid">
    <div class="card-block"
         [ngClass]="blockClass">
      <ng-content>
      </ng-content>
    </div>
  </div>

  <div *ngIf="isCardLoading"
       class="card-loader">
    <i class="fa fa-spinner rotate-refresh">
    </i>
  </div>
</div>
