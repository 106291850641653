<div class="form-material position-relative">
  <input
    *ngIf="type === 'text'"
    #vkTextInput
    type="text"
    class="form-control"
    autocomplete="new-email"
    [maxlength]="maxLength"
    [required]="required"
    [readonly]="readonly"
    [disabled]="disabled"
    [(ngModel)]="value"
    (ngModelChange)="virtualKeyboardService.sendValueToKeyboard($event, inputElement)"
  />

  <input
    *ngIf="type === 'password'"
    #vkPasswordInput
    type="password"
    class="form-control"
    autocomplete="new-password"
    [maxlength]="maxLength"
    [required]="required"
    [readonly]="readonly"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (ngModelChange)="virtualKeyboardService.sendValueToKeyboard($event, inputElement)"
    (blur)="blur.emit($event)"
  />

  <input
    *ngIf="type === 'number'"
    #vkNumericInput
    type="number"
    class="form-control"
    [min]="min"
    [max]="max"
    [step]="step"
    [required]="required"
    [readonly]="readonly"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (ngModelChange)="virtualKeyboardService.sendValueToKeyboard($event, inputElement)"
    (blur)="blur.emit($event)"
  />

  <textarea
    *ngIf="type === 'textarea'"
    #vkTextArea
    class="form-control"
    [cols]="cols"
    [rows]="rows"
    [maxlength]="maxLength"
    [required]="required"
    [readonly]="readonly"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (ngModelChange)="virtualKeyboardService.sendValueToKeyboard($event, inputElement)"
    (blur)="blur.emit($event)">
  </textarea>

  <label *ngIf="label"
         class="float-label"
         [ngClass]="{'float-label-enabled': this.value !== null}">
    {{label}}
  </label>

  <ng-container *ngIf="!readonly">
    <div class="{{keyboardIconClasses[type]}} float-right">
      <fa-icon
        *ngIf="type === 'text' || type === 'textarea' || type === 'password'"
        class="position-relative clear-icon"
        [icon]="iconClear"
        (click)="clear()"
        [ngClass]="{'disabled-keyboard': disabled}">
      </fa-icon>

      <fa-icon
        class="position-relative"
        [icon]="iconKeyboard"
        (click)="virtualKeyboardService.turnOnKeyboard($event, inputElement, this, 'value', disabled, type === 'number')"
        [ngClass]="{'disabled-keyboard': disabled}">
      </fa-icon>
    </div>
  </ng-container>
</div>



<app-virtual-keyboard
  [numericKeyboard]="virtualKeyboardService.numericKeyboard"
  [keyboardPlace]="virtualKeyboardService.keyboardPlace"
  [keyboardRect]="virtualKeyboardService.keyboardRect"
  [keyboardPosition]="virtualKeyboardService.keyboardPosition"
  [show]="virtualKeyboardService.showKeyboard"
  [inputValue]="virtualKeyboardService.valueToKeyboard"
  (showOff)="turnOffKeyboard()"
  (value)="virtualKeyboardService.setInputValueFromKeyboard($event)"
>
</app-virtual-keyboard>
