import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocationNode } from '@models/location-node';
import { ViewFilterOptions } from '@models/view-filter-options';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { BaseModel } from '@models/base-model';

declare type FilteringMode = 'none' | 'location' | 'dataList';

@Injectable()
export class TreeDataService {
  protected _locationTreeData: LocationNode[] = [];
  protected _dataList: BaseModel[] = [];
  protected dataListApiCall: () => Promise<BaseModel[]>;
  protected dataTreeApiCall: () => Promise<LocationNode[]>;
  protected filteringMode: FilteringMode = 'none';
  protected isDataLoaded: boolean;
  protected currentTreeType: string;

  public readonly dataLoaded = new BehaviorSubject<boolean>(false);
  public readonly filteredList$: BehaviorSubject<BaseModel[]> = new BehaviorSubject(null);
  public readonly filteredTree$: BehaviorSubject<LocationNode[]> = new BehaviorSubject(null);
  public readonly notFilteredTree$: BehaviorSubject<LocationNode[]> = new BehaviorSubject(null);

  public get locationTreeData(): LocationNode[] {
    return this._locationTreeData;
  }

  public get dataList(): BaseModel[] {
    return this._dataList;
  }

  static getLocationPath(locationTree: LocationNode[], locationId: string): string {
    for (const locationNode of locationTree) {
      if (locationNode.uid === locationId) {
        return locationNode.text;
      } else if (locationNode.children) {
        const locationPath = TreeDataService.getLocationPath(locationNode.children, locationId);

        if (locationPath) {
          return `${locationNode.text} | ${locationPath}`;
        }
      }
    }

    return null;
  }

  public setTree(locationTreeData?: LocationNode[]) {
    if (locationTreeData) {
      this._locationTreeData = locationTreeData;
      this.isDataLoaded = true;
      this.dataLoaded.next(true);
      this.filteredTree$.next(this._locationTreeData);
    }
  }

  public initTree(filteringMode: FilteringMode = 'none', refreshAction = () => this.refreshData()): Promise<void> {
    this.filteringMode = filteringMode;
    return refreshAction();
  }

  public invalidateData(): void {
    this.isDataLoaded = false;
  }

  public refreshData(): Promise<void> {
    if (this.isDataLoaded) {
      return Promise.resolve();
    }

    this.isDataLoaded = true;

    return forkJoin(
      [
        this.dataTreeApiCall().then(dataTree => dataTree),
        this.dataListApiCall().then(dataList => dataList)
      ]
    )
      .toPromise()
      .then(data => {
        this._locationTreeData = data[0];
        this._dataList = data[1];
        this.dataLoaded.next(true);
        this.filteredTree$.next(this._locationTreeData);
        this.filteredList$.next(this._dataList);
      });
  }

  public setFilter(filters?: ViewFilterOptions): void {
    if (this.filteringMode === 'location') {
      this.filterLocationTreeByFilter(filters);
    } else if (this.filteringMode === 'dataList') {
      this.FilterListData(filters);
    }
  }

  private FilterListData(filterOptions?: ViewFilterOptions): void {
    let filteredData: BaseModel[] = [...this._dataList];

    if (this.filteringMode === 'dataList') {
      if (filterOptions && filterOptions.isAnyFilterApplied()) {
        if (filterOptions.favorites) {
          filteredData = filteredData.filter(data => data.favorite);
        }
        if (filterOptions.selectedPlant) {
          filteredData = filteredData.filter(data => data.plant === filterOptions.selectedPlant);
        }
        if (filterOptions.selectedDepartment) {
          filteredData = filteredData.filter(data => data.department === filterOptions.selectedDepartment);
        }
        if (filterOptions.filterQuery) {
          const query = filterOptions.filterQuery.toUpperCase();

          filteredData = filteredData.filter((ele: BaseModel) => {
            const properties = [
              (ele.userFullName ? ele.userFullName : ''),
              ele.name,
              (ele.path || '')
            ];

            return properties.some(property => property.toUpperCase().includes(query));
          });
        }
      }

      this.filterLocationTreeDataByDataList(filteredData);
    }

    this.notifyDataListFiltered(filteredData);
  }

  private notifyDataListFiltered(filteredDataList: BaseModel[]): void {
    this.filteredList$.next(filteredDataList);
  }

  private filterLocationTreeDataByDataList(filteredLocations: BaseModel[]): void {
    let tree: LocationNode[] = [];

    if (this._locationTreeData.length) {
      tree = JSON.parse(JSON.stringify(this._locationTreeData));
    }

    const filteredPlantLocations: Set<string> = new Set(filteredLocations.map(filteredLocation => filteredLocation.plant));
    const filteredDepartmentLocations: Set<string> = new Set(filteredLocations.map(filteredLocation => filteredLocation.department));

    const filteredTree: LocationNode[] = [];
    const treeWithEmptyLocations: LocationNode[] = [];

    tree.forEach(countryNode => {
      if (countryNode.children) {
        const currentCountryNode = Object.assign({}, countryNode);
        const filteredPlantNodes = countryNode.children.filter(plant => filteredPlantLocations.has(plant.uid));
        currentCountryNode.children = filteredPlantNodes;

        filteredPlantNodes.forEach(plantNode => {
          let filteredDepartmentNodes = plantNode.children
            .filter(department => filteredDepartmentLocations.has(department.uid));
            // .map(department => this.clearElementsFromTree(department, filteredLocations.map(filteredLocation => filteredLocation.path)));
          filteredDepartmentNodes = filteredDepartmentNodes.filter(filteredDepartment => {
            // filter empty machines
            filteredDepartment.children = filteredDepartment.children
              .filter(machine => !!machine.children && !!machine.children.length);

            return !!filteredDepartment.children.length;
          });

          plantNode.children = filteredDepartmentNodes;
        });

        treeWithEmptyLocations.push(currentCountryNode);

        if (currentCountryNode.children.length) {
          filteredTree.push(currentCountryNode);
        }
      }
    });

    this.notifyOnLocationTreeFiltered(filteredTree, treeWithEmptyLocations);
  }

  private filterLocationTreeByFilter(filterOptions: ViewFilterOptions): void {
    let filteredTree = this._locationTreeData;

    if (filterOptions && filterOptions.filterQuery) {
      const query = filterOptions.filterQuery.toUpperCase();
      filteredTree = JSON.parse(JSON.stringify(filteredTree));
      filteredTree = this.filterTreeByPathSubString(filteredTree, query);
    }

    this.notifyOnLocationTreeFiltered(filteredTree, null);
  }

  private filterTreeByPathSubString(subTree: LocationNode[], pathSubName: string): LocationNode[] {
    const filteredTree = [];
    for (const subNode of subTree) {
      if (subNode.text.toUpperCase().includes(pathSubName)) {
        filteredTree.push(subNode);
      } else if (subNode.children) {
        const subFilteredTree = this.filterTreeByPathSubString(subNode.children, pathSubName);

        if (subFilteredTree.length > 0) {
          subNode.children = subFilteredTree;
          filteredTree.push(subNode);
        }
      }
    }

    return filteredTree;
  }

  private notifyOnLocationTreeFiltered(filteredTree: LocationNode[], treeWithEmptyLocations: LocationNode[]): void {
    this.filteredTree$.next(filteredTree);
    this.notFilteredTree$.next(treeWithEmptyLocations);
  }

  private clearElementsFromTree(elementTree: LocationNode, availableLocationPaths: string[]): LocationNode {
    let newElement = Object.assign({}, elementTree);

    const filteredChildren: LocationNode[] = elementTree.children
      .filter(element => availableLocationPaths
        .some(locationPath => locationPath
          .includes(element.path)));

    newElement = {
      ...newElement,
      children: filteredChildren
    };
    return newElement;
  }

  public refreshTree(): void {
    this.notifyOnLocationTreeFiltered(this.filteredTree$.getValue(), this.notFilteredTree$.getValue());
  }

  public deleteDataFromList(id: number) {
    const dataItemId = this._dataList.findIndex(data => data.id === id);
    this._dataList.splice(dataItemId, 1);
  }

  public refreshFavoriteData(id: number, value: boolean): void {
    const dataItem = this._dataList.find((dataElement: BaseModel) => dataElement.id === id);
    dataItem.favorite = value;
  }
}
