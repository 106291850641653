<div>
  <h4 class="my-0 mx-auto w-fit-content">
    {{signatureTitle}}
  </h4>

  <form
    #signatureForm="ngForm"
    class="form-material"
    id="signatureForm"
    (ngSubmit)="onSubmit(signatureForm.valid)"
  >
    <div class="row form-group">
      <label class="col-sm-3 col-form-label text-right">
        {{signaturePersonLabel}}
      </label>

      <div class="col-sm-3">
        <app-vk-input
          type="text"
          maxlength="50"
          name="initials"
          [required]="true"
          [(ngModel)]="signature.initials"
          [disabled]="true">
        </app-vk-input>

        <div *ngIf="signatureForm.submitted && !signature.initials"
             class="messages text-danger">
          {{initialValidationMessage}}
        </div>
      </div>

      <div class="d-flex">
        <label class="col-sm-6 col-form-label text-right">
          {{componentsTranslation.reactionPlan_radioButtonDeviation}}
        </label>

        <div class="col-sm-6">
          <app-vk-input
            type="number"
            name="deviationValue"
            min="0"
            step="1"
            [required]="true"
            [(ngModel)]="reactionPlanModel.deviationValue"
            [disabled]="signature.complete">
          </app-vk-input>

          <div *ngIf="signatureForm.submitted && reactionPlanModel.deviationValue === null"
               class="messages text-danger">
            {{globalTranslation.isRequired}}
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label *ngIf="signatureType === 'supervisor'"
             class="col-sm-3 col-form-label text-right">
        {{componentsTranslation.reactionPlan_remarkLabel}}
      </label>
      <label *ngIf="signatureType === 'qualityEngineer'"
        class="col-sm-3 col-form-label text-right">
        {{componentsTranslation.reactionPlan_correctiveActionLabel}}
      </label>

      <div class="col-sm-9">
        <app-vk-input
          type="textarea"
          maxlength="1000"
          name="comment"
          [required]="true"
          [(ngModel)]="signature.remarks"
          [disabled]="signature.complete">
        </app-vk-input>

        <div *ngIf="signatureForm.submitted && !signature.remarks"
             class="messages text-danger">
          {{globalTranslation.isRequired}}
        </div>
      </div>
    </div>

    <div *ngIf="signatureType === 'qualityEngineer'"
      class="row form-group">
      <label class="col-sm-3 col-form-label text-right">
        {{componentsTranslation.reactionPlan_ruleBreakOverride}}
      </label>
      <div class="col-md-3">
        <div class="checkbox-color"
             [ngClass]="reactionPlanModel.completedPlan && 'checkbox-disabled'">
          <input
            id="ruleBreakOverride"
            name="ruleBreakOverride"
            type="checkbox"
            [(ngModel)]="reactionPlanModel.ruleBreakOverride"
            [disabled]="signature.complete"/>
          <label for="ruleBreakOverride"></label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="position-relative my-0 mx-3">
          <ng-select
            required
            class="ng-select"
            labelForId="materialDispositionOptions"
            name="materialDispositionOptions"
            [searchable]="false"
            [clearable]="false"
            [placeholder]="componentsTranslation.reactionPlan_materialDisposition"
            [items]="materialDispositionOptions"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [disabled]="signature.complete"
            [ngClass]="{'cursor-disabled': reactionPlanModel.completedPlan}"
            [(ngModel)]="reactionPlanModel.materialDisposition">
          </ng-select>

          <div *ngIf="signature.complete && !reactionPlanModel.materialDisposition"
               class="messages text-danger text-center">
            {{globalTranslation.isRequired}}
          </div>
        </div>
      </div>
    </div>

    <div class="footer-form">
      <div>
        <span *ngIf="signature.complete">
          {{componentsTranslation.reactionPlan_dateLabel}}
          {{signature.date | date:'M/d/yy HH:mm a'}}
        </span>
      </div>

      <button
        class="btn btn-primary ripple light"
        type="submit"
        [disabled]="signature.complete"
        [ngStyle]="signature.complete && {'cursor': 'not-allowed'}"
        [ngClass]="signature.complete && 'disabled'"
      >
        {{componentsTranslation.reactionPlan_completeBtn}}
      </button>
    </div>
  </form>
</div>
