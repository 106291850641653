import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {GlobalVariablesPipe} from '@common/pipes/global-variables.pipe';
import {ParseNumberPipe} from '@common/pipes/parse-number.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    GlobalVariablesPipe,
    ParseNumberPipe,
  ],
  exports: [
    GlobalVariablesPipe,
    ParseNumberPipe,
  ]
})
export class PipesModule { }
