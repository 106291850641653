import {
  Directive, HostBinding, Inject, Input, OnInit, OnDestroy
} from '@angular/core';

import { AccordionDirective } from '@shared/accordion/accordion.directive';

@Directive({
  selector: '[appAccordionLink]'
})
export class AccordionLinkDirective implements OnInit, OnDestroy {
  private _open: boolean;
  private nav: AccordionDirective;

  @Input() public group: any;

  @HostBinding('class.pcoded-trigger')
  @Input() get open(): boolean {
    return this._open;
  }

  set open(value: boolean) {
    this._open = value;
    /*for slimscroll on and off*/
    document.querySelector('.pcoded-inner-navbar').classList.toggle('scroll-sidebar');
    if (value) {
      this.nav.closeOtherLinks(this);
    }
  }

  constructor(@Inject(AccordionDirective) nav: AccordionDirective) {
    this.nav = nav;
  }

  public ngOnInit(): void {
    this.nav.addLink(this);
  }

  public ngOnDestroy(): void {
    this.nav.removeGroup(this);
  }

  public toggle(): void {
    /*for slimscroll on and off*/
    document.querySelector('.pcoded-inner-navbar').classList.add('scroll-sidebar');
    this.open = !this.open;
  }
}
