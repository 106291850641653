import { Component, OnChanges, Input, Output, EventEmitter, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Characteristic } from '@models/characteristic';
import { CharacteristicSampleCollection } from '@models/characteristic-sample-collection';
// import { Chart } from 'angular-highcharts';
import { DecimalPipe, DatePipe } from '@angular/common';
import { GlobalVariable } from '@common/global';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { RecalcValues } from '@models/recalc-values';
import {RuleOption, TranslationService} from '@services/translation.service';
import { ComponentsTranslation } from '@models/translation';

import {SimpleChanges} from '@angular/core';
import {Chart} from 'highcharts';

@Component({
  selector: 'app-pre-control-chart',
  templateUrl: './pre-control-chart.component.html',
  styleUrls: ['./pre-control-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PreControlChartComponent {
  //
  // @Input() characteristic: Characteristic;
  // @Input() recalcValues: RecalcValues;
  // @Input() withoutDate: boolean;
  // @Input() options: boolean;
  // @Input() openOptions: boolean;
  // @Input() sampleCollections: CharacteristicSampleCollection[];
  // @Input() widthChartMax: number;
  // @Input() heightChartMax: number;
  // @Output() chartChanged = new EventEmitter<Chart>();
  // @Output() optionsChange = new EventEmitter<any>();
  // @Output() chartLoaded = new EventEmitter<boolean>();
  //
  // private key = GlobalVariable.SESSION_STORAGE_KEYS.characteristic_pre_control_chart;
  // private numberPipe = new DecimalPipe('en-US');
  // private decimalPlaces: string;
  //
  // public rules: RuleOption[] = [];
  // public chart: Chart;
  // public refreshIcon = faSync;
  // public chartOptions = {
  //   showUCL: false,
  //   showMEAN: false,
  //   showUGZ: false,
  //   showLGZ: true,
  //   showLCL: false,
  //   numberOfTicks: 5,
  // };
  // public dates = {};
  // public componentsTranslation: ComponentsTranslation;
  //
  // private plotLineUclOptions = {};
  // private plotLineLclOptions = {};
  // private plotCentralLineOptions = {};
  // private plotLineUgzOptions = {};
  // private plotLineLgzOptions = {};
  //
  // constructor(
  //   private translationService: TranslationService,
  // ) {
  //   this.translationService.translations$.subscribe(translation => {
  //     this.componentsTranslation = translation.components;
  //   });
  //
  //   this.translationService.rules$.subscribe(rules => {
  //     this.rules = rules;
  //   });
  // }
  //
  // public ngOnInit(): void {
  //   this.loadSessionStorage();
  //   if (this.recalcValues) {
  //     this.generateOptions();
  //   }
  //   if (this.recalcValues) {
  //     this.recalcValues.sampleCollections !== null ?
  //       this.sampleCollections = this.recalcValues.sampleCollections : this.sampleCollections = [];
  //   } else {
  //     this.recalcValues = null;
  //   }
  //   if (this.sampleCollections != null) {
  //     if (this.recalcValues) {
  //       this.generateChart(false);
  //     }
  //     this.chartChanged.emit(this.chart);
  //   } else {
  //     this.sampleCollections = [];
  //   }
  // }
  //
  // public ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.openOptions || changes.heightChartMax) {
  //     this.resizeChart();
  //   }
  //   if (changes.recalcValues) {
  //     if (this.recalcValues) {
  //       this.recalcValues.sampleCollections !== null ?
  //         this.sampleCollections = this.recalcValues.sampleCollections : this.sampleCollections = [];
  //     }
  //   }
  //   if (this.recalcValues != null && changes.recalcValues && this.characteristic.limitConfig) {
  //     if (this.recalcValues) {
  //       this.generateOptions();
  //       this.generateChart(true);
  //       this.chartChanged.emit(this.chart);
  //     }
  //   }
  //   if (changes.useXAxisLabels) {
  //     this.generateChart(true);
  //     this.chartChanged.emit(this.chart);
  //   }
  // }
  //
  // public ngOnDestroy(): void {
  //   this.setSessionStorage();
  // }
  //
  // private generateOptions(): void {
  //   this.plotLineUclOptions = {
  //     id: 'ucl',
  //     zIndex: 2,
  //     dashStyle: 'solid',
  //     width: 1,
  //     value: this.recalcValues.ucLx,
  //     label: {
  //       text: `${this.componentsTranslation.preControlChart_UClLabel}` + '=' +
  //         this.numberPipe.transform(this.recalcValues.ucLx, this.decimalPlaces),
  //       align: 'left',
  //       x: 0,
  //       style: {
  //         fontSize: '10px'
  //       }
  //     }
  //   };
  //
  //   this.plotLineLclOptions = {
  //     id: 'lcl',
  //     zIndex: 2,
  //     dashStyle: 'solid',
  //     width: 1,
  //     value: this.recalcValues.lcLx,
  //     label: {
  //       text: `${this.componentsTranslation.preControlChart_LCLLabel}` + '=' +
  //         this.numberPipe.transform(this.recalcValues.lcLx, this.decimalPlaces),
  //       align: 'left',
  //       x: 0,
  //       style: {
  //         fontSize: '10px'
  //       }
  //     }
  //   };
  //
  //   this.plotCentralLineOptions = {
  //     id: 'central',
  //     zIndex: 2,
  //     dashStyle: 'solid',
  //     width: 1,
  //     value: this.recalcValues.grandMean,
  //     label: {
  //       text: `${this.componentsTranslation.preControlChart_CentralLineLabel}` + '=' +
  //         this.numberPipe.transform(this.recalcValues.grandMean,
  //           this.decimalPlaces),
  //       align: 'left',
  //       x: 0,
  //       style: {
  //         fontSize: '10px'
  //       }
  //     }
  //   };
  //
  //   // W ugz wartości do zmiany this.recalcValue.cośtam
  //   this.plotLineUgzOptions = {
  //     id: 'ugz',
  //     zIndex: 2,
  //     dashStyle: 'solid',
  //     width: 1,
  //     value: this.characteristic.limitConfig.ugzValue,
  //     label: {
  //       text: `${this.componentsTranslation.preControlChart_UGZLabel}` + '=' +
  //         this.numberPipe.transform(this.characteristic.limitConfig.ugzValue, this.decimalPlaces),
  //       align: 'left',
  //       x: 0,
  //       style: {
  //         fontSize: '10px'
  //       }
  //     }
  //   };
  //
  //   this.plotLineLgzOptions = {
  //     id: 'lgz',
  //     zIndex: 2,
  //     dashStyle: 'solid',
  //     width: 1,
  //     value: this.characteristic.limitConfig.lgzValue,
  //     label: {
  //       text: `${this.componentsTranslation.preControlChart_LGZLabel}` + '=' +
  //         + this.numberPipe.transform(this.characteristic.limitConfig.lgzValue, this.decimalPlaces),
  //       align: 'left',
  //       x: 0,
  //       style: {
  //         fontSize: '10px'
  //       }
  //     }
  //   };
  //
  //   this.decimalPlaces = '1.' + this.characteristic.decimals + '-' + this.characteristic.decimals;
  // }
  //
  // public setSessionStorage(): void {
  //   sessionStorage.setItem(this.key, JSON.stringify(this.chartOptions));
  // }
  //
  // public loadSessionStorage(): void {
  //   const data = JSON.parse(sessionStorage.getItem(this.key));
  //   if (data !== null) {
  //     this.chartOptions = data;
  //   }
  // }
  //
  // public changePlotLine(event: boolean, value: string): void {
  //   if (value === 'ucl') {
  //     if (event) {
  //       this.chart.ref.yAxis[0].addPlotLine(this.plotLineUclOptions);
  //     } else {
  //       this.chart.ref.yAxis[0].removePlotLine(value);
  //     }
  //   }
  //   if (value === 'lcl') {
  //     if (event) {
  //       this.chart.ref.yAxis[0].addPlotLine(this.plotLineLclOptions);
  //     } else {
  //       this.chart.ref.yAxis[0].removePlotLine(value);
  //     }
  //   }
  //   if (value === 'central') {
  //     if (event) {
  //       this.chart.ref.yAxis[0].addPlotLine(this.plotCentralLineOptions);
  //     } else {
  //       this.chart.ref.yAxis[0].removePlotLine(value);
  //     }
  //   }
  //   if (value === 'ugz') {
  //     if (event) {
  //       this.chart.ref.yAxis[0].addPlotLine(this.plotLineUgzOptions);
  //     } else {
  //       this.chart.ref.yAxis[0].removePlotLine(value);
  //     }
  //   }
  //   if (value === 'lgz') {
  //     if (event) {
  //       this.chart.ref.yAxis[0].addPlotLine(this.plotLineLgzOptions);
  //     } else {
  //       this.chart.ref.yAxis[0].removePlotLine(value);
  //     }
  //   }
  // }
  //
  // public generateChart(refresh): void {
  //   if (this.sampleCollections === null || this.sampleCollections === undefined) {
  //     this.sampleCollections = [];
  //   }
  //   const withoutDate = this.withoutDate;
  //   const datePipe = new DatePipe('en-US');
  //   const dateformat = GlobalVariable.DATE_FORMAT;
  //   const numberPipe = new DecimalPipe('en-US');
  //   const values = [];
  //   // const valuesToCalculateMinMax = [];
  //   const data = [];
  //   const LSL = [];
  //   const USL = [];
  //   const limitLCL = [];
  //   const limitUCL = [];
  //   let highestYValue;
  //   let lowestYValue;
  //   const centralLine = [];
  //   const translation = this.componentsTranslation;
  //   this.sampleCollections.sort((a, b) => {
  //     return new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime();
  //   });
  //   this.sampleCollections.forEach((element, index) => {
  //     if (element.mean > highestYValue || !highestYValue) {
  //       highestYValue = element.mean;
  //     }
  //     if (element.mean < lowestYValue || !lowestYValue) {
  //       lowestYValue = element.mean;
  //     }
  //     const itemDate = new Date(element.dateCreated);
  //     const tmpData = {
  //       x: null,
  //       y: null,
  //       UCL: null,
  //       LCL: null,
  //       color: '#8ebfee',
  //       XBAR: null,
  //       rules: [],
  //       dateCreated: '',
  //       partNumber: ''
  //     };
  //     const chartDate = Date.UTC(
  //       itemDate.getFullYear(),
  //       itemDate.getMonth(),
  //       itemDate.getDate(),
  //       itemDate.getHours(),
  //       itemDate.getMinutes(),
  //       itemDate.getSeconds()
  //     );
  //
  //     // valuesToCalculateMinMax.push(element.mean);
  //     tmpData.x = index;
  //     tmpData.y = element.mean;
  //     tmpData.LCL = element.meanLCL;
  //     tmpData.UCL = element.meanUCL;
  //     tmpData.partNumber = element.partNumber;
  //     tmpData.XBAR = element.meanCenterLine;
  //     tmpData.dateCreated = datePipe.transform(itemDate, dateformat);
  //     tmpData.color = this.checkColor(element.mean);
  //     values.push(tmpData.LCL, tmpData.UCL, element.mean);
  //     this.dates = {
  //       ...this.dates,
  //       [index]: datePipe.transform(itemDate, dateformat)
  //     };
  //     LSL.push([chartDate, element.lsl]);
  //     USL.push([chartDate, element.usl]);
  //
  //     limitLCL.push([chartDate, tmpData.LCL]);
  //     limitUCL.push([chartDate, tmpData.UCL]);
  //     centralLine.push([chartDate, tmpData.XBAR]);
  //
  //     data.push(tmpData);
  //   });
  //   const dates = this.dates;
  //   const chart = new Chart(<any>{
  //     chart: {
  //       type: 'line',
  //       zoomType: 'x',
  //       panning: true,
  //       panKey: 'shift',
  //       events: {
  //         redraw: () => {
  //           this.chartLoaded.emit(true);
  //         }
  //       }
  //     },
  //     title: {
  //       text: ''
  //     },
  //     credits: {
  //       enabled: false
  //     },
  //     xAxis: {
  //       type: 'line',
  //       labels: {
  //         formatter: function () {
  //           return withoutDate ? '' : dates[this.value];
  //         }
  //       },
  //       tickInterval: 1,
  //       title: {
  //         text: this.componentsTranslation.preControlChart_chartTextDateXaxis
  //       },
  //       gridLineWidth: 1,
  //       min: 0,
  //     },
  //     yAxis: {
  //       title: {
  //         text: this.componentsTranslation.preControlChart_chartTextDateYaxis
  //       },
  //       tickAmount: this.chartOptions.numberOfTicks,
  //       minorGridLineWidth: 1,
  //       gridLineWidth: 1,
  //       plotLines: [],
  //       min: 0,
  //       plotBands: [
  //         {
  //           color: '#ff4e28',
  //           from: this.recalcValues.ucLx,
  //           to: (highestYValue ? highestYValue : 0) + 5000,
  //         },
  //         {
  //           color: '#ff4e28',
  //           from: this.recalcValues.lcLx,
  //           to: (lowestYValue ? lowestYValue : 0) - 5000,
  //         },
  //         {
  //           color: '#feff12',
  //           from: this.characteristic.limitConfig.lgzValue,
  //           to: this.recalcValues.lcLx,
  //         },
  //         {
  //           color: '#feff12',
  //           from: this.characteristic.limitConfig.ugzValue,
  //           to: this.recalcValues.ucLx,
  //         },
  //         {
  //           color: '#a0ff63',
  //           from: this.characteristic.limitConfig.ugzValue,
  //           to: this.characteristic.limitConfig.lgzValue,
  //         }
  //       ],
  //     },
  //     tooltip: {
  //       useHTML: true,
  //       formatter: function () {
  //         let s = '<p>' + this.point.dateCreated + '</p><table>';
  //         if (this.point.rules.length > 0) {
  //           s += `<tr><td colspan="2" style="color: red;">${translation.meanChart_RulesTooltip}</td></tr>`;
  //           for (const rule of this.point.rules) {
  //             s += '<tr><td colspan="2" style="color: red;">' + rule + '</td></tr>';
  //           }
  //           s += '<tr><td colspan="2" style="border-bottom: 1px dotted #00AEEF;"></td></tr>';
  //         }
  //         if (this.point.y != null) {
  //           s += '<tr><td style="font-weight: bold;">' +
  //             'Subgroup average: ' +
  //             '</td><td style="font-weight: bold;">' + numberPipe.transform(this.point.y, this.decimalPlaces) + '</td></tr>';
  //         }
  //         if (this.point.XBAR != null) {
  //           s += `<tr><td>${translation.meanChart_ProcessAverageTooltip} </td><td>` + numberPipe.transform(this.point.XBAR,
  //             this.decimalPlaces) + '</td></tr>';
  //         }
  //         if (this.point.partNumber != null) {
  //           s += `<tr><td>${translation.meanChart_partNumberTooltip} </td><td>` +  this.point.partNumber + `</td></tr>`;
  //         }
  //         s += '</table>';
  //
  //         return s;
  //       },
  //       footerFormat: '</table>',
  //     }
  //   });
  //
  //   if (this.chartOptions.showLGZ ) {
  //     chart.options.yAxis['plotLines'].push(this.plotLineLgzOptions);
  //   }
  //
  //   if (this.chartOptions.showMEAN) {
  //     chart.options.yAxis['plotLines'].push(this.plotCentralLineOptions);
  //   }
  //
  //   if (this.chartOptions.showUGZ) {
  //     chart.options.yAxis['plotLines'].push(this.plotLineUgzOptions);
  //   }
  //
  //   if (this.chartOptions.showLCL) {
  //     chart.options.yAxis['plotLines'].push(this.plotLineLclOptions);
  //   }
  //
  //   if (this.characteristic.limitConfig.uclLocationDraw && this.chartOptions.showUCL) {
  //     chart.options.yAxis['plotLines'].push(this.plotLineUclOptions);
  //   }
  //
  //   // valuesToCalculateMinMax.push(this.recalcValues.lsl);
  //   // valuesToCalculateMinMax.push(this.recalcValues.usl);
  //   // valuesToCalculateMinMax.push(this.recalcValues.grandMean);
  //   // valuesToCalculateMinMax.push(this.characteristic.limitConfig.ugzValue);
  //   // valuesToCalculateMinMax.push(this.characteristic.limitConfig.lgzValue);
  //   // dodaj jak powyzej wartosci ugz lgz
  //
  //   chart.addSerie({ name: this.componentsTranslation.preControlChart_Title, color: '#000000', data: data, marker: { enabled: true } });
  //
  //   // chart.options.yAxis['min'] = Math.min.apply(null, valuesToCalculateMinMax);
  //   // chart.options.yAxis['max'] = Math.max.apply(null, valuesToCalculateMinMax);
  //   this.chart = chart;
  //   if (!this.openOptions && refresh) {
  //     setTimeout(() => {
  //       const h = this.heightChartMax;
  //       const width = this.widthChartMax ;
  //       if (this.chart.ref) {
  //         this.chart.ref.setSize(width, h);
  //       }
  //     });
  //   }
  // }
  //
  // checkColor(value) {
  //   let color = '#000000';
  //   // zmien na wartości 0 lgz 20 ugz
  //   if (value < this.characteristic.limitConfig.lgzValue || value > this.characteristic.limitConfig.ugzValue) {
  //     color = '#ff9a00';
  //   }
  //   return color;
  // }
  //
  // private resizeChart(): void {
  //   if (!this.openOptions && this.chart && this.widthChartMax) {
  //     this.chart.ref.setSize(this.widthChartMax, this.heightChartMax);
  //   }
  //   if (this.openOptions && this.chart && this.widthChartMax) {
  //     const h = this.heightChartMax;
  //     const width = this.widthChartMax - 400;
  //     this.chart.ref.setSize(width, h);
  //   }
  // }
  //
  // public onResized(event: ResizedEvent): void {
  //   if (event.oldHeight > 100) {
  //     const h = event.newHeight;
  //     let width = event.newWidth;
  //     if (this.openOptions) {
  //       width -= 400;
  //     }
  //     if (this.chart.ref) {
  //       this.chart.ref.setSize(width, h);
  //     }
  //   }
  // }
  //
  // public integerNumber(event): void {
  //   const value = Number(event.target.value);
  //   this.chartOptions.numberOfTicks = Math.round(value);
  //   this.optionsChange.emit(this.chartOptions);
  // }
  //
  // public refresh(): void {
  //   this.generateChart(true);
  // }
}
