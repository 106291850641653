import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ProcessChart} from '@models/process-chart';
import {ProcessChartRows, ProcessChartRowsCell} from '@models/process-chart-rows';
import {Characteristic, GaugeType} from '@models/characteristic';
import {CalculatedCharacteristic} from '@models/calculated-characterisitc';
import {Subscription} from 'rxjs';
import {User} from '@models/user';
import {ReactionPlan} from '@models/reaction-plan';
import {Router} from '@angular/router';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {AttributeSampleValue, HelpersService, NotifierType} from '@services/helpers.service';
import {TranslationService} from '@services/translation.service';
import {ComponentsTranslation, GlobalTranslation} from '@models/translation';
import {DecimalPipe} from '@angular/common';
import {GlobalVariable} from '@common/global';
import {UserSettingsService} from '@services/user-settings.service';
import {UserService} from '@user/user.service';
import {ReactionPlanComponent} from '@shared/reaction-plan/reaction-plan.component';
import {ProcessChartApiService} from '@shared/process-chart-api.service';

interface PnOptions {
  chosen: boolean;
  label: string;
  value: string;
}

export enum ProcessChartViewType {
  ProcessChart,
  LocationScreen
}

@Component({
  selector: 'app-process-chart',
  templateUrl: './process-chart.component.html',
  styleUrls: ['./process-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [HelpersService],
  animations: [
    trigger('openClose', [
      state('open', style({
        width: '0',
        height: '0',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '18px solid #007bff',
      })),
      state('closed', style({
        width: '0',
        height: '0',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '18px solid #007bff',
        transform: 'rotate(-90deg)',
      })),
      transition('open <=> closed', [
        animate('0.6s')
      ]),
    ]),
    trigger('showContent', [
      state('openContent', style({
        'height': '*'
      })),
      state('closeContent', style({
        'height': '110px',
      })),
      transition('openContent <=> closeContent', [
        animate('0.6s')
      ]),
    ]),
  ],

})
export class ProcessChartComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly subscriptions = new Subscription();
  private isAddSampleActionBlocked: boolean;
  private isEditSampleActionBlocked: boolean;
  private loggedUser: User;
  private userAccessBlocked: boolean;

  public readonly disabledCharacteristicColor = GlobalVariable.COLOR_PROCESS_CHART.characteristic_disabled_text;
  public readonly infoIcon = faInfoCircle;
  public readonly processChartColors = GlobalVariable.COLOR_PROCESS_CHART;
  public readonly frequencyOptions = {};
  public readonly processChartViewTypes = ProcessChartViewType;
  public readonly gaugeTypes = GaugeType;

  public allCharacteristics: Array<Characteristic | CalculatedCharacteristic>;
  public attributeValue: {[p: number]: number} = {};
  public characteristic: Characteristic | CalculatedCharacteristic;
  public chosenPartNumbers: PnOptions[] = [];
  public componentsTranslation: ComponentsTranslation;
  public disableProcessChart = false;
  public locationScreenPartNumber: string = null;
  public filteredCalculatedCharacteristics: CalculatedCharacteristic[] = [];
  public filteredCharacteristics: Characteristic[] = [];
  public firstPartNumberAdded = false;
  public globalTranslation: GlobalTranslation;
  public leftSideFullWidth = true;
  public localeType: string;
  public machineText: string;
  public openAddSampleModal = false;
  public optionChosenFirst = false;
  public partNumber: string = null;
  public isPartNumberChosen = false;
  public pnOptions: PnOptions[] = [];
  public processChart: ProcessChart = {};
  public processChartName: string;
  public processChartOpen = false;
  public processChartRows: ProcessChartRows[];
  public quantityRows: number;
  public rightSideFullWidth = true;
  public sdNumber: string;
  public isLoading = false;

  @Input() locationScreenEnabled: boolean;
  @Input() viewType: ProcessChartViewType;
  @Input() processChartId: number;

  @ViewChild(ReactionPlanComponent) reactionPlanComponent: ReactionPlanComponent;

  constructor(
    private processChartApiService: ProcessChartApiService,
    private helperService: HelpersService,
    private router: Router,
    private translationService: TranslationService,
    private numberPipe: DecimalPipe,
    private userSettings: UserSettingsService
  ) {
    this.setTranslation();

    this.frequencyOptions = {
      1: this.globalTranslation.startOfShiftOptions,
      2: this.globalTranslation.everyHourOptions,
      3: this.globalTranslation.every2HoursOptions,
      4: this.globalTranslation.every4HoursOptions,
      5: this.globalTranslation.afterProcessChangeOptions,
      6: this.globalTranslation.afterChangeoverOptions,
      7: this.globalTranslation.afterMaintenanceInterventionOptions,
      8: this.globalTranslation.toolChangeOptions,
      9: this.globalTranslation.every6HoursOptions,
      10: this.globalTranslation.midOfShiftOptions,
      11: this.globalTranslation.oncePerShiftOptions,
      12: this.globalTranslation.afterCorrectionOptions,
      13: this.globalTranslation.oncePerDayOptions,
      14: this.globalTranslation.afterMealOptions,
      15: this.globalTranslation.afterModelChangeOptions
    };
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.componentsTranslation = translation.components;
      this.globalTranslation = translation.global;
    });

    const localeSubscription = this.translationService.translationLocale$.subscribe(locale => this.localeType = locale);

    this.subscriptions.add(translationSubscription);
    this.subscriptions.add(localeSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnInit(): void {
    this.loggedUser = UserService.getLoggedUser();
    this.pnOptions.map((option: PnOptions) => {
      option.chosen = false;
    });

    if (this.viewType === ProcessChartViewType.LocationScreen) {
      this.disableProcessChart = !(this.locationScreenEnabled);
    }

    this.quantityRows = this.userSettings.rowQuantity;
    this.setPermissions();
  }

  public ngAfterViewInit(): void {
    this.loadData(false);
  }

  private setPermissions(): void {
    const hasPermission = (permission: string) => {
      return HelpersService.hasUserPermission(this.loggedUser, permission);
    };

    this.isEditSampleActionBlocked = !hasPermission('sample.edit');
    this.isAddSampleActionBlocked = !hasPermission('sample.add');
  }

  public reload() {
    this.loadData(true);
    this.getCells();
  }

  public refreshData(): void {
    this.refresh();
  }

  public refresh(): void {
    this.loadData(true);
    this.getCells();
  }

  private loadData(refresh: boolean): void {
    this.isLoading = true;
    this.processChartApiService.getProcessChart(this.processChartId).then(processChart => {
      if (!refresh) {
        this.getAllParts(processChart);
        this.processChart = processChart;
        this.allCharacteristics = [...this.processChart.fullCharacteristics, ...this.processChart.fullCalculatedCharacteristics];
        this.processChartName = this.processChart.name;
      }
      this.isLoading = false;
    }).then(() => {
      this.getProcessChartSdAndDepartment();
    });
  }

  private getAllParts(processChart: ProcessChart): void {
    const characteristicParts = processChart.fullCharacteristics
      ? processChart.fullCharacteristics
        .map(fullCharacteristic => fullCharacteristic.partNumbers
          .map(part => part.partNumber))
        .reduce((previousParts, currentParts) => [...previousParts, ...currentParts])
      : [];

    const calculatedCharacteristicParts = processChart.fullCalculatedCharacteristics
      ? processChart.fullCalculatedCharacteristics
        .map(fullCalculatedCharacteristic => fullCalculatedCharacteristic.partId)
      : [];

    const partNumbers = new Set<string>([...characteristicParts, ...calculatedCharacteristicParts]);
    this.pnOptions = [];

    partNumbers.forEach(partId => {
      this.pnOptions.push({label: `PN: ${partId}`, value: partId, chosen: false});
    });
  }

  public getProcessChartSdAndDepartment(): void {
    // const processChartListSubscription = this.processChartTreeService.filteredList$
    //   .filter(data => !!data)
    //   .subscribe(list => {
    //   this.isLoading = true;
    //   TODO: lack of data
    //   const currentProcessChart = list.find((processChart: ProcessChart) => processChart.id === this.processChartId);
    //   this.sdNumber = currentProcessChart.sdNumber;
    //   this.machineText = currentProcessChart.machineText;
    //
    //   this.isLoading = false;
    // });
    //
    // this.subscriptions.add(processChartListSubscription);
  }

  private getCells(): void {
    this.isLoading = true;
    this.processChartApiService.getProcessChartRows(this.processChartId, this.partNumber).then((rows: ProcessChartRows[]) => {
      rows.sort((a, b) => {
        return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
      });
      this.processChartRows = rows;
      this.sortCharacteristics();
    }).then(() => {
      this.setCharacteristicHeaderBackground();
      if (!this.firstPartNumberAdded) {
        this.firstPartNumberAdded = true;
        this.toggleProcessChart();
      }
      if (this.viewType === ProcessChartViewType.ProcessChart) {
        this.processChartOpen = true;
      }
      this.isLoading = false;
    }).catch(() => {
      this.isLoading = false;
      this.helperService.notificationTrigger(NotifierType.Error, this.globalTranslation.errorFetchMsg);
    });
  }

  private sortCharacteristics(): void {
    const sortSequenceNumber = (a: any, b: any) => {
      return a.sequenceNumber - b.sequenceNumber;
    };

    const allCharacteristics = [...this.filteredCalculatedCharacteristics, ...this.filteredCharacteristics];
    allCharacteristics.sort(sortSequenceNumber);
    this.allCharacteristics = allCharacteristics;
    this.setAttributeCell();
  }

  private setAttributeCell(): void {
    this.attributeValue = {};
    this.processChartRows.forEach((processChartRows: ProcessChartRows, index: number) => {
      processChartRows.cells.forEach((cell: ProcessChartRowsCell) => {
        this.allCharacteristics.forEach((characteristic: Characteristic, characteristicIndex: number) => {
          if (characteristic.id === cell.entityId && characteristic.gageType === GaugeType.Attribute) {
            this.attributeValue[index + characteristicIndex] = cell.value;
          }
        });
      });
    });
  }

  private setCharacteristicHeaderBackground(): void {
    this.clearCharacteristicsFromBrokenBackground();
    this.processChartRows.forEach((row) => {
      row.cells.forEach((cell) => {
        if (cell.ruleBroken === true && !cell.reactionPlanCompleted) {
          const characteristicDescribeElement = document.getElementById(`characteristic${cell.entityId}`);
          if (characteristicDescribeElement) {
            characteristicDescribeElement.classList.add('broken-characteristic');
          }
        }
      });
    });
  }

  private clearCharacteristicsFromBrokenBackground(): void {
    this.processChart.fullCharacteristics.forEach((characteristic: Characteristic) => {
      const characteristicDescribeElement = document.getElementById(`characteristic${characteristic.id}`);
      if (characteristicDescribeElement) {
        characteristicDescribeElement.classList.remove('broken-characteristic');
      }
    });
  }

  public toggleProcessChart(): void {
    if (this.firstPartNumberAdded) {
      this.processChartOpen = !this.processChartOpen;
    } else {
      this.helperService.notificationTrigger(NotifierType.Warn, this.componentsTranslation.processChart_selectPartNumberWarning);
    }
  }

  public addPartNumber(partNumber: string): void {
    if (this.viewType === ProcessChartViewType.LocationScreen) {
      const chosenPnIndex = this.pnOptions.findIndex(option => option.value === partNumber);
      this.pnOptions[chosenPnIndex].chosen = !this.pnOptions[chosenPnIndex].chosen;
      this.setChosenPartNumbers();
      this.checkIsSelectedPNIsExist();
      this.optionChosenFirst = true;
      this.locationScreenPartNumber = partNumber; // Math.floor((Math.random() * 1000000) + 1);
    } else {
      this.partNumber = partNumber;
      this.filterCharacteristicsByPart();
      this.isPartNumberChosen = true;
    }
  }

  private setChosenPartNumbers(): void {
    const newChosenPartNumbersArr: PnOptions[] = [];
    this.pnOptions.forEach((PN) => {
      if (PN.chosen) {
        newChosenPartNumbersArr.push(PN);
        if (this.viewType === ProcessChartViewType.LocationScreen && !this.isPartNumberChosen) {
          this.selectPartNumber(PN.value);
        }
      }
    });
    this.chosenPartNumbers = [...newChosenPartNumbersArr];
  }

  public selectPartNumber(partNumber: string): void {
    this.partNumber = partNumber;
    this.filterCharacteristicsByPart();
    this.isPartNumberChosen = true;
  }

  private checkIsSelectedPNIsExist(): void {
    if (this.partNumber) {
      this.partNumber = this.chosenPartNumbers
        .find(chosenPartNumber => chosenPartNumber.value === this.partNumber)
        .value;
    }
  }

  private filterCharacteristicsByPart(): void {
    this.filteredCalculatedCharacteristics = [];
    this.filteredCharacteristics = [];
    const PN = String(this.partNumber);
    this.processChart.fullCalculatedCharacteristics.forEach((calculatedCharacteristic: CalculatedCharacteristic) => {
      if (calculatedCharacteristic.partId === PN) {
        this.filteredCalculatedCharacteristics.push(calculatedCharacteristic);
      }
    });
    this.processChart.fullCharacteristics.forEach((characteristic: Characteristic) => {
      if (characteristic.partNumbers.some(part => String(part.partNumber) === PN)) {
        this.filteredCharacteristics.push(characteristic);
      }
    });
    this.getCells();
  }

  public addReactionPlan(row: ProcessChartRows, characteristic: Characteristic | CalculatedCharacteristic): boolean {
    if (this.userAccessBlocked || !characteristic.enabled || this.disableProcessChart) {
      return;
    }

    this.characteristic = characteristic;
    let reactionId = null;
    if (this.setStyleCell(row, characteristic)) {
      row.cells.forEach((cell: ProcessChartRowsCell) => {
        if (characteristic.id === cell.entityId) {
          reactionId = cell.reactionPlanId;
        }
      });
    }

    if (reactionId === 0 || reactionId === null || characteristic.calculationString) {
      return false;
    }

    this.isLoading = true;
    this.processChartApiService.getReactionPlan(reactionId).then((reactionPlan: ReactionPlan) => {
      this.isLoading = false;
      if (!reactionPlan.completedPlan && this.viewType === ProcessChartViewType.ProcessChart) {
        this.helperService.notificationTrigger(NotifierType.Warn, this.componentsTranslation.processChart_cantEditReactionPlanWarning);
      } else {
        this.reactionPlanComponent.showModal(reactionPlan);
      }
    });

    return false;
  }

  public setStyleCell(row: ProcessChartRows, characteristic: Characteristic | CalculatedCharacteristic): any {
    if (characteristic.calculationString) {
      const background = characteristic.enabled
        ? this.processChartColors.calculatedCharacteristic
        : this.processChartColors.calculatedCharacteristic_disabled;

      return { 'background': background };
    }

    let styles = null;

    if (this.viewType === ProcessChartViewType.LocationScreen) {
      if (row.notRunning) {
        styles = {
          'cursor': row.notRunning
            ? 'not-allowed'
            : 'pointer'
        };
      }
    }

    row.cells.forEach((cell: ProcessChartRowsCell) => {
      if (characteristic.id === cell.entityId) {
        if (cell.ruleBroken) {
          styles = {
            ...styles,
            'background': cell.reactionPlanCompleted
              ? this.processChartColors.fulfilled_broken_characteristic_cell
              : this.processChartColors.broken_characteristic,
            'cursor': characteristic.enabled
              ? 'pointer'
              : 'not-allowed',
          };
        }
        if (characteristic instanceof Characteristic && characteristic.gageType === GaugeType.Attribute) {
          styles = {
            ...styles,
            'cursor': 'pointer',
            'padding': '0!important',
          };
        } else {
          styles = {
            ...styles,
            'padding': '5px',
          };
        }
      }
      if (!characteristic.enabled) {
        styles = {
          ...styles,
          'cursor': 'not-allowed',
          'color': this.processChartColors.characteristic_disabled_text,
        };
      }
      if (this.disableProcessChart) {
        styles = {
          ...styles,
          'cursor': 'not-allowed',
        };
      }
    });
    return styles;
  }

  // TODO: Allow to edit sample from process chart table view
  public editSampleCollection(characteristic: Characteristic | CalculatedCharacteristic, row: ProcessChartRows, attributeValue: number): void {
    if (!this.isEditSampleActionBlocked || row.notRunning || !characteristic.enabled || this.disableProcessChart
      || characteristic.calculationString || this.viewType === ProcessChartViewType.ProcessChart) {
      return;
    }
    // if (this.setCellValue(row, characteristic) === AttributeSampleValue.NR && attributeValue === undefined) {
    // }
  }

  public setCellValue(row: ProcessChartRows, characteristic: Characteristic): number {
    const nrValue = AttributeSampleValue.NR;

    if (row.notRunning) {
      return nrValue;
    }

    let returnValue: number = nrValue;
    const typeOfCharacteristic = characteristic.calculationString
      ? 'CalculatedCharacteristic'
      : 'Characteristic';

    row.cells.forEach((cell: ProcessChartRowsCell) => {
      if (cell.cellType === typeOfCharacteristic && characteristic.id === cell.entityId && cell.value !== null) {
        const decimals = characteristic.decimals || 2;
        returnValue = HelpersService.round(cell.value, decimals, 'ceil');
      }
    });

    return returnValue;
  }

  public setBackgroundAndCursor(characteristic: Characteristic, mainTr: boolean, severityCell: boolean, frequency: boolean): any {
    const calculationCharacteristicBackground = {
      'background': this.processChartColors.calculatedCharacteristic,
    };
    const calculationCharacteristicBackgroundDisabled = {
      'background': this.processChartColors.calculatedCharacteristic_disabled,
    };
    const severityCellBackground = {
      'background': this.processChartColors.severity_cell,
    };

    if (characteristic.calculationString && !frequency && !severityCell) {
      return characteristic.enabled
        ? calculationCharacteristicBackground
        : calculationCharacteristicBackgroundDisabled;
    } else if (!characteristic.calculationString && severityCell && characteristic.severity) {
      return severityCellBackground;
    }
  }

  public changeLeftSide(): void {
    this.leftSideFullWidth = !this.leftSideFullWidth;
  }

  public changeRightSide(): void {
    this.rightSideFullWidth = !this.rightSideFullWidth;
  }

  public changeRouteToCharacteristic(characteristic: Characteristic): void {
    const urlPrefix = characteristic.calculationString
      ? '/process-chart/calculated-characteristic/'
      : '/characteristic/view/';

    this.router.navigateByUrl(urlPrefix + characteristic.id).then();
  }

  public closeAddSampleModal(): void {
    this.openAddSampleModal = false;
  }

  public setQuantity(): void {
    this.userSettings.setRowQuantity(this.quantityRows);
  }

  public addSamplesModal(): void {
    if (this.isAddSampleActionBlocked) {
      return;
    }
    this.openAddSampleModal = true;
  }

  public transformDate(date: Date): string {
    const newDate = new Date(date);

    return newDate.toString();
  }

  public transformTime(date: Date): any {
    const newDate = new Date(date);
    const offset = newDate.getTimezoneOffset() / 60;
    const hours = newDate.getHours();
    newDate.setHours(hours - offset);

    return newDate;
  }
}
