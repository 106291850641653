import {Translation} from '@models/translation';

// tslint:disable:max-line-length
export class PolishTranslation {
  public static readonly translation: Translation = {
    type: `polish`,
    global: {
      addSampleBtn: `Dodaj pomiar`,
      afterChangeoverOptions: `Po przezbrojeniu`,
      afterCorrectionOptions: `After Corrections`,
      afterMaintenanceInterventionOptions: `Po interwencji UR`,
      afterMealOptions: `After Meal`,
      afterModelChangeOptions: `After Model Change`,
      afterProcessChangeOptions: `Po zmianie procesu`,
      cancelBtn: `Anuluj`,
      clearBtn: `clear`,
      closeBtn: `Zamknij`,
      confirmButtonText: `Tak`,
      confirmDelete: `Tak, skasuj!`,
      dataDeletedMsgTitle: `Skasowano!`,
      dataEditedMsg: `Dane były edytowane`,
      dataLostMsg: `Czy jesteś pewien? Dane zostaną utracone`,
      dataLostTextUndo: `Nie będziesz mógł tego cofnąć `,
      dataMismatch: `Some Part Numbers no longer exist in the Masterdata.`,
      dataRestored: `Dane zostały odzyskane`,
      dataSaved: `Dane zostały zapisane`,
      editCharacteristicBtn: `Edytuj Charakterystykę`,
      empty: `Puste`,
      emptyAttribute: `Pusty`,
      enterBtn: `Enter`,
      entries: `wpisy`,
      errorFetchMsg: `Dane nie zostały pobrane`,
      errorOccurred: `Pojawił się błąd`,
      every2HoursOptions: `Co dwie godziny`,
      every4HoursOptions: `Co cztery godziny`,
      every6HoursOptions: `Co sześć godzin`,
      everyHourOptions: `Co Godzinę`,
      excelExportBtn: `Eksport do Excela`,
      failAttribute: `Zły`,
      isRequired: `Value is required`,
      lclFullName: `Lower Control Limit`,
      ldcFullName: `Lower Deviation Constant`,
      lostChangesStatement: `Czy jesteś pewien? Zmiany nie będą zapisane`,
      lslFullName: `Lower Specification Limit`,
      midOfShiftOptions: `Środek Zmiany`,
      modalTitleSelectLocation: `Wybierz lokalizację`,
      nameLabel: `Name`,
      notRunningAttribute: `N/R`,
      oncePerDayOptions: `Once Per Day`,
      oncePerShiftOptions: `Once Per Shift`,
      passedAttribute: `Dobry`,
      refreshBtn: `Odśwież`,
      removeMsg: `Nie będzie można odwrócić zmiany`,
      removeMsgTitle: `Czy jesteś pewien ?`,
      reset: `Reset`,
      ruleEightDescription: `Cztery z pięciu punktów > odchylenie standardowe (po tej samej stronie)`,
      ruleElevenDescription: `Group out of the Nominal Range`,
      ruleFiveDescription: `Sześć  kolejnych punktów rosnąco/malejąco`,
      ruleFourDescription: `Siedem kolejnych punktów po tej samej stronie linii centralnej`,
      ruleNineDescription: `Piętnaście punktów z rzędu w obrębie jednego odchylenia standardowego od linii środkowej (z każdej strony)`,
      ruleOneDescription: `Grupa poza limitem specyfikacji`,
      ruleSevenDescription: `Dwa z trzech punktów > 2 odchylenia standardowe (po tej samej stronie)`,
      ruleSixDescription: `Czternaście punktów z rzędu, naprzemiennie w górę i w dół`,
      ruleTenDescription: `Osiem punktów z rzędu> jedno standardowe odchylenie od linii środkowej (z każdej strony)`,
      ruleThreeDescription: `Jeden punkt poza 3 odchyleniami standardowymi `,
      ruleTwoDescription: `Grupa poza limitem kontrolnym, ale w specyfikacji`,
      ruleTwelveDescription: `Seven points in a row increasing or decreasing`,
      ruleThirteenDescription: `Seven points in a row between the mean and one of the control limits (on one side)`,
      saveBtn: `Zapisz`,
      searchBtn: `Search`,
      show: `Pokaż`,
      showEmptyLocationLabel: `Pokaż puste lokalizacje`,
      sslFullName: `Single Specification Limit`,
      startOfShiftOptions: `Początek zmiany `,
      submitBtn: `Potwierdź `,
      toolChangeOptions: `Zmiana Narzędzia`,
      uclFullName: `Upper Control Limit`,
      udcFullName: `Upper Deviation Constant`,
      uslFullName: `Upper Specification Limit`,
      noPartNumber: `no part number available`
    },
    characteristics: {
      addStringToCharaNameAfterCopy: ` – copy`,
      attribute: `Atrybut`,
      characteristicIsInPartExist: `Charakterystyka dla tej części istnieje`,
      characteristicNameIsExist: `Charakterystyka o tej nazwie już istnieje`,
      characteristicType: `Characteristic type`,
      characteristicView: `Widok Charakterystyki`,
      chartDefinition: `Definicja wykresu`,
      chartType: `Typ Wykresu`,
      chartTypeIMR: `I-MR (Lokalizacja, Zakres)`,
      chartTypeWithout: `Bez Wykresu`,
      chartTypeXR: `XR (Lokalizacja, Zakres)`,
      cp: `Cp:`,
      cpk: `Cpk:`,
      dataInput: `Wprowadzanie danych`,
      dateStampLabel: `Data/czas`,
      description: `Opis`,
      disabledCharacteristicWarning: `Jeśli wyłączysz tę charakterystykę , wszystkie charakterystyki, które ją wykorzystują, również będą wyłączone. Chcesz kontynuować ?`,
      enabledCharacteristic: `Charakterystyka włączona`,
      enterUniqueName: `Wpisz unikatową nazwę charakterystyki`,
      excludeReasons: `Exclude reasons`,
      fixtureAddTooltip: `Add fixture`,
      fixtureApplyToAll: `Apply fixture to all`,
      fixtureDeleteTooltip: `Delete fixture`,
      fixtureHeaderLabel: `Fixture`,
      fixtureHeaderNameLabel: `Name: `,
      fixtureHeaderNumberLabel: `Number: `,
      fixtureNoOptionsString: `No options to pick`,
      fixtureRemoveFromAll: `Remove fixture from all`,
      fixtureSelectTitle: `Fixtures to select`,
      formLabelMax: `Max`,
      formLabelMin: `Min`,
      frequency: `Częstotliwość`,
      frequencySelectPlaceholder: `Select frequency`,
      characteristicSampleReasonValidation: `Wymagana co najmniej jedna opcja: \`Początek zmiany\`, \`Co godzinę\`, etc. `,
      gaugeType: `Typ Gauge'a`,
      general: `Ogólny`,
      graphFiltering: `Graph filtering`,
      lcl: `LCL`,
      ldc: `LDC`,
      locationChartTitle: `Lokalizacja`,
      lsl: `DGS`,
      manualControlLabel: `Manual Control`,
      maxLimitSslOption: `Max limit`,
      maxLimitValidationFirstPart: `Maksymalny limit nie może być większy`,
      maxLimitValidationSecondPart: `Maksymalny limit nie może być mniejszy`,
      minLimitSslOption: `Min limit`,
      minLimitValidationFirstPart: `Minimalny limit nie może być większy `,
      minLimitValidationSecondPart: `Minimalny limit nie może być mniejszy`,
      minMaxValidation: `Wartość minimum nie może być większa lub równa maksymalnej`,
      modelPartNumbers: `Model Part Numbers`,
      multiPart: `Nr Części  # `,
      name: `Nazwa`,
      nominalMinus: `Nominal -`,
      nominalOnOff: `Nominal (On/Off)`,
      nominalPlus: `Nominal +`,
      nominalValue: `Nominal Value`,
      oneSidedChartBorderTypeLabel: `One-sided chart border type`,
      oneSidedChartBorderTypeFirstOption: `LimitConfig`,
      oneSidedChartBorderTypeFirstOptionTooltip: `Chart will be limited to specification limit and control limit by default.`,
      oneSidedChartBorderTypeSecondOption: `MinMax`,
      oneSidedChartBorderTypeSecondOptionTooltip: `Chart will be limited to specification limit and an outlier sample value by default.`,
      part: `Numer Części / Część `,
      pickLocationBtn: `Wybierz lokalizację dla charakterystyki`,
      pp: `Pp:`,
      ppk: `Ppk:`,
      precision: `Precyzja/ Dokładność?`,
      promptLabel: `Wyświetl`,
      qclCode: `kod QCL`,
      qclCodeFormFive: `CL5`,
      qclCodeFormFour: `CL4`,
      qclCodeFormNone: `Nie wybrano? / Żaden/ ND/NA`,
      qclCodeFormOne: `CL1`,
      qclCodeFormThree: `CL3`,
      qclCodeFormTwo: `CL2`,
      rangeChartTitle: `Zakres`,
      requiredGaugeDescription: `Wymagany # Gauge /opis `,
      rule: `Reguła`,
      rules: `Reguły `,
      samples: `Próbki`,
      samplesQuantityLabel: `Ilość próbek`,
      search: `Szukaj `,
      selectRules: `Select rules`,
      serialNumberTableLabel: `Serial Number`,
      settingsAlerting: `Alerty`,
      settingsAlertingGroups: `Grupa Alertowa`,
      settingsPart: `Part #`,
      partNumbers: `Part Numbers`,
      partNumbersSelectPlaceholder: `Select part number(s)`,
      settingsQualitySignOff: `Zgoda Jakości`,
      settingsSerial: `Nr seryjny #`,
      settingsSupervisorSignOff: `Zgoda Lidera/ przełożonego?`,
      settingsTitle: `Panel Ustawień`,
      severityLabel: `Severity 9/10`,
      showOnChart: `Pokaż na wykresie`,
      specLimitFirstOption: `One sided`,
      specLimitLabel: `Spec limit type`,
      specLimitSecondOption: `Two sided`,
      ssl: `SSL`,
      sslLimitType: `Limit type`,
      tableCp: `Cp`,
      tableCpk: `Cpk`,
      tableDateCreated: `Data/Czas`,
      tableDeviationTooltip: `Deviation value`,
      tableLsl: `DLK`,
      tableMean: `Średnia`,
      tableMeanCenterLine: `Średnia Ogólna`,
      tableNo: `Nie wybrano`,
      tableNumberOfSamples: `Próbki`,
      tableOperatorInitials: `Inicjały operatora`,
      tablePartNumber: `Part number`,
      tableRange: `Zakres`,
      tableRangeCentralLine: `R-bar`,
      tableRangeCenterLineMr: `mR-bar`,
      tableSampleValue: `Wartość `,
      tableShorthandDeviation: `D`,
      tableUsl: `GLK`,
      tableValue: `Wartość `,
      targetCpk: `Docelowe Cpk`,
      trendLine: `Trend Line`,
      ucl: `UCL`,
      uclLclValidation: `UCL should be greater than LCL`,
      udc: `UDC `,
      unit: `Jednostka`,
      usl: `GGS`,
      uslLslValidation: `GGS musi być większy niż DGS`,
      variable: `Zmienna`
    },
    administration: {
      partsList: `Part list`,
      partAddBtn: `Add part`,
      partNo: `Part No`,
      partEnabled: `Enabled`,
      partName: `Part Name`,
      partNumber: `Part Number`,
      partDescription: `Description`,
      partUsed: `Part Used`,
      partModel: `Part Model`,
      partGrossCount: `Gross Count`,
      partPPC: `PPC`,
      partEditTooltip: `Edit part`,
      partDeleteTooltip: `Delete part`,
      partTitle: `Part`,
      reactionPlanReasonList: `Reaction plan reason list`,
      reactionPlanReasonAddBtn: `Add reaction plan reason`,
      reactionPlanReasonName: `Reaction Plan Reason Name`,
      reactionPlanReasonType: `Type`,
      reactionPlanReasonIsActive: `Active`,
      reactionPlanReasonEnabled: `Enabled`,
      reactionPlanEditTooltip: `Edit reaction plan`,
      reactionPlanDeleteTooltip: `Delete reaction plan`,
      reactionPlanIsUsedTooltip: `Reaction plan is used`,
      reactionPlanTitle: `Reaction plan`,
      reactionPlanNotificationDeleted: `Reaction plan has been deleted`,
      sampleReasonList: `Sample reason list`,
      sampleReasonAddBtn: `Add sample reason`,
      sampleReasonName: `Sample Reason Name`,
      sampleReasonType: `Type`,
      sampleReasonIsDefault: `Default`,
      sampleReasonEditTooltip: `Edit sample reason`,
      sampleReasonDeleteTooltip: `Delete sample reason`,
      sampleReasonIsUsedTooltip: `Sample reason is used`,
      sampleReasonTitle: `Sample reason`,
      sampleReasonNotificationDeleted: `Sample reason has been deleted`,
      addFixtureBtn: `Add Fixture`,
      addUnitBtn: `Dodaj jednostkę`,
      addUserBtn: `Dodaj użytkownika`,
      addMappingBtn: `Add Mapping`,
      adminPermissionsAlert: `Admin permissions can not be changed`,
      descriptionBlankWarning: `Opis nie może być pusty`,
      fixtureDeleteTooltip: `Delete fixture`,
      fixtureEditTooltip: `Edit fixture`,
      fixtureIsUsedTooltip: `Fixture is used`,
      fixtureList: `Fixture list`,
      fixtureName: `Fixture name`,
      fixtureNotificationDeleted: `Fixture has been deleted`,
      fixtureTitle: `Fixture`,
      fixtureValidationNameIsTaken: `An error occurred - probably name is already taken`,
      idleLogoutMessage: `Automatyczne wylogowanie po bezczynności  (min)`,
      idleLogoutMessagePlantUser: `Automatyczne wylogowanie po bezczynności użytkownika Plant`,
      partModelAddModel: `Add Model`,
      partModelDeleteModelTooltip: `Delete model`,
      partModelEditModelTooltip: `Edit model`,
      partModelName: `Model name`,
      partModelUsed: `Model is used`,
      partModels: `Part models`,
      partModelParts: `Parts`,
      partMappingIsUsed: `Part Mapping is in use`,
      partMappingDelete: `Delete Part Mapping`,
      partMappingEdit: `Edit Part Mapping`,
      nameBlankWarning: `Nazwa nie może być pusta`,
      permissionsMatrix: `Matryca Uprawnień`,
      recycleBinCharacteristicDeleteTooltip: `Remove characteristic permanently`,
      recycleBinCharacteristicName: `Characteristic Name`,
      recycleBinCharacteristicUsed: `Characteristic is used`,
      recycleBinDataRecycled: `Date Recycled`,
      recycleBinLocationScreenDeleteTooltip: `Remove location screen permanently`,
      recycleBinLocationScreenName: `Location Screen Name`,
      recycleBinLocationScreenUsed: `Location Screen is used`,
      recycleBinNotificationCharacteristicDeleted: `Characteristic has been deleted`,
      recycleBinNotificationLocationScreenDeleted: `Location screen has been deleted`,
      recycleBinNotificationProcessChartDeleted: `Process chart has been deleted`,
      recycleBinProcessChartDeleteTooltip: `Remove process chart permanently`,
      recycleBinProcessChartName: `Process Chart Name`,
      recycleBinProcessChartUsed: `Process Chart is used`,
      recycleBinRecycledBy: `Recycled By`,
      recycleBinRestoreCharacteristicTooltip: `Restore characteristic`,
      recycleBinRestoreLocationScreenTooltip: `Restore location screen`,
      recycleBinRestoreProcessChartTooltip: `Restore process chart`,
      recycleBinTitle: `Recycle Bin`,
      recycleBinTypeLabel: `Recycle bin type`,
      recycleBinUserName: `Username`,
      recycleBinUserDeleteTooltip: `Remove user permanently`,
      recycleBinNotificationUserDeleted: `User has been deleted`,
      recycleBinRestoreUserTooltip: `Restore user`,
      recycleBinUserUsed: `User is used`,
      roleListDeleteMessage: `Rola użytkownika została skasowana`,
      roleListDeleteTooltip: `Delete role`,
      roleListEditTooltip: `Edit role`,
      roleListIsUsedTooltip: `Role is used`,
      roleListNameIsTakenMsg: `Błąd - nazwa prawdopodobnie już w użyciu`,
      roleListTableActions: `Actions`,
      roleListTableDescription: `Opis`,
      roleListTableName: `Nazwa`,
      ruleSettingTableName: `Nazwa`,
      ruleSettingTableValue: `Wartość`,
      ruleSettingModalHeader: `Rule Setting`,
      ruleSettingModalRuleLabel: `Select Rule`,
      ruleSettingAddSettingBtn: `Add Rule Setting`,
      ruleSettingListHeader: `Rule Settings List`,
      settings: `Ustawienia`,
      ruleManagement: `Rule Management`,
      settingsCleanDataBtnLabel: `Automatycznie usuwaj dane starsze niż 1 rok.`,
      showLabel: `Pokaż`,
      unitDeleteTooltip: `Delete unit`,
      unitEditTooltip: `Edit unit`,
      unitIsUsedTooltip: `Unit is used`,
      unitLists: `Lista jednostek`,
      name: `Nazwa`,
      unitNotificationUnitDeleted: `Jednostka usunięta`,
      unitShortName: `Nazwa skrócona `,
      unitTitle: `Jednostka`,
      unitValidationNameBlank: `Nazwa nie może być pusta`,
      unitValidationNameIsTaken: `Błąd - skrót jest już w użyciu`,
      unitValidationShortNameBlank: `Nazwa skrótu nie może byc pusta`,
      userFileFormatWarning: `File format is incorrect, please make sure that You are using jpg/jpeg/img/png format`,
      userFileSizeWarning: `Image size is too big, max size is 3 MB, use smaller photo`,
      userListAddMasterDataBtn: `Dodaj dane z mastera`,
      userListDeleteTooltip: `Delete user`,
      userListEditTooltip: `Edit user`,
      userListIsUsedTooltip: `User is used`,
      userListNotificationAccDeleted: `Konto użytkownika skasowane`,
      userListNotificationCantDelAcc: `Nie możesz usunąć swojego konta`,
      userListNotificationUserNameIsUsed: `Błąd - taki użytkownik istnieje`,
      userListNotificationUserSaved: `Użytkownik zapisany`,
      userListTitle: `Lista użytkowników`,
      userModalActiveLabel: `Aktywny`,
      userModalAddPhotoBtn: `Dodaj`,
      userModalAddLocationBtn: `Dodaj Lokację`,
      userModalClearRoleBtn: `Clear`,
      userModalAddUserByPlantBtn: `Dodaj użytkownika do zakładu`,
      userModalAddedRolesLabel: `Role dodane do zakładu`,
      userModalChangePhotoBtn: `Zmień`,
      userModalChosePlantTitle: `Wybierz przypisanie do zakładu`,
      userModalConfirmPasswordLabel: `Potwierdź hasło`,
      userModalConfirmPasswordValidationBlank: `Potwierdzenie hasła nie może być puste`,
      userModalConfirmPasswordValidationDifferent: `Hasła nie mogą być różne`,
      userModalEditFirstNameValidation: `Imię nie może być puste`,
      userModalEditNameValidation: `Nazwa użytkownika nie może byc pusta`,
      userModalEditTitle: `Użytkownik`,
      userModalEmailLabel: `email`,
      userModalEmailValidation: `Wprowadź poprawny email `,
      userModalFirstNameLabel: `Imię`,
      userModalLastNameLabel: `Nazwisko`,
      userModalLastNameValidation: `Nazwisko nie może być puste`,
      userModalLocationPathBtnChange: `Zmień ścieżkę lokalizacji`,
      userModalLocationPathBtnChoose: `Wybierz ścieżkę lokalizacji`,
      userModalLocationPathValidation: `Ścieżka zakładu nie może być pusta`,
      userModalMasterdataUsn: 'Masterdata Usn',
      userModalNextBtn: `Następny`,
      userModalPasswordLabel: `Hasło`,
      userModalPasswordValidationBlank: `Hasło nie może być puste`,
      userModalPasswordValidationDifferent: `Hasła nie mogą być różne`,
      userModalPlantLabel: `Zakład`,
      userModalRemoveBtn: `Usuń`,
      userModalRolesByPlantLabel: `Role w zakładzie`,
      userModalRolesByPlantLocationLabel: `Plant location`,
      userModalRolesLabel: `Role`,
      userModalRolesValidation: `role nie mogą być puste`,
      userModalTreeTitle: `Wybierz lokalizacje dla użytkownika`,
      userModalUserNameLabel: `Nazwa Użytkownika`,
      userSpecialMarkDefaultWarning: `The query is invalid. Please check the correct use of the following special characters: ! \` # $ % & ' ( ) * + , - . / \\ : ; < = > ? @ [ ] ^ _ { | } ~`,
      userSpecialMarkEmailWarning: `The query is invalid. Please check the correct use of the following special characters: ! \` # $ % & ' ( ) * + , / \\ : ; < = > ? [ ] ^ { | } ~`,
      userSpecialMarkPasswordWarning: `The query is invalid. Please check the correct use of the following special characters: = ? < > { }`,
      usersAddUserMasterdataModalTitle: `Wybierz użytkownika`,
      usersListColumnActive: `Aktywny`,
      usersListColumnEmail: `email`,
      usersListColumnFirstName: `Imię`,
      usersListColumnLastName: `Nazwisko`,
      usersListColumnLastLogin: `Ostatnie Logowanie`,
      usersListColumnUsername: `Nazwa użytkownika`,
      usersRolesAddUserBtn: `Dodaj rolę użytkownika`,
      usersRolesListHeader: `Lista ról użytkownika`,
      usersRolesModalHeader: `Rola użytkownika`,
      validationIdleLogoutMessageBlank: `Czas automatycznego wylogowania nie może być pusty`,
      validationIdleLogoutMessageLogoutTime: `Czas automatycznego wylogowania nie może być mniejszy niż 1`,
      valueBlankWarning: `Value is required`
    },
    auth: {
      signInTitle: `Zaloguj się`,
      errorInvalidGrant: `Sprawdź czy nazwa użytkownika i hasło są poprawne`,
      errorInvalidRequest: `Nie znaleziono użytkownika`,
      error: `Błąd`,
      passwordLabel: `Hasło`,
      signInBtn: `Zaloguj się`,
      usernameLabel: `Użytkownik`
    },
    layout: {
      passwordChangeModalChangePasswordBtn: `Zmień hasło`,
      passwordChangeModalConfirmPasswordLabel: `Potwierdź hasło`,
      passwordChangeModalConfirmPasswordValidation: `Hasło potwierdzenia nie może być puste`,
      passwordChangeModalDifferentPassValidation: `Hasła nie mogą być różne`,
      passwordChangeModalPasswordChanged: `Hasło zostało zmienione`,
      passwordChangeModalPasswordLabel: `Hasło`,
      passwordChangeModalPasswordValidation: `Hasło nie może być puste`,
      passwordChangeModalTitle: `Zmiana hasła`,
      userMenuOptionLogout: `Wylogowanie`,
      userMenuOptionSettings: `Ustawienia`
    },
    locationScreen: {
      createEnableLabel: `Ekran lokalizacji włączony`,
      createEnterNameLabel: `Wprowadź unikalną nazwę Ekranu Lokalizacji`,
      createLackOfProcessCharts: `Brak Kart Procesu`,
      createLocationScreenNameValidation: `Ekran Lokalizacji o tej nazwie już istnieje`,
      createNameLabel: `Nazwa`,
      createNameValidationMessage: `Ekran Lokalizacji o tej nazwie już istnieje`,
      createNewLocationScreenBtn: `Wybierz miejsce dla nowego Ekranu Lokalizacji`,
      createPickProcessChartLabel: `Wybierz karę procesu do tego Ekranu lokalizacji`,
      createProcessChartCheckboxLabel: `Karta Procesu`,
      createProcessChartListTitle: `Karta Procesu Istnieje na tym ekranie Lokacji`,
      createSelectLocation: `Wybierz Lokalizację`,
      locationScreenView: `Widok  Ekranu Lokalizacji`
    },
    processChart: {
      calculatedAttributeCharacteristicDisabled: `You can't add attribute Characteristic to Calculated Characteristic`,
      calculatedCreateCalculationString: `Wzór wyliczania`,
      calculatedCreateConstantValue: `Wartość stała`,
      calculatedCreateCorrectCompletedEdit: `edytuj`,
      calculatedCreateCorrectCompletedMsgPart1: `Czy chcesz`,
      calculatedCreateCorrectCompletedMsgPart2: `Wyliczyć Charakterystykę?`,
      calculatedCreateCorrectCompletedMsgTitle: `Wzór do wyliczania jest poprawny`,
      calculatedCreateCorrectCompletedSave: `zapisz`,
      calculatedCreateEnabledBtn: `Włącz wyliczaną charakterystykę`,
      calculatedCreateEnterName: `Wprowadź nazwę wyliczanej charakterystyki`,
      calculatedCreateIncludedCardHeader: `Charakterystyka istnieje na tej karcie procesu`,
      calculatedCreateLocationPath: `Ścieżka lokalizacji`,
      calculatedCreateMoreCharacteristicErrorMsg: `Co najmniej dwie charakterystyki (lub jedna i wartość stała) muszą być dodane`,
      calculatedCreateNameLabel: `Nazwa`,
      calculatedCreateNameValidation: `Ta nazwa jest zajęta`,
      calculatedCreateResetBtn: `Resetowanie`,
      calculatedCreateSelectOperation: `Wybierz Operację`,
      calculatedCreateWithoutNameErrorMsg: `Napisz nazwę charakterystyki wyliczanej`,
      calculatedCreateWithoutOperatorErrorMsg: `Operator musi być dodany`,
      createCalculatedCharacteristicsIsUsedWarning: `Wyliczana Charakterystyka zawiera charakterystykę użytą już w innej karcie procesu`,
      createCharacteristicIsUsedWarning: `Charakterystyka jest już używana na innej karcie Procesu`,
      createCheckboxCharacteristicToSelect: `Charakterystyka`,
      createCheckboxCharacteristicToSelectPN: ` - Numer Części `,
      createDataHaveBeen: `Dane zostały`,
      createEditedData: `Dane zostały edytowane`,
      createEnableProcessChartCheckboxLabel: `Karta Procesu włączona`,
      createEnterUniqueName: `Wprowadź unikalną nazwę Karty procesu`,
      createIncludedCharacteristicsCartTitle: `Character's zawarta w tej karcie procesu`,
      createLackOfCharacteristics: `Brak Charakterystyk`,
      createMaxCharacteristicsWarning: `Dodałeś maksymalną ilość (20) charakterystyk`,
      createNameLabel: `Nazwa`,
      createNameValidation: `Karta Procesu o tej nazwie już istnieje`,
      createNewCalculatedCharacteristicsBtn: `Nowa wyliczana charakterystyka `,
      createPickCalculatedCharacteristicsLabel: `Wybierz i dodaj wyliczaną Charakterystykę do tej Karty Procesu`,
      createPickCharacteristicCardHeader: `Wybierz charakterystyki do tej Karty Procesu`,
      createPickLocationBtn: `Wybierz miejsce dla nowej Karty procesu`,
      createPickLocationModalTitle: `Wybierz lokalizację`,
      createSavedData: `Dane zostały zapisane`,
      createTeamCenterLabel: `Teamcenter Doc # / Rev`,
      processChartView: `Widok karty procesu`,
      processChartButtonWarningTooltip: `You need to select at least one characteristic and complete the form`
    },
    userSetting: {
      addPhotoBtn: `Dodaj`,
      cardHeader: `O mnie`,
      changePhotoBtn: `Zmień`,
      confirmMsgBtn: `Tak, zmień hasło`,
      confirmPasswordLabel: `Potwierdź nowe hasło`,
      confirmPasswordValidation: `Hasło nie może być puste`,
      currentPasswordLabel: `Obecne Hasło `,
      currentPasswordValidationBlank: `Hasło nie może być puste`,
      currentPasswordValidationIsIncorrect: `Hasło niepoprawne`,
      firstNameLabel: `Imię`,
      firstNameValidation: `Imię nie może być puste`,
      lastNameLabel: `Nazwisko`,
      lastNameValidation: `Nazwisko nie może być puste`,
      newPasswordLabel: `Nowe hasło`,
      newPasswordValidation: `Hasło nie może być puste`,
      passwordIncorrectMsg: `Obecne hasło niepoprawne`,
      passwordsValidationDifferent: `Hasła muszą być jednakowe`,
      removePhotoBtn: `Usuń`,
      userNameLabel: `Użytkownik`
    },
    components: {
      addPartMappingModal_modalHeader: `Part Mapping`,
      addPartMappingSelectLocationBtn: `Select Location`,
      addPartMappingSelectLocationNameExists: `Location name already exists`,
      addPartMappingSelectLocationNameUnique: `Location name must be unique`,
      addPartMappingCmmPartsPlaceholder: `Select CMM Parts for the mapping`,
      addPartMappingSubmitBtn: `Add Mapping`,
      addSampleCollection_excludeLabel: `Wyklucz z procesu i wyliczeń`,
      addSampleCollection_limitBrokenMsgMaxVal: `Maksymalna wartość:`,
      addSampleCollection_limitBrokenMsgMinVal: `Minimalna wartość:`,
      addSampleCollection_limitBrokenMsgTitle: `Wartość z poza zakresu `,
      addSampleCollection_limitBrokenMsgWantToContinue: `Czy jesteś pewien, że chcesz dodać wartość z poza limitów?`,
      addSampleCollection_notesLabel: `Notatki`,
      addSampleCollection_sampleEditedMsg: `Próbki zostały zmienione`,
      addSampleCollection_saveBtn: `Zapisz zmiany`,
      addSampleCollection_titleAddSamples: `Dodaj próbki`,
      addSampleCollection_titleEditSamples: `Edytuj próbki`,
      addSampleCollection_validationValueOver: `Wartość próbki nie może być większa niż`,
      addSampleCollection_validationValueUnder: `Wartość próbki nie może być mniejsza niż`,
      addSampleModal_addedSamplesMsg: `Próbki zostały dodane`,
      addSampleModal_addSampleBtn: `Dodaj`,
      addSampleModal_addSamplesError: `You need to add samples or set 'Not running'`,
      addSampleModal_cardTitleSamples: `Próbki`,
      addSampleModal_completeBtn: `UKOŃCZONO`,
      addSampleModal_modalHeader: `DODAJ PRÓBKĘ`,
      addSampleModal_notRunningLabel: `Nie Pracuje`,
      addSampleModal_operatorInitialLabel: `Inicjały Operatora`,
      addSampleModal_partNumberLabel: `Part number:`,
      addSampleModal_reasonLabel: `Przyczyna`,
      addSampleModal_remarksLabel: `Uwagi (opcjonalne)`,
      addSampleModal_serialNumberLabel: `Serial number`,
      addSampleCollection_subTitleSamples: `Próbki`,
      addSampleCollection_title: `Instrukcje`,
      chartOptions_trendLine: `Trend Line`,
      chartOptions_yAxisMin: `Y axis min`,
      chartOptions_yAxisMax: `Y axis max`,
      confirmUserPermission_emptyFieldsWarning: `Formularz musi być wypełniony`,
      confirmUserPermission_engineerPermissionWarn: `Użytkownik nie ma uprawnień inżyniera jakość`,
      confirmUserPermission_incorrectPasswordWarn: `Hasło niepoprawne lub użytkownik nie istnieje`,
      confirmUserPermission_passwordLabel: `Hasło`,
      confirmUserPermission_permissionConfirmedMsg: `Potwierdzono uprawnienia`,
      confirmUserPermission_qualitySupervisorPermissionWarn: `Użytkownik nie ma uprawnień kierownika`,
      confirmUserPermission_usernameLabel: `Nazwa Użytkownika`,
      filters_cardTitle: `Filtry`,
      filters_clearBtn: `Wyczyść `,
      filters_defaultOptionDept: `Dowolny departament`,
      filters_defaultOptionPlant: `Dowolny Zakład`,
      filters_departmentLabel: `Oddział/ Wydział ?`,
      filters_listViewBtn: `Widok Listy`,
      filters_plantLabel: `Zakład`,
      filters_cmmPartLabel: `CMM Part:`,
      filters_mdPartLabel: `MD Part:`,
      searchLabel: `Szukaj`,
      filters_showFavoriteLabel: `Pokaż ulubione`,
      filters_treeViewBtn: `Widok Drzewa`,
      meanChart_cardTitle: `Panel opcji`,
      meanChart_chartTextDateXaxis: `Data`,
      meanChart_chartTextLocationYaxis: `Lokalizacja`,
      meanChart_LCLLabel: `DLK`,
      meanChart_LSLLabel: `DGS`,
      meanChart_maxLimitOptions: `Limit type: Max limit`,
      meanChart_meanLabel: `Średnia`,
      meanChart_minLimitOptions: `Limit type: Min limit`,
      meanChart_nominalRange: `Nominal Range`,
      meanChart_partNumberTooltip: `Part number:`,
      meanChart_plotLineTextCentralLineLabelGrandMean: `Grand Mean`,
      meanChart_plotLineTextCentralLineLabelMean: `Średnia`,
      meanChart_plotLineTextLCLLabel: `DLK=`,
      meanChart_plotLineTextLSLLabel: `DGS=`,
      meanChart_plotLineTextRangeMinusLabel: `Range minus=`,
      meanChart_plotLineTextRangePlusLabel: `Range plus=`,
      meanChart_plotLineTextSSLLabel: `SSL=`,
      meanChart_plotLineTextUCLLabel: `GLK=`,
      meanChart_plotLineTextUSLLabel: `GGS=`,
      meanChart_ProcessAverageTooltip: `Process average:`,
      meanChart_recalcGroupPlotLine: `Przelicz na grupie`,
      meanChart_refreshChartTooltip: `Refresh location chart`,
      meanChart_RulesTooltip: `Rules:`,
      meanChart_SSLLabel: `SSL`,
      meanChart_SubgroupAverage: `Subgroup average:`,
      meanChart_ticksAmount: `Ilość linii wykresu`,
      meanChart_maximizeChart: `maximize chart`,
      meanChart_UCLLabel: `GLK`,
      meanChart_USLLabel: `GGS`,
      preControlChart_CentralLineLabel: `Central`,
      preControlChart_chartTextDateXaxis: `Date`,
      preControlChart_chartTextDateYaxis: `Location`,
      preControlChart_LCLLabel: `LCL`,
      preControlChart_LGZLabel: `LGZ`,
      preControlChart_Ticks: `Ticks`,
      preControlChart_Title: `Pre-Control`,
      preControlChart_UClLabel: `UCL`,
      preControlChart_UGZLabel: `UGZ`,
      processChart_cantEditReactionPlanWarning: `Plan reakcji może być edytowany tylko na ekranie lokalizacji`,
      processChart_dateColumnName: `Data`,
      processChart_frequencyShortVersionFourHr: `Co 4h`,
      processChart_frequencyShortVersionOneHr: `Co 1h `,
      processChart_frequencyShortVersionSixHr: `Co 6h`,
      processChart_frequencyShortVersionTwoHr: `Co 2h`,
      processChart_gageInstruction: `Postępuj zgodnie z instrukcją gauge`,
      processChart_headerInformation: `Do sprawdzenia. Zaraportuj odczyty poza specyfikacją do zespołu`,
      processChart_inspColumnName: `SPRAWDŹ`,
      processChart_machine: `MASZYNA`,
      processChart_partLabel: `# Części `,
      processChart_processChartNameLabel: `NAZWA KARTY PROCESU`,
      processChart_reasonColumnName: `POWÓD SPRAWDZENIA`,
      processChart_remarksColumnName: `UWAGI `,
      processChart_rowsQuantity: `Ilość wierszy`,
      processChart_selectPartNumberWarning: `Wybierz numer części `,
      processChart_severityCell: `SEV 9/10`,
      processChart_supervisorInitialsColumnName: `INICJAŁY KIEROWNIKA`,
      processChart_timeColumnName: `CZAS`,
      rangeChart_cardTitle: `Panel Opcji`,
      rangeChart_chartTextDateXaxis: `Data `,
      rangeChart_chartTitle: `Zakres`,
      rangeChart_LCLLabel: `DLK`,
      rangeChart_plotLineTextCentralLineLabelMrbar: `mR-bar`,
      rangeChart_plotLineTextCentralLineLabelRbar: `R-bar`,
      rangeChart_plotLineTextLCLLabel: `DLK=`,
      rangeChart_plotLineTextUCLLabel: `GLK=`,
      rangeChart_rangeTooltip: `Range:`,
      rangeChart_refreshChartTooltip: `Refresh range chart`,
      rangeChart_rulesTooltip: `Rules:`,
      rangeChart_ticksAmount: `Ilość linii`,
      rangeChart_UCLLabel: `GLK`,
      reactionPlan_actionTakenLabel: `Action Taken`,
      reactionPlan_calculationReset: `Calculation Reset`,
      reactionPlan_changeMachineParameterOption: `Changing machine parameters`,
      reactionPlan_characteristicDescription: `Opis Charakterystyki`,
      reactionPlan_characteristicName: `Nazwa Charakterystyki`,
      reactionPlan_cleanMachineOption: `Cleaning machine, device`,
      reactionPlan_completeBtn: `ZAKOŃCZONO`,
      reactionPlan_correctionOfDeviceOption: `Correction  of measurement device`,
      reactionPlan_correctiveActionLabel: `Corrective Action`,
      reactionPlan_dateLabel: `Data:`,
      reactionPlan_dateTimeViolation: `Data/ Czas Przekroczenia`,
      reactionPlan_deviationOption: `Deviation`,
      reactionPlan_interventionOption: `Maintenance intervention`,
      reactionPlan_lslLabel: `DGS:`,
      reactionPlan_machineAdjustmentOption: `Machine adjustment`,
      reactionPlan_materialChangeOption: `Material change`,
      reactionPlan_materialDisposition: `dyspozycja materiału`,
      reactionPlan_modalTitle: `PLAN REAKCJI `,
      reactionPlan_operationInput: `Wejścia operatora`,
      reactionPlan_operatorCommentLabel: `Komentarz operatora`,
      reactionPlan_operatorCommentValidationMessage: `Komentarz operatora nie może być pusty`,
      reactionPlan_operatorInitialsLabel: `Inicjały operatora`,
      reactionPlan_operatorInitialsValidationMessage: `Inicjały operatora nie mogą być puste`,
      reactionPlan_processStopped: `Proces zatrzymany //Zatrzymanie procesu? `,
      reactionPlan_qeFormInitialsValidationMsg: `Inicjały inżyniera jakości nie mogą być puste`,
      reactionPlan_qeFormLabel: `Inżynier jakości:`,
      reactionPlan_qeFormTitle: `AKCEPTACJA JAKOŚCI`,
      reactionPlan_qeLoginComponentTitle: `WERYFIKACJA JAKOŚCI`,
      reactionPlan_qualityInput: `Quality Input`,
      reactionPlan_qualitySignOff: `AKCEPTACJA JAKOŚCI`,
      reactionPlan_radioButtonCorrectedLabel: `Poprawiono`,
      reactionPlan_radioButtonDeviation: `Deviation`,
      reactionPlan_radioButtonHoldLabel: `Wstrzymany`,
      reactionPlan_radioButtonPassedLabel: `Przekazano na skład`,
      reactionPlan_radioButtonReworkLabel: `Rework`,
      reactionPlan_radioButtonScrapLabel: `Złom`,
      reactionPlan_radioButtonSortLabel: `Sortowanie`,
      reactionPlan_reactionPlanSavedMsg: `Plan reakcji zapisany`,
      reactionPlan_remarkLabel: `Uwagi:`,
      reactionPlan_repairDeviceOption: `Repair or replacement of measurement device`,
      reactionPlan_rule: `Przekroczone zasady/ Złamanie zasady`,
      reactionPlan_ruleBreakOverride: `Zmień kolor na czarny`,
      reactionPlan_rulesViolated: `Przekroczone zasady/ Złamanie zasady ?`,
      reactionPlan_severity: `SEVERITY 9/10`,
      reactionPlan_supervisorSignOff: `ZATWIERDZENIE KIEROWNIKA`,
      reactionPlan_svFormInitials: `Inicjały Kierownika:`,
      reactionPlan_svFormInitialsValidationMsg: `Inicjały kierownika nie mogą być puste`,
      reactionPlan_svFormTitle: `ZATWIERDZENIE KIEROWNIKA`,
      reactionPlan_svLoginComponentTitle: `WERYFIKACJA KIEROWNIKA`,
      reactionPlan_toolAdjustmentOption: `Tool adjustment`,
      reactionPlan_toolChangeOption: `Tool change`,
      reactionPlan_uslLabel: `GGS:`,
      reactionPlan_valueLabel: `Wartość:`,
      reactionPlan_verificationOfDeviceOption: `Verification of measurement device`,
      sampleReason_eventOption: `Event`,
      sampleReason_frequencyOption: `Frequency`,
      sampleReason_hourlyOption: `Hourly`,
      tableResult_cantDisabledCharacteristicMsg: `Nie można dodawać próbek do wyłączonych charakterystyk`,
      tableResult_characteristic: `Characteristic`,
      tableResult_itemNameControlCharts: `Wykres kontrolny`,
      tableResult_itemNameLocationScreen: `Ekran lokalizacji`,
      tableResult_itemNameProcessChart: `Karta Procesu `,
      tableResult_locationScreen: `Location Screen`,
      tableResult_maxFavoriteValidationPartOne: `Przekroczono maksymalną liczbę`,
      tableResult_maxFavoriteValidationPartTwo: `Przekroczono maksymalną liczbę ulubionych`,
      tableResult_moveToRecycleBinMsg: `This will go the Recycle Bin`,
      tableResult_multi: `Multi`,
      tableResult_noPermissionToAddSamplesMsg: `Brak uprawnień do dodawania próbek`,
      tableResult_processChart: `Process Chart`,
      tableResult_tableActions: `Akcje`,
      tableResult_tableCreator: `Stworzył`,
      tableResult_tableLastModified: `Ostatnio modyfikowane`,
      tableResult_tableName: `Nazwa`,
      tableResult_tableNamePath: `Nazwa / Ścieżka`,
      tableResult_tablePart: `Część`,
      tableResult_tablePath: `Ścieżka `,
      tableResult_tooltipAddData: `Dodaj dane`,
      tableResult_tooltipAddToFavorite: `Dodaj do ulubionych`,
      tableResult_tooltipCopy: `Kopiuj`,
      tableResult_tooltipEdit: `Edycja`,
      tableResult_tooltipRemoveFavorite: `Usuń z ulubionych`,
      tableResult_tooltipView: `Widok`,
      tableResult_tooltipCMMCharacteristic: `Can't add data to a CMM Characteristic`,
      tableResult_typeToValidationMaxFavoriteCharacteristics: `Charakterystyki`,
      tableResult_typeToValidationMaxFavoriteLocationScreen: `Ekrany Lokalizacji`,
      tableResult_typeToValidationMaxFavoriteProcessChart: `Karty procesu`,
      variableChart_trendLine: `Trend Line`
    },
    menu: {
      administration: `Administracja`,
      administrationFixtures: `Fixtures`,
      administrationPartModels: `Part Models`,
      administrationParts: `Parts`,
      administrationPartMapping: `Part Mapping`,
      administrationPermissions: `Uprawnienia`,
      administrationReactionPlanReasons: `Reaction Plan Reasons`,
      administrationRecycleBin: `Recycle Bin`,
      administrationRoles: `Role użytkownika`,
      administrationRuleSettings: `Rule Settings`,
      administrationSampleReasons: `Sample Reasons`,
      administrationSettings: `Ustawienia`,
      administrationUnits: `Jednostki`,
      administrationUsers: `Użytkownicy`,
      characteristic: `Charakterystyka`,
      characteristicCreate: `utwórz`,
      characteristicView: `wyświetl `,
      cmmData: `CMM Data`,
      cmmDataRunChart: `run chart`,
      cmmDataView: `view`,
      dashboard: `Tablica`,
      locationScreen: `Ekran lokalizacji`,
      locationScreenCreate: `stwórz`,
      locationScreenView: `wyświetl`,
      processChart: `Karta Procesu`,
      processChartCreate: `stwórz`,
      processChartView: `wyświetl `,
      reports: `Raporty`,
      user: `Użytkownik`
    },
    dashboard: {
      controlChardHideState: `control charts has been hidden`,
      itemsPerPageSelect: `Items per page`,
      switchLabelCompactView: `widok kompaktowy`
    },
    CMMTranslation: {
      runChartChartLslPlotLine: `LSL:`,
      runChartChartSerieName: `Run chart`,
      runChartChartTitle: `Run Chart`,
      runChartChartTooltipValue: `Value:`,
      runChartChartUslPlotLine: `USL:`,
      runChartChartXAxisName: `Timestamp`,
      runChartChartYAxisName: `Value`,
      runChartFiltersCharacteristicPlaceholder: `Characteristic`,
      runChartFiltersDateRangePlaceholder: `Date Range`,
      runChartFiltersDepartmentPlaceholder: `Department`,
      runChartFiltersDevicePlaceholder: `Device`,
      runChartFiltersMachinePlaceholder: `Machine`,
      runChartFiltersPartNumberPlaceholder: `Part Number`,
      runChartFiltersPlantPlaceholder: `Plant`,
      runChartFiltersMeasurementPlanPlaceholder: `Measurement Plan`,
      runChartFiltersPlotGraphBtn: `Plot Graph`,
      runChartFiltersResetBtn: `Reset filters`,
      runChartModalBackButton: `Back to details`,
      runChartModalDetailsMachine: `Machine:`,
      runChartModalDetailsMeasurement: `Measurement Type:`,
      runChartModalDetailsPN: `PN:`,
      runChartModalDetailsTimestamp: `Timestamp:`,
      runChartModalTableCharacteristic: `Characteristic`,
      runChartModalTableTitle: `Measurements`,
      runChartModalTableValue: `Value`,
      runChartModalTitle: `Reports Details`,
      runChartModalViewPdfBtn: `View Report PDF`,
      runChartReportListTitle: `Reports`,
      runChartTableColumnTimeStamp: `Timestamp`,
      runChartTableColumnValue: `Value`,
      runChartTableValueListTitle: `Values`,
      viewCharacteristicsCardTitle: `Characteristics`,
      viewCharacteristicsTableComments: `Comments`,
      viewCharacteristicsTableName: `Name`,
      viewCharacteristicsTableValue: `Value`,
      viewCmmCardCMM: `CMM-`,
      viewCmmCardLab: `Lab`,
      viewCmmCardPlanta: `plant:`,
      viewCmmCardReports: `Shift reports`,
      viewCmmGetDevicesTooltip: `Pick plant and time interval`,
      viewCmmPlantsChoiceLabel: `Plants to choice`,
      viewCmmTimeIntervalLabel: `Pick time interval`,
      viewGetDevicesBtn: `Get Devices`,
      viewPlantCardTitle: `Plants`,
      viewReportCardTitle: `Current Shift Reports`,
      viewReportCardViewLink: `View`
    }
  };
}
