import {AfterViewInit, Component, OnChanges, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ChartService} from '@shared/charts/chart.service';
import {TranslationService} from '@services/translation.service';
import {BaseChartComponent, ChartData, SeriesType} from '@shared/charts/base-chart/base-chart.component';
import {DataPoint} from '@shared/charts/sample-point';
import {SeriesOptionsType, XAxisOptions, YAxisOptions} from 'highcharts';

import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
More(Highcharts);

@Component({
  selector: 'app-box-plot-chart\'',
  templateUrl: '../base-chart/./base-chart.component.html',
  styleUrls: ['../base-chart/./base-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ChartService]
})
export class BoxPlotChartComponent extends BaseChartComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  chartOptionsLocalStorageKey = 'boxPlotOptionsLocalStorageKey';

  constructor(
    private translationService: TranslationService,
    private chartService: ChartService
  ) {
    super(translationService);

    this.chartContainerId = 'bellCurveId';
    this.seriesType = SeriesType.BellCurve;
    this.seriesColor = '#81b5eb';

    this.chartOptions.yAxisMinValue = 600;
    this.chartOptions.yAxisMaxValue = 1200;

    // this.sampleTooltipFormatter = (point: DataPoint) => this.chartService.createHistogramTooltip(point);
    // this.xAxisLabelFormatter = (value: number) => labels[value];
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public ngOnChanges(changes): void {
    if (changes.isOptionsOpened) {
      this.resizeChart();
    }
  }

  public ngAfterViewInit(): void {
    this.init();
  }

  protected setLabels() {
    this.sampleName = 'Bell Curve - Data';
    this.chartCardTitle = 'Bell Curve';
    this.xAxisTitle = 'Data';
    this.yAxisTitle = 'Data';
  }

  protected init(): void {
    if (this.chartContainer) {
      this.setLabels();
      this.generateChart();
    }
  }

  protected generateSamples(): ChartData {
    const labels: string[] = [];
    const points: DataPoint[] = [];

    return { points: points, labels: labels };
  }

  protected generateSeries(): Array<SeriesOptionsType> {
    this.xAxisLabelFormatter = null;

    return [
      {
        name: 'Bell curve',
        type: 'boxplot',
        data: this.getObservationsData(),
      },
      {
        name: this.sampleName,
        type: 'scatter',
        data: this.getOutliersData(),
        color: this.seriesColor,
        marker: {
          enabled: true,
          radius: 1.5
        }
      }
    ];
  }

  protected getXAxisOptions(): Array<XAxisOptions> {
    const xAxisOptions = super.getXAxisOptions();
    xAxisOptions.push(
      {
        categories: ['1', '2', '3', '4', '5'],
        title: {
          text: 'bell'
        },
        opposite: true
      });

    return xAxisOptions;
  }

  protected getYAxisOptions(): Array<YAxisOptions> {
    const yAxisOptions = super.getYAxisOptions();
    yAxisOptions.push(
      {
        title: {
          text: 'bell'
        },
        alignTicks: false,
        opposite: true,
        plotLines: [{
          value: 932,
          color: 'red',
          width: 1,
          label: {
            text: 'Theoretical mean: 932',
            align: 'center',
            style: {
              color: 'gray'
            }
          }
        }]
      });

    return yAxisOptions;
  }

  private getObservationsData(): Array<number[]> {
    return [
      [760, 801, 848, 895, 965],
      [733, 853, 939, 980, 1080],
      [714, 762, 817, 870, 918],
      [724, 802, 806, 871, 950],
      [834, 836, 864, 882, 910]
    ];
  }

  private getOutliersData(): Array<number[]> {
    return [ // x, y positions where 0 is the first category
      [0, 644],
      [4, 718],
      [4, 951],
      [4, 969]
    ];
  }
}
