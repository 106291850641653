import {animate, AUTO_STYLE, state, style, transition, trigger} from '@angular/animations';


export enum ExpandState {
  Collapsed = 'collapsed',
  Expanded = 'expanded',
}

export const toggleExpandAnimation = trigger('expandToggle', [
  state(`${ExpandState.Collapsed}, *`,
    style({
      overflow: 'hidden',
      height: '0px',
    })
  ),
  state(`${ExpandState.Expanded}`,
    style({
      overflow: 'visible',
      height: AUTO_STYLE,
    })
  ),
  transition(`${ExpandState.Collapsed} <=> ${ExpandState.Expanded}`,
    animate( '400ms ease-in-out'))
]);

export const toggleDropdownAnimation = trigger('dropdownToggle', [
  state(`${ExpandState.Collapsed}, *`,
    style({
      overflow: 'hidden',
      height: '0px',
    })
  ),
  state(`${ExpandState.Expanded}`,
    style({
      overflow: 'visible',

      height: AUTO_STYLE
    })
  ),
  transition(`${ExpandState.Collapsed} <=> ${ExpandState.Expanded}`,
    animate( '400ms linear'))
]);
