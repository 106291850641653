import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ReactionPlan, Signature} from '@models/reaction-plan';
import {ComponentsTranslation, GlobalTranslation} from '@models/translation';
import {SignatureType} from '@models/verification-confirmation';
import {TranslationService} from '@services/translation.service';
import {Subscription} from 'rxjs';
import {ControlContainer, NgForm} from '@angular/forms';
import {ReactionPlanReasonType} from '@models/reaction-plan-reason';
import {LabeledValue} from '@common/global';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm}]
})
export class SignatureComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();

  public signature = new Signature();
  public globalTranslation: GlobalTranslation;
  public componentsTranslation: ComponentsTranslation;

  @Input() reactionPlanModel: ReactionPlan;
  @Input() signatureType: SignatureType;
  @Input() signatureTitle: string;
  @Input() signaturePersonLabel: string;
  @Input() initialValidationMessage: string;
  @Input() materialDispositionOptions: LabeledValue<string>[];

  constructor(private translationService: TranslationService) {
    this.setTranslation();
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.globalTranslation = translation.global;
      this.componentsTranslation = translation.components;
    });

    this.subscriptions.add(translationSubscription);
  }

  public ngOnInit(): void {
    this.signature = this.reactionPlanModel.getSignature(this.signatureType);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onSubmit(isFormValid: boolean): void {
    if (isFormValid) {
      this.signature.date = new Date();
      this.signature.complete = true;
      this.reactionPlanModel.setSignature(this.signatureType, this.signature);
    }
  }
}
