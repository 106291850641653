<app-modal-basic
  #modal
  [dialogClass]="'modal-lg'"
  [width]="1000"
  [height]="950"
  xmlns="http://www.w3.org/1999/html">
  <div class="app-modal-header w-100 my-0 mx-auto">
    <div class="header-title w-100">
      <h4 class="modal-title w-100">
        {{componentsTranslation.addPartMappingModal_modalHeader}}
      </h4>
    </div>

    <button class="close basic-close"
            (click)="closeModal()"
            type="button">
      <span aria-hidden="true">
        &times;
      </span>
    </button>
  </div>
  <form #partMappingForm="ngForm"
        id="partMappingForm"
        class="app-modal-body row form-material w-100 m-0"
        (ngSubmit)="onSubmit(partMappingForm.value, partMappingForm.valid)"
        autocomplete="off">
    <div class="row"
         style="width: 100%">
      <div class="col-md-12 small-padding-col">
        <div class="card w-100">
          <div class="card-block header-block">
            <app-location-pick
              [form]="partMappingForm"
              [locationPickName]="componentsTranslation.addPartMappingSelectLocationBtn"
              [locationPickDisabled]="false"
              [userAccessBlocked]="false"
              [locationPath]="partMapping.locationPath"
              (selectLocationClicked)="onSelectLocationClick()">
            </app-location-pick>

            <app-location-tree-modal style="height: 100%"
              [treeDataService]="characteristicTreeService"
              [userAccessBlocked]="false"
              [location]="partMapping.uid"
              (sdNumberChange)="onSdNumberChange($event)"
              (locationPathChange)="onLocationPathChange($event)"
              (locationIdChange)="onLocationIdChange($event)">
            </app-location-tree-modal>

            <div class="row mt-3">
              <div class="col-md-3 d-flex align-items-center">
                CMM Parts
              </div>
              <div class="col-sm-12">
                <ng-select
                  #characteristicSampleReasons
                  class="ng-select"
                  labelForId="characteristicSampleReasons"
                  name="characteristicSampleReasons"
                  required
                  [placeholder]="componentsTranslation.addPartMappingCmmPartsPlaceholder"
                  [multiple]="true"
                  [disabled]="false"
                  [items]="cmmMachineOptions"
                  [bindLabel]="'label'"
                  [ngClass]="{'cursor-disabled': false}"
                  [(ngModel)]="selectedCmmMachines"
                  (change)="onSelectedCmmMachinesChange()"
                  (clear)="onSelectedCmmMachinesClear()">
                </ng-select>

                <ng-container *ngIf="partMappingForm.submitted">
                  <div *ngIf="!selectedCmmMachines.length"
                       class="messages text-danger">
                    {{globalTranslation.isRequired}}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="app-modal-footer">
    <button class="btn btn-primary ripple light"
            form="partMappingForm"
            [disabled]="!partMappingSubmitEnabled"
            type="submit">
      {{componentsTranslation.addSampleModal_completeBtn}}
    </button>
  </div>
</app-modal-basic>
