export class PartMapping {
  uid: string;
  sdNumber: string;
  locationPath: string;
  machineTYList: string[];
  machineTYListString: string;
  partNumberList: string[];
  partNumberListString: string;
  isUsed: boolean;
}
