import {UserPhotoService} from '@user/user-photo.service';
import {HelpersService, NotifierType} from '@services/helpers.service';
import {Subscription} from 'rxjs';
import {VirtualKeyboardService} from '@shared/virtual-keyboard/virtual-keyboard.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {AvatarStatus, Credentials, User} from '@models/user';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import {AdministrationTranslation, GlobalTranslation, UserSettingsTranslation} from '@models/translation';
import {TranslationService} from '@services/translation.service';
import {UserService} from '@user/user.service';
import {UserApiService} from '@user/user-api.service';

export const fadeInOutTranslateAnimation = trigger('fadeInOutTranslate', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('400ms ease-in-out', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ transform: 'translate(0)' }),
    animate('400ms ease-in-out', style({ opacity: 0 }))
  ])
]);

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [
    fadeInOutTranslateAnimation
  ]
})
export class ProfileComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();

  public readonly avatarStatuses = AvatarStatus;

  public administrationTranslation: AdministrationTranslation;
  public canEdit = true;
  public globalTranslation: GlobalTranslation;
  public incorrectPassword = false;
  public isLoading: boolean;
  public user: User = new User();
  public userSettingTranslation: UserSettingsTranslation;

  constructor(
    private userApiService: UserApiService,
    private router: Router,
    private translationService: TranslationService,
    private helpersService: HelpersService,
    public userPhotoService: UserPhotoService,
    public virtualKeyboardService: VirtualKeyboardService
  ) {
    this.setTranslation();
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.administrationTranslation = translation.administration;
      this.userSettingTranslation = translation.userSetting;
      this.globalTranslation = translation.global;
    });

    this.subscriptions.add(translationSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnInit(): void {
    this.isLoading = true;
    this.userApiService.getUser().then(user => {
      this.user = user;
      this.userPhotoService.initUserPhotoState(this.user);
      this.user.roles = null;
      this.isLoading = false;
    });
  }

  public onSubmit(formValues: User, isValid: boolean): void {
    this.virtualKeyboardService.turnOffKeyboard();

    if (!isValid || !this.canEdit) {
      return;
    }
    this.incorrectPassword = false;
    const apiUser = this.prepareDataToApi(formValues);

    if (this.user.currentPassword) {
      // const data = {
      //   password: this.user.currentPassword,
      //   login: this.user.userName
      // };
      this.getUserToken(this.user.credentials, apiUser);
    } else {
      delete apiUser.currentPassword;
      this.updateUser(apiUser);
    }
  }

  private prepareDataToApi(formValues: User): User {
    const userData = new User(formValues);
    userData.userName = this.user.userName;
    userData.lastName = this.user.lastName;
    userData.active = true;
    userData.email = this.user.email;
    userData.firstName = this.user.firstName;
    userData.avatarStatus = this.user.avatarStatus;

    return userData;
  }

  private getUserToken(data: Credentials, user: User): void {
    this.isLoading = true;
    this.userApiService.getUserToken(data)
      .then(() => {
        this.isLoading = false;
        this.showUpdatePasswordConfirmAlert(user);
      })
      .catch(() => {
        this.isLoading = false;
        this.incorrectPassword = true;
        this.helpersService.notificationTrigger(NotifierType.Error, this.userSettingTranslation.passwordIncorrectMsg);
      });
  }

  private showUpdatePasswordConfirmAlert(user: User): void {
    const alertOptions = HelpersService.createAlertOptions(
      this.globalTranslation.removeMsgTitle,
      this.globalTranslation.removeMsg,
      this.userSettingTranslation.confirmMsgBtn,
      'warning');

    swal(alertOptions)
      .then((result) => {
        if (result.value) {
          delete user.currentPassword;
          this.updateUser(user);
        }
      });
  }

  private updateUser(updateUser: User): void {
    this.userPhotoService.setUserPhoto(updateUser);

    this.isLoading = true;
    this.userApiService.updateUserMe(updateUser)
      .then(() => {
        this.userApiService.getUser()
          .then(loggedUser => {
            this.isLoading = false;
            UserService.saveLoggedUser(loggedUser);
            this.helpersService.notificationTrigger(NotifierType.Success, this.globalTranslation.dataSaved);
            this.router.navigate(['/dashboard']).then();

            return false;
          });
      })
      .catch(() => {
        this.isLoading = false;
        this.helpersService.notificationTrigger(NotifierType.Error, this.globalTranslation.errorOccurred);
      });
  }

  public handlePhotoFile(files: FileList): void {
    this.userPhotoService.handlePhotoFile(files, this.user);
  }

  public onPhotoRemoved(): void {
    this.userPhotoService.removeUserPhoto(this.user);
  }
}
