import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss']
})
export class SwitcherComponent {

  @Input() value: boolean;
  @Input() label: string;

  @Output() valueChange = new EventEmitter<boolean>();

  constructor() { }
}
