import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {cardClose, CardOpenCloseToggleState, cardToggle, CardToggleState} from '@shared/card/card-animation';
import {ComponentsTranslation} from '@models/translation';
import { faHeartBroken } from '@fortawesome/free-solid-svg-icons';
import {TranslationService} from '@services/translation.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  animations: [cardToggle, cardClose],
  encapsulation: ViewEncapsulation.None
})
export class CardComponent implements OnInit {
  public componentsTranslation: ComponentsTranslation;
  private readonly subscriptions = new Subscription();
  public isOptionsMenuOpened: boolean;
  public isCardLoading = false;
  public cardToggleClass = CardToggleState.Expanded;
  public openCloseToggleState = CardOpenCloseToggleState.Opened;
  public fullscreenCardClass: 'full-card' | 'non-full';
  public fullscreenCardIcon: 'icon-minimize' | 'icon-maximize';
  public minimizeIcon: 'icon-minus' | 'icon-plus';
  public optionMenuIcon: 'icon-toggle-left' | 'icon-toggle-right';
  public readonly faHeartBroken = faHeartBroken;
  public cardLoadClass: '' | 'card-load';

  @Input() headerContent: string;
  @Input() title: string;
  @Input() blockClass: string;
  @Input() headerClass: string;
  @Input() cardClass: string;
  @Input() useClassHeader = false;
  @Input() enableFullscreenOption = false;
  @Input() enableMinimizeOption = false;
  @Input() enableReloadOption = false;
  @Input() enableCloseOption = false;
  @Input() isOptionsMenuEnabled: boolean;

  @Input() enableFavoriteOption: boolean;

  @Output() fullScreenChange = new EventEmitter<boolean>(false);
  @Output() unfavoriteClicked = new EventEmitter();

  constructor(private translationService: TranslationService) {
    this.fullscreenCardIcon = 'icon-maximize';
    this.minimizeIcon = 'icon-minus';
    this.setTranslation();
  }

  public ngOnInit(): void {
    if (this.isOptionsMenuEnabled) {
      this.toggleOptionsMenuIcon();
    }
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.componentsTranslation = translation.components;
    });

    this.subscriptions.add(translationSubscription);
  }

  public toggleVisibilityCard(): void {
    this.minimizeIcon = this.minimizeIcon === 'icon-minus'
      ? 'icon-plus'
      : 'icon-minus';

    this.cardToggleClass = this.cardToggleClass === CardToggleState.Collapsed
      ? CardToggleState.Expanded
      : CardToggleState.Collapsed;
  }

  public closeCard(): void {
    this.openCloseToggleState = CardOpenCloseToggleState.Closed;
  }

  public toggleFullscreen(): void {
    if (this.fullscreenCardClass === 'full-card') {
      this.fullscreenCardClass = 'non-full';
      this.fullScreenChange.emit(false);
      document.body.style['overflow'] = 'auto';
    } else {
      this.fullscreenCardClass = 'full-card';
      this.fullScreenChange.emit(true);
      document.body.style['overflow'] = 'hidden';
    }

    this.fullscreenCardIcon = this.fullscreenCardIcon === 'icon-maximize'
      ? 'icon-minimize'
      : 'icon-maximize';
  }

  public cardRefresh(): void {
    this.isCardLoading = true;
    this.cardLoadClass = 'card-load';
    setTimeout( () => {
      this.cardLoadClass = '';
      this.isCardLoading = false;
    }, 3000);
  }

  public toggleOptionsMenu(): void {
    this.isOptionsMenuOpened = !this.isOptionsMenuOpened;
    this.toggleOptionsMenuIcon();
  }

  private toggleOptionsMenuIcon(): void {
    this.optionMenuIcon = this.isOptionsMenuOpened
      ? 'icon-toggle-left'
      : 'icon-toggle-right';
  }

  public unfavoriteCharacteristic(): void {
    this.unfavoriteClicked.emit();
  }
}
