import {Pipe, PipeTransform} from '@angular/core';
import {Global,  GlobalVariable} from '@common/global';

@Pipe({
  name: 'globalVariables'
})
export class GlobalVariablesPipe implements PipeTransform {

  transform(value: any, args?: any): Global {
    return  GlobalVariable;
  }
}
