<app-modal-basic
  [dialogClass]="'modal-lg'">
  <div class="app-modal-header modal-header-with-search">
    <h4 class="modal-title">
      {{globalTranslation.modalTitleSelectLocation}}
    </h4>

    <div class="modal-button-wrapper">
      <form class="form-material">
        <div class="form-group mb-0">
          <app-vk-input
            type="text"
            maxlength="50"
            name="locationTreeFilterQuery"
            [label]="componentsTranslation.searchLabel"
            [required]="true"
            [(ngModel)]="filterOptions.filterQuery"
            [disabled]="userAccessBlocked">
          </app-vk-input>
        </div>
      </form>

      <button type="button"
              class="btn btn-primary ripple light middleBtn"
              (click)="applyFilter()"
              [ngClass]="{disabled: userAccessBlocked}"
              [disabled]="userAccessBlocked">
        {{globalTranslation.searchBtn}}
      </button>

      <button type="button"
              class="btn btn-default ripple"
              (click)="clearSearchData()">
        {{globalTranslation.clearBtn}}
      </button>
    </div>
  </div>

  <div class="app-modal-body"
       style="max-height: calc(100vh - 210px); overflow-y: auto;">
    <div class="card my-0">
      <ng-container *ngIf="isModalOpen">
        <div *ngIf="dataTree"
             class="card-block p-0">
          <app-tree
            [dataTree]="dataTree"
            [initialLocationId]="location"
            (locationPath)="onLocationPathChange($event)"
            (sdNumber)="onSdNumberChange($event)"
            (departmentId)="onDepartmentIdChange($event)"
            (plantId)="onPlantIdChange($event)"
            (locationId)="onLocationIdChange($event)"
          >
          </app-tree>
        </div>

        <div *ngIf="!dataTree"
             style="height: 200px">
          <app-loader [isLoading]="true"
                      [isBackgroundLoading]="false">
          </app-loader>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button"
            class="btn btn-default ripple"
            (click)="closeModal()">
      {{globalTranslation.closeBtn}}
    </button>
  </div>
</app-modal-basic>
