import {NgModule} from '@angular/core';
import {AppRoutingModule} from '@app/app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxMaskModule} from 'ngx-mask';
import {SharedModule} from '@shared/shared.module';
import {NotifierModule, NotifierService} from 'angular-notifier';
import {UserModule} from '@user/user.module';

import {ApiService} from '@services/api.service';
import {HelpersService} from '@services/helpers.service';
import {TabFilterService} from '@shared/filters/tab-filter.service';
import {TranslationService} from '@services/translation.service';
import {TreeDataApiService} from '@services/tree-data-api.service';
import {TreeDataService} from '@services/tree-data.service';
import {UserService} from '@user/user.service';
import {UserSettingsService} from '@services/user-settings.service';
import {VirtualKeyboardService} from '@shared/virtual-keyboard/virtual-keyboard.service';

import {AdminComponent} from '@layout/admin/admin.component';
import {AppComponent} from '@app/app.component';
import {AuthComponent} from '@layout/auth/auth.component';
import {AuthGuard} from '@auth/auth.guard';
import {BreadcrumbsComponent} from '@layout/admin/breadcrumbs/breadcrumbs.component';
import {FirstUserSignInComponent} from '@layout/admin/first-user-sign-in/first-user-sign-in.component';

import {DatePipe, registerLocaleData} from '@angular/common';
import {MenuItems} from '@shared/menu-items/menu-items';

import {DateReviverInterceptor} from '@common/interceptors/date-reviver-interceptor';

import localeEn from '@angular/common/locales/en';
import localePl from '@angular/common/locales/pl';
import localeEs from '@angular/common/locales/es';
import localeZh from '@angular/common/locales/zh';
import localePt from '@angular/common/locales/pt';
import {CharacteristicApiService} from '@characteristic/characteristic-api.service';
import {ProcessChartApiService} from '@shared/process-chart-api.service';
import {CharacteristicTreeService} from '@characteristic/characteristic-tree.service';
import {ProcessChartTreeService} from '@process-chart/process-chart-tree.service';
import {LocationScreenTreeService} from '@location-screen/location-screen-tree.service';


registerLocaleData(localeEn, 'en-EN');
registerLocaleData(localePl, 'pl-PL');
registerLocaleData(localeEs, 'es-ES');
registerLocaleData(localeZh, 'zh-ZH');
registerLocaleData(localePt, 'pt-PT');

@NgModule({
    declarations: [
        AppComponent,
        AdminComponent,
        AuthComponent,
        BreadcrumbsComponent,
        FirstUserSignInComponent,
    ],
    imports: [
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        NgbModule,
        NgxMaskModule.forRoot(),
        UserModule,
        NotifierModule.withConfig({
          position: {
            horizontal: {
              position: 'right',
              distance: 12,
            },
            vertical: {
              position: 'top',
              distance: 12,
              gap: 10,
            },
          },
          theme: 'material',
          behaviour: {
            autoHide: 5000,
            onClick: false,
            onMouseover: 'pauseAutoHide',
            showDismissButton: true,
            stacking: 4,
          },
          animations: {
            enabled: true,
            show: {
              preset: 'slide',
              speed: 300,
              easing: 'ease',
            },
            hide: {
              preset: 'fade',
              speed: 300,
              easing: 'ease',
              offset: 50,
            },
            shift: {
              speed: 300,
              easing: 'ease',
            },
            overlap: 150,
          },
        }),
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: DateReviverInterceptor, multi: true},
        ApiService,
        HelpersService,
        TabFilterService,
        TranslationService,
        TreeDataApiService,
        TreeDataService,
        UserService,
        UserSettingsService,
        VirtualKeyboardService,
        AuthGuard,
        MenuItems,
        DatePipe
    ],
    exports: [
        FirstUserSignInComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
