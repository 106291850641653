import { NgModule } from '@angular/core';

import {ClickOutsideDirective} from '@common/directives/click-outside.directive';
import {EmailValidatorDirective} from '@common/directives/email-validator.directive';
import {EqualValidatorDirective} from '@common/directives/equal-validator.directive';
import {SetAttributeCellDirective} from '@common/directives/set-attribute-cell.directive';
import { MinMaxValidatorDirective } from './min-max-validator.directive';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    EmailValidatorDirective,
    EqualValidatorDirective,
    SetAttributeCellDirective,
    MinMaxValidatorDirective,
  ],
  exports: [
    ClickOutsideDirective,
    EmailValidatorDirective,
    EqualValidatorDirective,
    SetAttributeCellDirective,
    MinMaxValidatorDirective,
  ],
})
export class DirectivesModule { }
