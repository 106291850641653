import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Characteristic} from '@models/characteristic';
import {SslLimitType} from '@models/limit-config';
import {RecalcValues} from '@models/recalc-values';
import {ComponentsTranslation, GlobalTranslation, Translation} from '@models/translation';
import {TranslationService} from '@services/translation.service';
import {Key} from 'ts-key-enum';
import {CharacteristicSampleCollection} from '@models/characteristic-sample-collection';
// import {ResizedEvent} from 'angular-resize-event/resized-event';

import {HelpersService} from '@services/helpers.service';
import {DataPoint, SamplePoint} from '@shared/charts/sample-point';
import {Subscription} from 'rxjs';
import {pull} from 'lodash';
import * as Highcharts from 'highcharts';

import {
  YAxisOptions,
  Chart,
  SeriesOptionsType,
  XAxisOptions,
  XAxisPlotLinesOptions,
  SeriesLineOptions
} from 'highcharts';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';


export enum LimitType {
  UCL= 'ucl',
  USL= 'usl',
  LCL= 'lcl',
  LSL= 'lsl',
  CentralLine = 'centralLine',
  RangePlus = 'rangePlus',
  RangeMinus = 'rangeMinus',
  NominalRange = 'nominalRange',
  TrendLine = 'trendLine'
}

export interface ChartData {
  points: DataPoint[];
  labels: string[];
}

export enum SeriesType {
  Line = 'line',
  Column = 'column',
  BellCurve = 'bellcurve'
}

@Component({
  selector: 'app-base-chart',
  templateUrl: './base-chart.component.html',
  styleUrls: ['./base-chart.component.scss']
})
export class BaseChartComponent implements OnInit, OnDestroy {
  private chartConfiguration: Highcharts.Options;
  private _decimals: number;

  protected readonly subscriptions = new Subscription();

  protected set decimals(decimals: number) {
    this._decimals = decimals;
    this.chartOptions.minMaxStep = Math.pow(10, -(decimals));
  }
  protected seriesType = SeriesType.Line;
  protected seriesColor = '#000000';
  protected chartOptionsLocalStorageKey: string;
  protected widthChartMax: number;
  protected heightChartMax: number;
  protected centralLineLabel: string;
  protected sampleName: string;
  protected xAxisTitle: string;
  protected yAxisTitle: string;

  public readonly plotLineMap: Map<string, Highcharts.YAxisPlotLinesOptions[]> = new Map<string, Highcharts.YAxisPlotLinesOptions[]>();
  public readonly limitTypes = LimitType;
  public readonly sslLimitTypes = SslLimitType;

  public chartContainerId: string;
  public chartOptions: {
    showOptions: {
      limitType: string,
      available: boolean,
      showLimit: boolean,
      limitValue: number,
      label: string
    }[],
    ticksAmount: number,
    yAxisMinValue: number,
    yAxisMaxValue: number,
    minMaxStep: number
  } = { showOptions: [], ticksAmount: 5, yAxisMinValue: 0, yAxisMaxValue: 0, minMaxStep: 1};

  public isLoading = false;
  public chartCardTitle: string;
  public decimalPlaces: string;
  public locale: string;
  public componentsTranslation = new ComponentsTranslation();
  public globalTranslation = new GlobalTranslation();
  public chart: Chart;
  public sampleCollections: CharacteristicSampleCollection[] = [];

  @Input() characteristic: Characteristic;
  @Input() recalcValues: RecalcValues;
  @Input() isOptionEnabled: boolean;
  @Input() isOptionsOpened: boolean;

  @ViewChild('optionPanel') protected optionPanel: ElementRef;
  @ViewChild('chartContainer') chartContainer: ElementRef;

  constructor (
    translationService: TranslationService
  ) {
    this.setTranslation(translationService);
  }

  public static getBaseComponentMethodError(methodName: string): Error {
    return new Error(`${methodName} from inherited component should be invoked`);
  }

  protected sampleTooltipFormatter: (point: SamplePoint) => string = point => String(point.y);
  protected xAxisLabelFormatter: (value: number) => string = value => String(value);

  private setTranslation(translationService: TranslationService): void {
    const translationSubscription = translationService.translations$
      .subscribe(translation => {
        this.componentsTranslation = translation.components;
        this.globalTranslation = translation.global;

        this.init();
      });

    const localeSubscription = translationService.translationLocale$
      .subscribe(locale => {
        this.locale = locale;

        this.init();
      });
    this.subscriptions.add(localeSubscription);
    this.subscriptions.add(translationSubscription);
  }

  public ngOnInit(): void {
    this.decimals = this.characteristic
      ? this.characteristic.decimals - 1
      : 2;
  }

  public ngOnDestroy(): void {
    pull(Highcharts.charts, this.chart);
    this.subscriptions.unsubscribe();
  }

  protected generateChart(): void {
    this.sortSampleCollectionByCreationDate();
    this.createChart();
  }

  protected setLabels(): void {
    throw new Error(this.setLabels.name + ' from inherited component should be invoked');
  }

  protected init(): void {
    throw BaseChartComponent.getBaseComponentMethodError(this.init.name);
  }

  protected generateSamples(): ChartData {
    throw BaseChartComponent.getBaseComponentMethodError(this.generateSamples.name);
  }

  protected generateSeries(): Array<SeriesOptionsType> {
    throw BaseChartComponent.getBaseComponentMethodError(this.generateSeries.name);
  }

  public onResized(event): void {
    if (event.newWidth > 0) {
      this.widthChartMax = event.newWidth;
      this.heightChartMax = event.newHeight;
      this.resizeChart();
    }
  }

  protected resizeChart(): void {
    if (this.chart && this.widthChartMax) {

      const width = this.isOptionsOpened
        ? this.widthChartMax - this.optionPanel.nativeElement.offsetWidth
        : this.widthChartMax;

      this.chart.update({chart: {width: width, height: this.heightChartMax}}, true, false, true);
    }
  }

  public updateYAxisTicksAmount(): void {
    this.chartOptions.ticksAmount = Math.max(2, this.chartOptions.ticksAmount);
    this.chart.update({ yAxis: { tickAmount: this.chartOptions.ticksAmount }}, true);
  }

  public onKeyUp(event: KeyboardEvent): void {
    const digit = Number(event.key);

    if (!digit && event.key !== Key.Backspace) {
      event.preventDefault();
    }
  }

  public clipYAxisMinValue(): void {
    if (this.chartOptions.yAxisMinValue > this.chartOptions.yAxisMaxValue) {
      this.chartOptions.yAxisMinValue = this.chartOptions.yAxisMaxValue;
    }

    this.setYaxisTicks();
  }

  public clipYAxisMaxValue(): void {
    if (this.chartOptions.yAxisMaxValue < this.chartOptions.yAxisMinValue) {
      this.chartOptions.yAxisMaxValue = this.chartOptions.yAxisMinValue;
    }

    this.setYaxisTicks();
  }

  protected setYaxisTicks(): void {
    if (this.chart) {
      this.chart.update(
        {yAxis : {min: this.chartOptions.yAxisMinValue, max: this.chartOptions.yAxisMaxValue}},
        true,
        false,
        true);
    }
  }

  protected calculateYAxisTicks(): number[] {
    let ticks: number[] = [];
    const range = this.chartOptions.yAxisMaxValue - this.chartOptions.yAxisMinValue;
    let tickNumber = this.chartOptions.ticksAmount - 1;
    const tickInterval = range / tickNumber;
    const precision = this.calculatePrecision(tickInterval);
    let tickValue = this.chartOptions.yAxisMinValue;
    ticks.push(HelpersService.round(this.chartOptions.yAxisMinValue, this._decimals, 'floor'));

    while (--tickNumber > 0) {
      tickValue += tickInterval;
      const roundedTickValue = HelpersService.round(tickValue, precision);
      ticks.push(roundedTickValue);
    }

    ticks.push(HelpersService.round(this.chartOptions.yAxisMaxValue, this._decimals, 'ceil'));
    const checkIfZeroIsInMinMaxRange = () => this.chartOptions.yAxisMinValue < 0 && this.chartOptions.yAxisMaxValue > 0;

    if (!ticks.includes(0) && checkIfZeroIsInMinMaxRange()) {
      ticks = this.replaceTickWithZero(ticks);
    }

    return ticks;
  }

  protected calculatePrecision(value: number): number {
    value = Math.abs(value);
    let precision = 1;
    while (value !== 0 && value < 1) {
      value *= 10;
      precision++;
    }

    return precision;
  }

  private replaceTickWithZero(ticks: number[]): number[] {
    ticks.push(0);
    ticks.sort((a, b) => a - b);

    const zeroTickIndex = ticks.findIndex(tickValue => tickValue === 0);
    let tickIndexToReplace;
    switch (zeroTickIndex) {
      case 0:
        tickIndexToReplace = 1;
        break;
      case ticks.length - 1:
        tickIndexToReplace = zeroTickIndex - 1;
        break;
      default:
        tickIndexToReplace = Math.abs(ticks[zeroTickIndex - 1]) > ticks[zeroTickIndex + 1]
          ? zeroTickIndex + 1
          : zeroTickIndex - 1;
    }

    ticks.splice(tickIndexToReplace, 1);

    return ticks;
  }

  public changePlotLine(optionChecked: boolean, value: string): void {
    console.log(this.characteristic.limitConfig.trendLineLocationDraw);
    if (value !== LimitType.TrendLine) {
      const yAxis = this.chart.yAxis[0];
      const plotLines = this.plotLineMap.get(value);

      if (optionChecked) {
        plotLines.forEach(plotLine => yAxis.addPlotLine(plotLine));
      } else {
        plotLines.forEach(plotLine => yAxis.removePlotLine(plotLine.id));
      }
      localStorage.setItem(this.chartOptionsLocalStorageKey, JSON.stringify(this.chartOptions.showOptions));
      this.chart.redraw();
    } else {
      this.generateChart();
      localStorage.setItem(this.chartOptionsLocalStorageKey, JSON.stringify(this.chartOptions.showOptions));
    }
  }

  protected AddPlotLinesToChart(chart: Chart): void {
    this.chartOptions.showOptions
      .filter(option => option.available && option.showLimit && option.limitType !== LimitType.TrendLine)
      .forEach(option => {
        const plotLines = this.plotLineMap.get(option.limitType);
        (chart.options.yAxis[0] as YAxisOptions).plotLines.push(...plotLines);
      });
  }

  private getVisiblePlotLines(): Array<Highcharts.YAxisPlotLinesOptions> {
    const plotLines: Highcharts.YAxisPlotLinesOptions[] = [];

    this.chartOptions.showOptions
      .filter(option => option.available && option.showLimit && option.limitType !== LimitType.TrendLine)
      .forEach(option => plotLines.push(...this.plotLineMap.get(option.limitType)));

    return plotLines;
  }

  protected sortSampleCollectionByCreationDate(): void {
    this.sampleCollections.sort((a, b) => {
      return new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime();
    });
  }

  protected getXAxisOptions(): Array<XAxisOptions> {
    const xAxisLabelFormatter = this.xAxisLabelFormatter;
    const calculationResetPlotLines: Highcharts.XAxisPlotLinesOptions[] = [];

    for (let i = 0; i < this.sampleCollections.length; i++) {
      if (this.sampleCollections[i].calculationReset === true) {
        calculationResetPlotLines.push(<Highcharts.XAxisPlotLinesOptions> {
          id: 'Calculation Reset ' + i.toString(),
          value: i,
          color: '#FF0000',
          zIndex: 200,
          width: 3,
          dashStyle: 'ShortDot'
        });
      }
    }

    return [
      {
        tickInterval: 1,
        labels: xAxisLabelFormatter
          ? {
            formatter: function () {
              return xAxisLabelFormatter(this.value);
            }
          }
          : {},
        title: {
          text: this.xAxisTitle
        },
        gridLineWidth: 1,
        plotLines: calculationResetPlotLines,
        min: 0
      }
    ];
  }

  protected getYAxisOptions(): Array<YAxisOptions> {
    return [
      {
        visible: true,
        title: {
          text: this.yAxisTitle
        },
        tickAmount: this.chartOptions.ticksAmount,
        tickPositioner: () => this.calculateYAxisTicks(),
        startOnTick: false,
        endOnTick: false,
        minorGridLineWidth: 1,
        gridLineWidth: 1,
        plotLines: this.getVisiblePlotLines(),
        min: 0,
      }
    ];
  }

  protected createChart(): void {
    // local copies for avoid different meaning of this in callback (function) context
    const sampleTooltipFormatter = this.sampleTooltipFormatter;
    const series = this.generateSeries();

    this.chartConfiguration = <Highcharts.Options>{
      chart: {
        zoomType: 'x',
        panning: true,
        panKey: 'shift',
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: this.getXAxisOptions(),
      yAxis: this.getYAxisOptions(),
      tooltip: {
        useHTML: true,
        formatter: function () {
          return sampleTooltipFormatter(this.point as any);
        },
        footerFormat: '</table>',
      },
      series: series,
      plotOptions: {
        column: {
          pointPadding: 0,
          groupPadding: 0
        },
      }
    };

    Highcharts.chart(this.chartContainer.nativeElement, this.chartConfiguration, chart => {
      this.chart = chart;
      this.chart.reflow();
      this.resizeChart();
      this.setYaxisTicks();
    });
  }
}
