import {Auth} from '@models/auth';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '@environment/environment';
import {HelpersService, NotifierType} from '@services/helpers.service';
import {HttpOptions} from '@common/types/http-options';
import {Observable} from 'rxjs';
import {CharacteristicSampleCollection} from '@models/characteristic-sample-collection';
import {LocationNode} from '@models/location-node';
import {Credentials, User} from '@models/user';
import {Sample} from '@models/sample';
import {Part} from '@models/part';
import {ApplicationInfo} from '@models/application-info';

declare type ResponseType = 'json' | 'text' | 'blob' | 'arraybuffer';

@Injectable()
export class ApiService {
  private readonly authorizationTokenUrl = `authorization/token`;

  public readonly apiPrefix = `${environment.apiPrefix}/api`;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private helpersService: HelpersService
  ) { }

  public static getCurrentToken(): string {
    return localStorage.getItem('token');
  }

  public static setTokens(authorization: Auth): string {
    if (authorization.refresh_token) {
      localStorage.setItem('refresh_token', authorization.refresh_token);
    }

    const access_token = `${authorization.token_type} ${authorization.access_token}`;
    localStorage.setItem('token', access_token);
    localStorage.setItem('nextRefresh', Number(new Date().getTime() + (authorization.expires_in) * 1000).toString());
    return access_token;
  }

  public static createDefaultOptions(): HttpOptions {
    // const tokenValue = this.refreshUserToken();
    const tokenValue = ApiService.getCurrentToken();
    if (!tokenValue) {
      return null;
    }

    const localHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': tokenValue,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true'
    });

    return  {headers: localHeaders};
  }

  private refreshUserToken(): string {
    const nextRefresh = localStorage.getItem('nextRefresh');
    // if (!nextRefresh || Number(nextRefresh) >= new Date().getTime()) {
    if (!nextRefresh) {
      try {
        const refreshToken = localStorage.getItem('refresh_token');

        if (!refreshToken) {
          return null;
        }

        const url = `${this.apiPrefix}/${this.authorizationTokenUrl}`;
        const request = new XMLHttpRequest();
        request.open('POST', url, false);
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        const body = new URLSearchParams();
        body.set('grant_type', 'refresh_token');
        body.set('refresh_token', refreshToken);
        request.send(body);

        if (Number(request.status) === 200) {
          const auth = JSON.parse(request.responseText) as Auth;
          return ApiService.setTokens(auth);
        } else {
          return null;
        }
      } catch (e) {
      }
    } else {
      return ApiService.getCurrentToken();
    }
  }

  public getUserToken(credentials: Credentials): Promise<Auth> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
    };
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', credentials.login)
      .set('password', credentials.password)
      .set('client_id', 'spc-frontend')
      .set('client_secret', 'q1w2e3r4t5');

    return this.postForm(this.authorizationTokenUrl, body, httpOptions);
  }

  public postForm<T>(url: string, data: HttpParams, httpOptions?: HttpOptions): Promise<T> {
    return this.postInternal(url, data.toString(), httpOptions);
  }

  public post<T>(url: string, data: any): Promise<T> {
    const options = ApiService.createDefaultOptions();

    return this.postInternal(url, JSON.stringify(data), options);
  }

  private postInternal<T>(url: string, data: any, options: HttpOptions): Promise<T> {
    return this.handleRequest(
      url,
      options,
      (requestUrl: string, httpOptions: HttpOptions) => this.httpClient.post<T>(requestUrl, data, httpOptions));
  }

  public get<T>(url: string, options: HttpOptions = ApiService.createDefaultOptions()): Promise<T> {
    return this.handleRequest<T>(
      url,
      options,
      (requestUrl: string, httpOptions: HttpOptions) => this.httpClient.get<T>(requestUrl, httpOptions));
  }

  public put<T>(url: string, data: any): Promise<T> {
    const options = ApiService.createDefaultOptions();

    return this.handleRequest<T>(
      url,
      options,
      (requestUrl: string, httpOptions: HttpOptions) => this.httpClient.put<T>(requestUrl, JSON.stringify(data), httpOptions));
  }

  public delete<T>(url: string): Promise<T> {
    const options = ApiService.createDefaultOptions();

    return this.handleRequest<T>(
      url,
      options,
      (requestUrl: string, httpOptions: HttpOptions) => this.httpClient.delete<T>(requestUrl, httpOptions));
  }

  private handleRequest<T>(
    url: string,
    options: HttpOptions,
    requestAction: (requestUrl: string, httpOptions: HttpOptions) => Observable<T>): Promise<T> {

    url = `${this.apiPrefix}/${url}`;

    return requestAction(url, options)
      .toPromise()
      .then(response => response)
      .catch(this.handleError.bind(this));
  }

  private handleError(error: any): any {
    // HTTP 401 - Authorization Rejected
    if (Number(error.status) === 401) {
      this.logout();
      return Promise.reject('Session expired');

      // if (this.router.url !== String('/login') && this.router.url !== String('/')) {
      //   window.location.assign(window.location.pathname + '/auth/login');
      // }

      // HTTP 403 - Forbidden
    } else if (Number(error.status) === 403) {
      return Promise.reject(error.error);
    } else {
      console.error(`An error occurred. Error code: ${Number(error.status)}`, error);
      const promiseError = !error.error || 'error';

      if (error.errorDetails) {
        this.helpersService.notificationTrigger(NotifierType.Error, error.errorDetails.Message, error.errorDetails.ClassName);
      }
      return Promise.reject(promiseError);
    }
  }

  public logout(): void {
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.router.url } }).then();
  }

  public getApplicationInfo(): Promise<ApplicationInfo> {
    const relativeUrl = `ApplicationInfo`;

    return this.get(relativeUrl);
  }

  public getPartsByCharacteristicLocation(locationId: string): Promise<Part[]> {
    const relativeUrl = `Characteristic/Location/GetParts/${locationId}`;

    return this.get(relativeUrl);
  }
}
