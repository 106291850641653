import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import Keyboard from 'simple-keyboard';
import { animate, style, transition, trigger } from '@angular/animations';

export interface KeyboardPosition {
  top: number;
  left: number;
}

export interface ElementRect {
  bottom: number;
  readonly height: number;
  left: number;
  right: number;
  top: number;
  readonly width: number;
}

@Component({
  selector: 'app-virtual-keyboard',
  templateUrl: './virtual-keyboard.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './virtual-keyboard.component.scss',
    '../../../../node_modules/simple-keyboard/build/css/index.css'
  ],
  animations: [
    trigger(
      'myAnimation',
      [
        transition(
          ':enter', [
            style({transform: 'translateX(100%)', opacity: 0}),
            animate('500ms', style({transform: 'translateX(0)', 'opacity': 1}))
          ]
        ),
        transition(
          ':leave', [
            style({transform: 'translateX(0)', 'opacity': 1}),
            animate('500ms', style({transform: 'translateX(100%)', 'opacity': 0}))
          ]
        )]
    )
  ],
})
export class VirtualKeyboardComponent implements OnChanges {
  // private readonly defaultTopPosition = -20;
  // private readonly defaultLeftPosition = -770;
  // private readonly defaultLeftNumericKeyboard = -290;

  private readonly defaultTopPosition = 0;
  private readonly defaultLeftPosition = 0;
  private readonly defaultLeftNumericKeyboard = 0;
  private readonly defaultLayout = {
    'default': [
      '{esc} ` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      '{lock} a s d f g h j k l {enter}',
      '{shift} z x c v b n m , . / {shift}',
      '{space}'
    ],
    'shift': [
      '{esc} ~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      '{lock} A S D F G H J K L {enter}',
      '{shift} Z X C V B N M < > ? {shift}',
      '{space}'
    ]
  };
  private readonly numericLayout = {
    'default': [
      '1 2 3 +',
      '4 5 6 -',
      '7 8 9 .',
      '0 {bksp}',
      '{enterData}'
    ]
  };

  private pushedUpKeyboardPosition = false;
  private keyboard: Keyboard;
  private val = '';
  private backspace = false;
  private lastKeyboardPosition: KeyboardPosition;
  private lastEventBtn: string;

  public positionTop = '';
  public positionLeft = '';
  public currentKeyboardPosition: KeyboardPosition = {top: 0, left: 0};
  public keyboardWidth: number;
  public keyboardHeight = 280;

  @Input()
  public keyboardPlace: string;

  @Input()
  public keyboardRect: ElementRect;

  @Input()
  public set keyboardPosition(value: number[]) {
    const keyboardPosition = value
      ? {top: value[0], left: value[1]}
      : null;

    this.setPosition(keyboardPosition);
  }

  @Input()
  public show: boolean;

  @Input()
  public numericKeyboard: boolean;

  @Input()
  set inputValue(value: string) {
    this.val = value;
    if (this.keyboard) {
      this.keyboard.setInput(value);
    }
  }

  @Output() public value = new EventEmitter<string>();
  @Output() public showOff = new EventEmitter();

  constructor(private virtualKeyboardRef: ElementRef) {
    window.addEventListener('keyup', (event: KeyboardEvent) => {
      const enterPressOnNumericKeyboard = event.key === 'Enter' && this.numericKeyboard;
      const escPress = event.key === 'Escape';
      if (this.show && (escPress || enterPressOnNumericKeyboard)) {
        this.showOff.emit();
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.show && this.show === true) {
      setTimeout(() => {
        const layout = this.numericKeyboard ? this.numericLayout : this.defaultLayout;
        this.keyboard = new Keyboard({
          onChange: input => this.onChange(input),
          onKeyPress: button => this.onKeyPress(button),
          layout: layout,
          newLineOnEnter: !this.numericKeyboard,
          mergeDisplay: true,
          display: {
            '{enterData}': 'enter',
          }
        });
        this.keyboard.setInput(String(this.val));
        if (this.numericKeyboard) {
          this.keyboard.addButtonTheme('+', 'keyboard-numeric-plus-button');
          this.keyboard.addButtonTheme('{bksp}', 'keyboard-numeric-backspace-button');
        }
        this.setPosition(this.lastKeyboardPosition);
      });
    } else if (changes.show && this.show === false) {
      this.pushedUpKeyboardPosition = false;
    }
  }

  private setPosition(keyboardPosition: KeyboardPosition): void {
    this.lastKeyboardPosition = keyboardPosition;
    const positionAfterCorrection = this.positionsCorrections();
    this.setCurrentPosition(positionAfterCorrection);
  }

  private positionsCorrections(): KeyboardPosition {
    if (this.numericKeyboard) {
      this.keyboardWidth = 300;
    } else {
      if (document.body.clientWidth < 1440) {
        this.keyboardWidth = 500;
      } else {
        this.keyboardWidth = 800;
      }
    }

    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    let top = 0;
    let left = 0;
    if (this.show) {
      if (this.keyboardPlace === 'top') {
        top = this.keyboardRect.top - this.keyboardHeight > 0
          ? Math.min(windowHeight - this.keyboardHeight, this.keyboardRect.top - this.keyboardHeight)
          : this.keyboardRect.top > 0
            ? this.keyboardRect.bottom
            : 0;
      } else if (this.keyboardPlace !== 'top') {
        top = this.keyboardRect.bottom + this.keyboardHeight < windowHeight
          ? Math.max(0, this.keyboardRect.bottom)
          : this.keyboardRect.bottom < windowHeight
            ? this.keyboardRect.top - this.keyboardHeight
            : windowHeight - this.keyboardHeight;
      }

      const xShift = document.body.clientWidth < 1440
        ? 300
        : 0;

      left = this.keyboardRect.right - this.keyboardWidth + xShift;
    }

    return {
      top: top,
      left: left
    };
  }

  private setCurrentPosition(positionAfterCorrection: KeyboardPosition): void {
    if (this.lastKeyboardPosition) {
      this.currentKeyboardPosition = {
        top: positionAfterCorrection.top,
        left: positionAfterCorrection.left
      };
    }
  }

  public onChange(input: string): void {
    if (this.numericKeyboard) {
      let inp = input;
      const firstChar = input.charAt(0);
      if (this.numericKeyboard && input.split('-').length > 1 || input.split('+').length > 1) {
        inp = inp.replace(/-/g, '');
        inp = inp.replace(/\+/g, '');
        if (firstChar === '-') {
          inp = '-' + inp;
        }
      }
      const string1 = inp.slice(0, inp.indexOf('.') + 1);
      const string2 = inp.slice(inp.indexOf('.') + 1);
      inp = (string1 + string2.split('.').join('')).replace(/\s/g, '');
      if (firstChar !== '-' && this.lastEventBtn === '-') {
        inp = '-' + inp;
      }
      if (firstChar === '-' && this.lastEventBtn === '+') {
        inp = inp.substr(1);
      }
      this.keyboard.setInput(inp);
      this.backspace = false;
      this.value.emit(inp);
    } else {
      this.value.emit(input);
    }
  }

  public onKeyPress(button: string): void {
    if (button === '{shift}' || button === '{lock}') {
      this.handleShift();
    }
    if (button === '{bksp}') {
      this.backspace = true;
    }
    if (button === '{enterData}' && this.numericKeyboard) {
      this.showOff.emit();
    }
    if (button === '{esc}') {
      this.showOff.emit();
    }
    this.lastEventBtn = button;
  }

  private handleShift(): void {
    const currentLayout = this.keyboard.options.layoutName;
    const shiftToggle = currentLayout === 'default' ? 'shift' : 'default';
    this.keyboard.setOptions({
      layoutName: shiftToggle
    });
  }
}
