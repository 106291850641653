import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {RuleOption, TranslationService} from '@services/translation.service';
import {Subscription} from 'rxjs';
import {ComponentsTranslation} from '@models/translation';
import {LabeledValue} from '@common/global';
import {remove} from 'lodash-es';

@Component({
  selector: 'app-expanded-select',
  templateUrl: './expanded-select.component.html',
  styleUrls: ['./expanded-select.component.scss']
})
export class ExpandedSelectComponent implements OnDestroy {
  private readonly subscriptions = new Subscription();
  public componentsTranslation: ComponentsTranslation;

  @Input() label = '';
  @Input() disabled = 'false';
  @Input() selectOptions: LabeledValue<RuleOption>[] = [];
  @Input() chosenSelectOptions: LabeledValue<RuleOption>[] = [];
  @Input() onSelectChanged;
  @Input() onRulesClear;

  constructor(private translationService: TranslationService) {
    this.setTranslation();
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.componentsTranslation = translation.components;
    });

    this.subscriptions.add(translationSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public removeSelectedRule(ruleOption: LabeledValue<RuleOption>, event: MouseEvent): void {
    console.log(ruleOption);

    remove(this.chosenSelectOptions, x => x.value.ruleId === ruleOption.value.ruleId);
    this.chosenSelectOptions = [...this.chosenSelectOptions];
  }

}
