import {Injectable} from '@angular/core';
import {User} from '@models/user';
import {UserApiService} from '@user/user-api.service';
import {UserService} from '@user/user.service';
import {BehaviorSubject, Subject} from 'rxjs';

export enum DataViewMode {
  TableView = 1,
  TreeView = 2
}

@Injectable()
export class UserSettingsService {
  private dataViewMode: DataViewMode;
  private loggedUser: User;

  public readonly dataViewMode$: Subject<DataViewMode> = new BehaviorSubject(DataViewMode.TableView);
  public readonly rowFieldsProcessChart$: Subject<number> = new BehaviorSubject(10);

  public rowQuantity: number;

  constructor(
    private userApiService: UserApiService,
  ) {
    this.loggedUser = UserService.getLoggedUser();
    this.setListTypeOnInit();
    this.setRowQuantityOnInit();
  }

  private setListTypeOnInit(): void {
    this.dataViewMode = this.loggedUser && this.loggedUser.listType
      ? this.loggedUser.listType
      : DataViewMode.TableView;

    this.dataViewMode$.next(this.dataViewMode);
  }

  private setRowQuantityOnInit(): void {
    this.rowQuantity = this.loggedUser && this.loggedUser.rowQuantity
      ? this.loggedUser.rowQuantity
      : 10;

    this.rowFieldsProcessChart$.next(this.rowQuantity);
  }

  public setListType(dataViewMode: DataViewMode): void {
    this.dataViewMode = dataViewMode;
    this.dataViewMode$.next(dataViewMode);
    this.updateUser();
  }

  public setRowQuantity(value: number): void {
    this.rowQuantity = value;
    this.rowFieldsProcessChart$.next(value);
    this.updateUser();
  }

  public updateUser(): void {
    if (!this.loggedUser) {
      this.loggedUser = UserService.getLoggedUser();
    }

    this.loggedUser.rowQuantity = this.rowQuantity;
    this.loggedUser.listType = this.dataViewMode;

    UserService.saveLoggedUser(this.loggedUser);
    this.userApiService.updateUserMe(this.loggedUser)
      .then();
  }
}
