export class BaseModel {
  dateCreated?: Date;
  dateModified?: Date;
  userCreatedBy?: string;
  userModifiedBy?: string;
  name?: string;
  department?: string;
  enabled?: boolean;
  favorite?: boolean;
  hidden?: boolean;
  id?: number;
  location?: any;
  locationId?: string;
  path?: string;
  plant?: string;
  userFullName?: string;
  userId?: number;
  isCMM?: boolean;

  constructor() {
  }
}
