<app-loader
  [isLoading]="isLoading"
  [isBackgroundLoading]="false">
</app-loader>

<div class="wrapper"
     (resized)="onResized($event)">
  <div
    #chartContainer
    id="container"
    class="inner-chart"
    [ngClass]="{'full-chart' : !isOptionEnabled || !isOptionsOpened}">
  </div>

  <div *ngIf="isOptionEnabled"
       #optionPanel
       [ngClass]="{
       'open-panel': isOptionEnabled && isOptionsOpened,
       'close-panel': isOptionEnabled && !isOptionsOpened
       }">
    <div class="option-panel">
      <div class="card">
        <div class="card-header">
          <h5 class="card-header-left">
            {{chartCardTitle}}
          </h5>
        </div>

        <div class="d-flex">
          <div class="col-sm-5 panel ml-1">
            <ng-container *ngFor="let option of chartOptions.showOptions">
              <div *ngIf="option.available"
                   class="panel-row">
                <div class="switch-label"
                     [ngbTooltip]="option.limitValue.toString()">
                  <ui-switch
                    class="js-single"
                    size="small"
                    name="active"
                    [(ngModel)]="option.showLimit"
                    (ngModelChange)="changePlotLine($event, option.limitType)">
                  </ui-switch>

                  <label class="col-form-label">
                    {{option.label}}
                  </label>
                </div>
              </div>
            </ng-container>
            <!--<ng-container>
              <div class="panel-row">
                <div class="switch-label"
                     [ngbTooltip]="componentsTranslation.chartOptions_trendLine">
                  <ui-switch
                    class="js-single"
                    size="small"
                    name="active"
                    [(ngModel)]="characteristic.limitConfig.trendLineLocationDraw"
                    (ngModelChange)="generateChart()">
                  </ui-switch>

                  <label class="col-form-label">
                    {{componentsTranslation.chartOptions_trendLine}}
                  </label>
                </div>
              </div>
            </ng-container>-->
          </div>

          <div class="col-sm-5 panel mr-1 align-items-center">
            <div class="panel-row align-items-center">
              <input
                type="number"
                class="form-control"
                name="ticksNumber"
                min="2"
                step="1"
                (keypress)="onKeyUp($event)"
                [(ngModel)]="chartOptions.ticksAmount"
                (ngModelChange)="updateYAxisTicksAmount()"
              />

              <label>
                {{componentsTranslation.rangeChart_ticksAmount}}
              </label>
            </div>

<!--            change output is used instead of ngModelChange due it allows to clip just after it changed-->
            <div class="panel-row">
              <input
                #yAxisMin
                class="form-control"
                type="number"
                name="YAxisMinValue"
                [max]="yAxisMax.value"
                [step]="chartOptions.minMaxStep"
                [(ngModel)]="chartOptions.yAxisMinValue"
                (change)="clipYAxisMinValue()"
              />

              <label>
                {{componentsTranslation.chartOptions_yAxisMin}}
              </label>
            </div>

<!--            change output is used instead of ngModelChange due it allows to clip just after it changed-->
            <div class="panel-row">
              <input
                #yAxisMax
                class="form-control"
                type="number"
                name="YAxisMaxValue"
                [min]="yAxisMin.value"
                [step]="chartOptions.minMaxStep"
                [(ngModel)]="chartOptions.yAxisMaxValue"
                (change)="clipYAxisMaxValue()"
              />

              <label>
                {{componentsTranslation.chartOptions_yAxisMax}}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
