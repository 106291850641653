import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {UserService} from '@user/user.service';
import {Subscription} from 'rxjs';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

enum ImageType {
  Base64 = 1,
  Url
}
@Component({
  selector: 'app-user-photo',
  templateUrl: './user-photo.component.html',
  styleUrls: ['./user-photo.component.scss']
})
export class UserPhotoComponent implements OnInit, OnChanges, OnDestroy {
  private readonly subscriptions = new Subscription();
  private readonly defaultImageUrl = 'assets/images/mysterious.png';

  public readonly imageTypes = ImageType;
  public imageType: ImageType;
  public photoUrl = this.defaultImageUrl;
  public sanitizedBase64Image: SafeUrl;

  @Input() photo: string;
  @Input() urlPhotoPrefix: string;
  @Input() photoClass: string;

  constructor(private domSanitizer: DomSanitizer) { }

  public ngOnInit(): void {
    const userSubscription = UserService.user$.subscribe(() => this.updateUrl());
    this.subscriptions.add(userSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.updateUrl();
  }

  public updateUrl(): void {
    this.photoUrl = null;
    this.sanitizedBase64Image = null;

    if (this.photo) {
      if (this.photo.includes('base64')) {
        this.imageType = ImageType.Base64;
        this.sanitizedBase64Image = this.domSanitizer.bypassSecurityTrustUrl(this.photo);
      } else if (this.urlPhotoPrefix) {
        this.imageType = ImageType.Url;
        this.photoUrl = this.urlPhotoPrefix + this.photo;

        // workaround to trigger load the image from server due to link does not change, but file on server
        this.photoUrl += `#${new Date().getTime()}`;
      }
    } else {
      this.imageType = ImageType.Url;
      this.photoUrl = this.defaultImageUrl;
    }
  }
}
