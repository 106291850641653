import {Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {ModalBasicComponent} from '@shared/modal-basic/modal-basic.component';
import {Characteristic} from '@models/characteristic';
import {ReactionPlan} from '@models/reaction-plan';
import {GlobalVariable, LabeledValue} from '@common/global';
import {User} from '@models/user';
import swal from 'sweetalert2';
import {NgForm} from '@angular/forms';
import {RuleOption, TranslationService} from '@services/translation.service';
import {ComponentsTranslation, GlobalTranslation} from '@models/translation';
import {HelpersService, NotifierType} from '@services/helpers.service';
import {UserService} from '@user/user.service';
import {ReactionPlanReasonType} from '@models/reaction-plan-reason';
import {SignatureType, VerificationConfirmation} from '@models/verification-confirmation';
import {CharacteristicApiService} from '@characteristic/characteristic-api.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-reaction-plan',
  templateUrl: './reaction-plan.component.html',
  styleUrls: ['./reaction-plan.component.scss']
})
export class ReactionPlanComponent implements OnDestroy {
  private readonly subscriptions = new Subscription();

  public readonly signatureTypes = SignatureType;

  public loggedUser: User;
  public rules: RuleOption[] = [];
  public openPermissionModal = false;
  public checkPermissionModalTitle = '';
  public requiredPermission: SignatureType;
  public qualityEngineerAuthorized = false;
  public userAccessBlocked = false;
  public supervisorAuthorized = false;
  public reactionPlanModel: ReactionPlan = new ReactionPlan();
  public componentsTranslation: ComponentsTranslation;
  public globalTranslation: GlobalTranslation;
  public actionTakenOptions: LabeledValue<string>[] = [];
  public materialDispositionOptions: LabeledValue<string>[] = [];


  public get isFormValid(): boolean {
    return !!this.characteristic &&
      (!this.characteristic.supervisorSignOff || this.reactionPlanModel.supervisorSignOffComplete) &&
      (!this.characteristic.qualitySignOff || this.reactionPlanModel.qualityEngSignOffComplete);
  }

  @Input() characteristic: Characteristic;
  @Output() refresh = new EventEmitter();

  @ViewChild('modal') modal: ModalBasicComponent;
  @ViewChild('reactionPlan') reactionPlan: NgForm;

  constructor(
    private characteristicApiService: CharacteristicApiService,
    private helpersService: HelpersService,
    private translationService: TranslationService
  ) {
    this.setTranslation();

    this.translationService.rules$.subscribe(rules => {
      this.rules = rules;
    });
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.componentsTranslation = translation.components;
      this.globalTranslation = translation.global;
    });

    this.subscriptions.add(translationSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public init(): void {
    this.loggedUser = UserService.getLoggedUser();
    this.setPermissions();
    this.setDefaultOperatorInitials();
    this.getReactionPlanOptions();
  }

  private setDefaultOperatorInitials(): void {
    if (!this.reactionPlanModel.operatorInitials) {
      this.reactionPlanModel.operatorInitials = this.loggedUser.initials;
    }
  }

  private setPermissions(): void {
    this.userAccessBlocked = !HelpersService.hasUserPermission(this.loggedUser, 'reactionPlan.editOperationsInput');
  }

  private getReactionPlanOptions(): void {
    this.characteristicApiService.getReactionPlanReasons().then(reactionPlanReasons => {
      const filterReactionPlanOptions = (reactionPlanReasonType: ReactionPlanReasonType) => {
        return reactionPlanReasons
          .filter(reactionPlanReason => reactionPlanReason.type === reactionPlanReasonType)
          .map(reactionPlanReason => new LabeledValue<string>(reactionPlanReason.name));
      };

      this.actionTakenOptions = filterReactionPlanOptions(ReactionPlanReasonType.ActionTaken);
      this.materialDispositionOptions = filterReactionPlanOptions(ReactionPlanReasonType.MaterialDisposition);
    });
  }

  public onSubmitOperationsReaction() {
    this.reactionPlanModel.completedOperations = true;
    this.updateReactionPlan();
  }

  public onSubmitCompletedReaction(): void {
    let completedPlan = true;
    if (this.characteristic.supervisorSignOff) {
      completedPlan = completedPlan && this.reactionPlanModel.supervisorSignOffComplete;
    }
    if (this.characteristic.qualitySignOff) {
      completedPlan = completedPlan && this.reactionPlanModel.qualityEngSignOffComplete;
    }
    this.reactionPlanModel.completedPlan = completedPlan;
    this.updateReactionPlan();
  }

  private updateReactionPlan(): void {
    this.characteristicApiService.updateReactionPlan(this.reactionPlanModel.id, this.reactionPlanModel)
      .then(() => {
        this.helpersService.notificationTrigger(NotifierType.Success, this.componentsTranslation.reactionPlan_reactionPlanSavedMsg);
      })
      .then(() => {
        this.resetModal();
        this.refresh.emit();
      })
      .catch(() => {
      });
  }

  public isRuleBroken(ruleOption: RuleOption): boolean {
    let ruleBroken = false;

    this.reactionPlanModel.brokenRules.forEach((rule) => {
      if (ruleOption.ruleId === rule.brokenRule) {
        ruleBroken = true;
      }
    });

    return ruleBroken;
  }

  public showModal(reactionPlan: ReactionPlan): void {
    this.reactionPlanModel = reactionPlan;
    this.init();
    this.modal.show();
  }

  public closeModal(): void {
    if (this.reactionPlanModel.completedPlan || this.userAccessBlocked) {
      this.resetModal();

      return;
    }

    swal({
      title: this.globalTranslation.removeMsgTitle,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: `${GlobalVariable.COLOR.confirmButtonColor}`,
      cancelButtonColor: `${GlobalVariable.COLOR.cancelButtonColor}`,
      confirmButtonText: this.globalTranslation.confirmButtonText
    }).then((answer) => {
      if (answer) {
        this.resetModal();
      }
    });
  }

  public resetModal(): void {
    this.qualityEngineerAuthorized = false;
    this.supervisorAuthorized = false;
    this.reactionPlan.resetForm();
    this.reactionPlanModel = new ReactionPlan();
    this.modal.hide();
  }

  public supervisorSingOff(): void {
    this.openSignOffModal(this.componentsTranslation.reactionPlan_svLoginComponentTitle, SignatureType.Supervisor);
  }

  public qualitySingOff(): void {
    this.openSignOffModal(this.componentsTranslation.reactionPlan_qeLoginComponentTitle, SignatureType.QualityEngineer);
  }

  private openSignOffModal(checkPermissionModalTitle: string, requiredPermission: SignatureType): void {
    this.checkPermissionModalTitle = checkPermissionModalTitle;
    this.requiredPermission = requiredPermission;
    this.openPermissionModal = true;
  }

  public onUserAuthorized(verificationConfirmation: VerificationConfirmation): void {
    if (verificationConfirmation) {
      if (this.requiredPermission === SignatureType.Supervisor) {
        this.reactionPlanModel.supervisorInitials = verificationConfirmation.item2;
        this.supervisorAuthorized = true;
      } else if (this.requiredPermission === SignatureType.QualityEngineer) {
        this.reactionPlanModel.qualityEngInitials = verificationConfirmation.item2;
        this.qualityEngineerAuthorized = true;
      }
    }
  }
}
