
import {filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {EnvironmentType} from '@common/enums/environment-type';
import {Language} from '@common/enums/language';
import {GlobalVariable} from '@common/global';
import {ChineseTranslation} from '@common/translations/chinese';
import {EnglishTranslation} from '@common/translations/english';
import {PolishTranslation} from '@common/translations/polish';
import {PortugueseTranslation} from '@common/translations/portuguese';
import {SpanishTranslation} from '@common/translations/spanish';
import {environment} from '@environment/environment';
import {Characteristic} from '@models/characteristic';
import {Translation} from '@models/translation';
import {UserService} from '@user/user.service';
import {BehaviorSubject} from 'rxjs';
import {nameof} from 'ts-simple-nameof';

export interface RuleOption {
  ruleId: number;
  id: string;
  label: string;
  name: string;
  disabled?: boolean;
}

@Injectable()
export class TranslationService {
  private readonly localeTypes = {
    [Language.English]: GlobalVariable.TYPE_LOCALE.english,
    [Language.Polish]: GlobalVariable.TYPE_LOCALE.polish,
    [Language.Spanish]: GlobalVariable.TYPE_LOCALE.spanish,
    [Language.Chinese]: GlobalVariable.TYPE_LOCALE.chinese,
    [Language.Portuguese]: GlobalVariable.TYPE_LOCALE.portuguese,
  };

  private readonly translationsObject = <{[number: string]: Translation}>{
    [Language.English]: EnglishTranslation.translation,
    [Language.Polish]: PolishTranslation.translation,
    [Language.Spanish]: SpanishTranslation.translation,
    [Language.Chinese]: ChineseTranslation.translation,
    [Language.Portuguese]: PortugueseTranslation.translation
  };

  public translations$: BehaviorSubject<Translation> = new BehaviorSubject(this.translationsObject[Language.English]);
  public translationLocale$: BehaviorSubject<string> = new BehaviorSubject(GlobalVariable.TYPE_LOCALE.english);
  public rules$: BehaviorSubject<RuleOption[]> = new BehaviorSubject([]);

  public translations: Translation;
  public translationLocale: string;

  constructor() {
    let translationId = Language.English;
    UserService.user$.pipe(
      filter(loggedUser => loggedUser && !!loggedUser.language))
      .subscribe(loggedUser => translationId = loggedUser.language);

    this.setTranslation(translationId);
  }

  public setTranslation(languageNumber: Language): void {
    try {
      this.translations = this.translationsObject[languageNumber];
    } catch (e: any) {
      return;
    }
    this.translations$.next(this.translations);
    this.setGlobalVariablesTranslation();
    this.setSeparationType(languageNumber);
  }

  private setGlobalVariablesTranslation(): void {
    this.setRulesDescription(this.translations);
  }

  private setSeparationType(languageNumber: Language): void {
    this.translationLocale = this.localeTypes[languageNumber];
    this.translationLocale$.next(this.translationLocale);
  }

  private setRulesDescription(language: Translation): void {
    const ruleLabelPrefix = 'Rule ';
    this.rules$.next([
      { id: nameof<Characteristic>(obj => obj.rule1), ruleId: 1, label: `${ruleLabelPrefix}1`, name: language.global.ruleOneDescription, disabled: false },
      { id: nameof<Characteristic>(obj => obj.rule2), ruleId: 2, label: `${ruleLabelPrefix}2`, name: language.global.ruleTwoDescription, disabled: false },
      { id: nameof<Characteristic>(obj => obj.rule3), ruleId: 3, label: `${ruleLabelPrefix}3`, name: language.global.ruleThreeDescription, disabled: false },
      { id: nameof<Characteristic>(obj => obj.rule4), ruleId: 4, label: `${ruleLabelPrefix}4`, name: language.global.ruleFourDescription, disabled: false },
      { id: nameof<Characteristic>(obj => obj.rule5), ruleId: 5, label: `${ruleLabelPrefix}5`, name: language.global.ruleFiveDescription, disabled: false },
      { id: nameof<Characteristic>(obj => obj.rule6), ruleId: 6, label: `${ruleLabelPrefix}6`, name: language.global.ruleSixDescription, disabled: false },
      { id: nameof<Characteristic>(obj => obj.rule7), ruleId: 7, label: `${ruleLabelPrefix}7`, name: language.global.ruleSevenDescription, disabled: false },
      { id: nameof<Characteristic>(obj => obj.rule8), ruleId: 8, label: `${ruleLabelPrefix}8`, name: language.global.ruleEightDescription, disabled: false },
      { id: nameof<Characteristic>(obj => obj.rule9), ruleId: 9, label: `${ruleLabelPrefix}9`, name: language.global.ruleNineDescription, disabled: false },
      { id: nameof<Characteristic>(obj => obj.rule10), ruleId: 10, label: `${ruleLabelPrefix}10`, name: language.global.ruleTenDescription, disabled: false },
      ... GlobalVariable.isNotMexico
        ? [{ id: nameof<Characteristic>(obj => obj.rule11), ruleId: 11, label: `${ruleLabelPrefix}11`, name: language.global.ruleElevenDescription, disabled: false }]
        : [],
      { id: nameof<Characteristic>(obj => obj.rule12), ruleId: 12, label: `${ruleLabelPrefix}12`, name: language.global.ruleTwelveDescription, disabled: false },
      { id: nameof<Characteristic>(obj => obj.rule13), ruleId: 13, label: `${ruleLabelPrefix}13`, name: language.global.ruleThirteenDescription, disabled: false }
    ]);
  }
}
