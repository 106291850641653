import { Characteristic } from '@models/characteristic';
import {SignatureType} from '@models/verification-confirmation';

export class Signature {
  remarks: string;
  initials: string;
  complete: boolean;
  date: Date;
}

export class ReactionPlan {
  public actionTaken: string;
  public bgColor: string;
  public brokenRules: BrokenRule[] = [];
  public characteristic: Characteristic;
  public characteristicCollectionId: number;
  public characteristicId: number;
  public characteristicReactionPlanCollection: ReactionPlan[] = [];
  public completedPlan = false;
  public completedOperations = false;
  public dateCreated: Date;
  public dateModified: Date;
  public deviationValue = 0;
  public id: number = null;
  public lsl: number = null;
  public materialDisposition: string;
  public no: number = null;
  public operatorComment: string;
  public operatorInitials: string;
  public partNumber: string;
  public processStopped = false;
  public ruleBreakOverride = false;
  public qualityEngComment: string;
  public qualityEngInitials: string;
  public qualityEngSignOffComplete = false;
  public qualityEngSignOffDate: Date;
  public supervisorComment: string;
  public supervisorInitials: string;
  public supervisorSignOffComplete = false;
  public supervisorSignOffDate: Date;
  public usl: number;
  public value: string;

  constructor(reactionPlan: Partial<ReactionPlan> = {}
  ) {
    Object.assign(this, reactionPlan);
  }

  public getSignature(signatureType: SignatureType): Signature {
    switch (signatureType) {
      case SignatureType.Supervisor:
        return this.supervisorSignature;
      case SignatureType.QualityEngineer:
        return this.qualityEngineerSignature;
    }
  }

  public setSignature(signatureType: SignatureType, signature: Signature): void {
    switch (signatureType) {
      case SignatureType.Supervisor:
        this.supervisorSignature = signature;
        break;
      case SignatureType.QualityEngineer:
        this.qualityEngineerSignature = signature;
        break;
    }
  }

  get supervisorSignature(): Signature {
    return {
      remarks: this.supervisorComment,
      initials: this.supervisorInitials,
      complete: this.supervisorSignOffComplete,
      date: this.supervisorSignOffDate
    };
  }

  set supervisorSignature(signature: Signature) {
    this.supervisorComment = signature.remarks;
    this.supervisorInitials = signature.initials;
    this.supervisorSignOffComplete = signature.complete;
    this.supervisorSignOffDate = signature.date;
  }

  get qualityEngineerSignature(): Signature {
    return {
      remarks: this.qualityEngComment,
      initials: this.qualityEngInitials,
      complete: this.qualityEngSignOffComplete,
      date: this.qualityEngSignOffDate
    };
  }

  set qualityEngineerSignature(signature: Signature) {
    this.qualityEngComment = signature.remarks;
    this.qualityEngInitials = signature.initials;
    this.qualityEngSignOffComplete = signature.complete;
    this.qualityEngSignOffDate = signature.date;
  }
}

export class BrokenRule {
  reactionPlanId: number;
  brokenRule: number;
}
