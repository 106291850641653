import {VirtualKeyboardService} from '@shared/virtual-keyboard/virtual-keyboard.service';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ModalBasicComponent} from '@shared/modal-basic/modal-basic.component';
import swal from 'sweetalert2';
import {Characteristic, GaugeType, SpecLimitType} from '@models/characteristic';
import {NgForm} from '@angular/forms';
import {Sample} from '@models/sample';
import {CharacteristicSampleCollection} from '@models/characteristic-sample-collection';
import {ProcessChart} from '@models/process-chart';
import {AddSample} from '@models/add-sample';
import {AttributeSampleValue, HelpersService, NotifierType} from '@services/helpers.service';
import {TranslationService} from '@services/translation.service';
import {ComponentsTranslation, GlobalTranslation} from '@models/translation';
import {User} from '@models/user';
import {SslLimitType} from '@models/limit-config';
import {UserService} from '@user/user.service';
import {LabeledValue} from '@common/global';
import {SampleReason} from '@models/sample-reason';
import {AddSamplesApiService} from '@shared/add-samples-api.service';
import {Subscription} from 'rxjs';
import {AddSampleData} from '@models/add-sample-data';
import {AddSampleSource} from '@shared/add-sample-modal/add-sample-source';
import {PartNumber} from '@models/part-number';


export interface AttributeSample {
  characteristic: Characteristic;
  value: number;
  collectionDesc: string;
}

@Component({
  selector: 'app-add-sample-modal',
  templateUrl: './add-sample-modal.component.html',
  styleUrls: ['./add-sample-modal.component.scss'],
  providers: [HelpersService]
})
export class AddSampleModalComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();

  private currentPartNumber: string;

  public readonly addSampleSources = AddSampleSource;

  public addSampleSource: AddSampleSource;
  public addSample = new AddSample();
  public averageOfSamples = new Map<number, number>();
  public averageOfSamplesBeforeOnNR = new Map<number, number>();
  public characteristicTooAddSamples: Characteristic;
  public componentsTranslation: ComponentsTranslation;
  public globalTranslation: GlobalTranslation;
  public hasSampleValueChanged = false;
  public loggedUser: User;
  public openModalAddSampleCollection: boolean;
  public parts: PartNumber[] = [];
  public characteristicData: Characteristic;
  public processChartData: ProcessChart;
  public sampleReasonsOptions: LabeledValue<number>[] = [];
  public sampleCollections: {[characteristicId: number]: CharacteristicSampleCollection} = {};
  public sampleCollectionsBeforeOnNR: {[characteristicId: number]: CharacteristicSampleCollection} = {};
  public sampleCollectionsToChange: CharacteristicSampleCollection;
  public selectedCharacteristics: Characteristic[] = [];
  public serialNumberEnabled = false;
  public withoutValuesAddSample = false;
  public isSubmitting: boolean;

  @Input() fromCharacteristic: boolean;
  @Input() processChartId: number;

  @Input() set characteristic(characteristic: Characteristic) {
    this.addSampleSource = AddSampleSource.Characteristic;
    this.characteristicData = characteristic;
  }

  @Input() set partNumber(partNumber: string) {
    this.currentPartNumber = partNumber;
    this.selectedCharacteristics = [];
  }

  @Input() set processChart(processChart: ProcessChart) {
    this.addSampleSource = AddSampleSource.ProcessChart;
    this.processChartData = processChart;
  }

  @Output() public refreshData = new EventEmitter();
  @Output() public setClose = new EventEmitter();

  @ViewChild('modal') modal: ModalBasicComponent;
  @ViewChild('sampleForm') currentForm: NgForm;

  constructor(
    private addSamplesApiService: AddSamplesApiService,
    private helperService: HelpersService,
    private translationService: TranslationService,
    public virtualKeyboardService: VirtualKeyboardService
  ) {
    this.setTranslation();

    this.loggedUser = UserService.getLoggedUser();
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.componentsTranslation = translation.components;
      this.globalTranslation = translation.global;
    });

    this.subscriptions.add(translationSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnInit(): void {
    this.initAddSample();
    this.setSampleValueInCharacteristicViewMode();
    this.setCharacteristicByPartNumber();
    this.getParts();
    this.setReasonOptions();
    this.modal.show();
  }

  private initAddSample(): void {
    this.checkIsSerialNumberEnabled();
    this.addSample = new AddSample();

    if (!this.serialNumberEnabled) {
      delete this.addSample.serialNumber;
    }
    if (this.addSampleSource === AddSampleSource.Characteristic) {
      delete this.addSample.notRunning;
    }

    this.currentForm.resetForm();
    setTimeout(() => {
      this.addSample.operatorInitials = this.loggedUser.initials;

      if (this.currentPartNumber) {
        this.addSample.partId = this.currentPartNumber;
      } else if (this.parts.length === 1) {
        this.addSample.partId = this.parts[0].partNumber;
      }

      this.currentForm.setValue(this.addSample);
    });
  }

  private checkIsSerialNumberEnabled(): void {
    if (this.characteristicData && this.characteristicData.serialNumber) {
      this.serialNumberEnabled = true;
    }
  }

  private setSampleValueInCharacteristicViewMode(): void {
    if (this.characteristicData) {
      this.sampleCollections = {
        [this.characteristicData.id]: {
          collectionDesc: this.characteristicData.description,
          characteristicId: this.characteristicData.id,
          characteristicCollectionSamples: null,
          calculationReset: false
        }
      };
    }
  }

  public setCharacteristicByPartNumber(): void {
    if (this.characteristicData) {
      this.selectedCharacteristics.push(this.characteristicData);
    } else {
      const selectedCharacteristics = this.processChartData.fullCharacteristics
        .filter(characteristic => characteristic.partNumbers.some(part => part.partNumber === this.currentPartNumber));
      this.selectedCharacteristics.push(...selectedCharacteristics);
    }
  }

  private getParts(): void {
    if (this.characteristicData) {
      this.parts = this.characteristicData.partNumbers;
    } else if (this.processChartData) {
      this.parts = [{partNumber: this.currentPartNumber}];
    }
  }

  public setReasonOptions(): void {
    let availableSampleReasons: SampleReason[];
    const characteristicsSampleReasons = new Set<number>();
    const setSampleReasonsAction = (characteristic: Characteristic) => {
      characteristic.sampleReasons
        .forEach(sampleReason => characteristicsSampleReasons.add(sampleReason.sampleReasonId));
    };

    this.addSamplesApiService.getSampleReasons().then(sampleReasons => {
      availableSampleReasons = sampleReasons;

      if (this.addSampleSource === AddSampleSource.Characteristic) {
        setSampleReasonsAction(this.characteristicData);
      } else {
        for (const characteristic of this.processChartData.fullCharacteristics) {
          setSampleReasonsAction(characteristic);
        }
      }

      this.sampleReasonsOptions = availableSampleReasons
        .filter(sampleReason => Array.from(characteristicsSampleReasons)
          .find(sampleReasonId => sampleReason.id === sampleReasonId))
        .map(sampleReason => new LabeledValue<number>(sampleReason.id, sampleReason.name));
    });

    this.sampleReasonsOptions = [];
  }

  public setButtonStyle(characteristic: Characteristic, notRunning: boolean): string {
    let classes = '';

    if (characteristic.gageType === GaugeType.Variable) {
      classes = this.setButtonStyleVariable(characteristic);
    } else if (characteristic.gageType === GaugeType.Attribute) {
      classes = this.setButtonStyleAttribute(characteristic.id);
    }
    if (notRunning || !characteristic.enabled) {
      classes += ' ' + 'is-disable';
    }

    return classes;
  }

  private setButtonStyleAttribute(id: number): string {
    let componentClass: string;
    const averageSampleValue = this.averageOfSamples.get(id) as AttributeSampleValue;

    switch (averageSampleValue) {
      case AttributeSampleValue.X:
        componentClass = 'add-sample-btn-fail';
        break;
      case AttributeSampleValue.Plus:
        componentClass = 'add-sample-btn-pass';
        break;
      case AttributeSampleValue.Null:
      case AttributeSampleValue.NR:
        componentClass = 'add-sample-btn-nr';
        break;
      default:
        componentClass = 'add-sample-btn-attribute';
    }

    return componentClass;
  }

  private setButtonStyleVariable(characteristic: Characteristic): string {
    const ruleBroken = this.checkIsRuleOneBroken(characteristic);
    if (ruleBroken === null) {
      return 'add-sample-btn';
    } else if (ruleBroken) {
      return 'add-sample-btn-fail';
    } else {
      return 'add-sample-btn-pass';
    }
  }

  private checkIsRuleOneBroken(characteristic: Characteristic): boolean {
    const value = Number(this.averageOfSamples.get(characteristic.id));

    if (!value) {
      return null;
    }

    switch (characteristic.specLimitType) {
      case null:
        return null;
      case SpecLimitType.OneSided:
        return this.oneSidedSpecLimitRuleCheck(value, characteristic);
      case SpecLimitType.TwoSided:
        return this.twoSidedSpecLimitRuleCheck(value, characteristic);
      default:
        throw new Error(`Unsupported spec limit type: ${characteristic.specLimitType}`);
    }
  }

  private oneSidedSpecLimitRuleCheck(value: number, characteristic: Characteristic): boolean {
    const limitConfig = characteristic.limitConfig;

    switch (characteristic.limitConfig.sslLimit) {
      case SslLimitType.min:
        return value < limitConfig.lslValue;
      case SslLimitType.max:
        return value > limitConfig.uslValue;
      default:
        throw new Error(`Unsupported spec limit type: ${characteristic.limitConfig.sslLimit}`);
    }
  }

  private twoSidedSpecLimitRuleCheck(value: number, characteristic: Characteristic): boolean {
    const {lslValue: lowerLimit, uslValue: upperLimit} = characteristic.limitConfig;

    return (value > upperLimit || value < lowerLimit);
  }

  public checkIsNotRunningOn(event): void {
    if (event) {
      this.sampleCollectionsBeforeOnNR = this.sampleCollections;
      this.averageOfSamplesBeforeOnNR = this.averageOfSamples;

      if (!this.characteristicData) {
        this.sampleCollections = {};
      }

      this.averageOfSamples.clear();
    } else {
      this.sampleCollections = this.sampleCollectionsBeforeOnNR;
      this.averageOfSamples = this.averageOfSamplesBeforeOnNR;
    }
  }

  public closeAddSampleModal(): void {
    this.openModalAddSampleCollection = false;
  }

  public openSampleModal(characteristic: Characteristic): void {
    if (!characteristic.enabled) {
      return;
    }
    if (!this.addSample.notRunning) {
      this.characteristicTooAddSamples = characteristic;
      this.sampleCollectionsToChange = null;
      if (this.sampleCollections[characteristic.id]) {
        this.sampleCollectionsToChange = this.sampleCollections[characteristic.id];
      }
      this.openModalAddSampleCollection = true;
      this.withoutValuesAddSample = this.setValueButton(characteristic) === this.componentsTranslation.addSampleModal_addSampleBtn;
    }
  }

  public closeModal(): void {
    const alertOptions = HelpersService.createAlertOptions(
      this.globalTranslation.removeMsgTitle,
      '',
      this.globalTranslation.confirmButtonText,
      'warning');

    swal(alertOptions)
      .then((answer) => {
        if (answer.value === true) {
          this.setClose.emit();
          this.currentForm.resetForm();
          this.selectedCharacteristics = [];
          this.averageOfSamples.clear();
          this.virtualKeyboardService.turnOffKeyboard();
          this.sampleReasonsOptions = [];
          this.parts = [];
          this.modal.hide();
        }
      });
  }

  public onSubmitSample(sampleForm: NgForm): void {
    if (sampleForm.invalid) {
      return;
    }

    this.isSubmitting = true;

    const prepareAndSendData = () => {
      const dataToSend: AddSampleData = {
        reasonId: this.addSample.reasonId,
        operatorInitials: this.addSample.operatorInitials,
        remarks: this.addSample.remarks,
        partId: this.currentPartNumber,
        notRunning: this.addSample.notRunning || false,
        processChartId: processChartId,
        calculationReset: this.addSample.calculationReset,
        characteristicSampleCollections: [
          ...this.prepareDataToApi(
            this.addSample.reasonId,
            this.currentPartNumber,
            this.addSample.serialNumber,
            this.addSample.operatorInitials,
            this.addSample.calculationReset
          )
        ]
      };

      this.sendDataToApi(dataToSend);
    };

    this.virtualKeyboardService.turnOffKeyboard();
    let processChartId: number = null;

    if (this.characteristicData) {
      processChartId = this.processChartId;

      if (this.addSampleSource === AddSampleSource.Characteristic) {
        this.currentPartNumber = this.addSample.partId;
      }
    } else {
      processChartId = this.processChartData.id;
    }

    if (this.addSampleSource === AddSampleSource.Characteristic && this.averageOfSamples.size === 0) {
      this.isSubmitting = false;
      return;
    }
    if (this.characteristicData && this.characteristicData.gageType !== GaugeType.Attribute
      && !(this.addSample.notRunning || this.hasSampleValueChanged)) {
      this.helperService.notificationTrigger(NotifierType.Error, this.componentsTranslation.addSampleModal_addSamplesError);
    }

    if ((this.addSample.notRunning || this.hasSampleValueChanged)
      || (this.characteristicTooAddSamples && this.characteristicTooAddSamples.gageType === GaugeType.Attribute)) {
      if (this.characteristicTooAddSamples) {
        this.addSamplesApiService.isCharacteristicInProcessChartAndGetId(this.characteristicTooAddSamples.id)
          .then(idProcessChart => {
            if (idProcessChart) {
              processChartId = idProcessChart;
            }
          }).then(() => {
          prepareAndSendData();
        });
      } else {
        prepareAndSendData();
      }
    }
  }

  public prepareDataToApi(reasonId: number, partNumber: string, serialNumber: string, operatorInitials: string, calculationReset: boolean
  ): CharacteristicSampleCollection[] {
    const listOfSampleCollection: CharacteristicSampleCollection[] = [];
    for (const samples in this.sampleCollections) {
      if (this.sampleCollections.hasOwnProperty(samples)) {
        const sampleCollection = this.sampleCollections[samples];
        if (sampleCollection) {
          listOfSampleCollection.push({
            collectionDesc: sampleCollection.collectionDesc || null,
            characteristicId: sampleCollection.characteristicId,
            reasonId: reasonId,
            operatorInitials: operatorInitials,
            characteristicCollectionSamples: sampleCollection.characteristicCollectionSamples,
            partNumber: partNumber,
            serialNumber: serialNumber,
            numberOfSamples: sampleCollection.numberOfSamples,
            calculationReset: calculationReset
          });
        }
      }
    }
    return listOfSampleCollection;
  }

  private sendDataToApi(data: AddSampleData): void {
    this.addSamplesApiService.addSamplesCollection(data)
      .then(() => {
        this.setClose.emit();
        this.modal.hide();
        this.currentForm.resetForm();
        this.currentForm.reset();
        this.helperService.notificationTrigger(NotifierType.Success, this.componentsTranslation.addSampleModal_addedSamplesMsg);

        this.selectedCharacteristics = [];
        this.averageOfSamples.clear();
        this.parts = [];
        this.isSubmitting = false;
      })
      .then(() => {
        this.refreshData.emit();
      });
  }



  public setValueButton(characteristic: Characteristic): string {
    if (this.averageOfSamples.has(characteristic.id)) {
      const averageSampleValue = this.averageOfSamples.get(characteristic.id);

      if (characteristic.gageType === GaugeType.Attribute) {
        switch (averageSampleValue) {
          case AttributeSampleValue.Null:
            return this.globalTranslation.emptyAttribute;
          case AttributeSampleValue.X:
            return this.globalTranslation.failAttribute;
          case AttributeSampleValue.Plus:
            return this.globalTranslation.passedAttribute;
          case AttributeSampleValue.NR:
          default:
            return this.globalTranslation.notRunningAttribute;
        }
      } else if (this.characteristicData.gageType === GaugeType.Variable) {
        return HelpersService.setDecimals(averageSampleValue, characteristic.decimals);
      }
    }

    return this.componentsTranslation.addSampleModal_addSampleBtn;
  }

  public setAttributeSample(attributeSample: AttributeSample): void {
    this.openModalAddSampleCollection = false;
    this.sampleCollections = {
      ...this.sampleCollections,
      [attributeSample.characteristic.id]: {
        characteristicCollectionSamples: <Sample[]>[{sampleValue: attributeSample.value}],
        collectionDesc: attributeSample.collectionDesc,
        characteristicId: attributeSample.characteristic.id,
        calculationReset: false
      }
    };
    this.averageOfSamples.set(attributeSample.characteristic.id, attributeSample.value);
  }

  public setSamples(samplesCollection: CharacteristicSampleCollection): void {
    if (samplesCollection.characteristicCollectionSamples[0].sampleValue === null) {
      return;
    }

    this.openModalAddSampleCollection = false;
    this.sampleCollections = {
      ...this.sampleCollections,
      [samplesCollection.characteristicId]: samplesCollection
    };

    this.averageOfSamples.set(samplesCollection.characteristicId, this.setAverageOfSamples(samplesCollection.characteristicCollectionSamples));

    if (samplesCollection.isSampleCorrect) {
      this.hasSampleValueChanged = samplesCollection.isSampleCorrect;
    }
  }

  private setAverageOfSamples(samplesCollection: Sample[]): number {
    let average = 0;
    let divideNumber = 0;
    samplesCollection.forEach((sample: Sample) => {
      if (sample.sampleValue !== null) {
        average += Number(sample.sampleValue);
        divideNumber++;
      }
    });

    if (divideNumber > 0) {
      return average / divideNumber;
    } else if (divideNumber === 0) {
      return 0;
    }
  }

  public setScrollEventsToButton(event: WheelEvent, characteristic: Characteristic): void {
    if (characteristic.gageType === GaugeType.Attribute) {
      event.preventDefault();
      const averageSampleValue = this.averageOfSamples.get(characteristic.id);
      const isScrollUpEvent = event.deltaY < 0;
      const isScrollDownEvent = event.deltaY > 0;

      if ((averageSampleValue === AttributeSampleValue.Plus && isScrollUpEvent) ||
        (averageSampleValue === AttributeSampleValue.X && isScrollDownEvent)) {
        return;
      }

      const attributeSampleValue = isScrollUpEvent
        ? AttributeSampleValue.Plus
        : AttributeSampleValue.X;

      this.sampleCollections[characteristic.id] = {
        characteristicCollectionSamples: [{sampleValue: attributeSampleValue}],
        collectionDesc: null,
        characteristicId: characteristic.id,
        calculationReset: false
      };

      this.averageOfSamples.set(characteristic.id, attributeSampleValue);
    }
  }
}
