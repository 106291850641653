import {Translation} from '@models/translation';

// tslint:disable:max-line-length
export class SpanishTranslation {
  public static readonly translation: Translation = {
    type: `spanish`,
    global: {
      addSampleBtn: `Agregar Muestra`,
      afterChangeoverOptions: `Después del Cambio`,
      afterCorrectionOptions: `After Corrections`,
      afterMaintenanceInterventionOptions: `Después de una Intervención de Mantenimiento`,
      afterMealOptions: `After Meal`,
      afterModelChangeOptions: `After Model Change`,
      afterProcessChangeOptions: `Después de Cambio de Proceso`,
      cancelBtn: `Cancelar`,
      clearBtn: `clear`,
      closeBtn: `Cerrar`,
      confirmButtonText: `Si`,
      confirmDelete: `Si, Borrado!`,
      dataDeletedMsgTitle: `Borrado!`,
      dataEditedMsg: `La Información ha sido modificada`,
      dataLostMsg: `Estas seguro? Toda la información se perderá`,
      dataLostTextUndo: `No podrás deshacer esto`,
      dataMismatch: `Some Part Numbers no longer exist in the Masterdata.`,
      dataRestored: `Data have been restored`,
      dataSaved: `información ha sido guardada`,
      editCharacteristicBtn: `Editar Característica`,
      empty: `Vacías`,
      emptyAttribute: `vacío`,
      enterBtn: `Enter`,
      entries: `entradas`,
      errorFetchMsg: `La Información no ha sido Obtenida`,
      errorOccurred: `Ocurrió un Error`,
      every2HoursOptions: `Cada Dos Horas`,
      every4HoursOptions: `Cada Cuatro Horas`,
      every6HoursOptions: `Cada Seis Horas`,
      everyHourOptions: `Cada Hora`,
      excelExportBtn: `Exportar a Excel`,
      failAttribute: `Fallo`,
      isRequired: `Value is required`,
      lclFullName: `Lower Control Limit`,
      ldcFullName: `Lower Deviation Constant`,
      lostChangesStatement: `Estas seguro? Todos los cambios se perderán`,
      lslFullName: `Lower Specification Limit`,
      midOfShiftOptions: `Medio Turno`,
      modalTitleSelectLocation: `Seleccionar Ubicación`,
      nameLabel: `Name`,
      notRunningAttribute: `N/R`,
      oncePerDayOptions: `Once Per Day`,
      oncePerShiftOptions: `Once Per Shift`,
      passedAttribute: `Pasado`,
      refreshBtn: `Actualizar`,
      removeMsg: `No podrás dar marcha atrás`,
      removeMsgTitle: `Estas Seguro?`,
      reset: `Reset`,
      ruleEightDescription: `Cuatro de Cinco puntos > Una Desviación estándar de la linea del centro (mismo lado)`,
      ruleElevenDescription: `Group out of the Nominal Range`,
      ruleFiveDescription: `Seis puntos en una hilera incrementando o decrementando`,
      ruleFourDescription: `Siete puntos en una hilera en el mismo lado de la linea del centro (arriba o abajo)`,
      ruleNineDescription: `Quince puntos en una hilera dentro de una desviación estándar de la linea del centro (cualquier lado)`,
      ruleOneDescription: `Grupo fuera del Límite de especificación`,
      ruleSevenDescription: `Dos de Tres puntos > Dos Desviaciones estándar de la linea del centro (mismo lado)`,
      ruleSixDescription: `Trece puntos en una hilera, alternando ce arriba o abajo`,
      ruleTenDescription: `Ocho puntos en una hilera > Una desviación estándar de la linea del centro (cualquier lado)`,
      ruleThreeDescription: `Un punto mas que tres desviaciones estándar de la linea del centro`,
      ruleTwoDescription: `Grupo fuera del Limite de Control, pero dentro del Limite de Especificación`,
      ruleTwelveDescription: `Seven points in a row increasing or decreasing`,
      ruleThirteenDescription: `Seven points in a row between the mean and one of the control limits (on one side)`,
      saveBtn: `Guardar`,
      searchBtn: `Search`,
      show: `Mostrar`,
      showEmptyLocationLabel: `Mostrar Ubicaciones vacías`,
      sslFullName: `Single Specification Limit`,
      startOfShiftOptions: `Inicio de Turno`,
      submitBtn: `Enviar`,
      toolChangeOptions: `Cambio de Herramienta`,
      uclFullName: `Upper Control Limit`,
      udcFullName: `Upper Deviation Constant`,
      uslFullName: `Upper Specification Limit`,
      noPartNumber: `no part number available`
    },
    characteristics: {
      addStringToCharaNameAfterCopy: ` – copy`,
      attribute: `Atributo`,
      characteristicIsInPartExist: `Ya existe una característica para esta parte`,
      characteristicNameIsExist: `Una característica con este nombre ya existe`,
      characteristicType: `Characteristic type`,
      characteristicView: `Características`,
      chartDefinition: `Definición gráfica`,
      chartType: `Tipo de gráfica`,
      chartTypeIMR: `I-MR (Ubicación, Rango)`,
      chartTypeWithout: `Sin Gráfica`,
      chartTypeXR: `XR (Ubicación, Rango)`,
      cp: `CP:`,
      cpk: `CPK:`,
      dataInput: `Ingresar datos`,
      dateStampLabel: `Sello de Fecha`,
      description: `Descripción`,
      disabledCharacteristicWarning: `Si deshabilitas esta característica, Todas las otras Características que la usen para calcular serán también serán deshabilitadas.Estás seguro que quieres continuar?`,
      enabledCharacteristic: `Habilitar característica`,
      enterUniqueName: `Ingresar nombre de característica único:`,
      excludeReasons: `Exclude reasons`,
      fixtureAddTooltip: `Add fixture`,
      fixtureApplyToAll: `Apply fixture to all`,
      fixtureDeleteTooltip: `Delete fixture`,
      fixtureHeaderLabel: `Fixture`,
      fixtureHeaderNameLabel: `Name: `,
      fixtureHeaderNumberLabel: `Number: `,
      fixtureNoOptionsString: `No options to pick`,
      fixtureRemoveFromAll: `Remove fixture from all`,
      fixtureSelectTitle: `Fixtures to select`,
      formLabelMax: `Max`,
      formLabelMin: `Min`,
      frequency: `Frecuencia`,
      frequencySelectPlaceholder: `Select frequency`,
      characteristicSampleReasonValidation: `Al menos uno de \`Inicio de Turno\`, \`Mitad de turno\`, \`Cada hora\`, \`Cada dos horas\`, \`Cada cuatro horas\`, \`Cada seis horas\` es requerido`,
      gaugeType: `Tipo de Gauge`,
      general: `General`,
      graphFiltering: `Graph filtering`,
      lcl: `LCL`,
      ldc: `LDC`,
      locationChartTitle: `Ubicación`,
      lsl: `LSL`,
      manualControlLabel: `Manual Control`,
      maxLimitSslOption: `Max limit`,
      maxLimitValidationFirstPart: `Limite max no puede estar sobre`,
      maxLimitValidationSecondPart: `o debajo -`,
      minLimitSslOption: `Min limit`,
      minLimitValidationFirstPart: `Limit min no pude estar sobre`,
      minLimitValidationSecondPart: `o debajo -`,
      minMaxValidation: `El valor min no puede ser mayor o igual que max`,
      modelPartNumbers: `Model Part Numbers`,
      multiPart: `# de Parte`,
      name: `Nombre`,
      nominalMinus: `Nominal -`,
      nominalOnOff: `Nominal (On/Off)`,
      nominalPlus: `Nominal +`,
      nominalValue: `Nominal Value`,
      oneSidedChartBorderTypeLabel: `One-sided chart border type`,
      oneSidedChartBorderTypeFirstOption: `LimitConfig`,
      oneSidedChartBorderTypeFirstOptionTooltip: `Chart will be limited to specification limit and control limit by default.`,
      oneSidedChartBorderTypeSecondOption: `MinMax`,
      oneSidedChartBorderTypeSecondOptionTooltip: `Chart will be limited to specification limit and an outlier sample value by default.`,
      part: `Part #`,
      pickLocationBtn: `Elegir ubicación para la característica nueva`,
      pp: `PP:`,
      ppk: `PPK:`,
      precision: `Precisión`,
      promptLabel: `Prompt`,
      qclCode: `Código QCL`,
      qclCodeFormFive: `CL5`,
      qclCodeFormFour: `CL4`,
      qclCodeFormNone: `None`,
      qclCodeFormOne: `CL1`,
      qclCodeFormThree: `CL3`,
      qclCodeFormTwo: `CL2`,
      rangeChartTitle: `Rango`,
      requiredGaugeDescription: `Se requiere Gauge # / Descripción`,
      rule: `Regla`,
      rules: `Reglas`,
      samples: `Muestras`,
      samplesQuantityLabel: `Cantidad de muestras`,
      search: `Buscar`,
      selectRules: `Select rules`,
      serialNumberTableLabel: `Serial Number`,
      settingsAlerting: `Alertando`,
      settingsAlertingGroups: `Alertando Grupo(s)`,
      settingsPart: `Part #`,
      partNumbers: `Part Numbers`,
      partNumbersSelectPlaceholder: `Select part number(s)`,
      settingsQualitySignOff: `Cerrar sesión Calidad`,
      settingsSerial: `# de Serie`,
      settingsSupervisorSignOff: `Cerrar sesión Supervisor`,
      settingsTitle: `Panel de Configuración`,
      severityLabel: `Severidad 9/10`,
      showOnChart: `Mostrar en gráfica`,
      specLimitFirstOption: `One sided`,
      specLimitLabel: `Spec limit type`,
      specLimitSecondOption: `Two sided`,
      ssl: `SSL`,
      sslLimitType: `Limit type`,
      tableCp: `Cp`,
      tableCpk: `Cpk`,
      tableDateCreated: `Fecha/Tiempo`,
      tableDeviationTooltip: `Deviation value`,
      tableLsl: `LCL`,
      tableMean: `Media`,
      tableMeanCenterLine: `Gran Media`,
      tableNo: `No`,
      tableNumberOfSamples: `Muestras`,
      tableOperatorInitials: `Iniciales de Operador`,
      tablePartNumber: `Part number`,
      tableRange: `Rango`,
      tableRangeCentralLine: `R-bar`,
      tableRangeCenterLineMr: `mR-bar`,
      tableSampleValue: `Valor`,
      tableShorthandDeviation: `D`,
      tableUsl: `USL`,
      tableValue: `Valor`,
      targetCpk: `Objetivo CPK`,
      trendLine: `Trend Line`,
      ucl: `UCL`,
      uclLclValidation: `UCL should be greater than LCL`,
      udc: `UDC`,
      unit: `Unidad`,
      usl: `USL`,
      uslLslValidation: `USL debería ser mayor que LSL`,
      variable: `Variable`
    },
    administration: {
      partsList: `Part list`,
      partAddBtn: `Add part`,
      partNo: `Part No`,
      partEnabled: `Enabled`,
      partName: `Part Name`,
      partNumber: `Part Number`,
      partDescription: `Description`,
      partUsed: `Part Used`,
      partModel: `Part Model`,
      partGrossCount: `Gross Count`,
      partPPC: `PPC`,
      partEditTooltip: `Edit part`,
      partDeleteTooltip: `Delete part`,
      partTitle: `Part`,
      reactionPlanReasonList: `Reaction plan reason list`,
      reactionPlanReasonAddBtn: `Add reaction plan reason`,
      reactionPlanReasonName: `Reaction Plan Reason Name`,
      reactionPlanReasonType: `Type`,
      reactionPlanReasonIsActive: `Active`,
      reactionPlanReasonEnabled: `Enabled`,
      reactionPlanEditTooltip: `Edit reaction plan`,
      reactionPlanDeleteTooltip: `Delete reaction plan`,
      reactionPlanIsUsedTooltip: `Reaction plan is used`,
      reactionPlanTitle: `Reaction plan`,
      reactionPlanNotificationDeleted: `Reaction plan has been deleted`,
      sampleReasonList: `Sample reason list`,
      sampleReasonAddBtn: `Add sample reason`,
      sampleReasonName: `Sample Reason Name`,
      sampleReasonType: `Type`,
      sampleReasonIsDefault: `Default`,
      sampleReasonEditTooltip: `Edit sample reason`,
      sampleReasonDeleteTooltip: `Delete sample reason`,
      sampleReasonIsUsedTooltip: `Sample reason is used`,
      sampleReasonTitle: `Sample reason`,
      sampleReasonNotificationDeleted: `Sample reason has been deleted`,
      addFixtureBtn: `Add Fixture`,
      addUnitBtn: `Agregar Unidad`,
      addUserBtn: `Agregar Usuario`,
      addMappingBtn: `Add Mapping`,
      adminPermissionsAlert: `Admin permissions can not be changed`,
      descriptionBlankWarning: `La Descripción no puede estar vacía`,
      fixtureDeleteTooltip: `Delete fixture`,
      fixtureEditTooltip: `Edit fixture`,
      fixtureIsUsedTooltip: `Fixture is used`,
      fixtureList: `Fixture list`,
      fixtureName: `Fixture name`,
      fixtureNotificationDeleted: `Fixture has been deleted`,
      fixtureTitle: `Fixture`,
      fixtureValidationNameIsTaken: `An error occurred - probably name is already taken`,
      idleLogoutMessage: `Tiempo de Inactividad de Usuario (minutos)`,
      idleLogoutMessagePlantUser: `Tiempo de Inactividad de Usuario de planta (minutos)`,
      partModelAddModel: `Add Model`,
      partModelDeleteModelTooltip: `Delete model`,
      partModelEditModelTooltip: `Edit model`,
      partModelName: `Model name`,
      partModels: `Part models`,
      partModelUsed: `Model is used`,
      partModelParts: `Parts`,
      partMappingIsUsed: `Part Mapping is in use`,
      partMappingDelete: `Delete Part Mapping`,
      partMappingEdit: `Edit Part Mapping`,
      nameBlankWarning: `El Nombre no puede estar vacío`,
      permissionsMatrix: `Matriz de Permisos`,
      recycleBinCharacteristicDeleteTooltip: `Remove characteristic permanently`,
      recycleBinCharacteristicName: `Characteristic Name`,
      recycleBinCharacteristicUsed: `Characteristic is used`,
      recycleBinDataRecycled: `Date Recycled`,
      recycleBinLocationScreenDeleteTooltip: `Remove location screen permanently`,
      recycleBinLocationScreenName: `Location Screen Name`,
      recycleBinLocationScreenUsed: `Location Screen is used`,
      recycleBinNotificationCharacteristicDeleted: `Characteristic has been deleted`,
      recycleBinNotificationLocationScreenDeleted: `Location screen has been deleted`,
      recycleBinNotificationProcessChartDeleted: `Process chart has been deleted`,
      recycleBinProcessChartDeleteTooltip: `Remove process chart permanently`,
      recycleBinProcessChartName: `Process Chart Name`,
      recycleBinProcessChartUsed: `Process Chart is used`,
      recycleBinRecycledBy: `Recycled By`,
      recycleBinRestoreCharacteristicTooltip: `Restore characteristic`,
      recycleBinRestoreLocationScreenTooltip: `Restore location screen`,
      recycleBinRestoreProcessChartTooltip: `Restore process chart`,
      recycleBinTitle: `Recycle Bin`,
      recycleBinTypeLabel: `Recycle bin type`,
      recycleBinUserName: `Username`,
      recycleBinUserDeleteTooltip: `Remove user permanently`,
      recycleBinNotificationUserDeleted: `User has been deleted`,
      recycleBinRestoreUserTooltip: `Restore user`,
      recycleBinUserUsed: `User is used`,
      roleListDeleteMessage: `Rol de usuario eliminado`,
      roleListDeleteTooltip: `Delete role`,
      roleListEditTooltip: `Edit role`,
      roleListIsUsedTooltip: `Role is used`,
      roleListNameIsTakenMsg: `Ocurrió un error - probablemente el nombre está en uso`,
      roleListTableActions: `Actions`,
      roleListTableDescription: `Descripción`,
      roleListTableName: `Nombre`,
      ruleSettingTableName: `Name`,
      ruleSettingTableValue: `Value`,
      ruleSettingModalHeader: `Rule Setting`,
      ruleSettingModalRuleLabel: `Select Rule`,
      ruleSettingAddSettingBtn: `Add Rule Setting`,
      ruleSettingListHeader: `Rule Settings List`,
      settings: `Ajustes`,
      ruleManagement: `Rule Management`,
      settingsCleanDataBtnLabel: `Eliminar automaticamente información de mas de un año`,
      showLabel: `Mostrar`,
      unitDeleteTooltip: `Delete unit`,
      unitEditTooltip: `Edit unit`,
      unitIsUsedTooltip: `Unit is used`,
      unitLists: `Lista de Unidades`,
      name: `Nombre`,
      unitNotificationUnitDeleted: `Unidad eliminada`,
      unitShortName: `Nombre corto`,
      unitTitle: `Unidad`,
      unitValidationNameBlank: `Nombre no puede estar vacío`,
      unitValidationNameIsTaken: `Ocurrió un error - probablemente el nombre corto está en uso`,
      unitValidationShortNameBlank: `Nombre corto no puede estar vacío`,
      userFileFormatWarning: `File format is incorrect, please make sure that You are using jpg/jpeg/img/png format`,
      userFileSizeWarning: `Image size is too big, max size is 3 MB, use smaller photo`,
      userListAddMasterDataBtn: `Agregar usuario de Masterdata`,
      userListDeleteTooltip: `Delete user`,
      userListEditTooltip: `Edit user`,
      userListIsUsedTooltip: `User is used`,
      userListNotificationAccDeleted: `Cuenta de usuario eliminada`,
      userListNotificationCantDelAcc: `No puedes eliminar tu cuenta`,
      userListNotificationUserNameIsUsed: `Ocurrió un error - probablemente el nombre de usuario está en uso`,
      userListNotificationUserSaved: `Usuario ha sido guardado`,
      userListTitle: `Lista de Usuarios`,
      userModalActiveLabel: `Activo`,
      userModalAddPhotoBtn: `Agregar`,
      userModalAddLocationBtn: `Agregar Localización`,
      userModalClearRoleBtn: `Clear`,
      userModalAddUserByPlantBtn: `Agregar usuario por planta`,
      userModalAddedRolesLabel: `Agregar roles a las plantas:`,
      userModalChangePhotoBtn: `Modificar`,
      userModalChosePlantTitle: `Seleccionar asignación del usuario para la planta`,
      userModalConfirmPasswordLabel: `Confirmar contraseña`,
      userModalConfirmPasswordValidationBlank: `La confirmación de la contraseña no puede estar vacías`,
      userModalConfirmPasswordValidationDifferent: `Las contraseñas no pueden ser diferentes`,
      userModalEditFirstNameValidation: `Primer nombre no puede estar vacío`,
      userModalEditNameValidation: `Nombre de Usuario no puede estar vacío`,
      userModalEditTitle: `Usuario`,
      userModalEmailLabel: `Correo Electrónico`,
      userModalEmailValidation: `Escribir dirección de correo electrónico correcta`,
      userModalFirstNameLabel: `Primer Nombre`,
      userModalLastNameLabel: `Apellido`,
      userModalLastNameValidation: `Apellido no puede estar vacío`,
      userModalLocationPathBtnChange: `Cambiar ruta de ubicación`,
      userModalLocationPathBtnChoose: `Seleccionar ruta de ubicación`,
      userModalLocationPathValidation: `Ruta de ubicación no puede estar vacía`,
      userModalMasterdataUsn: 'Masterdata Usn',
      userModalNextBtn: `Siguiente`,
      userModalPasswordLabel: `Contraseña`,
      userModalPasswordValidationBlank: `La contraseña no puede estar vacía`,
      userModalPasswordValidationDifferent: `Las contraseñas no pueden ser diferentes`,
      userModalPlantLabel: `Planta:`,
      userModalRemoveBtn: `Eliminar`,
      userModalRolesByPlantLabel: `Roles por planta`,
      userModalRolesByPlantLocationLabel: `Plant location`,
      userModalRolesLabel: `Roles`,
      userModalRolesValidation: `Roles no pueden estar vacíos`,
      userModalTreeTitle: `Seleccionar ubicación para el usuario`,
      userModalUserNameLabel: `Nombre de Usuario`,
      userSpecialMarkDefaultWarning: `The query is invalid. Please check the correct use of the following special characters: ! \` # $ % & ' ( ) * + , - . / \\ : ; < = > ? @ [ ] ^ _ { | } ~`,
      userSpecialMarkEmailWarning: `The query is invalid. Please check the correct use of the following special characters: ! \` # $ % & ' ( ) * + , / \\ : ; < = > ? [ ] ^ { | } ~`,
      userSpecialMarkPasswordWarning: `The query is invalid. Please check the correct use of the following special characters: = ? < > { }`,
      usersAddUserMasterdataModalTitle: `Seleccionar usuario`,
      usersListColumnActive: `Activo`,
      usersListColumnEmail: `Email`,
      usersListColumnFirstName: `Primer nombre`,
      usersListColumnLastName: `Apellido`,
      usersListColumnLastLogin: `Last Login`,
      usersListColumnUsername: `Nombre de usuario`,
      usersRolesAddUserBtn: `Agregar Rol de usuario`,
      usersRolesListHeader: `Lista de Roles de usuarios`,
      usersRolesModalHeader: `Rol de usuario`,
      validationIdleLogoutMessageBlank: `Tiempo de Inactividad de Usuario no puede estar vacío`,
      validationIdleLogoutMessageLogoutTime: `Tiempo de Inactividad de Usuario no pude ser menor que 1`,
      valueBlankWarning: `Value is required`
    },
    auth: {
      signInTitle: `Abrir Sesión`,
      errorInvalidGrant: `Por favor revisar que tu nombre de usuario y contraseña sean correctos`,
      errorInvalidRequest: `Cuenta de usuario no encontrada`,
      error: `Error`,
      passwordLabel: `Contraseña`,
      signInBtn: `Abrir Sesión`,
      usernameLabel: `Nombre de Usuario`
    },
    layout: {
      passwordChangeModalChangePasswordBtn: `Cambiar Contraseña`,
      passwordChangeModalConfirmPasswordLabel: `Confirmar Contraseña`,
      passwordChangeModalConfirmPasswordValidation: `Confirmar Contraseña no puede estar vacía`,
      passwordChangeModalDifferentPassValidation: `Las contraseñas no pueden ser diferentes`,
      passwordChangeModalPasswordChanged: `La contraseña ha sido cambiada`,
      passwordChangeModalPasswordLabel: `Contraseña`,
      passwordChangeModalPasswordValidation: `La Contraseña no puede estar vacía`,
      passwordChangeModalTitle: `Cambiar contraseña`,
      userMenuOptionLogout: `Cerrar Sesión`,
      userMenuOptionSettings: `Configuración`
    },
    locationScreen: {
      createEnableLabel: `Habilitar Pantalla de Ubicación`,
      createEnterNameLabel: `Ingresar nombre único para la pantalla de ubicación`,
      createLackOfProcessCharts: `Falta de Gráficas de proceso`,
      createLocationScreenNameValidation: `Pantalla de Ubicación con este nombre ya existe`,
      createNameLabel: `Nombre`,
      createNameValidationMessage: `Pantalla de Ubicación con este nombre ya existe`,
      createNewLocationScreenBtn: `Elegir ubicación nueva para la Pantalla de Ubicación`,
      createPickProcessChartLabel: `Selecciona Gráficas de proceso a incluir en esta Pantalla de Ubicación`,
      createProcessChartCheckboxLabel: `Gráfica de proceso`,
      createProcessChartListTitle: `Gráficas de proceso incluidas en esta Pantalla de Ubicación`,
      createSelectLocation: `Seleccionar Ubicación`,
      locationScreenView: `Vista de Pantalla de Ubicación`
    },
    processChart: {
      calculatedAttributeCharacteristicDisabled: `You can't add attribute Characteristic to Calculated Characteristic`,
      calculatedCreateCalculationString: `Cadena de Cálculo:`,
      calculatedCreateConstantValue: `Valor constante:`,
      calculatedCreateCorrectCompletedEdit: `Editar`,
      calculatedCreateCorrectCompletedMsgPart1: `Quieres`,
      calculatedCreateCorrectCompletedMsgPart2: `Características calculadas?`,
      calculatedCreateCorrectCompletedMsgTitle: `Cadena de Cálculo completada correctamente`,
      calculatedCreateCorrectCompletedSave: `Guardar`,
      calculatedCreateEnabledBtn: `Característica de cálculo habilitada`,
      calculatedCreateEnterName: `Ingresar nombre único para Característica calculada:`,
      calculatedCreateIncludedCardHeader: `Características incluidas en esta Gráfica de proceso`,
      calculatedCreateLocationPath: `Path de Ubicación:`,
      calculatedCreateMoreCharacteristicErrorMsg: `Al menos dos características  (o características  y valores constantes) deberían ser agregadas`,
      calculatedCreateNameLabel: `Nombre`,
      calculatedCreateNameValidation: `Este nombre ya está en uso`,
      calculatedCreateResetBtn: `Reiniciar`,
      calculatedCreateSelectOperation: `Selecciona operación`,
      calculatedCreateWithoutNameErrorMsg: `Debes escribir el nombre de la característica calculada`,
      calculatedCreateWithoutOperatorErrorMsg: `Un operador debe ser agregado`,
      createCalculatedCharacteristicsIsUsedWarning: `Características calculadas contienen características  en uso en otra Gráfica de procesos`,
      createCharacteristicIsUsedWarning: `Característica en uso en otra Gráfica de proceso`,
      createCheckboxCharacteristicToSelect: `Característica`,
      createCheckboxCharacteristicToSelectPN: ` - PN `,
      createDataHaveBeen: `Información ha sido`,
      createEditedData: `Editada`,
      createEnableProcessChartCheckboxLabel: `Habilitar gráfica de proceso`,
      createEnterUniqueName: `Ingresar nombre único para Gráfica de proceso`,
      createIncludedCharacteristicsCartTitle: `Características incluidas en esta Gráfica de proceso`,
      createLackOfCharacteristics: `Falta de Características`,
      createMaxCharacteristicsWarning: `Has agregado el número maximo de características (20)`,
      createNameLabel: `Nombre`,
      createNameValidation: `Gráfica de procesos con este nombre ya existe`,
      createNewCalculatedCharacteristicsBtn: `Nueva Característica calculada`,
      createPickCalculatedCharacteristicsLabel: `Selecciona Características calculadas para incluir en esta Gráfica de proceso`,
      createPickCharacteristicCardHeader: `Seleccionar Característica para incluirla en esta Gráfica de proceso`,
      createPickLocationBtn: `Seleccionar ubicación para nueva Gráfica de Proceso`,
      createPickLocationModalTitle: `Seleccionar Ubicación`,
      createSavedData: `guardada`,
      createTeamCenterLabel: `Teamcenter Doc # / Rev`,
      processChartView: `Vista de Gráfica de proceso`,
      processChartButtonWarningTooltip: `You need to select at least one characteristic and complete the form`
    },
    userSetting: {
      addPhotoBtn: `Agregar`,
      cardHeader: `Acerca de mí`,
      changePhotoBtn: `Cambiar`,
      confirmMsgBtn: `Si, cambia la contraseña!`,
      confirmPasswordLabel: `Confirmar nueva contraseña`,
      confirmPasswordValidation: `La contraseña no puede estar vacía`,
      currentPasswordLabel: `Contraseña actual`,
      currentPasswordValidationBlank: `Contraseña actual no puede estar vacía`,
      currentPasswordValidationIsIncorrect: `La contraseña es incorrecta`,
      firstNameLabel: `Primer nombre`,
      firstNameValidation: `Primer nombre no puede estar vacío`,
      lastNameLabel: `Apellido`,
      lastNameValidation: `Apellido no puede estar vacío`,
      newPasswordLabel: `Nueva Contraseña`,
      newPasswordValidation: `La contraseña no puede estar vacía`,
      passwordIncorrectMsg: `La contraseña actual es incorrecta`,
      passwordsValidationDifferent: `Las contraseñas no pueden ser diferentes`,
      removePhotoBtn: `Eliminar`,
      userNameLabel: `Nombre de usuario`
    },
    components: {
      addPartMappingModal_modalHeader: `Part Mapping`,
      addPartMappingSelectLocationBtn: `Select Location`,
      addPartMappingSelectLocationNameExists: `Location name already exists`,
      addPartMappingSelectLocationNameUnique: `Location name must be unique`,
      addPartMappingCmmPartsPlaceholder: `Select CMM Parts for the mapping`,
      addPartMappingSubmitBtn: `Add Mapping`,
      addSampleCollection_excludeLabel: `Excluir de la gráfica y los cálculos`,
      addSampleCollection_limitBrokenMsgMaxVal: `Valor Maximo:`,
      addSampleCollection_limitBrokenMsgMinVal: `Valor minimo:`,
      addSampleCollection_limitBrokenMsgTitle: `Ingresar valor fuera de rango`,
      addSampleCollection_limitBrokenMsgWantToContinue: `Estas seguro que quieres agregar un valor fuera de estos límites?`,
      addSampleCollection_notesLabel: `Notas`,
      addSampleCollection_sampleEditedMsg: `Muestras han sido editadas`,
      addSampleCollection_saveBtn: `Guardar cambios`,
      addSampleCollection_titleAddSamples: `Agregar muestras`,
      addSampleCollection_titleEditSamples: `Editar muestras`,
      addSampleCollection_validationValueOver: `Valor de muestra no puede estar sobre`,
      addSampleCollection_validationValueUnder: `O debajo -`,
      addSampleModal_addedSamplesMsg: `Muestras han sido agregadas`,
      addSampleModal_addSampleBtn: `Agregar`,
      addSampleModal_addSamplesError: `You need to add samples or set 'Not running'`,
      addSampleModal_cardTitleSamples: `Muestras`,
      addSampleModal_completeBtn: `COMPLETADO`,
      addSampleModal_modalHeader: `AGREGAR MUESTRA`,
      addSampleModal_notRunningLabel: `detenido`,
      addSampleModal_operatorInitialLabel: `Iniciales de Operador`,
      addSampleModal_partNumberLabel: `Part number`,
      addSampleModal_reasonLabel: `Razón`,
      addSampleModal_remarksLabel: `Observaciones (opcional)`,
      addSampleModal_serialNumberLabel: `Serial number`,
      addSampleCollection_subTitleSamples: `Muestras`,
      addSampleCollection_title: `Instrucciones`,
      chartOptions_trendLine: `Trend Line`,
      chartOptions_yAxisMin: `Y axis min`,
      chartOptions_yAxisMax: `Y axis max`,
      confirmUserPermission_emptyFieldsWarning: `Las formas necesitan ser llenadas`,
      confirmUserPermission_engineerPermissionWarn: `El usuario no tiene permisos de Ingeniero de calidad`,
      confirmUserPermission_incorrectPasswordWarn: ` Contraseña es incorrecta o el usuario no existe`,
      confirmUserPermission_passwordLabel: `Contraseña`,
      confirmUserPermission_permissionConfirmedMsg: `Permisos de Usuario confirmados`,
      confirmUserPermission_qualitySupervisorPermissionWarn: `El usuario no tiene permisos del supervisor de calidad`,
      confirmUserPermission_usernameLabel: `Nombre de usuario`,
      filters_cardTitle: `Filtros`,
      filters_clearBtn: `Limpiar`,
      filters_defaultOptionDept: `Cualquier departamento`,
      filters_defaultOptionPlant: `Cualquier planta`,
      filters_departmentLabel: `Dept:`,
      filters_listViewBtn: `Vista  de Lista`,
      filters_plantLabel: `Panta:`,
      filters_cmmPartLabel: `CMM Part:`,
      filters_mdPartLabel: `MD Part:`,
      searchLabel: `Buscar`,
      filters_showFavoriteLabel: `Mostrar favoritos`,
      filters_treeViewBtn: `Vista de Árbol`,
      meanChart_cardTitle: `Panel de Opciones`,
      meanChart_chartTextDateXaxis: `Fecha`,
      meanChart_chartTextLocationYaxis: `Ubicación`,
      meanChart_LCLLabel: `LCL`,
      meanChart_LSLLabel: `LSL`,
      meanChart_maxLimitOptions: `Limit type: Max limit`,
      meanChart_meanLabel: `Media`,
      meanChart_minLimitOptions: `Limit type: Min limit`,
      meanChart_nominalRange: `Nominal Range`,
      meanChart_partNumberTooltip: `Part number:`,
      meanChart_plotLineTextCentralLineLabelGrandMean: `Gran Media`,
      meanChart_plotLineTextCentralLineLabelMean: `Media`,
      meanChart_plotLineTextLCLLabel: `LCL=`,
      meanChart_plotLineTextLSLLabel: `LSL=`,
      meanChart_plotLineTextRangeMinusLabel: `Range minus=`,
      meanChart_plotLineTextRangePlusLabel: `Range plus=`,
      meanChart_plotLineTextSSLLabel: `SSL=`,
      meanChart_plotLineTextUCLLabel: `UCL=`,
      meanChart_plotLineTextUSLLabel: `USL=`,
      meanChart_ProcessAverageTooltip: `Process average:`,
      meanChart_recalcGroupPlotLine: `Recalc en grupo`,
      meanChart_refreshChartTooltip: `Refresh location chart`,
      meanChart_RulesTooltip: `Rules:`,
      meanChart_SSLLabel: `SSL`,
      meanChart_SubgroupAverage: `Subgroup average:`,
      meanChart_ticksAmount: `Cantidad de muestras`,
      meanChart_maximizeChart: `maximize chart`,
      meanChart_UCLLabel: `UCL`,
      meanChart_USLLabel: `USL`,
      preControlChart_CentralLineLabel: `Central`,
      preControlChart_chartTextDateXaxis: `Date`,
      preControlChart_chartTextDateYaxis: `Location`,
      preControlChart_LCLLabel: `LCL`,
      preControlChart_LGZLabel: `LGZ`,
      preControlChart_Ticks: `Ticks`,
      preControlChart_Title: `Pre-Control`,
      preControlChart_UClLabel: `UCL`,
      preControlChart_UGZLabel: `UGZ`,
      processChart_cantEditReactionPlanWarning: `Plan de reacción solo puede ser modificado en la pantalla de Ubicación`,
      processChart_dateColumnName: `Fecha`,
      processChart_frequencyShortVersionFourHr: `Cada 4hr`,
      processChart_frequencyShortVersionOneHr: `Cada 1hr`,
      processChart_frequencyShortVersionSixHr: `Cada 6hr`,
      processChart_frequencyShortVersionTwoHr: `Cada 2hr`,
      processChart_gageInstruction: `Por favor referirse a las instrucciones del Gauge`,
      processChart_headerInformation: `Artículos para revisar. Reportar cualquier lectura fuera de los límites especificados al equipo.`,
      processChart_inspColumnName: `INSP por`,
      processChart_machine: `MAQUINA`,
      processChart_partLabel: `# de Parte`,
      processChart_processChartNameLabel: `NOMBRE DE GRAFICA DE PROCESO:`,
      processChart_reasonColumnName: `RAZÓN PARA LA REVISION`,
      processChart_remarksColumnName: `OBSERVACIONES`,
      processChart_rowsQuantity: `Cantidad de Renglones`,
      processChart_selectPartNumberWarning: `Por favor seleccione numero de parte`,
      processChart_severityCell: `SEV 9/10`,
      processChart_supervisorInitialsColumnName: `INICIALES DE SUPERVISOR`,
      processChart_timeColumnName: `Tiempo`,
      rangeChart_cardTitle: `Panel de Opciones`,
      rangeChart_chartTextDateXaxis: `Fecha`,
      rangeChart_chartTitle: `Rango`,
      rangeChart_LCLLabel: `LCL`,
      rangeChart_plotLineTextCentralLineLabelMrbar: `mR-bar`,
      rangeChart_plotLineTextCentralLineLabelRbar: `R-bar`,
      rangeChart_plotLineTextLCLLabel: `LCL=`,
      rangeChart_plotLineTextUCLLabel: `UCL=`,
      rangeChart_rangeTooltip: `Range:`,
      rangeChart_refreshChartTooltip: `Refresh range chart`,
      rangeChart_rulesTooltip: `Rules:`,
      rangeChart_ticksAmount: `Cantidad de Ticks`,
      rangeChart_UCLLabel: `UCL`,
      reactionPlan_actionTakenLabel: `Action Taken`,
      reactionPlan_calculationReset: `Calculation Reset`,
      reactionPlan_changeMachineParameterOption: `Changing machine parameters`,
      reactionPlan_characteristicDescription: `Descripción de Característica:`,
      reactionPlan_characteristicName: `Nombre de Característica:`,
      reactionPlan_cleanMachineOption: `Cleaning machine, device`,
      reactionPlan_completeBtn: `COMPLETADO`,
      reactionPlan_correctionOfDeviceOption: `Correction  of measurement device`,
      reactionPlan_correctiveActionLabel: `Corrective Action`,
      reactionPlan_dateLabel: `Fecha:`,
      reactionPlan_dateTimeViolation: `Fecha / Tiempo de Infracción`,
      reactionPlan_deviationOption: `Deviation`,
      reactionPlan_interventionOption: `Maintenance intervention`,
      reactionPlan_lslLabel: `LSL:`,
      reactionPlan_machineAdjustmentOption: `Machine adjustment`,
      reactionPlan_materialChangeOption: `Material change`,
      reactionPlan_materialDisposition: `Disposición de Material`,
      reactionPlan_modalTitle: `PLAN DE REACCIÓN`,
      reactionPlan_operationInput: `APORTE DE OPERACIONES`,
      reactionPlan_operatorCommentLabel: `Comentario de Operador:`,
      reactionPlan_operatorCommentValidationMessage: `Comentario de Operador no puede estar vacío`,
      reactionPlan_operatorInitialsLabel: `Iniciales de Operador`,
      reactionPlan_operatorInitialsValidationMessage: `Iniciales de Operador no pueden estar vacías`,
      reactionPlan_processStopped: `Proceso detenido?`,
      reactionPlan_qeFormInitialsValidationMsg: `Iniciales del Ingeniero de Calidad no pueden estar vacías`,
      reactionPlan_qeFormLabel: `Ingeniero de Calidad:`,
      reactionPlan_qeFormTitle: `CERRAR SESIÓN CALIDAD`,
      reactionPlan_qeLoginComponentTitle: `VERIFICACIÓN DE USUARIO DE CALIDAD`,
      reactionPlan_qualityInput: `Quality Input`,
      reactionPlan_qualitySignOff: `CERRAR SESIÓN CALIDAD`,
      reactionPlan_radioButtonCorrectedLabel: `Corregido`,
      reactionPlan_radioButtonDeviation: `Deviation`,
      reactionPlan_radioButtonHoldLabel: `Detener`,
      reactionPlan_radioButtonPassedLabel: `Pasado a stock`,
      reactionPlan_radioButtonReworkLabel: `Retrabajo`,
      reactionPlan_radioButtonScrapLabel: `Scrap`,
      reactionPlan_radioButtonSortLabel: `Ordenar`,
      reactionPlan_reactionPlanSavedMsg: `Plan de Reacción Guardado`,
      reactionPlan_remarkLabel: `Observación:`,
      reactionPlan_repairDeviceOption: `Repair or replacement of measurement device`,
      reactionPlan_rule: `Reglas Violadas`,
      reactionPlan_ruleBreakOverride: `Change color to black`,
      reactionPlan_rulesViolated: `Reglas Violadas`,
      reactionPlan_severity: `SEVERIDAD 9/10`,
      reactionPlan_supervisorSignOff: `CERRAR SESIÓN SUPERVISOR`,
      reactionPlan_svFormInitials: `Iniciales de Supervisor:`,
      reactionPlan_svFormInitialsValidationMsg: `Iniciales de Supervisor no pueden estar vacías`,
      reactionPlan_svFormTitle: `CERRAR SESIÓN SUPERVISOR`,
      reactionPlan_svLoginComponentTitle: `VERIFICACIÓN DE USUARIO SUPERVISOR`,
      reactionPlan_toolAdjustmentOption: `Tool adjustment`,
      reactionPlan_toolChangeOption: `Tool change`,
      reactionPlan_uslLabel: `USL:`,
      reactionPlan_valueLabel: `Valor:`,
      reactionPlan_verificationOfDeviceOption: `Verification of measurement device`,
      sampleReason_eventOption: `Event`,
      sampleReason_frequencyOption: `Frequency`,
      sampleReason_hourlyOption: `Hourly`,
      tableResult_cantDisabledCharacteristicMsg: `No puedes agregar muestras a características deshabilitadas`,
      tableResult_characteristic: `Característica`,
      tableResult_itemNameControlCharts: `Gráfica de Control`,
      tableResult_itemNameLocationScreen: `Pantalla de Ubicación`,
      tableResult_itemNameProcessChart: `Gráfica de Proceso`,
      tableResult_locationScreen: `Location Screen`,
      tableResult_maxFavoriteValidationPartOne: `Has excedido el numero de maximo`,
      tableResult_maxFavoriteValidationPartTwo: `de tus favoritos`,
      tableResult_moveToRecycleBinMsg: `This will go the Recycle Bin`,
      tableResult_multi: `Multi`,
      tableResult_noPermissionToAddSamplesMsg: `No tienes permisos para agregar muestras`,
      tableResult_processChart: `Process Chart`,
      tableResult_tableActions: `Acciones`,
      tableResult_tableCreator: `Creador`,
      tableResult_tableLastModified: `Ultima Modificación`,
      tableResult_tableName: `Nombre`,
      tableResult_tableNamePath: `Nombre / Path`,
      tableResult_tablePart: `Parte`,
      tableResult_tablePath: `Path`,
      tableResult_tooltipAddData: `Agregar información`,
      tableResult_tooltipAddToFavorite: `Agregar a Favorito`,
      tableResult_tooltipCopy: `Copiar`,
      tableResult_tooltipEdit: `Editar`,
      tableResult_tooltipRemoveFavorite: `Eliminar de Favorito`,
      tableResult_tooltipView: `Vista`,
      tableResult_tooltipCMMCharacteristic: `Can't add data to a CMM Characteristic`,
      tableResult_typeToValidationMaxFavoriteCharacteristics: `Características`,
      tableResult_typeToValidationMaxFavoriteLocationScreen: `Pantalla de Ubicaciones`,
      tableResult_typeToValidationMaxFavoriteProcessChart: `Gráficas de Proceso`,
      variableChart_trendLine: `Trend Line`
    },
    menu: {
      administration: `Administración`,
      administrationFixtures: `Fixtures`,
      administrationPartModels: `Part Models`,
      administrationParts: `Parts`,
      administrationPartMapping: `Part Mapping`,
      administrationPermissions: `Permisos`,
      administrationReactionPlanReasons: `Reaction Plan Reasons`,
      administrationRecycleBin: `Recycle Bin`,
      administrationRoles: `Roles de Usuarias`,
      administrationRuleSettings: `Rule Settings`,
      administrationSampleReasons: `Sample Reasons`,
      administrationSettings: `Configuración`,
      administrationUnits: `Unidades`,
      administrationUsers: `Usuarios`,
      characteristic: `Característica`,
      characteristicCreate: `Crear`,
      characteristicView: `Vista`,
      cmmData: `CMM Data`,
      cmmDataRunChart: `run chart`,
      cmmDataView: `Vista`,
      dashboard: `Tablero`,
      locationScreen: `Pantalla de Ubicación`,
      locationScreenCreate: `Crear`,
      locationScreenView: `Vista`,
      processChart: `Gráfica de proceso`,
      processChartCreate: `Crear`,
      processChartView: `Vista`,
      reports: `Reportes`,
      user: `User`
    },
    dashboard: {
      controlChardHideState: `control charts has been hidden`,
      itemsPerPageSelect: `Items per page`,
      switchLabelCompactView: `Vista Compacta`
    },
    CMMTranslation: {
      runChartChartLslPlotLine: `LSL:`,
      runChartChartSerieName: `Run chart`,
      runChartChartTitle: `Run Chart`,
      runChartChartTooltipValue: `Value:`,
      runChartChartUslPlotLine: `USL:`,
      runChartChartXAxisName: `Timestamp`,
      runChartChartYAxisName: `Value`,
      runChartFiltersCharacteristicPlaceholder: `Characteristic`,
      runChartFiltersDateRangePlaceholder: `Date Range`,
      runChartFiltersDepartmentPlaceholder: `Department`,
      runChartFiltersDevicePlaceholder: `Device`,
      runChartFiltersMachinePlaceholder: `Machine`,
      runChartFiltersPartNumberPlaceholder: `Part Number`,
      runChartFiltersPlantPlaceholder: `Plant`,
      runChartFiltersMeasurementPlanPlaceholder: `Measurement Plan`,
      runChartFiltersPlotGraphBtn: `Plot Graph`,
      runChartFiltersResetBtn: `Reset filters`,
      runChartModalBackButton: `Back to details`,
      runChartModalDetailsMachine: `Machine:`,
      runChartModalDetailsMeasurement: `Measurement Type:`,
      runChartModalDetailsPN: `PN:`,
      runChartModalDetailsTimestamp: `Timestamp:`,
      runChartModalTableCharacteristic: `Characteristic`,
      runChartModalTableTitle: `Measurements`,
      runChartModalTableValue: `Value`,
      runChartModalTitle: `Reports Details`,
      runChartModalViewPdfBtn: `View Report PDF`,
      runChartReportListTitle: `Reports`,
      runChartTableColumnTimeStamp: `Timestamp`,
      runChartTableColumnValue: `Value`,
      runChartTableValueListTitle: `Values`,
      viewCharacteristicsCardTitle: `Characteristics`,
      viewCharacteristicsTableComments: `Comments`,
      viewCharacteristicsTableName: `Name`,
      viewCharacteristicsTableValue: `Value`,
      viewCmmCardCMM: `CMM-`,
      viewCmmCardLab: `Lab`,
      viewCmmCardPlanta: `Planta:`,
      viewCmmCardReports: `Shift reports`,
      viewCmmGetDevicesTooltip: `Pick plant and time interval`,
      viewCmmPlantsChoiceLabel: `Plants to choice`,
      viewCmmTimeIntervalLabel: `Pick time interval`,
      viewGetDevicesBtn: `Get Devices`,
      viewPlantCardTitle: `Plants`,
      viewReportCardTitle: `Current Shift Reports`,
      viewReportCardViewLink: `View`
    }
  };
}
