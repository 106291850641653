export class Translation {
  type: string;
  administration: AdministrationTranslation;
  auth: AuthTranslation;
  characteristics: CharacteristicsTranslation;
  CMMTranslation: CMMTranslation;
  components: ComponentsTranslation;
  dashboard: DashboardTranslation;
  global: GlobalTranslation;
  layout: LayoutTranslation;
  locationScreen: LocationScreenTranslation;
  menu: MenuTranslation;
  processChart: ProcessChartTranslation;
  userSetting: UserSettingsTranslation;
}

export class AdministrationTranslation {
  partsList: string;
  partAddBtn: string;
  partNo: string;
  partEnabled: string;
  partName: string;
  partNumber: string;
  partDescription: string;
  partUsed: string;
  partModel: string;
  partGrossCount: string;
  partPPC: string;
  partEditTooltip: string;
  partDeleteTooltip: string;
  partTitle: string;
  reactionPlanReasonList: string;
  reactionPlanReasonAddBtn: string;
  reactionPlanReasonName: string;
  reactionPlanReasonType: string;
  reactionPlanReasonIsActive: string;
  reactionPlanReasonEnabled: string;
  reactionPlanEditTooltip: string;
  reactionPlanDeleteTooltip: string;
  reactionPlanIsUsedTooltip: string;
  reactionPlanTitle: string;
  reactionPlanNotificationDeleted: string;
  sampleReasonList: string;
  sampleReasonAddBtn: string;
  sampleReasonName: string;
  sampleReasonType: string;
  sampleReasonIsDefault: string;
  sampleReasonEditTooltip: string;
  sampleReasonDeleteTooltip: string;
  sampleReasonIsUsedTooltip: string;
  sampleReasonTitle: string;
  sampleReasonNotificationDeleted: string;
  addFixtureBtn: string;
  addUnitBtn: string;
  addUserBtn: string;
  addMappingBtn: string;
  adminPermissionsAlert: string;
  descriptionBlankWarning: string;
  fixtureDeleteTooltip: string;
  fixtureEditTooltip: string;
  fixtureIsUsedTooltip: string;
  fixtureList: string;
  fixtureName: string;
  fixtureNotificationDeleted: string;
  fixtureTitle: string;
  fixtureValidationNameIsTaken: string;
  idleLogoutMessage: string;
  idleLogoutMessagePlantUser: string;
  partModelAddModel: string;
  partModelDeleteModelTooltip: string;
  partModelEditModelTooltip: string;
  partModelName: string;
  partModelUsed: string;
  partModels: string;
  partModelParts: string;
  partMappingIsUsed: string;
  partMappingDelete: string;
  partMappingEdit: string;
  nameBlankWarning: string;
  permissionsMatrix: string;
  recycleBinCharacteristicDeleteTooltip: string;
  recycleBinCharacteristicName: string;
  recycleBinCharacteristicUsed: string;
  recycleBinDataRecycled: string;
  recycleBinLocationScreenDeleteTooltip: string;
  recycleBinLocationScreenName: string;
  recycleBinLocationScreenUsed: string;
  recycleBinNotificationCharacteristicDeleted: string;
  recycleBinNotificationLocationScreenDeleted: string;
  recycleBinNotificationProcessChartDeleted: string;
  recycleBinProcessChartDeleteTooltip: string;
  recycleBinProcessChartName: string;
  recycleBinProcessChartUsed: string;
  recycleBinRecycledBy: string;
  recycleBinRestoreCharacteristicTooltip: string;
  recycleBinTitle: string;
  recycleBinRestoreLocationScreenTooltip: string;
  recycleBinRestoreProcessChartTooltip: string;
  recycleBinTypeLabel: string;
  recycleBinUserName: string;
  recycleBinUserDeleteTooltip: string;
  recycleBinNotificationUserDeleted: string;
  recycleBinRestoreUserTooltip: string;
  recycleBinUserUsed: string;
  roleListDeleteMessage: string;
  roleListDeleteTooltip: string;
  roleListEditTooltip: string;
  roleListIsUsedTooltip: string;
  roleListNameIsTakenMsg: string;
  roleListTableActions: string;
  roleListTableDescription: string;
  roleListTableName: string;
  ruleSettingTableName: string;
  ruleSettingTableValue: string;
  ruleSettingModalRuleLabel: string;
  ruleSettingModalHeader: string;
  ruleSettingAddSettingBtn: string;
  ruleSettingListHeader: string;
  settings: string;
  ruleManagement: string;
  settingsCleanDataBtnLabel: string;
  showLabel: string;
  unitDeleteTooltip: string;
  unitEditTooltip: string;
  unitIsUsedTooltip: string;
  unitLists: string;
  name: string;
  unitNotificationUnitDeleted: string;
  unitShortName: string;
  unitTitle: string;
  unitValidationNameBlank: string;
  unitValidationNameIsTaken: string;
  unitValidationShortNameBlank: string;
  userFileFormatWarning: string;
  userFileSizeWarning: string;
  userListAddMasterDataBtn: string;
  userListDeleteTooltip: string;
  userListEditTooltip: string;
  userListIsUsedTooltip: string;
  userListNotificationAccDeleted: string;
  userListNotificationCantDelAcc: string;
  userListNotificationUserNameIsUsed: string;
  userListNotificationUserSaved: string;
  userListTitle: string;
  userModalActiveLabel: string;
  userModalAddPhotoBtn: string;
  userModalAddLocationBtn: string;
  userModalClearRoleBtn: string;
  userModalAddUserByPlantBtn: string;
  userModalAddedRolesLabel: string;
  userModalChangePhotoBtn: string;
  userModalChosePlantTitle: string;
  userModalConfirmPasswordLabel: string;
  userModalConfirmPasswordValidationBlank: string;
  userModalConfirmPasswordValidationDifferent: string;
  userModalEditFirstNameValidation: string;
  userModalEditNameValidation: string;
  userModalEditTitle: string;
  userModalEmailLabel: string;
  userModalEmailValidation: string;
  userModalFirstNameLabel: string;
  userModalLastNameLabel: string;
  userModalLastNameValidation: string;
  userModalLocationPathBtnChange: string;
  userModalLocationPathBtnChoose: string;
  userModalLocationPathValidation: string;
  userModalMasterdataUsn: string;
  userModalNextBtn: string;
  userModalPasswordLabel: string;
  userModalPasswordValidationBlank: string;
  userModalPasswordValidationDifferent: string;
  userModalPlantLabel: string;
  userModalRemoveBtn: string;
  userModalRolesByPlantLabel: string;
  userModalRolesByPlantLocationLabel: string;
  userModalRolesLabel: string;
  userModalRolesValidation: string;
  userModalTreeTitle: string;
  userModalUserNameLabel: string;
  userSpecialMarkDefaultWarning: string;
  userSpecialMarkEmailWarning: string;
  userSpecialMarkPasswordWarning: string;
  usersAddUserMasterdataModalTitle: string;
  usersListColumnActive: string;
  usersListColumnEmail: string;
  usersListColumnFirstName: string;
  usersListColumnLastName: string;
  usersListColumnLastLogin: string;
  usersListColumnUsername: string;
  usersRolesAddUserBtn: string;
  usersRolesListHeader: string;
  usersRolesModalHeader: string;
  validationIdleLogoutMessageBlank: string;
  validationIdleLogoutMessageLogoutTime: string;
  valueBlankWarning: string;
}

export class AuthTranslation {
  signInTitle: string;
  errorInvalidGrant: string;
  errorInvalidRequest: string;
  error: string;
  passwordLabel: string;
  signInBtn: string;
  usernameLabel: string;
}

export class CharacteristicsTranslation {
  addStringToCharaNameAfterCopy: string;
  attribute: string;
  characteristicIsInPartExist: string;
  characteristicNameIsExist: string;
  characteristicType: string;
  characteristicView: string;
  chartDefinition: string;
  chartType: string;
  chartTypeIMR: string;
  chartTypeWithout: string;
  chartTypeXR: string;
  cp: string;
  cpk: string;
  dataInput: string;
  dateStampLabel: string;
  description: string;
  disabledCharacteristicWarning: string;
  enabledCharacteristic: string;
  enterUniqueName: string;
  excludeReasons: string;
  fixtureAddTooltip: string;
  fixtureApplyToAll: string;
  fixtureDeleteTooltip: string;
  fixtureHeaderLabel: string;
  fixtureHeaderNameLabel: string;
  fixtureHeaderNumberLabel: string;
  fixtureNoOptionsString: string;
  fixtureRemoveFromAll: string;
  fixtureSelectTitle: string;
  formLabelMax: string;
  formLabelMin: string;
  frequency: string;
  frequencySelectPlaceholder: string;
  characteristicSampleReasonValidation: string;
  gaugeType: string;
  general: string;
  graphFiltering: string;
  lcl: string;
  ldc: string;
  locationChartTitle: string;
  lsl: string;
  manualControlLabel: string;
  maxLimitSslOption: string;
  maxLimitValidationFirstPart: string;
  maxLimitValidationSecondPart: string;
  minLimitSslOption: string;
  minLimitValidationFirstPart: string;
  minLimitValidationSecondPart: string;
  minMaxValidation: string;
  modelPartNumbers: string;
  multiPart: string;
  name: string;
  nominalMinus: string;
  nominalOnOff: string;
  nominalPlus: string;
  nominalValue: string;
  oneSidedChartBorderTypeFirstOption: string;
  oneSidedChartBorderTypeFirstOptionTooltip: string;
  oneSidedChartBorderTypeLabel: string;
  oneSidedChartBorderTypeSecondOption: string;
  oneSidedChartBorderTypeSecondOptionTooltip: string;
  part: string;
  partNumbers: string;
  partNumbersSelectPlaceholder: string;
  pickLocationBtn: string;
  pp: string;
  ppk: string;
  precision: string;
  promptLabel: string;
  qclCode: string;
  qclCodeFormFive: string;
  qclCodeFormFour: string;
  qclCodeFormNone: string;
  qclCodeFormOne: string;
  qclCodeFormThree: string;
  qclCodeFormTwo: string;
  rangeChartTitle: string;
  requiredGaugeDescription: string;
  rule: string;
  rules: string;
  samples: string;
  samplesQuantityLabel: string;
  search: string;
  selectRules: string;
  serialNumberTableLabel: string;
  settingsAlerting: string;
  settingsAlertingGroups: string;
  settingsPart: string;
  settingsQualitySignOff: string;
  settingsSerial: string;
  settingsSupervisorSignOff: string;
  settingsTitle: string;
  severityLabel: string;
  showOnChart: string;
  specLimitFirstOption: string;
  specLimitLabel: string;
  specLimitSecondOption: string;
  ssl: string;
  sslLimitType: string;
  tableCp: string;
  tableCpk: string;
  tableDateCreated: string;
  tableDeviationTooltip: string;
  tableLsl: string;
  tableMean: string;
  tableMeanCenterLine: string;
  tableNo: string;
  tableNumberOfSamples: string;
  tableOperatorInitials: string;
  tablePartNumber: string;
  tableRange: string;
  tableRangeCenterLineMr: string;
  tableRangeCentralLine: string;
  tableSampleValue: string;
  tableShorthandDeviation: string;
  tableUsl: string;
  tableValue: string;
  targetCpk: string;
  trendLine: string;
  ucl: string;
  uclLclValidation: string;
  udc: string;
  unit: string;
  usl: string;
  uslLslValidation: string;
  variable: string;
}

export class CMMTranslation {
  runChartChartLslPlotLine: string;
  runChartChartSerieName: string;
  runChartChartTitle: string;
  runChartChartTooltipValue: string;
  runChartChartUslPlotLine: string;
  runChartChartXAxisName: string;
  runChartChartYAxisName: string;
  runChartFiltersCharacteristicPlaceholder: string;
  runChartFiltersDateRangePlaceholder: string;
  runChartFiltersDepartmentPlaceholder: string;
  runChartFiltersDevicePlaceholder: string;
  runChartFiltersMachinePlaceholder: string;
  runChartFiltersPartNumberPlaceholder: string;
  runChartFiltersPlantPlaceholder: string;
  runChartFiltersMeasurementPlanPlaceholder: string;
  runChartFiltersPlotGraphBtn: string;
  runChartFiltersResetBtn: string;
  runChartModalBackButton: string;
  runChartModalDetailsMachine: string;
  runChartModalDetailsMeasurement: string;
  runChartModalDetailsPN: string;
  runChartModalDetailsTimestamp: string;
  runChartModalTableCharacteristic: string;
  runChartModalTableTitle: string;
  runChartModalTableValue: string;
  runChartModalTitle: string;
  runChartModalViewPdfBtn: string;
  runChartReportListTitle: string;
  runChartTableColumnTimeStamp: string;
  runChartTableColumnValue: string;
  runChartTableValueListTitle: string;
  viewCharacteristicsCardTitle: string;
  viewCharacteristicsTableComments: string;
  viewCharacteristicsTableName: string;
  viewCharacteristicsTableValue: string;
  viewCmmCardCMM: string;
  viewCmmCardLab: string;
  viewCmmCardPlanta: string;
  viewCmmCardReports: string;
  viewCmmGetDevicesTooltip: string;
  viewCmmPlantsChoiceLabel: string;
  viewCmmTimeIntervalLabel: string;
  viewGetDevicesBtn: string;
  viewPlantCardTitle: string;
  viewReportCardTitle: string;
  viewReportCardViewLink: string;
}

export class ComponentsTranslation {
  addPartMappingModal_modalHeader: string;
  addPartMappingSelectLocationBtn: string;
  addPartMappingSelectLocationNameExists: string;
  addPartMappingSelectLocationNameUnique: string;
  addPartMappingCmmPartsPlaceholder: string;
  addPartMappingSubmitBtn: string;
  addSampleCollection_excludeLabel: string;
  addSampleCollection_limitBrokenMsgMaxVal: string;
  addSampleCollection_limitBrokenMsgMinVal: string;
  addSampleCollection_limitBrokenMsgTitle: string;
  addSampleCollection_limitBrokenMsgWantToContinue: string;
  addSampleCollection_notesLabel: string;
  addSampleCollection_sampleEditedMsg: string;
  addSampleCollection_saveBtn: string;
  addSampleCollection_subTitleSamples: string;
  addSampleCollection_title: string;
  addSampleCollection_titleAddSamples: string;
  addSampleCollection_titleEditSamples: string;
  addSampleCollection_validationValueOver: string;
  addSampleCollection_validationValueUnder: string;
  addSampleModal_addedSamplesMsg: string;
  addSampleModal_addSampleBtn: string;
  addSampleModal_addSamplesError: string;
  addSampleModal_cardTitleSamples: string;
  addSampleModal_completeBtn: string;
  addSampleModal_modalHeader: string;
  addSampleModal_notRunningLabel: string;
  addSampleModal_operatorInitialLabel: string;
  addSampleModal_partNumberLabel: string;
  addSampleModal_reasonLabel: string;
  addSampleModal_remarksLabel: string;
  addSampleModal_serialNumberLabel: string;
  chartOptions_trendLine: string;
  chartOptions_yAxisMin: string;
  chartOptions_yAxisMax: string;
  confirmUserPermission_emptyFieldsWarning: string;
  confirmUserPermission_engineerPermissionWarn: string;
  confirmUserPermission_incorrectPasswordWarn: string;
  confirmUserPermission_passwordLabel: string;
  confirmUserPermission_permissionConfirmedMsg: string;
  confirmUserPermission_qualitySupervisorPermissionWarn: string;
  confirmUserPermission_usernameLabel: string;
  filters_cardTitle: string;
  filters_clearBtn: string;
  filters_defaultOptionDept: string;
  filters_defaultOptionPlant: string;
  filters_departmentLabel: string;
  filters_listViewBtn: string;
  filters_plantLabel: string;
  filters_cmmPartLabel: string;
  filters_mdPartLabel: string;
  searchLabel: string;
  filters_showFavoriteLabel: string;
  filters_treeViewBtn: string;
  meanChart_cardTitle: string;
  meanChart_chartTextDateXaxis: string;
  meanChart_chartTextLocationYaxis: string;
  meanChart_LCLLabel: string;
  meanChart_LSLLabel: string;
  meanChart_maxLimitOptions: string;
  meanChart_meanLabel: string;
  meanChart_minLimitOptions: string;
  meanChart_nominalRange: string;
  meanChart_partNumberTooltip: string;
  meanChart_plotLineTextCentralLineLabelGrandMean: string;
  meanChart_plotLineTextCentralLineLabelMean: string;
  meanChart_plotLineTextLCLLabel: string;
  meanChart_plotLineTextLSLLabel: string;
  meanChart_plotLineTextRangeMinusLabel: string;
  meanChart_plotLineTextRangePlusLabel: string;
  meanChart_plotLineTextSSLLabel: string;
  meanChart_plotLineTextUCLLabel: string;
  meanChart_plotLineTextUSLLabel: string;
  meanChart_ProcessAverageTooltip: string;
  meanChart_recalcGroupPlotLine: string;
  meanChart_RulesTooltip: string;
  meanChart_SSLLabel: string;
  meanChart_SubgroupAverage: string;
  meanChart_refreshChartTooltip: string;
  meanChart_ticksAmount: string;
  meanChart_maximizeChart: string;
  meanChart_UCLLabel: string;
  meanChart_USLLabel: string;
  preControlChart_CentralLineLabel: string;
  preControlChart_chartTextDateXaxis: string;
  preControlChart_chartTextDateYaxis: string;
  preControlChart_LCLLabel: string;
  preControlChart_LGZLabel: string;
  preControlChart_Ticks: string;
  preControlChart_Title: string;
  preControlChart_UClLabel: string;
  preControlChart_UGZLabel: string;
  processChart_cantEditReactionPlanWarning: string;
  processChart_dateColumnName: string;
  processChart_frequencyShortVersionFourHr: string;
  processChart_frequencyShortVersionOneHr: string;
  processChart_frequencyShortVersionSixHr: string;
  processChart_frequencyShortVersionTwoHr: string;
  processChart_gageInstruction: string;
  processChart_headerInformation: string;
  processChart_inspColumnName: string;
  processChart_machine: string;
  processChart_partLabel: string;
  processChart_processChartNameLabel: string;
  processChart_reasonColumnName: string;
  processChart_remarksColumnName: string;
  processChart_rowsQuantity: string;
  processChart_selectPartNumberWarning: string;
  processChart_severityCell: string;
  processChart_supervisorInitialsColumnName: string;
  processChart_timeColumnName: string;
  rangeChart_cardTitle: string;
  rangeChart_chartTextDateXaxis: string;
  rangeChart_chartTitle: string;
  rangeChart_LCLLabel: string;
  rangeChart_plotLineTextCentralLineLabelMrbar: string;
  rangeChart_plotLineTextCentralLineLabelRbar: string;
  rangeChart_plotLineTextLCLLabel: string;
  rangeChart_plotLineTextUCLLabel: string;
  rangeChart_rangeTooltip: string;
  rangeChart_rulesTooltip: string;
  rangeChart_refreshChartTooltip: string;
  rangeChart_ticksAmount: string;
  rangeChart_UCLLabel: string;
  reactionPlan_actionTakenLabel: string;
  reactionPlan_calculationReset: string;
  reactionPlan_changeMachineParameterOption: string;
  reactionPlan_characteristicDescription: string;
  reactionPlan_characteristicName: string;
  reactionPlan_cleanMachineOption: string;
  reactionPlan_completeBtn: string;
  reactionPlan_correctionOfDeviceOption: string;
  reactionPlan_correctiveActionLabel: string;
  reactionPlan_dateLabel: string;
  reactionPlan_dateTimeViolation: string;
  reactionPlan_deviationOption: string;
  reactionPlan_interventionOption: string;
  reactionPlan_lslLabel: string;
  reactionPlan_machineAdjustmentOption: string;
  reactionPlan_materialChangeOption: string;
  reactionPlan_materialDisposition: string;
  reactionPlan_modalTitle: string;
  reactionPlan_operationInput: string;
  reactionPlan_operatorCommentLabel: string;
  reactionPlan_operatorCommentValidationMessage: string;
  reactionPlan_operatorInitialsLabel: string;
  reactionPlan_operatorInitialsValidationMessage: string;
  reactionPlan_processStopped: string;
  reactionPlan_qeFormInitialsValidationMsg: string;
  reactionPlan_qeFormLabel: string;
  reactionPlan_qeFormTitle: string;
  reactionPlan_qeLoginComponentTitle: string;
  reactionPlan_qualityInput: string;
  reactionPlan_qualitySignOff: string;
  reactionPlan_radioButtonCorrectedLabel: string;
  reactionPlan_radioButtonDeviation: string;
  reactionPlan_radioButtonHoldLabel: string;
  reactionPlan_radioButtonPassedLabel: string;
  reactionPlan_radioButtonReworkLabel: string;
  reactionPlan_radioButtonScrapLabel: string;
  reactionPlan_radioButtonSortLabel: string;
  reactionPlan_reactionPlanSavedMsg: string;
  reactionPlan_remarkLabel: string;
  reactionPlan_repairDeviceOption: string;
  reactionPlan_rule: string;
  reactionPlan_ruleBreakOverride: string;
  reactionPlan_rulesViolated: string;
  reactionPlan_severity: string;
  reactionPlan_supervisorSignOff: string;
  reactionPlan_svFormInitials: string;
  reactionPlan_svFormInitialsValidationMsg: string;
  reactionPlan_svFormTitle: string;
  reactionPlan_svLoginComponentTitle: string;
  reactionPlan_toolAdjustmentOption: string;
  reactionPlan_toolChangeOption: string;
  reactionPlan_uslLabel: string;
  reactionPlan_valueLabel: string;
  reactionPlan_verificationOfDeviceOption: string;
  sampleReason_eventOption: string;
  sampleReason_frequencyOption: string;
  sampleReason_hourlyOption: string;
  tableResult_cantDisabledCharacteristicMsg: string;
  tableResult_characteristic: string;
  tableResult_itemNameControlCharts: string;
  tableResult_itemNameLocationScreen: string;
  tableResult_itemNameProcessChart: string;
  tableResult_locationScreen: string;
  tableResult_maxFavoriteValidationPartOne: string;
  tableResult_maxFavoriteValidationPartTwo: string;
  tableResult_moveToRecycleBinMsg: string;
  tableResult_multi: string;
  tableResult_noPermissionToAddSamplesMsg: string;
  tableResult_processChart: string;
  tableResult_tableActions: string;
  tableResult_tableCreator: string;
  tableResult_tableLastModified: string;
  tableResult_tableName: string;
  tableResult_tableNamePath: string;
  tableResult_tablePart: string;
  tableResult_tablePath: string;
  tableResult_tooltipAddData: string;
  tableResult_tooltipAddToFavorite: string;
  tableResult_tooltipCopy: string;
  tableResult_tooltipEdit: string;
  tableResult_tooltipRemoveFavorite: string;
  tableResult_tooltipView: string;
  tableResult_tooltipCMMCharacteristic: string;
  tableResult_typeToValidationMaxFavoriteCharacteristics: string;
  tableResult_typeToValidationMaxFavoriteLocationScreen: string;
  tableResult_typeToValidationMaxFavoriteProcessChart: string;
  variableChart_trendLine: string;
}

export class DashboardTranslation {
  controlChardHideState: string;
  itemsPerPageSelect: string;
  switchLabelCompactView: string;
}

export class GlobalTranslation {
  addSampleBtn: string;
  afterChangeoverOptions: string;
  afterCorrectionOptions: string;
  afterMaintenanceInterventionOptions: string;
  afterMealOptions: string;
  afterModelChangeOptions: string;
  afterProcessChangeOptions: string;
  cancelBtn: string;
  clearBtn: string;
  closeBtn: string;
  confirmButtonText: string;
  confirmDelete: string;
  dataDeletedMsgTitle: string;
  dataEditedMsg: string;
  dataLostMsg: string;
  dataLostTextUndo: string;
  dataMismatch: string;
  dataRestored: string;
  dataSaved: string;
  editCharacteristicBtn: string;
  empty: string;
  emptyAttribute: string;
  enterBtn: string;
  entries: string;
  errorFetchMsg: string;
  errorOccurred: string;
  every2HoursOptions: string;
  every4HoursOptions: string;
  every6HoursOptions: string;
  everyHourOptions: string;
  excelExportBtn: string;
  failAttribute: string;
  isRequired: string;
  lclFullName: string;
  ldcFullName: string;
  lostChangesStatement: string;
  lslFullName: string;
  midOfShiftOptions: string;
  modalTitleSelectLocation: string;
  nameLabel: string;
  notRunningAttribute: string;
  oncePerDayOptions: string;
  oncePerShiftOptions: string;
  passedAttribute: string;
  refreshBtn: string;
  removeMsg: string;
  removeMsgTitle: string;
  reset: string;
  ruleEightDescription: string;
  ruleElevenDescription: string;
  ruleFiveDescription: string;
  ruleFourDescription: string;
  ruleNineDescription: string;
  ruleOneDescription: string;
  ruleSevenDescription: string;
  ruleSixDescription: string;
  ruleTenDescription: string;
  ruleThreeDescription: string;
  ruleTwoDescription: string;
  ruleTwelveDescription: string;
  ruleThirteenDescription: string;
  saveBtn: string;
  searchBtn: string;
  show: string;
  showEmptyLocationLabel: string;
  sslFullName: string;
  startOfShiftOptions: string;
  submitBtn: string;
  toolChangeOptions: string;
  uclFullName: string;
  udcFullName: string;
  uslFullName: string;
  noPartNumber: string;
}

export class LayoutTranslation {
  passwordChangeModalChangePasswordBtn: string;
  passwordChangeModalConfirmPasswordLabel: string;
  passwordChangeModalConfirmPasswordValidation: string;
  passwordChangeModalDifferentPassValidation: string;
  passwordChangeModalPasswordChanged: string;
  passwordChangeModalPasswordLabel: string;
  passwordChangeModalPasswordValidation: string;
  passwordChangeModalTitle: string;
  userMenuOptionLogout: string;
  userMenuOptionSettings: string;
}

export class LocationScreenTranslation {
  createEnableLabel: string;
  createEnterNameLabel: string;
  createLackOfProcessCharts: string;
  createLocationScreenNameValidation: string;
  createNameLabel: string;
  createNameValidationMessage: string;
  createNewLocationScreenBtn: string;
  createPickProcessChartLabel: string;
  createProcessChartCheckboxLabel: string;
  createProcessChartListTitle: string;
  createSelectLocation: string;
  locationScreenView: string;
}

export class MenuTranslation {
  administration: string;
  administrationFixtures: string;
  administrationPartModels: string;
  administrationParts: string;
  administrationPartMapping: string;
  administrationPermissions: string;
  administrationReactionPlanReasons: string;
  administrationRecycleBin: string;
  administrationRoles: string;
  administrationRuleSettings: string;
  administrationSampleReasons: string;
  administrationSettings: string;
  administrationUnits: string;
  administrationUsers: string;
  characteristic: string;
  characteristicCreate: string;
  characteristicView: string;
  cmmData: string;
  cmmDataRunChart: string;
  cmmDataView: string;
  dashboard: string;
  locationScreen: string;
  locationScreenCreate: string;
  locationScreenView: string;
  processChart: string;
  processChartCreate: string;
  processChartView: string;
  reports: string;
  user: string;
}

export class ProcessChartTranslation {
  calculatedAttributeCharacteristicDisabled: string;
  calculatedCreateCalculationString: string;
  calculatedCreateConstantValue: string;
  calculatedCreateCorrectCompletedEdit: string;
  calculatedCreateCorrectCompletedMsgPart1: string;
  calculatedCreateCorrectCompletedMsgPart2: string;
  calculatedCreateCorrectCompletedMsgTitle: string;
  calculatedCreateCorrectCompletedSave: string;
  calculatedCreateEnabledBtn: string;
  calculatedCreateEnterName: string;
  calculatedCreateIncludedCardHeader: string;
  calculatedCreateLocationPath: string;
  calculatedCreateMoreCharacteristicErrorMsg: string;
  calculatedCreateNameLabel: string;
  calculatedCreateNameValidation: string;
  calculatedCreateResetBtn: string;
  calculatedCreateSelectOperation: string;
  calculatedCreateWithoutNameErrorMsg: string;
  calculatedCreateWithoutOperatorErrorMsg: string;
  createCalculatedCharacteristicsIsUsedWarning: string;
  createCharacteristicIsUsedWarning: string;
  createCheckboxCharacteristicToSelect: string;
  createCheckboxCharacteristicToSelectPN: string;
  createDataHaveBeen: string;
  createEditedData: string;
  createEnableProcessChartCheckboxLabel: string;
  createEnterUniqueName: string;
  createIncludedCharacteristicsCartTitle: string;
  createLackOfCharacteristics: string;
  createMaxCharacteristicsWarning: string;
  createNameLabel: string;
  createNameValidation: string;
  createNewCalculatedCharacteristicsBtn: string;
  createPickCalculatedCharacteristicsLabel: string;
  createPickCharacteristicCardHeader: string;
  createPickLocationBtn: string;
  createPickLocationModalTitle: string;
  createSavedData: string;
  createTeamCenterLabel: string;
  processChartView: string;
  processChartButtonWarningTooltip: string;
}

export class UserSettingsTranslation {
  addPhotoBtn: string;
  cardHeader: string;
  changePhotoBtn: string;
  confirmMsgBtn: string;
  confirmPasswordLabel: string;
  confirmPasswordValidation: string;
  currentPasswordLabel: string;
  currentPasswordValidationBlank: string;
  currentPasswordValidationIsIncorrect: string;
  firstNameLabel: string;
  firstNameValidation: string;
  lastNameLabel: string;
  lastNameValidation: string;
  newPasswordLabel: string;
  newPasswordValidation: string;
  passwordIncorrectMsg: string;
  passwordsValidationDifferent: string;
  removePhotoBtn: string;
  userNameLabel: string;
}
