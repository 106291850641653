import {environment} from '@environment/environment';
import {EnvironmentType} from '@common/enums/environment-type';

export class LabeledValue<T> {
  public label: string;

  constructor(
    public value: T,
    label?: string,
    public disabled?: boolean
  ) {
    this.label = label
      ? label
      : String(value);
  }
}

export class Global {
  public readonly ROWS_ON_PAGE = 1000;
  public readonly SESSION_STORAGE_KEYS = {
    characteristic_list: 'characteristicListFilterOptions',
    characteristic_create: 'characteristicListCreate',
    process_charts_list: 'processChartListFilterOptions',
    process_charts_create: 'processChartCreateData',
    location_screens_list: 'locationScreenListFilterOptions',
    location_screens_create: 'locationScreenCreate',
  };
  public readonly TREE_CELL_TYPES = {
    country: 'country',
    site: 'site',
    plant: 'plant',
    department: 'dept/value stream',
    cell: 'cell',
    subGroup: 'sub group',
    line: 'line',
    machine: 'machine',
    characteristic: 'characteristic',
    processChart: 'process chart',
    locationScreen: 'location screen'
  };
  public readonly OPERATION_FILTER_TYPE = {
    characteristic: 'characteristic',
    processChart: 'process chart',
    locationScreen: 'location screen'
  };
  public readonly MAX_SAMPLE_VALUE = 9999999;
  public readonly MAX_FAVOURITES_VALUE = {
    characteristic: 100,
    processChart: 100,
    locationScreen: 100,
  };
  public readonly AUTH_ERRORS = {
    invalid_request: 'invalid_request',
    invalid_grant: 'invalid_grant',
  };
  public readonly COLOR = {
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
  };
  public readonly COLOR_PROCESS_CHART = {
    calculatedCharacteristic: '#d1f0ff',
    calculatedCharacteristic_disabled: '#cedae0',
    characteristic: '#ffffff',
    characteristic_disabled_text: '#808080',
    broken_characteristic: '#FF0000',
    broken_characteristic_disabled: '#942424',
    severity_cell: '#f4ff87',
    severity_cell_disabled: '#c7cf75',
    broken_characteristic_cell: '#FF0000',
    broken_characteristic_cell_disabled: '#942424',
    fulfilled_broken_characteristic_cell: '#ffc0cb',
    fulfilled_broken_characteristic_cell_disabled: '#a9868c'
  };
  public readonly LANGUAGE_TYPE = {
    english: 'english',
    polish: 'polish',
    spanish: 'spanish',
    chinese: 'chinese',
    portuguese: 'portuguese',
  };
  public readonly TYPE_LOCALE = {
    english: 'en-EN',
    polish: 'pl-PL',
    spanish: 'es-ES',
    chinese: 'zh-ZH',
    portuguese: 'pt-PT',
  };
  public readonly ROUTE_PATHS = {
    dashboard: 'dashboard',
    characteristic: {
      root: 'characteristic',
      create: 'create',
      view: 'view',
    },
    processChart: {
      root: 'process-chart',
      create: 'create',
      view: 'view',
      calculatedCharacteristic: 'calculated-characteristic',
    },
    locationScreen: {
      root: 'location-screen',
      create: 'create',
      view: 'view',
    },
    cmmData: {
      root: 'cmm-data',
      view: 'view',
      runChart: 'run-chart',
    },
    administration: {
      root: 'administration',
      users: 'users',
      userRoles: 'user-roles',
      permissions: 'permissions',
      units: 'units',
      settings: 'settings',
      fixture: 'fixtures',
      recycleBin: 'recycle-bin',
      parts: 'parts',
      partsComparison: 'parts-comparison',
      sampleReasons: 'sample-reasons',
      reactionPlanReasons: 'reaction-plan-reasons',
      partModels: 'part-models',
      ruleSettings: 'rule-settings'
    },
    reports: 'reports',
    user: {
      root: 'user',
      profile: 'profile'
    }
  };
  public readonly rules = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

  public readonly DATE_FORMAT = 'M/d/yy, h:mm:ss a';
  public readonly API_DATE_FORMAT = 'd-M-yyyyTh:mm:ss a';
  public readonly environmentTypes = EnvironmentType;
  public readonly environmentType = environment.type;
  public readonly cmmFeaturesVisibility = !!(environment.type & (EnvironmentType.Local | EnvironmentType.US | EnvironmentType.PL | EnvironmentType.CN));
  public readonly partModelVisibility = !!(environment.type & (EnvironmentType.Local | EnvironmentType.US | EnvironmentType.MX | EnvironmentType.CN));
  public readonly isNotMexico = !(environment.type & (EnvironmentType.MX));
}

export const GlobalVariable = new Global();
