<app-modal-basic
  #modal
  [dialogClass]="'modal-lg'"
  [width]="1000"
>
  <div class="app-modal-header">
    <h4 class="modal-title">
      {{ title }}
    </h4>

    <button
      class="close basic-close"
      (click)="closeModal()"
      type="button"
    >
      <span aria-hidden="true">
        &times;
      </span>
    </button>
  </div>

  <div class="app-modal-body"
       *ngIf="characteristic"
       style="max-height: calc(100vh - 210px); overflow-y: auto;">
    <form id="formUnscheduled"
          #formUnscheduled="ngForm"
          (ngSubmit)="onSubmitSamples(formUnscheduled.valid)">
      <div class="card">
<!--        <div class="{{(''|globalVariables).environmentType !== (''|globalVariables).environmentTypes.MX ? 'mexican-server-body-grid-container' : 'body-grid-container'}}"></div>-->
        <div class="d-flex">
          <div class="col-md-6 d-flex flex-column">
            <div class="card-block">
              <h4 class="sub-title">
                {{componentsTranslation.addSampleCollection_title}}
              </h4>

              <p class="text-break">
                {{ characteristic.prompt }}
              </p>
            </div>

            <div *ngIf="(''|globalVariables).isNotMexico && characteristic?.fixtures?.length"
                 class="card-block">

              <form class="fixture-select">
                <h4 class="sub-title">
                  {{characteristicTranslation.fixtureHeaderLabel}}
                </h4>

                <hr>

                <select
                  name="fixtureName"
                  class="fixture-name w-100"
                  *ngIf="characteristic.fixtures && characteristic.fixtures.length > 0"
                  [(ngModel)]="selectedFixtureToAll"
                  required>
                  <option *ngFor="let fixture of characteristic.fixtures;"
                          [ngValue]="fixture.fixtureId">
                    {{fixture.fixtureName}}
                  </option>

                  <option *ngIf="characteristic.fixtures.length === 0"
                          disabled
                          selected>
                    {{characteristicTranslation.fixtureNoOptionsString}}
                  </option>
                </select>
              </form>

              <div class="cursor-pointer">
                <fa-icon
                  class="icon-border"
                  (click)="selectFixtureToAll()"
                  [icon]="iconPlus">
                </fa-icon>

                <label>
                  {{characteristicTranslation.fixtureApplyToAll}}
                </label>
              </div>

              <div class="cursor-pointer">
                <fa-icon
                  class="icon-border"
                  (click)="removeFixtureFromAll()"
                  [icon]="iconDelete">
                </fa-icon>

                <label>
                  {{characteristicTranslation.fixtureRemoveFromAll}}
                </label>
              </div>

            </div>
          </div>

          <div *ngIf="characteristic.gageType === gaugeTypes.Variable"
               class="col-md-6 float-right">
            <div class="card-block">
              <h4 class="sub-title">
                {{componentsTranslation.addSampleCollection_subTitleSamples}}
              </h4>

              <ng-container *ngFor="let item of [].constructor(characteristic.chartSamples); index as index; odd as odd; even as even">
                <ng-container *ngIf="even">
                  <div class="body-grid-samples">
                    <div>
                      <ng-container
                        [ngTemplateOutletContext]="{ sample: characteristicSample.characteristicCollectionSamples[index], index: index, form: formUnscheduled }"
                        [ngTemplateOutlet]="sampleTemplate">
                      </ng-container>
                    </div>

                    <div *ngIf="index + 1 !== characteristic.chartSamples || characteristic.chartSamples % 2 === 0">
                      <ng-container
                        [ngTemplateOutletContext]="{ sample: characteristicSample.characteristicCollectionSamples[index + 1], index: index + 1, form: formUnscheduled }"
                        [ngTemplateOutlet]="sampleTemplate">
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>

        <div>
          <div class="attribute-value"
               *ngIf="characteristic.gageType === gaugeTypes.Attribute">
            <div class="attribute-value">
              <fieldset class="float-right" style="margin-right: 60px">
                <label class="radioBtn">
                  <p>
                    {{globalTranslation.notRunningAttribute}}
                  </p>

                  <input
                    name="materialDisposition"
                    [(ngModel)]="attributeSampleValue"
                    type="radio"
                    [value]="attributeSampleValues.NR"
                  >

                  <span class="checkmark">
                  </span>
                </label>

                <label class="radioBtn d-flex align-items-center">
                  <p>
                    {{globalTranslation.failAttribute}}
                  </p>

                  <div class="icon-x-red ml-3">
                  </div>

                  <input
                    name="materialDisposition"
                    [(ngModel)]="attributeSampleValue"
                    type="radio"
                    [value]="attributeSampleValues.X"
                  >

                  <span class="checkmark">
                  </span>
                </label>

                <label class="radioBtn d-flex align-items-center">
                  <p>
                    {{globalTranslation.passedAttribute}}
                  </p>

                  <div class="icon-plus-green ml-3">
                  </div>

                  <input
                    name="materialDisposition"
                    [(ngModel)]="attributeSampleValue"
                    type="radio"
                    [value]="attributeSampleValues.Plus"
                  >

                  <span class="checkmark">
                  </span>
                </label>

                <div *ngIf="formUnscheduled.submitted && attributeSampleValue == null"
                     class="messages text-danger"
                >
                  {{globalTranslation.isRequired}}
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="card-block pb-1">
            <h4 class="sub-title mb-0">
              {{componentsTranslation.addSampleCollection_notesLabel}}
            </h4>

            <div class="form-group row">
              <div class="col-sm-12">
                <app-vk-input
                  type="textarea"
                  name="collectionDesc"
                  maxLength="1000"
                  cols="5"
                  rows="3"
                  [required]="true"
                  [(ngModel)]="characteristicSample.collectionDesc"
                  [ngModelOptions]="{standalone: true}">
                </app-vk-input>
              </div>
            </div>
          </div>

          <div *ngIf="characteristicSample.id != null"
               class="card-block pt-0">
            <h4 class="sub-title mb-0">
              {{componentsTranslation.addSampleCollection_excludeLabel}}
            </h4>

            <div class="form-group row">
              <div class="col-sm-12">
                <div class="float-left" style="margin-top: 8px;">
                  <ui-switch
                    id="excludeCollection"
                    name="excludeCollection"
                    class="js-single switchery-small"
                    [(ngModel)]="characteristicSample.excludeCollection"
                    [ngModelOptions]="{standalone: true}"
                    checkedLabel="Excluded"
                    color="#4680ff"
                    size="small"
                    switchColor="#ffffff"
                    uncheckedLabel="NOT Excluded">
                  </ui-switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="app-modal-footer">
    <div>
      <button class="btn btn-default ripple"
              (click)="closeModal()"
              type="button">
        {{globalTranslation.closeBtn}}
      </button>
    </div>

    <div>
      <button class="btn btn-primary ripple light"
              form="formUnscheduled"
              type="submit">
        {{componentsTranslation.addSampleCollection_saveBtn}}
      </button>
    </div>
  </div>
</app-modal-basic>

<ng-template #sampleTemplate
             let-form="form"
             let-index="index"
             let-sample="sample">
  <div class="add-sample-container">
    <div class="sample-with-fixture-extension">
      <div>
        <app-vk-input
          #sampleInput
          type="number"
          name="sampleInput"
          placeholder="Sample {{index + 1}}"
          [required]="characteristic.chartSamples > index"
          [disabled]="characteristic.chartSamples <= index"
          [(ngModel)]="asSample(sample).sampleValue"
          [ngModelOptions]="{standalone: true}"
          [focusOnKeyboardClose]="true"
          (keypress)="preventNonNumericalInput($event, sampleInput.value)"
          (blur)="onBlur($event, index, sampleInput.inputElement)">
        </app-vk-input>
      </div>

      <app-sample-input
        *ngIf="(''|globalVariables).isNotMexico && characteristic?.fixtures?.length"
        class="fixture-extension"
        [disabled]="characteristic.chartSamples < index + 1"
        [selectedFixtureId]="characteristicSample.characteristicCollectionSamples[index | parseNumber].fixtureId"
        (sampleFixtureDataChange)="combineFixtureDataWithSample(index, $event)"
        [fixtureData]="characteristic.fixtures"
        [ngClass]="{'disabled': characteristic.chartSamples < index + 1}">
      </app-sample-input>
    </div>

    <div class="messages text-danger"
         *ngIf="form['submitted'] && asSample(sample).sampleValue == null && characteristic.chartSamples > index">
      {{globalTranslation.isRequired}}
    </div>

    <div class="messages text-danger"
         *ngIf=" ['submitted'] && (asSample(sample).sampleValue > maxSampleValue || asSample(sample).sampleValue < -maxSampleValue)">
      {{componentsTranslation.addSampleCollection_validationValueOver}} {{maxSampleValue}} {{componentsTranslation.addSampleCollection_validationValueUnder}}{{maxSampleValue}}
    </div>
  </div>
</ng-template>
