
import {filter} from 'rxjs/operators';
import {Component} from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {BreadCrumbs} from '@common/types/bread-crumbs';
import {RouteData} from '@common/types/route-data';
import {uniqBy} from 'lodash';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {
  public breadcrumbs: BreadCrumbs[] = [];
  public lastBreadCrumbs: BreadCrumbs;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.breadcrumbs = [];
        let currentRoute = this.route.root;
        let url = '';

        do {
          const childrenRoutes = currentRoute.children;
          currentRoute = null;

          childrenRoutes.forEach(routes => {
            if (routes.outlet === 'primary') {
              const routeSnapshot = routes.snapshot;
              const routeData = routes.snapshot.data as RouteData;

              if (routeData.title) {
                url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
                this.breadcrumbs.push({
                  label: routeData.title,
                  status: routeData.status,
                  url: url
                });
              }
              currentRoute = routes;
            }
          });
        } while (currentRoute);

        this.breadcrumbs = uniqBy(this.breadcrumbs, (breadcrumb) => breadcrumb.label);
        this.lastBreadCrumbs = this.breadcrumbs[this.breadcrumbs.length - 1];
      });
  }
}
