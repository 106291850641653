<div class="card">
  <div class="card-header card-header-option">
    <div>
      <h5>
        {{componentsTranslation.filters_cardTitle}}
      </h5>
    </div>

    <div>
      <button
        type="button"
        class="btn btn-primary ripple light"
        (click)="clearFilter()">
        {{componentsTranslation.filters_clearBtn}}
      </button>
    </div>
  </div>

  <div class="selects">
    <div class="col w-100 selectors-wrapper">
      <div class="filter-selectors">
        <app-filter-selector
          [label]="componentsTranslation.filters_plantLabel"
          [filterOptions]="plantsLocation"
          [(selectedFilters)]="availableFilterOptions.selectedPlant"
          (selectedFiltersChange)="applyFilter(true)">
        </app-filter-selector>

        <app-filter-selector
          [label]="componentsTranslation.filters_departmentLabel"
          [filterOptions]="departmentsLocation"
          [(selectedFilters)]="availableFilterOptions.selectedDepartment"
          (selectedFiltersChange)="applyFilter()">
        </app-filter-selector>

        <app-vk-input
          class="d-flex align-items-end"
          style="flex: 0 0 auto"
          type="text"
          [required]="true"
          [label]="componentsTranslation.searchLabel"
          [(ngModel)]="availableFilterOptions.filterQuery"
          [ngModelOptions]="{standalone: true}"
          (ngModelChange)="applyFilter()">
        </app-vk-input>
      </div>
    </div>
  </div>
</div>
