import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {NotifierService as NotificationsService} from 'angular-notifier';
import {SweetAlertOptions, SweetAlertType} from 'sweetalert2';
import {GlobalVariable} from '@common/global';
import {User} from '@models/user';

export enum NotifierType {
  Default = 'default',
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warn = 'warning',
  Alert = 'error',
}

export enum MemorySizeUnit {
  kB = 1024,
  MB = kB * 1024,
  GB = MB * 1024
}

export enum AttributeSampleValue {
  Null = -2,
  NR = -1,
  X = 0,
  Plus = 1
}

export function clone<T>(obj: T): T {
  const cloneObj = new (<any>this.constructor)(); // line fixed

  for (const attribute in obj) {
    if (obj.hasOwnProperty(attribute)) {
      if (typeof this[attribute] === 'object') {
        cloneObj[attribute] = clone(obj[attribute]);
      } else {
        cloneObj[attribute] = obj[attribute];
      }
    }
  }
  return cloneObj;
}

@Injectable()
export class HelpersService {
  constructor(private notificationsService: NotificationsService) {
  }

  public static getLoggedUser(): User {
    return JSON.parse(localStorage.getItem('user')) as User;
  }

  public static saveLoggedUser(loggedUser: User): void {
    localStorage.setItem('user', JSON.stringify(loggedUser));
  }

  public static hasUserPermission(user: User, permission: string): boolean {
    return user.apiPermissions.includes(permission);
  }

  public static makeExcelFile(fileName: string, data: ArrayBuffer | Blob): File {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    return new File([data], fileName, {type: excelFileType});
  }

  public static openDownloadFileWindow(file: File, fileName = file.name): void {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(file, fileName);
    } else {
      const anchor = window.document.createElement('a');
      anchor.href = window.URL.createObjectURL(file);
      anchor.download = fileName;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(anchor.href);
    }
  }

  public static transformDateToCorrectTime(date: Date): string {
    const datePipe = new DatePipe('en-US');

    return datePipe.transform(date, GlobalVariable.API_DATE_FORMAT, '+0200');
  }

  public static round(value: number, decimals = 0, rounding: 'ceil' | 'floor' | 'auto' = 'auto'): number {
    const multiplier = !!decimals && decimals > 0
      ? Math.pow(10, decimals)
      : 1;

    let roundedValue: number = (value + Number.EPSILON) * multiplier;
    switch (rounding) {
      case 'auto':
        roundedValue = Math.round(roundedValue);
        break;
      case 'ceil':
        roundedValue = Math.ceil(roundedValue);
        break;
      case 'floor':
        roundedValue = Math.floor(roundedValue);
    }

    return roundedValue / multiplier;
  }

  public static setDecimals(value: number, decimals: number): string {
    if (decimals === null) {
      return value.toString();
    }

    return value !== null && decimals >= 1 && decimals <= 7
      ? value.toFixed(decimals)
      : null;
  }

  public static preventNonNumericalInput(keyEvent: KeyboardEvent, value: string): void {
    const skip = true;
    if (skip) {
      return;
    }

    value = value !== null
      ? value
      : '';

    const numericRegex = /^$|^[-]?[0-9]*[.]?[0-9]*$/;

    if (!(value + keyEvent.key).match(numericRegex)) {
      keyEvent.preventDefault();
    }
  }

  public static createAlertOptions(title: string, text: string, confirmText: string, alertType: SweetAlertType): SweetAlertOptions {
    const showDecisionButtons = alertType === 'warning';

    return {
      title: title,
      text: text,
      type: alertType,
      showCancelButton: showDecisionButtons,
      confirmButtonColor: `${GlobalVariable.COLOR.confirmButtonColor}`,
      cancelButtonColor: `${GlobalVariable.COLOR.cancelButtonColor}`,
      showConfirmButton: true,
      confirmButtonText: confirmText
    };
  }

  public static getFileName(path: string): string {
    return path.match(/([^\\/]*)$/).pop();
  }

  public static dateReviver(): (key: string, value: any) => any {
    const isoDateRegex
      = /^([0-9]{4})-((0[1-9])|(1[0-2]))-((0[1-9])|([1-2][0-9])|(3[0-1]))T(([0-1][0-9])|(2[0-3])):([0-5][0-9]):([0-5][0-9]).([0-9]{0,7})Z?$/;
    return (key: string, value: any) => {
      if (!!value && typeof value === 'string' && isoDateRegex.test(value)) {
        value = value.endsWith('Z')
          ? value
          : `${value}Z`;
        return new Date(value);
      }

      return value;
    };
  }

  public static valueToPixels(value: number): string {
    return `${value}px`;
  }

  public notificationTrigger(type: NotifierType, message: string, content = '', timeOut = 3000): void {
    const overrideObject = {
      timeOut: timeOut,
      pauseOnHover: true,
      clickToClose: true,
      clickIconToClose: true
    };
    // TODO CLEANUP?, check for overrideObject
    this.notificationsService.notify(type, message + (content ? '\n' + content : '') );
  }
}
