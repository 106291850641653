import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {HelpersService} from '@services/helpers.service';

export class DateReviverInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> > {

    if (httpRequest.responseType === 'json') {
      return next.handle(httpRequest)
        .pipe(
          filter(event => event instanceof HttpResponse),
          map((event: HttpResponse<any>) =>
            event.clone({
              body: JSON.parse(JSON.stringify(event.body), HelpersService.dateReviver())
            }))
        );
    } else {
      return next.handle(httpRequest);
    }
  }
}
