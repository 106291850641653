import {trigger, state, style, AUTO_STYLE, transition, animate} from '@angular/animations';

export enum CardToggleState {
  False = 'false',
  Collapsed = 'collapsed',
  Expanded = 'expanded',
}

export enum CardOpenCloseToggleState {
  Opened = 'opened',
  Closed = 'closed'
}

export const cardToggle = trigger('cardToggle', [
  state(`${CardToggleState.Collapsed}, void`,
    style({
      overflow: 'hidden',
      height: '0px',
    })
  ),
  state(CardToggleState.Expanded,
    style({
      overflow: 'hidden',
      height: AUTO_STYLE,
    })
  ),
  transition(`${CardToggleState.Collapsed} <=> ${CardToggleState.Expanded}`,
    animate('400ms ease-in-out'))
]);

export const cardClose = trigger('cardClose', [
  state(CardOpenCloseToggleState.Opened, style({
    opacity: 1
  })),
  state(CardOpenCloseToggleState.Closed, style({
    opacity: 0,
    display: 'none'
  })),
  transition(`${CardOpenCloseToggleState.Opened} => ${CardOpenCloseToggleState.Closed}`,
    animate('400ms'))
]);
