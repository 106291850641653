import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {VirtualKeyboardService} from '@shared/virtual-keyboard/virtual-keyboard.service';
import {TranslationService} from '@services/translation.service';
import {ComponentsTranslation, GlobalTranslation} from '@models/translation';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-location-pick',
  templateUrl: './location-pick.component.html',
  styleUrls: ['./location-pick.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm}]
})
export class LocationPickComponent implements OnDestroy {
  private readonly subscriptions = new Subscription();

  public globalTranslation: GlobalTranslation;
  public componentTranslation: ComponentsTranslation;

  @Input() form: NgForm;
  @Input() userAccessBlocked = false;
  @Input() locationPickName: string;
  @Input() locationPickDisabled = false;
  @Input() locationPath: string;

  @Output() selectLocationClicked = new EventEmitter();

  constructor(
    private translationService: TranslationService,
    public virtualKeyboardService: VirtualKeyboardService
  ) {
    this.setTranslation();
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.globalTranslation = translation.global;
    });

    this.subscriptions.add(translationSubscription);
  }
  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onSelectLocationClick(): void {
    this.selectLocationClicked.emit();
  }
}
