import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ModalBasicComponent} from '@shared/modal-basic/modal-basic.component';
import {Credentials, User} from '@models/user';
import {NgForm} from '@angular/forms';
import {ComponentsTranslation, GlobalTranslation} from '@models/translation';
import {TranslationService} from '@services/translation.service';
import {HelpersService, NotifierType} from '@services/helpers.service';
import {VirtualKeyboardService} from '@shared/virtual-keyboard/virtual-keyboard.service';
import {SignatureType, VerificationConfirmation} from '@models/verification-confirmation';
import {CharacteristicApiService} from '@characteristic/characteristic-api.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-authorization',
  templateUrl: './user-authorization.component.html',
  styleUrls: ['./user-authorization.component.scss']
})
export class UserAuthorizationComponent implements AfterViewInit, OnDestroy {
  private readonly subscriptions = new Subscription();

  private openPermissionModalTrigger: boolean;

  public credentials = new Credentials();
  public componentsTranslation: ComponentsTranslation;
  public globalTranslation: GlobalTranslation;

  @Input() set openPermissionModal(openPermissionModal: boolean) {
    this.openPermissionModalTrigger = openPermissionModal;
    if (openPermissionModal) {
      this.resetFormsValue();
      this.modalPermission.show();
    }
  }
  @Input() modalTitle: string;
  @Input() requiredPermission: SignatureType;

  @Output() openPermissionModalChange = new EventEmitter<boolean>();
  @Output() authorize = new EventEmitter<VerificationConfirmation>();

  @ViewChild('modalPermission') modalPermission: ModalBasicComponent;
  @ViewChild('checkPermissionsForm') checkPermissionsForm: NgForm;

  constructor(
    private characteristicApiService: CharacteristicApiService,
    private helpersService: HelpersService,
    private translationService: TranslationService,
    private virtualKeyboardService: VirtualKeyboardService
  ) {
    this.setTranslation();
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.componentsTranslation = translation.components;
      this.globalTranslation = translation.global;
    });

    this.subscriptions.add(translationSubscription);
  }

  public ngAfterViewInit() {
    this.resetFormsValue();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onSubmit(formData: User, isValid: Boolean): void {
    if (isValid) {
      let apiCall: () => Promise<VerificationConfirmation>;
      let verificationWarning: string;
      let verificationError: string;

      if (this.requiredPermission === SignatureType.QualityEngineer) {
        apiCall = () => this.characteristicApiService.qualityEngineerVerification(formData);
        verificationWarning = this.componentsTranslation.confirmUserPermission_engineerPermissionWarn;
        verificationError = this.componentsTranslation.confirmUserPermission_incorrectPasswordWarn;
      } else if (this.requiredPermission === SignatureType.Supervisor) {
        apiCall = () => this.characteristicApiService.supervisorVerification(formData);
        verificationWarning = this.componentsTranslation.confirmUserPermission_qualitySupervisorPermissionWarn;
        verificationError = this.componentsTranslation.confirmUserPermission_incorrectPasswordWarn;
      }

      apiCall()
        .then(verificationConfirmation => {
          if (verificationConfirmation.item1) {
            this.onUserAuthorized(verificationConfirmation);
          } else {
            this.helpersService.notificationTrigger(NotifierType.Warn, verificationWarning);
          }
        })
        .catch(() => {
          this.helpersService.notificationTrigger(NotifierType.Error, verificationError);
        });
    }
  }

  public onUserAuthorized(verificationConfirmation: VerificationConfirmation): void {
    this.authorize.emit(verificationConfirmation);
    this.closeModal();
    this.helpersService.notificationTrigger(
      NotifierType.Success, this.componentsTranslation.confirmUserPermission_permissionConfirmedMsg);
  }

  public closeModal(): void {
    this.resetFormsValue();
    this.virtualKeyboardService.turnOffKeyboard();
    this.modalPermission.hide();
    this.openPermissionModalChange.emit(false);
  }

  private resetFormsValue(): void {
    this.credentials = new Credentials();
    this.checkPermissionsForm.resetForm();
  }
}
