export enum ReactionPlanReasonType {
  ActionTaken = 1,
  MaterialDisposition = 2
}

export class ReactionPlanReason {
  public id: number;
  public name: string;
  public type: ReactionPlanReasonType;
  public active = true;
  public isUsed: boolean;
}
