import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Subject } from 'rxjs';
import {ApiService} from '@services/api.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor (private apiService: ApiService) {}

  canActivate(route: ActivatedRouteSnapshot, router: RouterStateSnapshot): boolean | Promise<boolean> | Subject<boolean> {
    const token = ApiService.getCurrentToken();

    if (!token) {
      this.apiService.logout();
    }

    return !!token;
  }
}
