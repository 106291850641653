import {Component, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation, AfterViewInit} from '@angular/core';
import {CharacteristicSamplesType, OneSidedChartBorderType, SpecLimitType} from '@models/characteristic';
import {CharacteristicSampleCollection} from '@models/characteristic-sample-collection';
import {CmmMeasurement} from '@models/cmm-characteristic-chart';
import {TranslationService} from '@services/translation.service';
import {SslLimitType} from '@models/limit-config';
import {BaseChartComponent, ChartData, LimitType, SeriesType} from '@shared/charts/base-chart/base-chart.component';
import {ChartService} from '@shared/charts/chart.service';
import {MeanSamplePoint, SamplePoint} from '@shared/charts/sample-point';
import * as Highcharts from 'highcharts';
import {SeriesLineOptions} from 'highcharts';
import {max, min} from 'lodash-es';
import HC_exporting from 'highcharts-regression';

HC_exporting(Highcharts);

interface SeriesLinearRegressionOptions extends SeriesLineOptions {
  regression: boolean;
  regressionSettings: {
    name?: string;
    tooltip?: {};
    dashStyle?: string;
    decimalPlaces?: number;
    useAllSeries?: boolean;
    regressionSeriesOptions?: {};
    type?: string;
    color?: string;
  };
}

@Component({
  selector: 'app-mean-chart',
  templateUrl: '../base-chart/./base-chart.component.html',
  styleUrls: ['../base-chart/./base-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ChartService]
})
export class MeanChartComponent extends BaseChartComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit  {
  chartOptionsLocalStorageKey = 'meanChartOptionsLocalStorageKey';
  centralLineLabel = '';

  @Input() useXAxisLabels = true;
  @Input() useCmmData: boolean;
  @Input() cmmData: CmmMeasurement[];

  constructor(
    private translationService: TranslationService,
    private chartService: ChartService
  ) {
    super(translationService);

    this.chartContainerId = 'controlChartId';
    this.seriesType = SeriesType.Line;
    this.sampleTooltipFormatter = (point: MeanSamplePoint) => this.chartService.createMeanChartTooltip(point);
  }

  public static getMeanSampleColor(appliedRules: string[], ruleBreakOverride: boolean): string {
    let color = '#000000';
    if (appliedRules.length > 0 && ruleBreakOverride !== true) {
      color = '#ff5252';
    }
    return color;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.chartService.decimals = `1.${this.characteristic.decimals}-${this.characteristic.decimals}`;
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    localStorage.removeItem(this.chartOptionsLocalStorageKey);
  }

  public ngOnChanges(changes): void {
    if (changes.isOptionsOpened) {
      this.resizeChart();
    }
    if (changes.recalcValues && this.recalcValues) {
      this.sampleCollections = this.recalcValues.sampleCollections || [];
      this.init();
    }

    if ((changes.cmmData && this.cmmData) || (changes.useXAxisLabels && this.useXAxisLabels)) {
      this.generateChart();
    }
  }

  protected setLabels(): void {
    this.sampleName = this.componentsTranslation.meanChart_meanLabel;
    this.chartCardTitle = this.componentsTranslation.meanChart_cardTitle;
    this.xAxisTitle = this.componentsTranslation.meanChart_chartTextDateXaxis;
    this.yAxisTitle = this.componentsTranslation.meanChart_chartTextLocationYaxis;
  }

  protected init(): void {
    this.setLabels();
  }

  public ngAfterViewInit(){
    if (this.recalcValues && this.chartContainer) {
      this.generateOptions();
      this.generateLimitSwitchOptions();
      this.generateChart();
    }
  }

  protected generateOptions(): void {
    const controlLimitsColor = '#0062ff';
    const centralLine = '#55b847';
    const oneSidedSpecLimitColor = '#b07600';
    const twoSidedSpecLimitColor = '#ff0000';
    const nominalRangeColor = '#7f02b0';
    const limitConfig = this.characteristic.limitConfig;
    this.centralLineLabel = this.characteristic.sampleType === CharacteristicSamplesType.SingleSample
      ? this.componentsTranslation.meanChart_plotLineTextCentralLineLabelGrandMean
      : this.componentsTranslation.meanChart_plotLineTextCentralLineLabelMean;

    this.plotLineMap.clear();

    let plotLine = this.chartService.createPlotLine(
      controlLimitsColor,
      LimitType.UCL,
      this.recalcValues.ucLx,
      this.componentsTranslation.meanChart_UCLLabel);

    this.plotLineMap.set(LimitType.UCL, [plotLine]);

    plotLine = this.chartService.createPlotLine(
      controlLimitsColor,
      LimitType.LCL,
      this.recalcValues.lcLx,
      this.componentsTranslation.meanChart_LCLLabel);

    this.plotLineMap.set(LimitType.LCL, [plotLine]);

    plotLine = this.chartService.createPlotLine(
      centralLine,
      LimitType.CentralLine,
      this.recalcValues.grandMean,
      this.centralLineLabel);

    this.plotLineMap.set(LimitType.CentralLine, [plotLine]);

    if (this.characteristic.specLimitType === SpecLimitType.TwoSided ||
      this.characteristic.limitConfig.sslLimit === SslLimitType.max) {
      plotLine = this.chartService.createPlotLine(
        twoSidedSpecLimitColor,
        LimitType.USL,
        limitConfig.uslValue,
        this.componentsTranslation.meanChart_USLLabel);

      this.plotLineMap.set(LimitType.USL, [plotLine]);
    }
    if (this.characteristic.specLimitType === SpecLimitType.TwoSided ||
      this.characteristic.limitConfig.sslLimit === SslLimitType.min) {
      plotLine = this.chartService.createPlotLine(
        twoSidedSpecLimitColor,
        LimitType.LSL,
        limitConfig.lslValue,
        this.componentsTranslation.meanChart_LSLLabel);

      this.plotLineMap.set(LimitType.LSL, [plotLine]);
    }
    if (this.characteristic.nominalEnabled) {
      plotLine = this.chartService.createPlotLine(
        nominalRangeColor,
        LimitType.RangeMinus,
        limitConfig.nominalLowerOffset,
        this.componentsTranslation.meanChart_plotLineTextRangeMinusLabel);

      this.plotLineMap.set(LimitType.RangeMinus, [plotLine]);

      const plotLine2 = this.chartService.createPlotLine(
        nominalRangeColor,
        LimitType.RangePlus,
        limitConfig.nominalUpperOffset,
        this.componentsTranslation.meanChart_plotLineTextRangePlusLabel);

      this.plotLineMap.set(LimitType.NominalRange, [plotLine, plotLine2]);
    }
  }

  private generateLimitSwitchOptions(): void {
    const nominalRangeAvailable = this.characteristic.nominalEnabled;
    const limitConfig = this.characteristic.limitConfig;
    const LSLAvailable = this.characteristic.specLimitType === SpecLimitType.TwoSided || limitConfig.sslLimit === SslLimitType.min;
    const USLAvailable = this.characteristic.specLimitType === SpecLimitType.TwoSided || limitConfig.sslLimit === SslLimitType.max;

    let showOptions = [
      {
        limitType: LimitType.UCL,
        available: true,
        showLimit: limitConfig.uclLocationDraw,
        limitValue: 0,
        label: this.componentsTranslation.meanChart_UCLLabel
      },
      {
        limitType: LimitType.USL,
        available: USLAvailable,
        showLimit: limitConfig.uslDraw,
        limitValue: 0,
        label: this.componentsTranslation.meanChart_USLLabel
      },
      {
        limitType: LimitType.CentralLine,
        available: true,
        showLimit: limitConfig.centralLineLocationDraw,
        limitValue: 0,
        label: this.componentsTranslation.meanChart_meanLabel
      },
      {
        limitType: LimitType.LSL,
        available: LSLAvailable,
        showLimit: limitConfig.lslDraw,
        limitValue: 0,
        label: this.componentsTranslation.meanChart_LSLLabel
      },
      {
        limitType: LimitType.LCL,
        available: true,
        showLimit: limitConfig.lclLocationDraw,
        limitValue: 0,
        label: this.componentsTranslation.meanChart_LCLLabel
      },
      {
        limitType: LimitType.NominalRange,
        available: nominalRangeAvailable,
        showLimit: true,
        limitValue: 0,
        label: this.componentsTranslation.meanChart_nominalRange
      },
      {
        limitType: LimitType.TrendLine,
        available: true,
        showLimit: this.characteristic.limitConfig.trendLineLocationDraw,
        limitValue: 0,
        label: this.componentsTranslation.variableChart_trendLine
      }
    ];

    showOptions
      .filter(option => option.available === true && option.limitType !== LimitType.TrendLine)
      .forEach(option => option.limitValue = this.plotLineMap.get(option.limitType)[0].value);

    const options = JSON.parse(localStorage.getItem(this.chartOptionsLocalStorageKey));

    if (options) {
      showOptions = options;
    }

    this.chartOptions.showOptions = showOptions;

    let yAxisMin: number;
    let yAxisMax: number;

    if (this.characteristic.specLimitType === SpecLimitType.TwoSided) {
      yAxisMin = this.recalcValues.lsl;
      yAxisMax = this.recalcValues.usl;

    } else {
      if (this.characteristic.oneSidedChartBorderType === OneSidedChartBorderType.LimitConfig) {
        yAxisMin = LSLAvailable
          ? this.recalcValues.lsl
          : this.recalcValues.lcLx;
        yAxisMax = USLAvailable ? this.recalcValues.usl : this.recalcValues.ucLx;
      } else {
        const sampleValues = this.recalcValues.sampleCollections.map(sampleCollection => sampleCollection.mean);
        yAxisMin = LSLAvailable
          ? this.recalcValues.lsl
          : min(sampleValues);
        yAxisMax = USLAvailable
          ? this.recalcValues.usl
          : max(sampleValues);
      }
    }

    if (this.recalcValues.sampleCollections != null && this.recalcValues.sampleCollections.length > 0 && (yAxisMin > yAxisMax)) {
      throw new Error('yAxisMin cannot be bigger or equal to yAxisMax');
    }

    this.chartOptions.yAxisMinValue = yAxisMin;
    this.chartOptions.yAxisMaxValue = yAxisMax;
  }

  protected generateSamples(): ChartData {
    return this.useCmmData
      ? this.generateCmmSamples()
      : this.generateSpcSamples();
  }

  private generateCmmSamples(): ChartData {
    const labels: string[] = [];
    const data: SamplePoint[] = [];

    this.cmmData.forEach((element, index) => {
      const formattedDate = this.chartService.transformDate(element.timestamp);
      const meanSampleData: MeanSamplePoint = {
        x: index,
        y: element.valueAvg,
        color: element.passFail ? '#000000' : '#ff5252',
        rules: [],
        dateCreated: formattedDate,
        LCL: null,
        UCL: null,
        partNumber: element.partNumber,
        XBAR: null
      };

      labels.push(formattedDate);
      data.push(meanSampleData);
    });

    this.chartOptions.yAxisMinValue = this.cmmData[0].lowerTolerance;
    this.chartOptions.yAxisMaxValue = this.cmmData[0].upperTolerance;

    return { points: data, labels: labels };
  }

  private generateSpcSamples(): ChartData {
    const labels: string[] = [];
    const data: SamplePoint[] = [];
    this.sampleCollections.forEach((element, index) => {
      const formattedDate = this.chartService.transformDate(element.dateCreated);
      const appliedRules = this.getAppliedRules(element);
      const meanSampleData: MeanSamplePoint = {
        x: index,
        y: element.mean,
        color: MeanChartComponent.getMeanSampleColor(appliedRules, element.ruleBreakOverride),
        rules: appliedRules,
        dateCreated: formattedDate,
        LCL: element.meanLCL,
        UCL: element.meanUCL,
        partNumber: element.partNumber,
        XBAR: element.meanCenterLine,
        startingUCL: element.meanStartingUCL,
        startingLCL: element.meanStartingLCL
      };

      labels.push(formattedDate);
      data.push(meanSampleData);
    });

    return { points: data, labels: labels };
  }

  protected generateSeries(): Array<SeriesLinearRegressionOptions> {
    if (this.sampleCollections.length < 1) {
      return [];
    }

    const chartData = this.generateSamples();
    this.xAxisLabelFormatter = point => this.useXAxisLabels ? chartData.labels[point] : '';

    return [
      {
        type: 'line',
        name: this.sampleName,
        regression: this.chartOptions.showOptions.find(option => option.limitType === LimitType.TrendLine).showLimit,
        regressionSettings: {
          name : 'Trend Line',
          type: 'linear',
          color: 'rgba(223, 183, 83, .9)',
          dashStyle: 'solid'
        },
        lineWidth: 2,
        color: this.seriesColor,
        data: chartData.points,
        showInLegend: !!chartData.points.length,
        marker: {
          enabled: true
        }
      }
    ];
  }

  private getAppliedRules(samples: CharacteristicSampleCollection): string[] {
    const appliedCharacteristicRules = Object.keys(samples)
      .filter(propertyName => !!propertyName
        .match('^rule{1}[0-9]+') && samples[propertyName] === true);

    return samples.rulesBroken
      .map(brokenRule => this.translationService.rules$.getValue().find(rule => rule.ruleId === brokenRule.ruleId).name) || [];
  }

  public changePlotLine(optionChecked: boolean, value: string): void {
    if (value === LimitType.TrendLine) {
      this.generateChart();
      localStorage.setItem(this.chartOptionsLocalStorageKey, JSON.stringify(this.chartOptions.showOptions));
    } else {
      super.changePlotLine(optionChecked, value);
    }
  }
}
