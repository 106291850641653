import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';


const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'auth/login',
        pathMatch: 'full'
      },
      {
        path: 'auth/login',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
          title: 'Dashboard',
          status: false
        }
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        data: {
          title: 'Reports',
          status: false
        }
      },
      {
        path: 'characteristic',
        loadChildren: () => import('./characteristic/characteristic.module').then(m => m.CharacteristicModule),
        data: {
          title: 'Characteristic',
          status: false
        }
      },
      {
        path: 'administration',
        loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
        data: {
          title: 'Administration',
          status: false
        }
      },
      {
        path: 'process-chart',
        loadChildren: () => import('./process-charts/process-chart.module').then(m => m.ProcessChartModule),
        data: {
          title: 'Process Chart',
          status: false
        }
      },
      {
        path: 'location-screen',
        loadChildren: () => import('./location-screens/location-screen.module').then(m => m.LocationScreenModule),
        data: {
          title: 'Location Screen',
          status: false
        }
      },
      {
        path: 'cmm-data',
        loadChildren: () => import('./CMM/cmm.module').then(m => m.CmmModule),
        data: {
          title: 'CMM',
          status: false
        }
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
