import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Characteristic, GaugeType, SpecLimitType} from '@models/characteristic';
import {User} from '@models/user';
import {CharacteristicSampleCollection} from '@models/characteristic-sample-collection';
import {ModalBasicComponent} from '@shared/modal-basic/modal-basic.component';
import {NgForm} from '@angular/forms';
import {ProcessChartRows, ProcessChartRowsCell} from '@models/process-chart-rows';
import swal from 'sweetalert2';
import {AttributeSampleValue, HelpersService, NotifierType} from '@services/helpers.service';
import {Sample} from '@models/sample';
import {GlobalVariable} from '@common/global';
import {CharacteristicsTranslation, ComponentsTranslation, GlobalTranslation} from '@models/translation';
import {TranslationService} from '@services/translation.service';
import {faKeyboard, faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';
import {SslLimitType} from '@models/limit-config';
import {AttributeSample} from '@shared/add-sample-modal/add-sample-modal.component';
import {VirtualKeyboardService} from '@shared/virtual-keyboard/virtual-keyboard.service';
import {Subscription} from 'rxjs';
import {UserService} from '@user/user.service';
import {AddSamplesApiService} from '@shared/add-samples-api.service';
import {CreationMode} from '@common/enums/creation-mode';
import {AddSampleSource} from '@shared/add-sample-modal/add-sample-source';


@Component({
  selector: 'app-add-sample-collection',
  templateUrl: './add-sample-collection.component.html',
  styleUrls: ['./add-sample-collection.component.scss'],
  providers: [HelpersService]
})
export class AddSampleCollectionComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();

  private loggedUser: User;

  public readonly iconDelete = faTimes;
  public readonly iconKeyboard = faKeyboard;
  public readonly iconPlus = faPlus;
  public readonly gaugeTypes = GaugeType;
  public readonly attributeSampleValues = AttributeSampleValue;

  public creationMode: CreationMode;
  public attributeSampleValue: number;
  public characteristicId: number;
  public characteristicSample: CharacteristicSampleCollection = new CharacteristicSampleCollection();
  public characteristicSampleToSend: CharacteristicSampleCollection = new CharacteristicSampleCollection();
  public characteristicTranslation: CharacteristicsTranslation;
  public selectedFixtureToAll: number;
  public componentsTranslation: ComponentsTranslation;
  public currentIndex = 0;
  public globalTranslation: GlobalTranslation;
  public maxSampleValue = GlobalVariable.MAX_SAMPLE_VALUE;
  public preventNonNumericalInput: (e: KeyboardEvent, value: string) => void = HelpersService.preventNonNumericalInput;
  public sampleCollections: CharacteristicSampleCollection[];
  public title = '';

  @Input() notUsed: boolean;
  @Input() fromCharacteristicView: boolean;
  @Input() addSampleSource: AddSampleSource;
  @Input() withoutValues: boolean;
  @Input() sampleCollectionsToChange: CharacteristicSampleCollection;
  @Input() initialSampleCollection: CharacteristicSampleCollection;
  @Input() processChartInputId: number;
  @Input() replaceNR: boolean;
  @Input() row: ProcessChartRows;
  @Input() characteristic: Characteristic;
  @Input() addSampleModal: boolean;

  @Output() loaderProcessChart = new EventEmitter<boolean>();
  @Output() refreshCharacteristic = new EventEmitter<any>();
  @Output() refreshProcessChart = new EventEmitter<any>();
  @Output() samples = new EventEmitter<CharacteristicSampleCollection>();
  @Output() setAttributeSample = new EventEmitter<AttributeSample>();
  @Output() setClose = new EventEmitter();

  @ViewChild('formUnscheduled') form: NgForm;
  @ViewChild('modal') modal: ModalBasicComponent;

  constructor(
    public addSamplesApiService: AddSamplesApiService,
    public virtualKeyboardService: VirtualKeyboardService,
    private helpersService: HelpersService,
    private translationService: TranslationService,
  ) {
    this.setTranslation();
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.characteristicTranslation = translation.characteristics;
      this.componentsTranslation = translation.components;
      this.globalTranslation = translation.global;
    });

    this.subscriptions.add(translationSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnInit(): void {
    this.title = this.creationMode === CreationMode.Edit && !this.replaceNR
      ? this.componentsTranslation.addSampleCollection_titleEditSamples
      : this.componentsTranslation.addSampleCollection_titleAddSamples;

    this.loggedUser = UserService.getLoggedUser();
  }

  @Input() set openModal(openModal: boolean) {
    if (openModal) {
      this.modal.show();
      this.creationMode = this.initialSampleCollection
        ? CreationMode.Edit
        : CreationMode.New;

      this.loadData(this.characteristic.id);
      this.characteristicId = this.characteristic.id;
      this.setSamplesValue();
      this.characteristicSample = new CharacteristicSampleCollection(this.characteristic.chartSamples);
      this.characteristicSampleToSend = new CharacteristicSampleCollection(this.characteristic.chartSamples);

      if (!this.fromCharacteristicView) {
        if (this.sampleCollectionsToChange && this.characteristicSample) {
          this.characteristicSample.collectionDesc = this.sampleCollectionsToChange.collectionDesc;
        }
        if (!this.attributeSampleValue) {
          this.attributeSampleValue = AttributeSampleValue.NR;
        }
      }
    }
  }

  private loadData(id: number): void {
    this.addSamplesApiService.getCharacteristicSamples(id)
      .then(sampleCollections => {
        this.sampleCollections = sampleCollections;
        this.sampleCollections.reverse().forEach((data, index) => {
          return data.no = index + 1;
        });
      });
  }

  private setSamplesValue(): void {
    if (this.creationMode === CreationMode.Edit) {
      let collectionId = 0;

      if (this.fromCharacteristicView) {
        collectionId = this.initialSampleCollection.id;
      }

      this.addSamplesApiService.getSamplesCollection(collectionId).then(collection => {
        if (collection) {
          if (this.characteristic.gageType === GaugeType.Attribute) {
            this.attributeSampleValue = collection.characteristicCollectionSamples[0].sampleValue;
          } else {
            collection.characteristicCollectionSamples.forEach((sample: Sample, index: number) => {
              const characteristicSample = this.characteristicSample.characteristicCollectionSamples[index];
              characteristicSample.sampleValue = sample.sampleValue;
              characteristicSample.fixtureId = sample.fixtureId;
            });
          }
          this.characteristicSample.collectionDesc = collection.collectionDesc;
        } else {
          this.characteristicSample.collectionDesc = null;
        }
      });
    }

    if (this.sampleCollectionsToChange) {
      if (this.sampleCollectionsToChange.characteristicCollectionSamples && !this.withoutValues) {
        if (typeof this.sampleCollectionsToChange.characteristicCollectionSamples === 'string') {
          this.attributeSampleValue = this.sampleCollectionsToChange.characteristicCollectionSamples;
          this.characteristicSample.collectionDesc = this.sampleCollectionsToChange.collectionDesc;
        } else {
          this.characteristicSample.characteristicCollectionSamples = [...this.sampleCollectionsToChange.characteristicCollectionSamples];
          this.characteristicSample.collectionDesc = this.sampleCollectionsToChange.collectionDesc;
        }
      }
    }
  }

  private isInvalid(isFormValid: boolean): boolean {
    let isWrongValues = false;
    if (this.attributeSampleValue === null && this.characteristic.gageType === GaugeType.Attribute || !isFormValid || !this.isValid()) {
      isWrongValues = true;
    }
    if (this.characteristic.gageType === GaugeType.Variable && this.validateSamplesValue()) {
      isWrongValues = true;
    }
    return isWrongValues;
  }

  private isValid(): boolean {
    let isValid = true;
    if (this.characteristic.gageType === GaugeType.Variable) {
      this.characteristicSample.characteristicCollectionSamples.forEach((sample: Sample, index: number) => {
        if (sample.sampleValue === null && index + 1 <= this.characteristic.chartSamples) {
          isValid = false;
        }
      });
    }
    return isValid;
  }

  private validateSamplesValue(): boolean {
    const length = this.characteristic.chartSamples;
    let isWrongValue = false;
    this.characteristicSample.characteristicCollectionSamples.forEach((sample: Sample, index: number) => {
      if (index < length) {
        if (sample.sampleValue > this.maxSampleValue || sample.sampleValue < -this.maxSampleValue) {
          isWrongValue = true;
        }
      }
    });
    return isWrongValue;
  }

  private addSampleInsteadNR(): void {
    this.clearSampleCollection();
    this.addSamplesApiService.addSampleCollectionInsteadNR(this.characteristic.id,
      this.characteristicSampleToSend,
      this.processChartInputId).then(() => {
      this.closeModal();
      this.refreshProcessChart.emit();
    });
  }

  private clearSampleCollection(): void {
    this.characteristicSampleToSend = Object.assign({}, this.characteristicSample);
    const sampleCollection: Sample[] = [];

    if (this.characteristic.gageType === GaugeType.Attribute) {
      sampleCollection.push({
        sampleValue: this.attributeSampleValue,
      });
    } else if (this.characteristic.gageType === GaugeType.Variable) {
      this.characteristicSample.characteristicCollectionSamples.forEach(sample => {
        if (sample.sampleValue || sample.sampleValue === 0) {
          sampleCollection.push({sampleValue: sample.sampleValue});
        }
      });
    }

    this.characteristicSampleToSend.characteristicCollectionSamples = sampleCollection;
    delete this.characteristicSampleToSend.no;
    delete this.characteristicSampleToSend.characteristicId;
    delete this.characteristicSampleToSend.numberOfSamples;
    delete this.characteristicSampleToSend.id;
  }

  public removeFixtureDataFromSampleData(sampleFixtureData) {
    this.characteristicSample.characteristicCollectionSamples[sampleFixtureData.sampleFixtureData.sampleIndex].fixtureId = null;
  }

  public onSubmitSamples(isFormValid: boolean): void {
    if (this.isInvalid(isFormValid)) {
      return;
    }
    if (this.fromCharacteristicView && this.creationMode === CreationMode.Edit && this.isValid()) {
      this.editFromCharacteristic();
      return;
    }
    if (this.characteristic.gageType === GaugeType.Attribute) {
      if (this.notUsed) {
        return;
      } else if (this.addSampleModal) {
        this.setAttributeSample.emit({
          characteristic: this.characteristic,
          value: this.attributeSampleValue,
          collectionDesc: this.characteristicSample.collectionDesc,
        });
        this.closeModal();
      }
    }
    if ((this.isValid() || this.replaceNR) && isFormValid) {
      if (this.replaceNR) {
        this.addSampleInsteadNR();
        return;
      }
      if (this.creationMode === CreationMode.Edit) {
        this.editSamplesValue();
      } else if (this.notUsed) {
        this.clearSampleCollection();
        this.addSamplesApiService.addSampleCollection(this.characteristic.id, this.characteristicSampleToSend)
          .then(() => {
            this.refreshCharacteristic.emit(false);
            this.helpersService.notificationTrigger(NotifierType.Success, this.globalTranslation.dataSaved);
            this.closeModal();
          });
      } else {
        const data: CharacteristicSampleCollection = {
          characteristicCollectionSamples: this.characteristicSample.characteristicCollectionSamples,
          characteristicId: this.characteristicId,
          numberOfSamples: this.characteristicSample.numberOfSamples,
          collectionDesc: this.characteristicSample.collectionDesc,
          isSampleCorrect:  true,
          calculationReset: this.characteristicSample.calculationReset
        };

        this.samples.emit(data);
        this.closeModal();
      }
    }
  }

  private editFromCharacteristic(): void {
    const afterEditAction = () => {
      this.refreshCharacteristic.emit(true);
    };

    this.editSamples(this.initialSampleCollection.id, afterEditAction);
  }

  public editSamplesValue(): void {
    let collectionId = null;
    this.row.cells.forEach((cell: ProcessChartRowsCell) => {
      if (this.characteristic.id === cell.entityId) {
        collectionId = cell.sampleCollectionId;
      }
    });

    const afterEditAction = () => {
      this.loaderProcessChart.emit(false);
      this.refreshProcessChart.emit();
    };

    this.editSamples(collectionId, afterEditAction);
  }

  private editSamples(sampleCollectionId: number, afterEditAction: () => void): void {
    const newSamplesCollection: Sample[] = [];
    if (this.characteristic.gageType === GaugeType.Attribute) {
      newSamplesCollection.push({
        sampleValue: this.attributeSampleValue
      });
    } else {
      this.characteristicSample.characteristicCollectionSamples.forEach((sample) => {
        if (sample.sampleValue !== null) {
          newSamplesCollection.push({
            sampleValue: sample.sampleValue,
            fixtureId: sample.fixtureId
          });
        }
      });
    }

    const sampleCollection: CharacteristicSampleCollection = {
      id: sampleCollectionId,
      collectionDesc: this.characteristicSample.collectionDesc,
      characteristicId: this.characteristic.id,
      reasonId: null,
      numberOfSamples: this.characteristicSample.numberOfSamples,
      characteristicCollectionSamples: newSamplesCollection,
      calculationReset: this.characteristicSample.calculationReset
    };
    this.loaderProcessChart.emit(false);
    this.addSamplesApiService.editCharacteristicSample(sampleCollection, this.characteristic.id, sampleCollectionId).then(() => {
      this.helpersService.notificationTrigger(NotifierType.Success, this.componentsTranslation.addSampleCollection_sampleEditedMsg);
      afterEditAction();
      this.closeModal();
    });
  }

  public closeModal(): void {
    this.characteristicSample = new CharacteristicSampleCollection();
    this.form.resetForm();
    this.modal.hide();
    this.setClose.emit();
  }

  public onBlur(event, sampleIndex: number, inputElement: HTMLElement): void {
    let value: number | string;
    if (typeof event === 'number') {
      value = event;
    } else {
      value = event.target.value;
    }

    if (this.virtualKeyboardService.showKeyboard === true && inputElement === this.virtualKeyboardService.lastInputElement) {
      return;
    }

    this.showStatementIfLimitsAreBroken(Number(value), sampleIndex);
  }

  public showStatementIfLimitsAreBroken(value: number, sampleIndex: number): void {
    if (this.isLimitBroken(value)) {
      swal(this.setSettingsInSwal())
        .then((e) => {
          if (!e.value) {
            this.characteristicSample.characteristicCollectionSamples[sampleIndex].sampleValue = null;
          }
        });
    }
  }

  private isLimitBroken(sampleValue: number): boolean {
    const min = this.characteristic.limitConfig.minLimit;
    const max = this.characteristic.limitConfig.maxLimit;

    switch (this.characteristic.specLimitType) {
      case SpecLimitType.OneSided:
        switch (this.characteristic.limitConfig.sslLimit) {
          case SslLimitType.min:
            return sampleValue < min;
          case SslLimitType.max:
            return sampleValue > max;
        }
        break;
      case SpecLimitType.TwoSided:
        return (sampleValue > max || sampleValue < min);
    }
  }

  private setSettingsInSwal(): any {
    const min = this.characteristic.limitConfig.minLimit;
    const max = this.characteristic.limitConfig.maxLimit;

    let text: string;
    switch (this.characteristic.specLimitType) {
      case SpecLimitType.TwoSided:
        text = `${this.componentsTranslation.addSampleCollection_limitBrokenMsgMinVal} ${min}.
               ${this.componentsTranslation.addSampleCollection_limitBrokenMsgMaxVal} ${max}.`;
        break;
      case SpecLimitType.OneSided:
        switch (this.characteristic.limitConfig.sslLimit) {
          case SslLimitType.min:
            text = `${this.componentsTranslation.addSampleCollection_limitBrokenMsgMinVal} ${min}.`;
            break;
          case SslLimitType.max:
            text = `${this.componentsTranslation.addSampleCollection_limitBrokenMsgMaxVal} ${max}.`;
            break;
        }
    }

    text += `\n${this.componentsTranslation.addSampleCollection_limitBrokenMsgWantToContinue}`;

    return HelpersService.createAlertOptions(
      this.componentsTranslation.addSampleCollection_limitBrokenMsgTitle,
      text,
      this.globalTranslation.confirmButtonText,
      'warning');
  }

  public selectFixtureToAll() {
    this.updateAllFixtures(this.selectedFixtureToAll);
  }

  public removeFixtureFromAll() {
    this.updateAllFixtures(null);
  }

  private updateAllFixtures(fixtureId: number): void {
    this.characteristicSample.characteristicCollectionSamples.forEach(sample => {
      sample.fixtureId = fixtureId;
    });
  }

  public combineFixtureDataWithSample(sampleIndex: number, fixtureIndex: number) {
    this.characteristicSample.characteristicCollectionSamples[sampleIndex].fixtureId = fixtureIndex;
  }

  public asSample(value: any): Sample {
    return value as Sample;
  }
}
