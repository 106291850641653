import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {UserRoutingModule} from '@user/user-routing-module';

import {ProfileComponent} from '@user/profile/profile.component';
import {UserPhotoComponent} from '@user/user-photo/user-photo.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    UserRoutingModule,
  ],
  exports: [
    UserPhotoComponent
  ],
  declarations: [
    ProfileComponent,
    UserPhotoComponent,
  ]
})
export class UserModule { }
