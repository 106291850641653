import { Injectable } from '@angular/core';
import {ApiService} from '@services/api.service';
import {Credentials, User} from '@app/common/models/user';
import {Auth} from '@models/auth';
import {MasterDataUser} from '@models/master-data-user';

@Injectable()
export class UserApiService {
  constructor(private apiService: ApiService) { }

  public getUser(): Promise<User> {
    const relativeUrl = `user/me`;

    return this.apiService.get(relativeUrl).then(user => new User(user));
  }

  public updateUserMe(user: User): Promise<User> {
    const relativeUrl = `user/me`;

    return this.apiService.put(relativeUrl, user);
  }

  public getMasterDataUserList(): Promise<MasterDataUser[]> {
    const relativeUrl = `Masterdata/UserList`;

    return this.apiService.get(relativeUrl);
  }

  public getUserToken(credentials: Credentials): Promise<Auth> {
    return this.apiService.getUserToken(credentials);
  }

  public logout(): void {
    this.apiService.logout();
  }
}
