<div class="d-flex align-items-center">
  <span *ngIf="label"
        class="mr-2">
    {{label}}
  </span>

  <ui-switch
    color="#4680ff"
    switchColor="#fff"
    size="medium"
    [checked]="value"
    [(ngModel)]="value"
    (ngModelChange)="valueChange.emit($event)">
  </ui-switch>
</div>
