<div class="row content">
  <div class="col-md-12">
    <div class="card card-background-lightgrey">
      <div class="open-close-btn"
           (click)="toggleProcessChart()">
        <div class="h-100 w-100"
             [@openClose]="processChartOpen ? 'open' : 'closed'"></div>
      </div>

      <div class="row">
        <div class="col-md-10 left-header">
          <div class="part-number-card">
            <div *ngFor="let PN of chosenPartNumbers"
                 class="card-header-content"
                 [ngStyle]="PN.value === partNumber && {'background': '#d1d1d1'}"
                 (click)="selectPartNumber(PN.value)">
              {{PN.label}}
            </div>
          </div>

          <div class="col-md-2 part-number-header"
               [ngClass]="{'not-allowed': !pnOptions.length}"
               [ngbTooltip]="!pnOptions.length ? globalTranslation.noPartNumber : ''">
            <select name="partNumber"
                    class="form-control card-background-lightgrey"
                    [disabled]="!pnOptions.length"
                    [(ngModel)]="viewType === processChartViewTypes.ProcessChart ? partNumber : locationScreenPartNumber"
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="addPartNumber($event)">
              <option *ngFor="let option of pnOptions"
                      [ngValue]='option.value'
                      [ngStyle]="option.chosen && {'background': '#a8a8a8'}">
                {{option.label}}
              </option>
            </select>

            <label *ngIf="!partNumber"
                   class="float-label label">
              {{componentsTranslation.processChart_partLabel}}
            </label>
          </div>

        </div>
        <div class="col-md-2 right-header card-header-content card-background-lightgrey">
          {{componentsTranslation.processChart_machine}}&nbsp;{{ sdNumber }}
        </div>
      </div>

      <div [@showContent]="processChartOpen ? 'openContent' : 'closeContent'"
           class="overflow-hidden">
        <div class="row mb-4">
          <div class="col-md-2">
            <div *ngIf="viewType === processChartViewTypes.LocationScreen && this.partNumber"
                 class="plus-button-wrapper">
              <button class="plus-button"
                      (click)="addSamplesModal()"
                      [ngStyle]="(!processChartRows || disableProcessChart) && {'cursor': 'not-allowed'}"
                      [disabled]="!processChartRows || disableProcessChart">
              </button>
            </div>
          </div>

          <div class="col-md-8">
            <div class="row card-content-header machine-text text-center">
              <h4 class="my-0 mx-auto">
                {{ machineText }}
              </h4>
            </div>

            <div class="row process-chart-name">
              <h5 class="chart-name">
                {{componentsTranslation.processChart_processChartNameLabel}} {{processChartName}}
              </h5>
            </div>

<!--            margin 20px seems unnecessary-->
            <div class="row info-process-chart">
              {{componentsTranslation.processChart_headerInformation}}
            </div>
          </div>
          <div class="col-md-2">
            <div class="process-chart-info-box">
              <p class="mt-3">
                {{componentsTranslation.processChart_gageInstruction}}
              </p>

              <div class="rows-quantity d-flex ml-auto mt-2 mr-2 p-1"
                   style="height: 25px;">
                <input type="number"
                       name="maxLimit"
                       class="form-control mr-2"
                       min="0"
                       step="1"
                       max="1000000"
                       [(ngModel)]="quantityRows"
                       (blur)="setQuantity()"
                       style="height: 25px; width: 60px;"/>

                <label class="float-label d-flex align-items-center"
                       style="height: 25px; line-height: 1.1;">
                  {{componentsTranslation.processChart_rowsQuantity}}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="partNumber"
             class="row data-tables">
          <div class="col-md-3 col-tables"
               [ngClass]="!leftSideFullWidth && 'left-table-collapse'">
            <table class="info-table">
              <tr class="header-row"
                  (dblclick)="changeLeftSide()"
                  style="vertical-align: bottom">
                <td class="collapse-column">
                  {{componentsTranslation.processChart_dateColumnName}}
                </td>

                <td class="collapse-column">
                  {{componentsTranslation.processChart_timeColumnName}}
                </td>

                <td class="non-collapse-column-inspected">
                  <p [ngClass]="!leftSideFullWidth && 'vertical-initials'"
                     style="font-size: 15px"
                     [ngStyle]="{'margin-bottom': leftSideFullWidth ? '0' : '5px' }">
                    {{componentsTranslation.processChart_inspColumnName}}
                  </p>
                </td>

                <td class="non-collapse-column-reason">
                  <p [ngClass]="!leftSideFullWidth && 'vertical-initials'"
                     style="font-size: 15px"
                     [ngStyle]="{'margin-bottom': leftSideFullWidth ? '0' : '5px' }">
                    {{componentsTranslation.processChart_reasonColumnName}}
                  </p>
                </td>
              </tr>

              <ng-container class="table-rows, body-row"
                            *ngFor="let row of processChartRows; let i = index">
                <tr *ngIf="i < quantityRows">
                  <td class="text-ellipsis collapse-column">
                    <div class="ellipsis-div">
                      {{ transformDate(row.dateCreated) | date: 'shortDate' }}
                    </div>
                  </td>

                  <td class="text-ellipsis collapse-column">
                    <div class="ellipsis-div">
                      {{ transformTime(row.dateCreated) | date: 'shortTime' }}
                    </div>
                  </td>

                  <td class="text-ellipsis">
                    <div class="ellipsis-div operator-initials-ellipsis"
                         [ngbTooltip]="row.operatorInitials"
                         placement="top">
                      {{ row.operatorInitials }}
                    </div>
                  </td>

                  <td class="text-ellipsis">
                    <div class="ellipsis-div operator-reason-ellipsis"
                         [ngbTooltip]="leftSideFullWidth ? frequencyOptions[row.reasonId]: ''"
                         placement="top">
                      <div *ngIf="leftSideFullWidth; else block"
                           class="ellipsis-div operator-reason-ellipsis">
                        {{ frequencyOptions[row.reasonId] }}
                      </div>

                      <ng-template #block>
                        <fa-icon
                          class="cursor-pointer p-0"
                          style="font-size: 16px;"
                          [icon]="infoIcon"
                          container="body"
                          ngbTooltip="{{ frequencyOptions[row.reasonId] }}"
                          placement="top">
                        </fa-icon>
                      </ng-template>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>

          <div class="col-tables d-flex w-100"
               style="overflow-y: hidden; overflow-x: auto;">
            <table class="main-table">
              <tr style="height: 40px">
                <td *ngFor="let characteristic of allCharacteristics"
                    [ngStyle]="setBackgroundAndCursor(characteristic, false, true, false)">
                  <div *ngIf="characteristic.severity"
                       class="severity text-ellipsis">
                    {{componentsTranslation.processChart_severityCell}}
                  </div>
                </td>
              </tr>

              <tr style="height: 40px">
                <td *ngFor="let characteristic of allCharacteristics">
                  <div *ngIf="characteristic.qclCode"
                       class="text-ellipsis">
                    {{characteristic.qclCode}}
                  </div>
                </td>
              </tr>

              <tr style="height: 40px">
                <td *ngFor="let characteristic of allCharacteristics"
                    class="text-ellipsis"
                    [ngStyle]="setBackgroundAndCursor(characteristic, false, false, true)">
                </td>
              </tr>

              <tr class="main-tr cursor-pointer">
                <td id="characteristic{{characteristic.id}}"
                    *ngFor="let characteristic of allCharacteristics;"
                    class="pc-column-{{characteristic.id}}"
                    [ngStyle]="setBackgroundAndCursor(characteristic, true, false, false)"
                    (click)="changeRouteToCharacteristic(characteristic)">
                  <div *ngIf="characteristic.calculationString"
                       class="vertical">
                    <p class="h-100 text-truncate"
                       [title]="characteristic.name">
                      <b>{{characteristic.name}}</b>
                    </p>

                    <br/>

                    <p class="h-100 text-truncate"
                       [title]="characteristic.calculationString">
                      <b>{{characteristic.calculationString}}</b>
                    </p>
                  </div>

                  <div *ngIf="!characteristic.calculationString"
                       class="vertical">
                    <p class="h-100 text-truncate"
                       title="{{characteristic.name}} - {{characteristic.qclCode}}"
                       [ngStyle]="!characteristic.enabled && {'color': disabledCharacteristicColor}">
                      <b>{{characteristic.name}} - {{characteristic.qclCode}}</b>
                    </p>

                    <br/>

                    <p class="h-100 text-truncate"
                       [title]="characteristic.requiredGage"
                       [ngStyle]="!characteristic.enabled && {'color': disabledCharacteristicColor}">
                      <b>{{characteristic.requiredGage}}</b>
                    </p>

                    <br/>

                    <p class="h-100 text-truncate"
                       [title]="characteristic.description"
                       [ngStyle]="!characteristic.enabled && {'color': disabledCharacteristicColor}">
                      <b>{{characteristic.description}}</b>
                    </p>
                  </div>
                </td>
              </tr>

              <ng-container *ngFor="let row of processChartRows; let i = index">
                <tr *ngIf="i < quantityRows">
                  <td *ngFor="let characteristic of allCharacteristics; let x = index"
                      class="text-ellipsis"
                      (contextmenu)="addReactionPlan(row, characteristic)"
                      [ngStyle]="setStyleCell(row, characteristic)"
                      (click)="editSampleCollection(characteristic, row, attributeValue[i+x])">
                    <ng-container *ngIf="characteristic.gageType === gaugeTypes.Variable">
                      {{setCellValue(row, characteristic)}}
                    </ng-container>

                    <ng-container *ngIf="characteristic.gageType === gaugeTypes.Attribute">
                      <div appSetAttributeCell
                           [value]="attributeValue[i+x]">
                      </div>
                    </ng-container>
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>

          <div class="col-md-3 col-tables"
               [ngClass]="!rightSideFullWidth && 'right-table-collapse'"
               (dblclick)="changeRightSide()">
            <table class="info-remarks-table">
              <tr class="right-table-header">
                <td class="reason-td">
                  <p [ngClass]="!rightSideFullWidth && 'vertical-initials'"
                     style="font-size: 15px"
                     [ngStyle]="{'margin-bottom': rightSideFullWidth ? '0' : '5px' }">
                    {{componentsTranslation.processChart_remarksColumnName}}
                  </p>
                </td>

                <td class="initials-td">
                  <p class="vertical-initials">
                    {{componentsTranslation.processChart_supervisorInitialsColumnName}}
                  </p>
                </td>
              </tr>

              <ng-container *ngFor="let row of processChartRows; let i = index">
                <tr class="table-rows"
                    *ngIf="i < quantityRows">
                  <td class="text-ellipsis">
                    <div class="ellipsis-div operator-reason-ellipsis"
                         [ngbTooltip]="rightSideFullWidth ? row.remarks : ''"
                         placement="top">
                      <div *ngIf="rightSideFullWidth; else iconReason"
                           class="ellipsis-div operator-reason-ellipsis">
                        {{ row.remarks }}
                      </div>

                      <ng-template #iconReason>
                        <fa-icon
                          class="cursor-pointer"
                          style="font-size: 16px;"
                          [icon]="infoIcon"
                          container="body"
                          ngbTooltip="{{ row.remarks }}"
                          placement="top">
                        </fa-icon>
                      </ng-template>
                    </div>
                  </td>

                  <td class="text-ellipsis">
                    <div>
                      {{row.supervisorInitials}}
                    </div>
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>

        <div *ngIf="viewType === processChartViewTypes.LocationScreen && !partNumber"
             class="select-pn-warning">
          {{componentsTranslation.processChart_selectPartNumberWarning}}
        </div>
<!--        <div class="footer-process-chart" *ngIf="locationScreenView && partNumber && !addNewSamplesPermission">-->
<!--        -->
<!--          &lt;!&ndash;<div class="samples-warning-message">&ndash;&gt;-->
<!--            &lt;!&ndash;mesege&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="samples-buttons-wrapper">&ndash;&gt;-->
<!--            &lt;!&ndash;<button class="btn btn-secondary ripple">Cancel</button>&ndash;&gt;-->
<!--            &lt;!&ndash;<button class="btn btn-primary ripple light">Complete</button>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--        </div>-->
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="isPartNumberChosen">
  <ng-container *ngIf="openAddSampleModal">
    <app-add-sample-modal
      [partNumber]='viewType === processChartViewTypes.ProcessChart ? partNumber : locationScreenPartNumber'
      [processChart]='processChart'
      (setClose)="closeAddSampleModal()"
      (refreshData)="refreshData()"
    >
    </app-add-sample-modal>
  </ng-container>

<!--  <ng-container *ngIf="openModalAddSampleCollection">-->
<!--    <app-add-sample-collection-->
<!--      [initialSampleCollection]="selectedSampleCollection"-->
<!--      [characteristic]="characteristic"-->
<!--      [fromCharacteristicView]="false"-->
<!--      [notUsed]="true"-->
<!--      [openModal]="openModalAddSampleCollection"-->
<!--      (setClose)="closeAddSampleCollectionModal()"-->
<!--    >-->
<!--    </app-add-sample-collection>-->
<!--  </ng-container>-->
</ng-container>

<ng-container *ngIf="characteristic">
  <app-reaction-plan
    [characteristic]="characteristic"
    (refresh)="reload()"
  >
  </app-reaction-plan>
</ng-container>
