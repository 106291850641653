import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Ng2TreeSettings, NodeEvent, Tree, TreeModel, TreeComponent as Ng2TreeComponent } from 'ng2-tree';
import {faCog, faCogs, faFolder, IconDefinition} from '@fortawesome/free-solid-svg-icons';
import { GlobalVariable } from '@common/global';
import { LocationNode } from '@models/location-node';
import { Router } from '@angular/router';
import {Subscription} from 'rxjs';
import {forEach} from 'lodash-es';

interface LocationTreeModel extends TreeModel {
  icon?: IconDefinition;
  parent?: LocationTreeModel;
  type?: string;
  isSelectable?: boolean;
}

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TreeComponent implements OnDestroy, OnChanges, AfterContentInit {
  private readonly subscriptions = new Subscription();
  private nodeId = 1;

  public readonly treeCellTypes = GlobalVariable.TREE_CELL_TYPES;
  public readonly folderIcon = faFolder;
  public readonly cogIcon = faCog;
  public readonly machineIcon = faCogs;
  public readonly settings: Ng2TreeSettings = {
    rootIsVisible: false,
    showCheckboxes: false,
  };
  public readonly treeModalSettings = {
    settings: {
      'static': true,
      'isCollapsedOnInit': true,
      'keepNodesInDOM': false,
    }
  };

  public tree: TreeModel;
  public isPathOpen: boolean;

  @Input() public initialLocationId: string;
  @Input() public dataTree: TreeModel;
  @Input() public pickLocation: boolean;
  @Input() public pickCharacteristic: boolean;
  @Input() public pickProcessChart: boolean;
  @Input() public pickLocationScreen: boolean;
  @Input() public pickLocationForUser: boolean;
  @Input() public pickLocationForLocationScreen: boolean;

  @Output() public locationPath = new EventEmitter<string>();
  @Output() public locationId = new EventEmitter<string>();
  @Output() public sdNumber = new EventEmitter<string>();
  @Output() public departmentId = new EventEmitter<string>();
  @Output() public plantId = new EventEmitter<string>();
  @Output() public nodeSelected = new EventEmitter<NodeEvent>();

  @ViewChild('treeComponent') treeComponent: Ng2TreeComponent;

  constructor(
    private router: Router
  ) { }

  static getLocation(locationTree: Tree): string {
    let locationPath = locationTree.value;

    // skip the last node "Countries"
    while (locationTree.parent.node.type !== GlobalVariable.TREE_CELL_TYPES.country) {
      locationTree = locationTree.parent;
      locationPath = `${locationTree.value} | ${locationPath}`;
    }

    return locationPath;
  }

  static getSdNumber(locationTree: Tree): string {
    return this.getSDNumberByType(locationTree, GlobalVariable.TREE_CELL_TYPES.machine);
    // return this.getNodeIdByType(locationTree, GlobalVariable.TREE_CELL_TYPES.machine);
  }

  static getDepartmentId(locationTree: Tree): string {
    return this.getNodeIdByType(locationTree, GlobalVariable.TREE_CELL_TYPES.department);
  }

  static getPlantId(locationTree: Tree): string {
    return this.getNodeIdByType(locationTree, GlobalVariable.TREE_CELL_TYPES.plant);
  }

  static getNodeIdByType(locationTree: Tree, nodeType: string): string {
    let nodeId: string;

    while (locationTree.parent.parent !== null) {
      if (locationTree.node.type === nodeType) {
        nodeId = locationTree.node.uid;
      }
      locationTree = locationTree.parent;
    }
    return nodeId;
  }

  static getSDNumberByType(locationTree: Tree, nodeType: string): string {
    let nodeId: string;

    while (locationTree.parent.parent !== null) {
      if (locationTree.node.type === nodeType) {
        nodeId = locationTree.node.sdNumber;
      }
      locationTree = locationTree.parent;
    }
    return nodeId;
  }

  static getTreeNodeByUid(locationTree: Tree, locationId: string): Tree {
    if (locationTree.toTreeModel().uid === locationId) {
      return locationTree;
    } else {
      if (locationTree.children) {
        for (const node of locationTree.children) {
          const tree = TreeComponent.getTreeNodeByUid(node, locationId);

          if (tree) {
            return tree;
          }
        }
      }
    }
  }

  static getSelectedNodeIds(locationTree: Tree): string[] {
    const selectedNodeIds = [];

    while (locationTree.parent !== null) {
      selectedNodeIds.push(locationTree.id);
      locationTree = locationTree.parent;
    }
    return selectedNodeIds.reverse();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterContentInit(): void {
    this.openPathInTree();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataTree && this.dataTree) {
      this.convertToTreeModel();
    }
  }

  private openPathInTree(): void {
    if (this.initialLocationId) {
      setTimeout(() => {
        const selectedTreeNode = TreeComponent.getTreeNodeByUid(this.treeComponent.tree, this.initialLocationId);
        const nodeIds = TreeComponent.getSelectedNodeIds(selectedTreeNode);

        nodeIds.forEach(id => {
          setTimeout(() => {
            const treeController = this.treeComponent.getControllerByNodeId(id);
            if (treeController) {
              treeController.expand();
            }
          }, 5);
        });
      }, 100);
      this.isPathOpen = true;
    }
  }

  private convertToTreeModel(): void {
    this.tree = { value: 'Countries', id: 0, type: GlobalVariable.TREE_CELL_TYPES.country, children: this.setDataTree(this.dataTree, null)};
  }

  private setDataTree(data: TreeModel, parent: TreeModel): LocationTreeModel[] {
    this.nodeId++;
    const children = <LocationTreeModel[]>[];
    let isSelectable = false;
    let text = '';
    let parentTree: LocationTreeModel;
    let subTree = <LocationTreeModel[]>[];

    data.forEach(element => {
      this.nodeId++;

      text = element.text;

      if (element.type === GlobalVariable.TREE_CELL_TYPES.machine) {
        text += ` (${element.sdNumber})`;
      }

      parentTree = <LocationTreeModel>{
        ...element,
        id: element.uid,
      };
      subTree = element.children
        ? this.setDataTree(element.children, parentTree)
        : null;
      isSelectable = this.isSelectableNode(element);

      element = <LocationTreeModel>{
        ...element,
        value: text,
        id: element.uid,
        icon: this.getIcon(element.type),
        parent: parent || null,
        ...this.treeModalSettings,
        children: subTree,
        isSelectable: isSelectable
      };

      if (!element.children || element.children.length === 0) {
        // tslint:disable-next-line
        const noChildren = ({children, ...rest}) => rest;
        element = noChildren(element);
      }
      children.push(element);
    });

    return children;
  }

  private getIcon(treeCellType: string): IconDefinition {
    switch (treeCellType) {
      case this.treeCellTypes.site:
      case this.treeCellTypes.plant:
      case this.treeCellTypes.department:
      case this.treeCellTypes.subGroup:
      case this.treeCellTypes.cell:
        return this.folderIcon;
      case this.treeCellTypes.line:
        return this.machineIcon;
      case this.treeCellTypes.machine:
        return this.cogIcon;
      default:
        return null;
    }
  }

  private isSelectableNode(locationNode: LocationNode): boolean {
    let isNodeSelectable: boolean;
    if (this.pickCharacteristic) {
      isNodeSelectable = locationNode.type === this.treeCellTypes.characteristic;
    } else if (this.pickProcessChart) {
      isNodeSelectable = locationNode.type === this.treeCellTypes.processChart;
    } else if (this.pickLocationScreen) {
      isNodeSelectable = locationNode.type === this.treeCellTypes.locationScreen;
    } else if (this.pickLocationForUser) {
      isNodeSelectable = locationNode.type === this.treeCellTypes.department;
    } else {
      isNodeSelectable = this.canAddCharacteristic(locationNode);
    }

    return isNodeSelectable;
  }

  private canAddCharacteristic(locationNode: LocationNode): boolean {
    let canAdd = false;

    if (locationNode.type === this.treeCellTypes.machine) {
      canAdd = true;
    } else if (locationNode.type === this.treeCellTypes.line) {
      canAdd = true;

      if (locationNode.children) {
        canAdd = locationNode.children.every((child: LocationNode) => child.type === this.treeCellTypes.characteristic);
      }
    }

    return canAdd;
  }

  public onNodeSelected(event: NodeEvent): void {
    if ((event.node.node as LocationTreeModel).isSelectable) {
      this.nodeSelected.emit(event);
      this.locationPath.emit(TreeComponent.getLocation(event.node));
      this.sdNumber.emit(TreeComponent.getSdNumber(event.node));
      this.sdNumber.emit(TreeComponent.getSdNumber(event.node));
      this.departmentId.emit(TreeComponent.getDepartmentId(event.node));
      this.plantId.emit(TreeComponent.getPlantId(event.node));
      this.locationId.emit(String(event.node.node.uid));

      let url: string;

      if (this.pickCharacteristic) {
        url = 'characteristic/view';
      } else if (this.pickProcessChart) {
        url = 'process-chart/view';
      } else if (this.pickLocationScreen) {
        url = 'location-screen/view';
      }

      if (url) {
        url += `/${event.node.node.uid}/`;
        this.navigateToUrl(url);
      }
      this.isPathOpen = false;
    }
  }

  public parseLocationTreeModel(element: any): LocationTreeModel {
    return element as LocationTreeModel;
  }

  private navigateToUrl(url: string): void {
    this.router.navigate([url]).then();
  }
}
