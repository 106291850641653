<div class="float-left d-flex align-items-center my-3">
  <label class="label-control d-flex align-items-center">
    {{globalTranslation.show}}

    <ng-select
      labelForId="pageOptions"
      class="ng-select input-sm page-size-element"
      [clearable]="false"
      [searchable]="false"
      [items]="pageOptions"
      [bindLabel]="'label'"
      [bindValue]="'value'"
      [ngModel]="itemsPerPage"
      (ngModelChange)="onItemsPerPageChange($event)">
    </ng-select>

    {{globalTranslation.entries}}
  </label>
</div>
