import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {VirtualKeyboardService} from '@shared/virtual-keyboard/virtual-keyboard.service';
import {faKeyboard, faTimes} from '@fortawesome/free-solid-svg-icons';

declare type type =
  'textarea' |
  'text' |
  'button' |
  'checkbox' |
  'color' |
  'date' |
  'datetime-local' |
  'email' |
  'file' |
  'hidden' |
  'image' |
  'month' |
  'number' |
  'password' |
  'radio' |
  'range' |
  'reset' |
  'search' |
  'submit' |
  'tel' |
  'time' |
  'url' |
  'week';

@Component({
  selector: 'app-vk-input',
  templateUrl: './vk-input.component.html',
  styleUrls: ['./vk-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VkInputComponent),
      multi: true
    }
  ]
})
export class VkInputComponent implements ControlValueAccessor, OnDestroy {
  public readonly iconKeyboard = faKeyboard;
  public readonly iconClear = faTimes;
  public readonly keyboardIconClasses = {
    'text': 'input-keyboard',
    'password': 'input-keyboard',
    'number': 'icon-input-numeric-high',
    'textarea': 'text-area-keyboard',
  };

  private _value: any;
  public get value(): any {
    if (typeof this._value === 'number') {
      return this._value.toFixed(7).replace(/^0+(?!\.)|(?:\.|(\..*?))0+$/gm, '$1');
    } else {
      return this._value;
    }
  }

  public set value(val: any) {
    this._value = val;
    this.emitChanges();
  }

  public get inputElement(): HTMLElement {
    switch (this.type) {
      case 'text':
        return this.textInputVk.nativeElement;
      case 'password':
        return this.passwordInputVk.nativeElement;
      case 'number':
        return  this.numericInputVk.nativeElement;
      case 'textarea':
        return this.textAreaVk.nativeElement;
    }
  }

  @Input() type: type = 'text';
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() cols: string;
  @Input() rows: string;
  @Input() required: boolean;
  @Input() readonly: boolean;
  @Input() maxLength: string;
  @Input() disabled = false;
  @Input() label: string;
  @Input() placeholder = '';
  @Input() focusOnKeyboardClose = true;

  @Output() blur = new EventEmitter<FocusEvent>();

  @ViewChild('vkTextInput') textInputVk: ElementRef;
  @ViewChild('vkPasswordInput') passwordInputVk: ElementRef;
  @ViewChild('vkNumericInput') numericInputVk: ElementRef;
  @ViewChild('vkTextArea') textAreaVk: ElementRef;

  constructor(
    public ref: ElementRef,
    public virtualKeyboardService: VirtualKeyboardService
  ) {}

  public ngOnDestroy(): void {
    this.virtualKeyboardService.turnOffKeyboard();
  }

  public onChange = (_: any) => {};

  private emitChanges(): void {
    this.onChange(this.value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
  }

  public writeValue(val: any): void {
    this.value = val;
  }

  public turnOffKeyboard(): void {
    this.virtualKeyboardService.turnOffKeyboard();

    if (this.focusOnKeyboardClose) {
      setTimeout(() => {
        this.inputElement.focus();
      }, 1000);
    }
  }

  public clear(): void {
    this.value = null;
  }
}
