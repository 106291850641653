import {TranslationService} from '@services/translation.service';
import {HelpersService, MemorySizeUnit, NotifierType} from '@services/helpers.service';
import {Injectable} from '@angular/core';
import {AdministrationTranslation} from '@models/translation';
import {AvatarStatus, User} from '@models/user';
import {environment} from '@environment/environment';

@Injectable()
export class UserPhotoService {
  private readonly validProfilePhotoFormats = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/img'
  ];

  private administrationTranslation: AdministrationTranslation;
  private initialUserPhoto: string;
  private base64Image: string;

  public readonly photoUrlPrefix = environment.apiPrefix;

  constructor(
    private translationService: TranslationService,
    private helpersService: HelpersService
  ) {
    this.setTranslation();
  }

  private setTranslation(): void {
    this.translationService.translations$.subscribe(translation => {
      this.administrationTranslation = translation.administration;
    });
  }

  public initUserPhotoState(user: User): void {
    user.avatarStatus = AvatarStatus.Initial;
    this.initialUserPhoto = user.photo;
    this.base64Image = null;
  }

  public handlePhotoFile(files: FileList, user: User): void {
    if (this.isUserPhotoValid(files)) {
      this.setPreviewPhoto(files[0], user);
      this.encodePhoto(files[0]);
    }
  }

  private isUserPhotoValid(files: FileList): boolean {
    const file = files[0];

    if (!files || !file) {
      return false;
    }

    if (!this.validProfilePhotoFormats.includes(file.type)) {
      this.helpersService.notificationTrigger(NotifierType.Error, this.administrationTranslation.userFileFormatWarning);

      return false;
    }

    if (file.size > MemorySizeUnit.MB * 3) {
      this.helpersService.notificationTrigger(NotifierType.Error, this.administrationTranslation.userFileSizeWarning);

      return false;
    }

    return true;
  }

  private setPreviewPhoto(file: File, user: User): void {
    const reader = new FileReader();
    reader.onloadend = () => {
      user.photo = reader.result as string;
      user.avatarStatus = AvatarStatus.Modified;
    };
    reader.readAsDataURL(file);
  }

  private encodePhoto(file: File): void {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.base64Image = btoa(reader.result as string);
    };
    reader.readAsBinaryString(file);
  }

  public setUserPhoto(user: User): void {
    if (this.base64Image) {
        user.photo = this.base64Image;
    } else if (user.photo === this.initialUserPhoto) {
      user.avatarStatus = AvatarStatus.Initial;
    }
  }

  public removeUserPhoto(user: User): void {
    this.base64Image = null;
    user.photo = null;
    user.avatarStatus = AvatarStatus.Removed;
  }
}
