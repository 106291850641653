<div>
  <span>
    Test
  </span>

  <ng-select
    [id]="label"
    [labelForId]="label"
    [name]="label"
    [multiple]="true"
    [searchable]="false"
    [items]="selectOptions"
    [disabled]="disabled"
    [(ngModel)]="chosenSelectOptions"
    (ngModelChange)="onSelectChanged($event)"
    (clear)="onRulesClear()"
    [bindLabel]="label">
    <ng-template ng-label-tmp let-item="item">
      <span
        class="ng-value-icon left ng-star-inserted"
        (click)="removeSelectedRule(item, $event)"
      >
        ×
      </span>
      <span
        class="ng-value-label ng-star-inserted"
        [title]="item.value.name"
      >
        {{item.label}}
      </span>
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
      <div [title]="item.value.name">
        {{item.label}}
      </div>
    </ng-template>
  </ng-select>
</div>
