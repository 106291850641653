import {Component, Input, OnDestroy, Inject, ViewEncapsulation} from '@angular/core';
import {Spinkit} from '@shared/spinner/spinkits';
import {Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: [
    './spinner.component.scss',
    './spinkit-css/sk-line-material.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnDestroy {
  private readonly subscriptions = new Subscription();
  public isSpinnerVisible = true;
  public Spinkit = Spinkit;

  @Input() public backgroundColor = 'rgba(70, 128, 255, 1)';
  @Input() public spinner = Spinkit.skLine;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    const routerEventsSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isSpinnerVisible = true;
      } else if ( event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.isSpinnerVisible = false;
      }
    }, () => {
      this.isSpinnerVisible = false;
    });

    this.subscriptions.add(routerEventsSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.isSpinnerVisible = false;
  }
}
