import {CmmOption} from '@models/cmm-option';
import {LimitConfig} from '@models/limit-config';
import {BaseModel} from '@models/base-model';
import {PartNumber} from '@models/part-number';
import {CharacteristicSampleReason} from '@models/sample-reason';
import {FixtureData} from '@characteristic/create/fixture-select/fixture-select.component';
import {CharacteristicPartModel} from '@models/part-model';
import {Rule} from '@models/rule';

export enum CharacteristicType {
  SPC = 1,
  CMM = 2
}

export enum CharacteristicControlType {
  Controlled = 1,
  Uncontrolled = 2
}

export enum CharacteristicSamplesType {
  SingleSample = 1,
  MultiSamples = 2
}

export enum GaugeType {
  None = 0,
  Variable = 1,
  Attribute = 2
}

export enum SpecLimitType {
  None,
  OneSided = 1,
  TwoSided = 2
}

export enum OneSidedChartBorderType {
  None,
  LimitConfig = 1,
  MinMax = 2
}

export enum QclCode {
  None = 'none',
  CL1 = 'CL1',
  CL2 = 'CL2',
  CL3 = 'CL3',
  CL4 = 'CL4',
  CL5 = 'CL5'
}

interface ReasonExcluded {
  id?: number;
  characteristicId?: number;
  reasonId: number;
}

export class Characteristic extends BaseModel {
  public alerting = false;
  public calculationString?: string;
  public calculationType?: number;
  public sampleReasons: CharacteristicSampleReason[] = [];
  public defaultDisplayedSamplesAmount = 24;
  public minimumDisplayedSamplesAmount = 24;
  public description?: string;
  public limitConfig: LimitConfig = new LimitConfig();
  public characteristicType: CharacteristicType = CharacteristicType.SPC;
  public controlType: CharacteristicControlType = CharacteristicControlType.Controlled;
  public sampleType: CharacteristicSamplesType = CharacteristicSamplesType.MultiSamples;
  public chartSamples = 1;
  public decimals?: number;
  public fixtures: FixtureData[] = [];
  public gageType = GaugeType.None;
  public limitEnabled = false;
  public models: CharacteristicPartModel[] = [];
  public nominalEnabled = false;
  public partNumbers: PartNumber[] = [];
  public prompt: string;
  public qclCode: QclCode = QclCode.None;
  public qualitySignOff = false;
  public reasonsExcluded: ReasonExcluded[] = [];
  public requiredCapabilityCp?: number;
  public requiredCapabilityPp?: number;
  public requiredGage?: string;
  public rules: Rule[] = [];
  public rule1 = true;
  public rule2 = false;
  public rule3 = false;
  public rule4 = false;
  public rule5 = false;
  public rule6 = false;
  public rule7 = false;
  public rule8 = false;
  public rule9 = false;
  public rule10 = false;
  public rule11 = false;
  public rule12 = false;
  public rule13 = false;
  public ruleViolation?: boolean;
  public sampleCollectionAlert?: boolean;
  public sampleCollectionAlertTime?: string;
  public serialNumber = false;
  public severity?: boolean;
  public specLimitType: SpecLimitType = SpecLimitType.TwoSided;
  public oneSidedChartBorderType: OneSidedChartBorderType = OneSidedChartBorderType.LimitConfig;
  public supervisorSignOff = false;
  public triggerTime?: number;
  public unitId?: number;
  public isMultipart: boolean;
  public isMultimodel: boolean;
  public correctiveActionCount?: number;

  public cmmCharacteristicId?: number;
  public cmmStationId?: number;
  public cmmMeasurementPlanId?: number;
  public cmmSampleUpdateDate?: Date;
  public machineTY: string;
  public masterDataSDNumber: string;
  public machineNest: string;
  public partNumber: number;

  public cmmFeature: string;
  public cmmProgram: string;
  public cmmFeatureId: string;

  constructor(characteristic: Partial<Characteristic> = {}) {
    super();

    Object.assign(this, characteristic);
  }

  /*
  export class CharacteristicShort extends BaseModel {
  public name: string;
  public plant: string;
  //public Operation: string;
  public models?: string;
  public isMultimodel: boolean;
  public partNumbers: string;
  public isMultipart: boolean;
  public dateModified: date;
  public userFullName: string;
  public DateTime DateCreated { get; set; }
  public int CorrectiveActionCount { get; set; }
  public string Path { get; set; }
  public string Department { get; set; }
  public bool Favorite { get; set; }
  public bool Enabled { get; set; }
  }*/

  get cmmOption(): CmmOption {
    return {
      cmmStationId: this.cmmStationId,
      featureId: this.cmmCharacteristicId,
      measurementPlanId: this.cmmMeasurementPlanId,
    };
  }
  set cmmOption(cmmOption) {
    this.cmmStationId = cmmOption.cmmStationId;
    this.cmmMeasurementPlanId = cmmOption.measurementPlanId;
    this.cmmCharacteristicId = cmmOption.featureId;
  }
}
