import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChildrenItem, Menu, MenuItems} from '@shared/menu-items/menu-items';
import {Router} from '@angular/router';
import {User} from '@models/user';
import {TranslationService} from '@services/translation.service';
import {GlobalTranslation, LayoutTranslation} from '@models/translation';
import {faRuler} from '@fortawesome/free-solid-svg-icons';
import {UserPhotoService} from '@user/user-photo.service';
import {UserService} from '@user/user.service';
import {Subscription} from 'rxjs';
import {UserApiService} from '@user/user-api.service';
import {ApiService} from '@services/api.service';
import {ApplicationInfo} from '@models/application-info';
import {ExpandState, toggleExpandAnimation} from '@common/animations';
import {FirstUserSignInComponent} from '@layout/admin/first-user-sign-in/first-user-sign-in.component';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: [
    toggleExpandAnimation
  ]
})
export class AdminComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();

  public readonly iconRuler = faRuler;
  public readonly navType = 'st2';
  public readonly themeLayout = 'vertical';
  public readonly verticalPlacement = 'left';
  public readonly verticalLayout = 'wide';
  public readonly pcodedHeaderPosition = 'fixed';
  public readonly pcodedSidebarPosition = 'fixed';
  public readonly navBarTheme = 'themelight1';
  public readonly activeItemTheme = 'theme1';
  public readonly menuTitleTheme = 'theme1';
  public readonly dropDownIcon = 'style1';
  public readonly subItemIcon = 'style1';

  public animateSidebar: 'pcoded-toggle-animate' | '' = '';

  public get verticalEffect(): 'overlay' | 'shrink' {
    return this.isMobileView
      ? 'overlay'
      : 'shrink';
  }

  public get headerFixedTop(): '50%' | 'auto' {
    return this.isMobileView
      ? '50%'
      : 'auto';
  }

  public windowWidth: number;

  public isNavigationNavbarVisible = true;

  public topNavbarExpandState = ExpandState.Collapsed;
  public sideNavbarUserOptionsExpandState = ExpandState.Collapsed;
  public topBarUserOptionsExpandState = ExpandState.Collapsed;

  public get topBarUserOptionsExpandClass(): 'show' | '' {
    return this.topBarUserOptionsExpandState === ExpandState.Expanded
      ? 'show'
      : '';
  }

  public applicationInfo = new ApplicationInfo();
  public user: User;
  public layoutTranslation: LayoutTranslation;
  public globalTranslation: GlobalTranslation;
  public menuItemsData: Menu[];

  public get isMobileView(): boolean {
    return this.windowWidth <= 992;
  }

  public get deviceType(): 'tablet' | 'phone' | 'desktop' {
    if (this.isMobileView) {
      return this.windowWidth < 768
        ? 'phone'
        : 'tablet';
    } else {
      return 'desktop';
    }
  }

  public appInfoExpandState = ExpandState.Collapsed;

  public get topNavBarUserOptionsExpandIcon(): | 'icon-chevron-up' | 'icon-chevron-down' {
    return this.topBarUserOptionsExpandState === ExpandState.Expanded
      ? 'icon-chevron-up'
      : 'icon-chevron-down';
  }

  public get sideNavBarUserOptionsExpandIcon(): | 'icon-chevron-up' | 'icon-chevron-down' {
    return this.sideNavbarUserOptionsExpandState === ExpandState.Expanded
      ? 'icon-chevron-up'
      : 'icon-chevron-down';
  }

  public get appInfoExpandIcon(): | 'icon-chevron-up' | 'icon-chevron-down' {
    return this.appInfoExpandState === ExpandState.Expanded
      ? 'icon-chevron-up'
      : 'icon-chevron-down';
  }

  public get sidebarFixedHeight(): '100%' | 'calc(100vh - 50px)' {
    return this.isNavigationNavbarVisible
      ? 'calc(100vh - 50px)'
      : '100%';
  }

  public get navigationNavbarToggleIcon(): 'icon-toggle-left' | 'icon-toggle-right' {
    return this.isNavigationNavbarVisible
      ? 'icon-toggle-right'
      : 'icon-toggle-left';
  }

  public get verticalNavbarType(): 'offcanvas' | 'expanded' | 'collapsed' {
    return this.isNavigationNavbarVisible
      ? 'expanded'
      : 'offcanvas';
      // : this.isMobileView
      //   ? 'offcanvas'
      //   : 'collapsed';
  }

  @ViewChild(FirstUserSignInComponent) firstUserSignInComponent: FirstUserSignInComponent;

  constructor(
    private menuItems: MenuItems,
    private router: Router,
    private apiService: ApiService,
    private userApiService: UserApiService,
    private translationService: TranslationService,
    public userPhotoService: UserPhotoService
  ) {
    this.subscribeUserChange();
    this.setTranslation();
    this.setMenuItems();
    this.setMenuAttributes();
    this.setHeaderAttributes();
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.layoutTranslation = translation.layout;
      this.globalTranslation = translation.global;
    });

    this.subscriptions.add(translationSubscription);
  }

  private subscribeUserChange(): void {
    const userSubscription = UserService.user$.subscribe(user => {
      this.user = user;

      if (!this.user) {
        this.logout();
      }
    });

    this.subscriptions.add(userSubscription);
  }

  public ngOnInit(): void {
    this.getApplicationInfo();
    this.setBackgroundPattern('theme1');
    this.getLoggedUser();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getApplicationInfo(): void {
    this.apiService.getApplicationInfo().then(applicationInfo => this.applicationInfo = applicationInfo);
  }

  private getLoggedUser(): void {
    console.log('GET User Admin');
    this.userApiService.getUser().then(user => {
      if (user && user.forcePasswordChange) {
        this.firstUserSignInComponent.showModal(user);
      }
    });
  }

  public setMenuItems(): void {
    const menuItemsSubscription = this.menuItems.menuItems$.subscribe(menuItems => this.menuItemsData = menuItems);
    this.subscriptions.add(menuItemsSubscription);
  }

  public onResize(event): void {
    this.windowWidth = event.target.innerWidth;
    this.setHeaderAttributes();

    /* for check device */
    if (this.isMobileView) {
      this.setMenuAttributes();
    }
  }

  private setHeaderAttributes(): void {
    if (this.isMobileView) {
      this.topNavbarExpandState = ExpandState.Collapsed;
    } else {
      this.topNavbarExpandState = ExpandState.Expanded;
    }
  }

  private setMenuAttributes(): void {
    const setPhoneOrTabletMenuAttributes = () => {
      this.isNavigationNavbarVisible = false;
    };

    if (this.isMobileView) {
      setPhoneOrTabletMenuAttributes();
    } else {
      this.isNavigationNavbarVisible = true;
    }

    /*else if (windowWidth >= 1024 && windowWidth < 1366) {
      this.pcodedDeviceType = 'desktop';
      this.verticalNavType = 'collapsed';
      this.verticalEffect = 'shrink';
      this.toggleIcon = 'icon-toggle-left';
      this.perfectDisable = 'disabled';
      this.sidebarFixedHeight = '100%';
    }*/
  }

  public toggleHeaderNavRight(): void {
    this.topNavbarExpandState = this.topNavbarExpandState === ExpandState.Expanded
      ? ExpandState.Collapsed
      : ExpandState.Expanded;
  }

  public readonly topNavBarUserOptionExpandToggle = () => {
    this.topBarUserOptionsExpandState = this.topBarUserOptionsExpandState === ExpandState.Collapsed
      ? ExpandState.Expanded
      : ExpandState.Collapsed;
  }

  public onTopNavbarOutsideClick(): void {
    this.topBarUserOptionsExpandState = ExpandState.Collapsed;
    // if (this.topBarUserOptionsExpandState === ExpandState.Expanded) {
    //   this.topNavBarUserOptionExpandToggle();
    // }
  }

  public toggleTopNavBarOptions(): void {
    if (this.isMobileView) {
      this.topNavbarExpandState = ExpandState.Collapsed;
    }

    this.isNavigationNavbarVisible = !this.isNavigationNavbarVisible;
  }

  public onSideNavBarClickedOutside(): void {
    if (this.verticalEffect === 'overlay' || (this.isMobileView && this.verticalNavbarType !== 'offcanvas')) {
      this.isNavigationNavbarVisible = false;
    }
  }

  private setBackgroundPattern(pattern: string): void {
    document.querySelector('body').setAttribute('themebg-pattern', pattern);
    // this.menuTitleTheme = this.freamType = this.activeItemTheme = this.headerTheme = pattern;
  }

  public hoverOutsideSidebar(): void {
    if (this.verticalNavbarType === 'collapsed') {
      const pcodedTriggerElement = document.querySelectorAll('.pcoded-trigger');

      for (let i = 0; i < pcodedTriggerElement.length; i++) {
        pcodedTriggerElement[i].classList.remove('pcoded-trigger');
      }
    }
  }

  public fireClick(e): void {
    if (this.verticalNavbarType === 'collapsed') {
      const parentNode = e.target.parentNode.parentNode;

      if (parentNode.classList.contains('pcoded-trigger')) {
        const subEle = parentNode.querySelectorAll('.pcoded-hasmenu');

        for (let i = 0; i < subEle.length; i++) {
          if (subEle[i].classList.contains('pcoded-trigger')) {
            subEle[i].classList.remove('pcoded-trigger');
          }
        }
      } else {
        e.target.click();
      }
    }
  }

  public fireClickLeave(event): void {
    if (this.verticalNavbarType === 'collapsed') {
      const parentEle = event.target.parentNode.parentNode as HTMLElement;
      const subEle = parentEle.querySelectorAll('.pcoded-hasmenu');
      for (let i = 0; i < subEle.length; i++) {
        if (subEle[i].classList.contains('pcoded-trigger')) {
          subEle[i].classList.remove('pcoded-trigger');
        }
      }
    }
  }

  public logout(): void {
    this.userApiService.logout();
  }

  public menuSubItemHasPermission(permissions: string[]): boolean {
    let valid = false;
    if (permissions.length === 0) {
      return true;
    }

    permissions.forEach(permission => {
      if (this.user && this.user.apiPermissions.includes(permission)) {
        valid = true;
        return;
      }
    }, this);

    return valid;
  }

  public menuSubItemsHasVisibleByPermissions(subItems: ChildrenItem[]): boolean {
    let valid = false;
    subItems.forEach(item => {
      const validTemp = this.menuSubItemHasPermission(item.permissions);
      if (validTemp) {
        valid = validTemp;
        return;
      }
    }, this);

    return valid;
  }

  public readonly sideNavbarUserOptionToggle = () => {
    this.sideNavbarUserOptionsExpandState = this.sideNavbarUserOptionsExpandState === ExpandState.Expanded
      ? ExpandState.Collapsed
      : ExpandState.Expanded;
  }

  public toggleAppInfo(): void {
    switch (this.appInfoExpandState) {
      case ExpandState.Collapsed:
        this.appInfoExpandState = ExpandState.Expanded;
        break;
      case ExpandState.Expanded:
        this.appInfoExpandState = ExpandState.Collapsed;
        break;
    }
  }
}
