import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {TranslationService} from '@services/translation.service';
import {Subscription} from 'rxjs';
import {ComponentsTranslation} from '@models/translation';
import {LabeledValue} from '@common/global';

@Component({
  selector: 'app-filter-selector',
  templateUrl: './filter-selector.component.html',
  styleUrls: ['./filter-selector.component.scss']
})
export class FilterSelectorComponent implements OnDestroy {
  private readonly subscriptions = new Subscription();

  public componentsTranslation: ComponentsTranslation;

  public get selectedFilterLabel(): string {
    const selectedFilterOption = this.filterOptions.find(filterOption => filterOption.value === this.selectedFilters);

    return selectedFilterOption ? selectedFilterOption.label : null;
  }

  @Input() filterName = '';
  @Input() label = '';
  @Input() selectedFilters: string;
  @Input() filterOptions: LabeledValue<string>[] = [];

  @Output() selectedFiltersChange = new EventEmitter<string>();

  constructor(private translationService: TranslationService) {
    this.setTranslation();
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.componentsTranslation = translation.components;
    });

    this.subscriptions.add(translationSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onFilterChange(selectedFilters: string): void {
    this.selectedFiltersChange.emit(selectedFilters);
  }
}
