<app-loader
  [isLoading]="isLoading"
  [isBackgroundLoading]="isLoading">
</app-loader>

<div class="row">
  <div class="col-sm-12 small-padding-col">
    <app-filters
      [(availableFilterOptions)]="filterOptions"
      [indexedDbKey]="indexedDbKey"
    >
      <!--
      <ng-content
        customFilterSelectors
        select="[customFilterSelectors]">
      </ng-content>

      <ng-content
        customFilterRanges
        select="[customFilterRanges]">
      </ng-content>
  -->
      <ng-container customFilterSwitchers>
        <app-switcher
          [label]="componentsTranslation.filters_showFavoriteLabel"
          [(value)]="filterOptions.favorites">
        </app-switcher>

        <ng-content
          select="[customFilterSwitchers]">
        </ng-content>
      </ng-container>
    </app-filters>
  </div>
</div>

<div class="row">
  <div class="col-sm-12 small-padding-col">
    <div class="card">
      <div class="card-header card-header-option">
        <h5 class="ml-3">
          {{viewTitle}}
        </h5>

        <div class="filter-button-view">
          <button
            type="button"
            class="btn btn-primary ripple light"
            (click)="switchView()">
            {{dataViewMode === dataViewModes.TableView
            ? componentsTranslation.filters_treeViewBtn
            : componentsTranslation.filters_listViewBtn}}
          </button>
        </div>
      </div>

      <div *ngIf="dataViewMode === dataViewModes.TreeView"
           class="row-table row-table-header">
        <div class="d-flex align-items-center mb-4">
          <app-switcher
            [label]="globalTranslation.showEmptyLocationLabel"
            [(value)]="showEmptyLocations"
            (valueChange)="changeTreeData($event)">
          </app-switcher>
        </div>

        <div class="tree-content my-0 mx-3">
          <ng-container *ngIf="dataTree">
            <app-tree
              [dataTree]="dataTree"
              [pickProcessChart]="true">
            </app-tree>
          </ng-container>
        </div>

        <app-loader
          [isLoading]="!dataTree"
          [isBackgroundLoading]="false">
        </app-loader>
      </div>

      <div *ngIf="dataViewMode === dataViewModes.TableView"
           class="row-table row-table-header">
        <ng-content
          select="[tableContent]">
        </ng-content>
      </div>
    </div>
  </div>
</div>
