<div class="fixture-container">
  <fa-icon
    class="add-fixture-button"
    [ngClass]="{'button-selected': !disabled && !!selectedFixtureId}"
    [ngbTooltip]="characteristicTranslation.fixtureAddTooltip"
    [icon]="iconPlus"
    (clickOutside)="closeFixtureList()"
    (click)="toggleFixtureList()">
  </fa-icon>

  <div *ngIf="!disabled && isFixtureListOpen"
       class="fixture-list">
    <div
      *ngFor="let fixture of fixtureData"
      class="fixture-list-item w-100"
      [ngClass]="{'is-selected': selectedFixtureId === fixture.fixtureId}"
    >
      <div class="fixture-text-with-icon"
           (click)="toggleFixture(fixture)">
        <app-responsive-text-line
          [isTooltipVisible]="true"
          [text]="getListItemText(fixture)">
        </app-responsive-text-line>
      </div>
    </div>
  </div>
</div>
