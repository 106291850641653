
import {filter} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {TranslationService} from '@services/translation.service';
import {Subscription} from 'rxjs';
import {ComponentsTranslation, GlobalTranslation} from '@models/translation';
import {VirtualKeyboardService} from '@shared/virtual-keyboard/virtual-keyboard.service';
import {TreeDataService} from '@services/tree-data.service';
import {ViewFilterOptions} from '@models/view-filter-options';
import {LocationNode} from '@models/location-node';
import {ModalBasicComponent} from '@shared/modal-basic/modal-basic.component';

@Component({
  selector: 'app-location-tree-modal',
  templateUrl: './location-tree-modal.component.html',
  styleUrls: ['./location-tree-modal.component.scss']
})
export class LocationTreeModalComponent implements OnInit, OnDestroy, OnChanges {
  private readonly subscriptions = new Subscription();

  public isModalOpen = false;

  public dataTree: LocationNode[];
  public filterOptions: ViewFilterOptions = new ViewFilterOptions();
  public globalTranslation: GlobalTranslation;
  public componentsTranslation: ComponentsTranslation;

  @Input() userAccessBlocked = false;
  @Input() treeDataService: TreeDataService;
  @Input() location: string;

  @Output() locationPathChange = new EventEmitter<string>(true);
  @Output() locationIdChange = new EventEmitter<string>();
  @Output() sdNumberChange = new EventEmitter<string>();
  @Output() departmentIdChange = new EventEmitter<string>();
  @Output() plantIdChange = new EventEmitter<string>();
  @Output() selectedLocationTreeNodeIdsChange = new EventEmitter<number[]>();

  @ViewChild(ModalBasicComponent) locationTreeModal: ModalBasicComponent;

  constructor(
    private translationService: TranslationService,
    public virtualKeyboardService: VirtualKeyboardService,
  ) {
    this.setTranslation();
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.componentsTranslation = translation.components;
      this.globalTranslation = translation.global;
    });

    this.subscriptions.add(translationSubscription);
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.treeDataService) {
      this.treeDataService.initTree('location').then(() => {
        this.treeDataService.setFilter(this.filterOptions);
        this.subscribeDataTree();
      });

    }
  }

  private subscribeDataTree(): void {
    const filteredTreeSubscription = this.treeDataService.filteredTree$.pipe(
      filter(data => !!data))
      .subscribe((data) => {
        this.dataTree = data;

        if (this.location) {
          this.initLocationPath();
        }
      });

    this.subscriptions.add(filteredTreeSubscription);
  }

  private initLocationPath(): void {
    const locationPath = TreeDataService.getLocationPath(this.dataTree, this.location);
    this.locationPathChange.emit(locationPath);
  }

  public openModal(): void {
    this.isModalOpen = true;
    this.locationTreeModal.show();
  }

  public closeModal(): void {
    this.virtualKeyboardService.turnOffKeyboard();
    this.locationTreeModal.hide();
    this.isModalOpen = false;
    this.clearSearchData();
  }

  public clearSearchData(): void {
    this.filterOptions.filterQuery = '';
    this.applyFilter();
  }

  public applyFilter(): void {
    this.treeDataService.setFilter(this.filterOptions);
  }

  public onLocationPathChange(locationPath: string): void {
    this.closeModal();
    this.locationPathChange.emit(locationPath);
  }

  public onLocationIdChange(locationId: string): void {
    this.locationIdChange.emit(locationId);
  }

  public onSdNumberChange(sdNumber: string): void {
    this.sdNumberChange.emit(sdNumber);
  }

  public onDepartmentIdChange(departmentId: string): void {
    this.departmentIdChange.emit(departmentId);
  }

  public onPlantIdChange(plantId: string): void {
    this.plantIdChange.emit(plantId);
  }
}
