<tree class="tree-main"
      #treeComponent
      [tree]="tree"
      [settings]="settings"
      (nodeSelected)="onNodeSelected($event)">
  <ng-template let-node>
    <ng-container *ngIf="parseLocationTreeModel(node).icon">
      <fa-icon style="font-size: 16px; padding: 0 3px;"
               [ngStyle]="parseLocationTreeModel(node).isSelectable && {'cursor': 'pointer'}"
               [icon]="parseLocationTreeModel(node).icon">
      </fa-icon>
    </ng-container>
    <span [innerHTML]="parseLocationTreeModel(node).value"
          [ngStyle]="parseLocationTreeModel(node).isSelectable && {'cursor': 'pointer'}">
    </span>
  </ng-template>
</tree>
