import { Injectable } from '@angular/core';
import {Plant} from '@models/plant';
import {Department} from '@models/department';
import {BehaviorSubject} from 'rxjs';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {ViewFilterOptions} from '@models/view-filter-options';
import {TableFilterApiService} from '@shared/table-filter-api.service';
import {SwitcherComponent} from '@shared/switcher/switcher.component';
import {FilterSelectorComponent} from '@shared/filters/filter-selector/filter-selector.component';

@Injectable()
export class TabFilterService {
  private dataLoaded: boolean;
  private copiedDataId: number;

  public readonly filtersChanged = new BehaviorSubject<ViewFilterOptions>(null);
  public readonly customFiltersInitialized = new BehaviorSubject<FilterSelectorComponent[]>([]);
  public readonly customSwitchersInitialized = new BehaviorSubject<SwitcherComponent[]>([]);
  public readonly filterOptionsChanged = new BehaviorSubject<{plants: Plant[], departments: Department[]}>(null);

  public plants: Plant[] = [];
  public departments: Department[] = [];

  constructor(
    private tabFilterResultApiService: TableFilterApiService,
  ) {
  }

  public getFiltersOptions(): void {
    if (!this.dataLoaded) {
      this.dataLoaded = true;
      forkJoin<Plant[], Department[]>([
        this.tabFilterResultApiService.getPlants(),
        this.tabFilterResultApiService.getDepartments(),
      ])
        .toPromise()
        .then(data => {
          this.plants = data[0] || [];
          this.departments = data[1] || [];
          this.notifyFilterOptionsChanged();
        });
    }
  }

  private notifyFilterOptionsChanged(): void {
    this.filterOptionsChanged.next({
      plants: this.plants,
      departments: this.departments
    });
  }

  public hasCopiedId(): boolean {
    return !!this.copiedDataId;
  }

  public setCopiedDataId(dataId: number): void {
    this.copiedDataId = dataId;
  }

  public getCopiedDataId(): number {
    const copiedDataId = this.copiedDataId;
    this.copiedDataId = null;

    return copiedDataId;
  }
}
