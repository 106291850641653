import {VirtualKeyboardService} from '@shared/virtual-keyboard/virtual-keyboard.service';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ModalBasicComponent} from '@shared/modal-basic/modal-basic.component';
import {NgForm} from '@angular/forms';
import {AttributeSampleValue, HelpersService} from '@services/helpers.service';
import {TranslationService} from '@services/translation.service';
import {ComponentsTranslation, GlobalTranslation} from '@models/translation';
import {User} from '@models/user';
import {UserService} from '@user/user.service';
import {LabeledValue} from '@common/global';
import {AddSamplesApiService} from '@shared/add-samples-api.service';
import {Subscription} from 'rxjs';
import {PartNumber} from '@models/part-number';
import {LocationTreeModalComponent} from '@shared/location-tree-modal/location-tree-modal.component';
import {CharacteristicTreeService} from '@characteristic/characteristic-tree.service';
import {AdministrationApiService} from '@administration/administration-api.service';
import {PartMapping} from '@models/part-mapping';


@Component({
  selector: 'app-add-part-mapping-modal',
  templateUrl: './add-part-mapping-modal.component.html',
  styleUrls: ['./add-part-mapping-modal.component.scss'],
  providers: [HelpersService]
})
export class AddPartMappingModalComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();
  public componentsTranslation: ComponentsTranslation;
  public globalTranslation: GlobalTranslation;
  public loggedUser: User;
  public parts: PartNumber[] = [];
  public cmmMachineOptions = [];
  public selectedCmmMachines = [];
  public partMapping: PartMapping = new PartMapping();
  public partMappingSubmitEnabled = false;

  @Input() partMappingForm: NgForm;

  @Output() public refreshData = new EventEmitter();
  @Output() public setClose = new EventEmitter();

  @ViewChild('partMappingModal') modal: ModalBasicComponent;
  @ViewChild('partMappingForm') currentForm: NgForm;
  @ViewChild(LocationTreeModalComponent) locationTreeModalComponent: LocationTreeModalComponent;

  constructor(
    private addSamplesApiService: AddSamplesApiService,
    private administrationApiService: AdministrationApiService,
    private helperService: HelpersService,
    private translationService: TranslationService,
    public virtualKeyboardService: VirtualKeyboardService,
    public characteristicTreeService: CharacteristicTreeService,
  ) {
    this.setTranslation();
    this.loggedUser = UserService.getLoggedUser();
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.componentsTranslation = translation.components;
      this.globalTranslation = translation.global;
    });

    this.subscriptions.add(translationSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnInit(): void {
    this.modal.show();
    this.administrationApiService.getCmmMachineList().then(cmmMachineList => {
      this.cmmMachineOptions = cmmMachineList;
    });
  }

  closeModal(): void {
    this.setClose.emit();
    this.refreshData.emit();
    this.modal.hide();
  }

  public onSelectLocationClick(): void {
    this.locationTreeModalComponent.openModal();
  }

  onSdNumberChange(sdNumber: string): void {
    this.partMapping.sdNumber = sdNumber;
    this.checkSubmit();
  }

  onLocationPathChange(locationPath: string): void {
    this.partMapping.locationPath = locationPath;
    this.checkSubmit();
  }

  onLocationIdChange(locationId: string): void {
    this.partMapping.uid = locationId;
    this.checkSubmit();
  }

  onSelectedCmmMachinesClear(): void {
    this.selectedCmmMachines = [];
    this.checkSubmit();
  }

  onSelectedCmmMachinesChange(): void {
    this.checkSubmit();
  }

  checkSubmit(): void {
    console.log('Machines: ', this.selectedCmmMachines);
    console.log('SDNumber: ', this.partMapping.sdNumber);
    console.log('Uid: ', this.partMapping.uid);
    console.log('Path: ', this.partMapping.locationPath);
    if (this.selectedCmmMachines.length > 0 && this.partMapping.sdNumber != null) {
      this.partMappingSubmitEnabled = true;
    } else {
      this.partMappingSubmitEnabled = false;
    }
  }

  onSubmit(value: any, valid: boolean): void {
    this.partMapping.machineTYList = this.selectedCmmMachines;
    console.log(this.partMapping);
    this.administrationApiService.postPartMapping(this.partMapping).then(idReturn => {
      console.log(idReturn);
      this.refreshData.emit();
    });
    this.closeModal();
  }
}
