<div class="row">
  <div class="col-md-3">
    <button
      id="selectLocationButton"
      class="btn btn-primary m-b-0"
      type="button"
      [ngClass]="{'disabled cursor-disabled': locationPickDisabled}"
      [disabled]="locationPickDisabled"
      (click)="onSelectLocationClick()"
    >
      {{locationPickName}}
    </button>
  </div>

  <div class="col-md-9 mt-1">
    <app-vk-input
      type="text"
      name="locationPath"
      [required]="true"
      [readonly]="true"
      [ngModel]="locationPath">
    </app-vk-input>

    <div *ngIf="form.submitted && !locationPath"
         class="messages text-danger">
      {{globalTranslation.isRequired}}
    </div>
  </div>
</div>
