
import {filter} from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {CustomTableColumn} from '@common/types/custom-table-column';
import {User} from '@models/user';
import {
  faChartBar,
  faCopy,
  faEdit,
  faFileAlt,
  faHeart,
  faHeartBroken,
  faInfoCircle,
  faTimes,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import {GlobalVariable} from '@common/global';
import {TreeDataService} from '@services/tree-data.service';
import {ColumnMode, DatatableComponent, SortDirection, SortPropDir, TableColumn} from '@swimlane/ngx-datatable';
import {Router} from '@angular/router';
import {TableRowModel} from '@models/table-row-model';
import {Characteristic} from '@models/characteristic';
import {ComponentsTranslation, GlobalTranslation} from '@models/translation';
import {TranslationService} from '@services/translation.service';
import {HelpersService, NotifierType} from '@services/helpers.service';
import {UserService} from '@user/user.service';
import {BaseModel} from '@models/base-model';
import {PaginationOptions} from '@shared/pagination/pagination-options';
import {SortingOptions} from '@models/sorting-options';
import {DataTableSorting} from '@common/enums/data-table-sorting';
import {DataTableSortingMode} from '@common/enums/data-table-sorting-mode';
import {Subscription} from 'rxjs';
import swal from 'sweetalert2';
import {nameof} from 'ts-simple-nameof';
import {TableFilterApiService} from '@shared/table-filter-api.service';
import {TabFilterService} from '@shared/filters/tab-filter.service';

@Component({
  selector: 'app-tab-filter-result',
  templateUrl: './tab-filter-result.component.html',
  styleUrls: ['./tab-filter-result.component.scss']
})
export class TabFilterResultComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  private readonly subscriptions = new Subscription();
  private readonly languageType = GlobalVariable.LANGUAGE_TYPE;
  private readonly columnToSortTypeMap = {
    [nameof<TableRowModel>(obj => obj.dateModified)]: DataTableSorting.ByDateModified,
    [nameof<TableRowModel>(obj => obj.dateCreated)]: DataTableSorting.ByDateCreated,
    [nameof<TableRowModel>(obj => obj.name)]: DataTableSorting.ByName,
    [nameof<TableRowModel>(obj => obj.userCreatedBy)]: DataTableSorting.ByUserCreatedBy,
    [nameof<TableRowModel>(obj => obj.plant)]: DataTableSorting.ByPlant,
    [nameof<TableRowModel>(obj => obj.partNumbers)]: DataTableSorting.ByPart,
    [nameof<TableRowModel>(obj => obj.correctiveActionCount)]: DataTableSorting.ByCorrectiveActionCount,
    [nameof<TableRowModel>(obj => obj.models)]: DataTableSorting.ByModel,
    [nameof<TableRowModel>(obj => obj.userModifiedBy)]: DataTableSorting.ByUserModifiedBy
  };

  private dateModifiedColumnWidth = 150;
  private loggedUser: User;

  public readonly columnMode = ColumnMode.force;
  public readonly faCopy = faCopy;
  public readonly faEdit = faEdit;
  public readonly faFill = faFileAlt;
  public readonly faHeart = faHeart;
  public readonly faHeartBroken = faHeartBroken;
  public readonly faRemove = faTimes;
  public readonly faTrash = faTrash;
  public readonly faView = faChartBar;
  public readonly infoIcon = faInfoCircle;
  public readonly maxFavouritesValues = GlobalVariable.MAX_FAVOURITES_VALUE;
  public readonly operationTypes = GlobalVariable.OPERATION_FILTER_TYPE;
  public readonly rowsOnPage = GlobalVariable.ROWS_ON_PAGE;
  public readonly sorts: SortPropDir[] = [
    {
      prop: nameof<TableRowModel>(obj => obj.dateCreated),
      dir: SortDirection.desc
    }
  ];

  public readonly routingAddresses: Map<string, string> = new Map([
    [this.operationTypes.characteristic, GlobalVariable.ROUTE_PATHS.characteristic.root],
    [this.operationTypes.processChart, GlobalVariable.ROUTE_PATHS.processChart.root],
    [this.operationTypes.locationScreen, GlobalVariable.ROUTE_PATHS.locationScreen.root],
  ]);

  public columns: CustomTableColumn[] = [];
  public itemViewPermission: boolean;
  public itemEditPermission: boolean;
  public itemCopyPermission: boolean;
  public itemDeletePermission: boolean;
  public addSamplePermission: boolean;
  public componentsTranslation: ComponentsTranslation;
  public currentDataList: BaseModel[] = [];
  public globalTranslation: GlobalTranslation;
  public rowsFilter: TableRowModel[] = [];

  @Input() headerHeight = 50;
  @Input() customColumns: CustomTableColumn[];
  @Input() itemName = '';
  @Input() treeDataService: TreeDataService;
  @Input() tableFilterApiService: TableFilterApiService;
  @Input() operationType: string;
  @Input() itemsCount = 0;
  @Input() offset = 0;
  @Input() paginationEnabled = false;

  @Output() paginationChange = new EventEmitter<PaginationOptions>();
  @Output() sortingChange = new EventEmitter<SortingOptions>();
  @Output() openCharacteristicAddSample = new EventEmitter<number>();

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('headerTemplate') headerTemplate: TemplateRef<any>;
  @ViewChild('textTemplate') textTemplate: TemplateRef<any>;
  @ViewChild('isCMMTemplate') isCMMTemplate: TemplateRef<any>;
  @ViewChild('dateTemplate') dateTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;
  @ViewChild('nameTemplate') nameTemplate: TemplateRef<any>;
  @ViewChild('namePathTemplate') namePathTemplate: TemplateRef<any>;
  @ViewChild('partNumbersTemplate') partNumbersTemplate: TemplateRef<any>;
  @ViewChild('partModelsTemplate') partModelsTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private helpersService: HelpersService,
    private translationService: TranslationService,
    private tabFilterService: TabFilterService,
    private cdr: ChangeDetectorRef
  ) {
    this.setTranslation();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['customColumns'] && this.customColumns) {
      console.log('customColumns', this.customColumns);
      this.setColumns();
    }
  }

  private setTranslation(): void {
    const translationSubscription = this.translationService.translations$.subscribe(translation => {
      this.componentsTranslation = translation.components;
      this.globalTranslation = translation.global;

      this.setColumnWidth(translation.type);
      this.setColumns();
    });

    this.subscriptions.add(translationSubscription);
  }

  private subscribeFilterChange(): void {
    const filterChangeSubscription = this.tabFilterService.filtersChanged.subscribe(() => {
      this.table.offset = 0;
    });

    this.subscriptions.add(filterChangeSubscription);
  }

  public ngOnInit(): void {
    this.loggedUser = UserService.getLoggedUser();
    this.getPermissions();

  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngAfterViewInit(): void {
    this.setColumns();
    this.subscribeFilterChange();
    this.loadData();
    this.cdr.detectChanges();
  }

  public onPaginationChange(paginationOptions: PaginationOptions): void {
    const options = paginationOptions.itemsPerPage !== this.itemsCount
      ? paginationOptions
      : null;
    this.paginationChange.emit(options);
  }

  private getPermissions(): void {
    let prefix;

    switch ( this.operationType) {
      case this.operationTypes.characteristic: { prefix = 'characteristic'; } break;
      case this.operationTypes.processChart: { prefix = 'processChart'; } break;
      case this.operationTypes.locationScreen: { prefix = 'locationScreen'; } break;
      case defaultStatus: { prefix = 'characteristic'; } break;
    }

    this.itemViewPermission = HelpersService.hasUserPermission(this.loggedUser, prefix + '.view');
    this.itemEditPermission = HelpersService.hasUserPermission(this.loggedUser, prefix + '.edit');
    this.itemCopyPermission = HelpersService.hasUserPermission(this.loggedUser, prefix + '.copy');
    this.itemDeletePermission = HelpersService.hasUserPermission(this.loggedUser, prefix + '.delete');
    this.addSamplePermission = HelpersService.hasUserPermission(this.loggedUser, 'sample.add');
  }

  private loadData(): void {
    const filteredDataSubscription = this.treeDataService.filteredList$.pipe(
      filter(data => !!data))
      .subscribe(dataList => {
        this.currentDataList = this.treeDataService.dataList;
        this.itemsCount = this.paginationEnabled
          ? this.itemsCount
          : this.currentDataList.length;
        this.prepareRows(dataList);
      });

    this.subscriptions.add(filteredDataSubscription);
  }

  private prepareRows(list: BaseModel[]): void {
    const rowsFilter: TableRowModel[] = [];

    list.forEach(model => {
      let data: TableRowModel = {
        ...this.createTableRow(model),
      };

      if (this.operationType === this.operationTypes.characteristic) {
        const characteristic = model as Characteristic;
        data = {
          ...data,
          plant: characteristic.plant,
          models: characteristic.models as any,
          partNumbers: characteristic.partNumbers as any,
          multipart: characteristic.isMultipart,
          multimodel: characteristic.isMultimodel,
          correctiveActionCount: characteristic.correctiveActionCount
        };
      }

      rowsFilter.push(data);
    });

    this.rowsFilter = rowsFilter;
  }

  private createTableRow(data: BaseModel): TableRowModel {
    return {
      id: data.id,
      favorite: data.favorite,
      path: data.path,
      dateModified: data.dateModified,
      dateCreated: data.dateCreated,
      userModifiedBy: data.userModifiedBy,
      userCreatedBy: data.userCreatedBy,
      name: data.name,
      enabled: data.enabled,
      isCMM: data.isCMM,
      routerAddress: this.routingAddresses.get(this.operationType),
    };
  }

  private setColumnWidth(languageType): void {
    if (languageType === this.languageType.polish) {
      this.dateModifiedColumnWidth = 220;
    } else if (languageType === this.languageType.spanish) {
      this.dateModifiedColumnWidth = 190;
    } else {
      this.dateModifiedColumnWidth = 150;
    }
  }

  private setColumns(): void {
    if (this.customColumns) {
      this.columns = this.customColumns;
      return;
    }

    this.columns = [
      {
        prop: nameof<TableRowModel>(obj => obj.name),
        name: `${this.componentsTranslation.tableResult_tableName} / ${this.itemName} ${this.componentsTranslation.tableResult_tablePath}`,
        cellTemplate: this.namePathTemplate
      },
      {
        prop: nameof<TableRowModel>(obj => obj.dateModified),
        name: `${ this.componentsTranslation.tableResult_tableLastModified}`,
        cellTemplate: this.dateTemplate,
        width: this.dateModifiedColumnWidth,
        maxWidth: this.dateModifiedColumnWidth
      },
      {
        prop: nameof<TableRowModel>(obj => obj.userCreatedBy),
        name: `${this.componentsTranslation.tableResult_tableCreator}`,
        cellTemplate: this.textTemplate,
        width: 200,
        maxWidth: 200
      },
      {
        name: `${this.componentsTranslation.tableResult_tableActions}`,
        cellTemplate: this.actionsTemplate,
        width: 180,
        maxWidth: 180,
        sortable: false
      }
    ];

    if (this.operationType === this.operationTypes.characteristic) {
      const partNumbersColumn = {
        prop: nameof<TableRowModel>(obj => obj.partNumbers),
        name: `${this.componentsTranslation.tableResult_tablePart}`,
        width: 100,
        maxWidth: 100,
        sortable: false,
        cellTemplate: this.partNumbersTemplate
      };

      this.columns.splice(1, 0, partNumbersColumn);
    }
  }

  private shotNotificationMaxFavourite(dataType: string, maxValue: number): void {
    const maxFavouriteMessage = `${this.componentsTranslation.tableResult_maxFavoriteValidationPartOne}
    ${maxValue} ${this.componentsTranslation.tableResult_maxFavoriteValidationPartTwo} ${dataType}`;

    this.helpersService.notificationTrigger(NotifierType.Warn, maxFavouriteMessage);
  }

  public copy(dataId: number): void {
    this.tabFilterService.setCopiedDataId(dataId);
    const routingAddress = this.routingAddresses.get(this.operationType);
    this.router.navigate([`/${routingAddress}/create`]).then();
  }

  public onResized(): void {
    this.rowsFilter = [...this.rowsFilter];
  }

  public openAddSample(dataId: number): void {
    if (!this.addSamplePermission) {

      this.helpersService.notificationTrigger(NotifierType.Warn, this.componentsTranslation.tableResult_noPermissionToAddSamplesMsg);

      return;
    }

    const chosenData = this.currentDataList.find((data: BaseModel) => data.id === dataId);

    if (chosenData) {
      const isDataEnabled = this.operationType === this.operationTypes.characteristic
        ? (chosenData as Characteristic).enabled
        : chosenData.enabled;

      if (!isDataEnabled) {
        this.helpersService.notificationTrigger(
          NotifierType.Warn,
          this.componentsTranslation.tableResult_cantDisabledCharacteristicMsg
        );

        return;
      }

      this.openCharacteristicAddSample.emit(dataId);
    }
  }

  public deleteItem(id: number): void {
    const alertOptions = HelpersService.createAlertOptions(
      this.globalTranslation.removeMsgTitle,
      this.componentsTranslation.tableResult_moveToRecycleBinMsg,
      this.globalTranslation.confirmDelete,
      'warning'
    );

    swal(alertOptions).then((result) => {
      if (result.value) {
        this.tableFilterApiService.hideModel(id).then(() => {
          this.treeDataService.deleteDataFromList(id);
          this.deleteItemFromRow(id);
        });
      }
    });
  }

  private deleteItemFromRow(id: number): void {
    const index = this.rowsFilter.findIndex(row => row.id === id);
    this.rowsFilter.splice(index, 1);
    this.rowsFilter = [...this.rowsFilter];
  }

  public removeFromFavorites(id: number): void {
    this.removeItemFromFavorites(id);
  }

  private removeItemFromFavorites(id: number): void {
    this.tableFilterApiService.removeModelFromFavorite(this.loggedUser.id, id).then(() => {
      this.changeFavouriteInRow(id, false);
      this.treeDataService.refreshFavoriteData(id, false);
    });

    const dataItem = this.currentDataList.find(data => data.id === id);
    dataItem.favorite = false;
  }

  public addToFavorites(id: number): void {
    this.addFavoriteItem(id);
  }

  private addFavoriteItem(id: number): void {
    let maxFavoriteValue: number;
    let validateTypeNotificationMessage: string;

    switch (this.operationType) {
      case this.operationTypes.characteristic:
        maxFavoriteValue = this.maxFavouritesValues.characteristic;
        validateTypeNotificationMessage = this.componentsTranslation.tableResult_typeToValidationMaxFavoriteCharacteristics;
        break;
      case this.operationTypes.processChart:
        maxFavoriteValue = this.maxFavouritesValues.processChart;
        validateTypeNotificationMessage = this.componentsTranslation.tableResult_typeToValidationMaxFavoriteProcessChart;
        break;
      case this.operationTypes.locationScreen:
        maxFavoriteValue = this.maxFavouritesValues.locationScreen;
        validateTypeNotificationMessage = this.componentsTranslation.tableResult_typeToValidationMaxFavoriteLocationScreen;
        break;
    }

    const favoriteNumber = this.countFavouriteItems(this.currentDataList);

    if (favoriteNumber < maxFavoriteValue) {
      const dataItem = this.currentDataList.find(data => data.id === id);
      dataItem.favorite = true;

      this.tableFilterApiService.addModelToFavorite(this.loggedUser.id, id).then(() => {
        this.changeFavouriteInRow(id, true);
        this.treeDataService.refreshFavoriteData(id, true);
      });
    } else {
      this.shotNotificationMaxFavourite(validateTypeNotificationMessage, maxFavoriteValue);
    }
  }

  private countFavouriteItems(list: BaseModel[]): number {
    return list.filter(element => element.favorite).length;
  }

  private changeFavouriteInRow(id: number, value: boolean): void {
    const selectedRowId = this.rowsFilter.findIndex(row => row.id === id);
    this.rowsFilter[selectedRowId].favorite = value;
  }

  public onSortingChange(event: { column: TableColumn, newValue: 'asc' | 'desc' }): void {
    const sortingOptions: SortingOptions = {
      sortingType: this.columnToSortTypeMap[event.column.prop],
      sortingMode: event.newValue === 'asc'
        ? DataTableSortingMode.Ascending
        : DataTableSortingMode.Descending
    };

    this.sortingChange.emit(sortingOptions);
  }

  public tableRowModel(row: TableRowModel): TableRowModel {
    return row;
  }

  public tableColumn(column: CustomTableColumn): CustomTableColumn {
    return column;
  }
}
